import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../../config/constants";


// Action Types for deleteShipmentPiece
const DELETESHIPMENTPIECE_REQUEST = "DELETESHIPMENTPIECE_REQUEST";
const DELETESHIPMENTPIECE_SUCCESS = "DELETESHIPMENTPIECE_SUCCESS";
const DELETESHIPMENTPIECE_FAILURE = "DELETESHIPMENTPIECE_FAILURE";

// Action Creators for deleteShipmentPiece
export const deleteShipmentPieceRequest = () => ({
  type: DELETESHIPMENTPIECE_REQUEST,
});

export const deleteShipmentPieceSuccess = () => ({
  type: DELETESHIPMENTPIECE_SUCCESS,
});

export const deleteShipmentPieceFailure = (error: string) => ({
  type: DELETESHIPMENTPIECE_FAILURE,
  payload: error,
});

// Initial State for deleteShipmentPiece
export interface DeleteShipmentPieceState {
  isAuthenticated: boolean;
  deleteShipmentPieceLoading: boolean;
  deleteShipmentPieceError: string | null;
}

const initialAuthState: DeleteShipmentPieceState = {
  isAuthenticated: false,
  deleteShipmentPieceLoading: false,
  deleteShipmentPieceError: null,
};

// Define the interface for the API response


// Reducer for Authentication
const deleteShipmentPieceReducer = (
  state = initialAuthState,
  action: any
): DeleteShipmentPieceState => {
  switch (action.type) {
    case DELETESHIPMENTPIECE_REQUEST:
      return { ...state, deleteShipmentPieceLoading: true, deleteShipmentPieceError: null };
    case DELETESHIPMENTPIECE_SUCCESS:
      return { ...state, deleteShipmentPieceLoading: false, isAuthenticated: true };
    case DELETESHIPMENTPIECE_FAILURE:
      return { ...state, deleteShipmentPieceLoading: false, deleteShipmentPieceError: action.payload };
    default:
      return state;
  }
};

// Thunk for deleteShipmentPiece
export const deleteShipmentPiece =
  (param: {
    shipmentPieceID:string
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(deleteShipmentPieceRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/DeleteShipmentPiece`,
        param
      );
      dispatch(deleteShipmentPieceSuccess());
      const data:any = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(deleteShipmentPieceFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(deleteShipmentPieceFailure(error.response.data.message));
      } else {
        dispatch(deleteShipmentPieceFailure(error.message));
      }
    }
  };

export default deleteShipmentPieceReducer;
