import React, { useState, FC, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  ButtonGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Grid,
  useMediaQuery,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import { UnDetailsItemDto } from "../../../models/long-form-item-dto";
import { handleDecimalValue } from "../../util/numeric-value";
import { MAX_COMPANY_ID } from "../../../config/constants";
import CustomDatePicker from "../CustomDatePicker";
import useSearchUNDetailsLogic from "../../lookup/searchUn-details/searchUNDetails.logic";
import CommodityListDialog from "../commodity-list-dialog/commodity-list-dialog";
import AddIcon from "@mui/icons-material/Add";
import { DangerousGoodsItemDto } from "../../../models/dangerous-goods-item-dto";
import useDangerousGoodsLogic from "../../../modules/track-order/track-order-get-dangerous-good.logic";
import useUpdateDangerousGoodsLogic from "../../../modules/track-order/track-order-edit-dangerous-good.logic";

// Define the prop types for the MyDialog component
interface MyDialogProps {
  open: boolean;
  handleClose: () => void;
  shipmentGUIDProp: string;
  onSuccessErrorDgMessage: (message: string) => void;
}
interface MenuStyles {
  maxHeight: string;
  maxWidth: string;
}

const EditDangerousGoods: FC<MyDialogProps> = ({
  open,
  handleClose,
  shipmentGUIDProp,
  onSuccessErrorDgMessage,
}) => {
  const { searchUNDetails } = useSearchUNDetailsLogic();
  const [isDangerousGoods, setIsDangerousGoods] = useState(false);
  const [isRedioActive, setIsRedioActive] = useState(false);
  const [isDryIceRequire, setIsDryIceRequire] = useState(false);
  const [isGelPacksRequire, setIsGelPacksRequire] = useState(false);
  const [dangerousGoodsData, setDangerousGoodsData] =
    useState<DangerousGoodsItemDto>({});
  const [openUnNumberDialog, setOpenUnNumberDialog] = useState(false);
  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
    maxWidth: "48%",
  });

  const isXs = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed
  const [unError, setUNError] = useState<boolean>(false);
  const { getDangerousGoods } = useDangerousGoodsLogic();
  const { updateDangerousGoods } = useUpdateDangerousGoodsLogic();
  const [dgtransportationlimit, setDgtransportationlimit] = useState("");

  useEffect(() => {
    let isMounted = true; // To track if the component is mounted
    setUNError(false);
    if (shipmentGUIDProp && open) {
      getDangerousGoodsData().catch(() => {
        if (isMounted) {
          setDangerousGoodsData({}); // Fallback in case of an error
        }
      });
    }
    return () => {
      isMounted = false; // Cleanup function
    };
  }, [open]);

  useEffect(() => {
    if (isXs) {
      setMenuStyles((prevStyles) => ({
        ...prevStyles,
        maxWidth: "80%",
      }));
    } else {
      setMenuStyles((prevStyles) => ({
        ...prevStyles,
        maxWidth: "48%",
      }));
    }
  }, [isXs]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, type, value, checked } = e.target as HTMLInputElement;
    setDangerousGoodsData((prev: any) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (name == "dgtransportationlimit") {
      setDgtransportationlimit(value);
    }
  };

  const handleSave = async () => {
    try {
      const { dgDryIceWeight, dgGelPackCount } = dangerousGoodsData;
      dangerousGoodsData.dgDryIceWeight =
        dgDryIceWeight > 0 ? dgDryIceWeight : null;
      dangerousGoodsData.dgGelPackCount = dgGelPackCount || undefined;

      const response = await updateDangerousGoods(dangerousGoodsData);
      const res = response?.data;

      if (res?.data === "SUCCESS" && res?.success) {
        onSuccessErrorDgMessage("SUCCESS");
      } else {
        onSuccessErrorDgMessage(res?.message || response?.data?.message);
      }
      handleClose();
    } catch (err: any) {
      onSuccessErrorDgMessage(err?.message);
      handleClose();
    }
  };

  const handleDialogClose = () => {
    handleClose();
    setDangerousGoodsData({});
    setDgtransportationlimit("");
  };

  const handleUnNumberKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && dangerousGoodsData?.dgunnumber) {
      try {
        const res = await checkUNDetails();
        if (res) {
          setDangerousGoodsData((prevState: any) => ({
            ...prevState,
            dgpropername: res.properName,
            dgcommodityclass: res.commodityClass,
            isDangerousGoods: res.isDangerousGoods,
            dgisradioactive: res.isRadioactive,
          }));
          setIsDangerousGoods(res.isDangerousGoods);
          setIsRedioActive(res.isRadioactive);
        }
      } catch (error) {}
    }
  };

  const handleUNNumberFocusOut = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    try {
      if (dangerousGoodsData?.dgunnumber) {
        const res = await checkUNDetails();
        if (res) {
          setDangerousGoodsData((prevState: any) => ({
            ...prevState,
            dgpropername: res.properName,
            dgcommodityclass: res.commodityClass,
            isDangerousGoods: res.isDangerousGoods,
            dgisradioactive: res.isRadioactive,
          }));
          setIsDangerousGoods(res.isDangerousGoods);
          setIsRedioActive(res.isRadioactive);
        }
      }
    } catch (error) {}
  };

  const checkUNDetails = async () => {
    try {
      setUNError(false);
      const param = {
        companyID: MAX_COMPANY_ID,
        unNumber: dangerousGoodsData?.dgunnumber,
      };
      const response = await searchUNDetails(param as any);
      if (response.data && response.data.data) {
        const res = response.data.data;
        if (res.length > 0) {
          setDangerousGoodsData((prevState: any) => ({
            ...prevState,
            dgpropername: res[0].properName,
            dgcommodityclass: res[0].commodityClass,
            isDangerousGoods: res[0].isDangerousGoods,
            dgisradioactive: res[0].isRadioactive,
          }));
          return res[0];
        } else {
          setUNError(true);
        }
      }
    } catch (err) {}
  };

  const handleUnNumberDialogOpen = () => {
    setOpenUnNumberDialog(true);
  };
  const handleUnNumberDialogClose = () => {
    setOpenUnNumberDialog(false);
  };

  const onSelectCommodity = (commodity: UnDetailsItemDto) => {
    setDangerousGoodsData((prevState: any) => ({
      ...prevState,
      dgunnumber: commodity.unNumber ? commodity.unNumber : "",
      dgpropername: commodity.properName ? commodity.properName : "",
      dgcommodityclass: commodity.commodityClass
        ? commodity.commodityClass
        : "",
      isDangerousGoods: commodity.isDangerousGoods,
      dgisradioactive: commodity.isRadioactive,
    }));
    setIsDangerousGoods(commodity.isDangerousGoods);
    setIsRedioActive(commodity.isRadioactive);
  };

  const getDangerousGoodsData = async () => {
    try {
      const param = { shipmentGUID: shipmentGUIDProp };
      const response = await getDangerousGoods(param);
      const res = response?.data?.data || {};
      setDangerousGoodsData(res);
      setIsDangerousGoods(res.isDangerousGoods || false);
      setIsRedioActive(res.dgisradioactive || false);
      setIsDryIceRequire(!!(res.carriertoprovidedryice || res.dgDryIceWeight));
      setIsGelPacksRequire(
        !!(res.carrierToProvideGelPack || res.dgGelPackCount)
      );
      setDgtransportationlimit(res?.dgtransportationlimit);
    } catch (err) {
      setDangerousGoodsData({});
    }
  };

  return (
    <Dialog
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      style={{ height: "100%" }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className="Popup-header p16" id="responsive-dialog-title">
        Edit Dangerous Goods/Radioactive
      </DialogTitle>
      {unError && (
        <Alert variant="filled" severity="error" id="txtEdgError">
          <div>Invalid UN Number entered.</div>
        </Alert>
      )}
      <DialogContent className="p16">
        <>
          <Box className="container padding0">
            <Box className="Divider pb-24">
              <Box className="d-flex gap-16">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="font14 weight-500">
                    Does the shipment contain dangerous goods?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      id="btnWcEditNewPieceIsDangerousGoodsYes"
                      variant={isDangerousGoods ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      size="small"
                      onClick={() => {
                        setIsDangerousGoods(true);
                        setDangerousGoodsData((prev: any) => ({
                          ...prev,
                          isDangerousGoods: true,
                        }));
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      id="btnWcEditNewPieceIsDangerousGoodsNo"
                      variant={!isDangerousGoods ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      size="small"
                      onClick={() => {
                        setIsDangerousGoods(false);
                        setDangerousGoodsData((prev: any) => ({
                          ...prev,
                          isDangerousGoods: false,
                        }));
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Box>
              <Box className="d-flex gap-16 w-100 pt-24 mb-24">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="font14 weight-500">
                    Does the shipment contain radioactive material?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      id="btnEditNewPieceIsRedioActiveYes"
                      variant={isRedioActive ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      size="small"
                      onClick={() => {
                        setIsRedioActive(true);
                        setDangerousGoodsData((prev: any) => ({
                          ...prev,
                          dgisradioactive: true,
                        }));
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      id="btnWcEditNewPieceIsRedioActiveNo"
                      variant={!isRedioActive ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      size="small"
                      onClick={() => {
                        setIsRedioActive(false);
                        setDangerousGoodsData((prev: any) => ({
                          ...prev,
                          dgisradioactive: false,
                        }));
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Box>

              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label="UN #"
                    variant="outlined"
                    name="dgunnumber"
                    id="txtUnNumber"
                    size="small"
                    value={dangerousGoodsData?.dgunnumber}
                    InputLabelProps={{
                      shrink: !!dangerousGoodsData?.dgunnumber,
                    }}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 10 }}
                    onKeyDown={handleUnNumberKeyDown}
                    onBlur={handleUNNumberFocusOut}
                    className="w-100"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleUnNumberDialogOpen}
                            id="btnDgAdd"
                          >
                            <AddIcon />
                          </IconButton>
                          <CommodityListDialog
                            open={openUnNumberDialog}
                            handleClose={handleUnNumberDialogClose}
                            onSelectCommodity={onSelectCommodity}
                            maxWidth={"sm"}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Name"
                    name="dgpropername"
                    id="txtWcEditNewPieceDgProperName"
                    size="small"
                    value={dangerousGoodsData?.dgpropername}
                    InputLabelProps={{
                      shrink: !!dangerousGoodsData?.dgpropername,
                    }}
                    onChange={handleInputChange}
                    variant="outlined"
                    className="w-100"
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <TextField
                    label="Class"
                    name="dgcommodityclass"
                    id="txtWcEditNewPieceDgCommodityClass"
                    size="small"
                    value={dangerousGoodsData?.dgcommodityclass}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      shrink: !!dangerousGoodsData?.dgcommodityclass,
                    }}
                    variant="outlined"
                    className="w-100"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                  <TextField
                    label="Trans IDX"
                    variant="outlined"
                    className="w-100"
                    id="txtTtransportationIndex"
                    name="dgtransportationindex"
                    size="small"
                    value={dangerousGoodsData?.dgtransportationindex}
                    InputLabelProps={{
                      shrink: !!dangerousGoodsData?.dgtransportationindex,
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={6}>
                  <FormControl className="w-100">
                    <InputLabel size="small">Limits</InputLabel>
                    <Select
                      label="Limits"
                      variant="outlined"
                      className="w-100"
                      id="ddDgTransportationLimit"
                      name="dgtransportationlimit"
                      size="small"
                      value={dgtransportationlimit}
                      onChange={handleInputChange as any}
                    >
                      <MenuItem value="P">
                        Passenger And Cargo Aircraft
                      </MenuItem>
                      <MenuItem value="C">Cargo Aircraft Only</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Box className="d-flex gap-16 w-100 pt-24">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="weight-500 font14">
                    Does the shipment require dry ice?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      variant={isDryIceRequire ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      id="btnWcEditNewPieceIsDryIceRequireYes"
                      size="small"
                      onClick={() => {
                        setIsDryIceRequire(true);
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      variant={!isDryIceRequire ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      id="btnWcEditNewPieceIsDryIceRequireNo"
                      size="small"
                      onClick={() => {
                        setIsDryIceRequire(false);
                        setDangerousGoodsData((prev: any) => ({
                          ...prev,
                          dgDryIceWeight: null,
                          carriertoprovidedryice: false,
                        }));
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                  {isDryIceRequire && (
                    <Box className="d-flex flex-column gap-2 w-100 mt-2">
                      <TextField
                        label="Amount of dry ice"
                        variant="outlined"
                        className="w-100  mt-3"
                        name="dgDryIceWeight"
                        id="txtWcEditNewPieceDgDryIceWeight"
                        size="small"
                        value={
                          dangerousGoodsData.dgDryIceWeight === null ||
                          dangerousGoodsData.dgDryIceWeight === 0
                            ? undefined
                            : dangerousGoodsData?.dgDryIceWeight
                        }
                        InputLabelProps={{
                          shrink: !!dangerousGoodsData?.dgDryIceWeight,
                        }}
                        onChange={handleInputChange}
                        onKeyDown={handleDecimalValue}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="cbWcEditNewPieceCarrierToProvideDryIce"
                            name="carriertoprovidedryice"
                            size="small"
                            checked={dangerousGoodsData?.carriertoprovidedryice}
                            onChange={handleInputChange}
                          />
                        }
                        label="LFS/MNX to provide"
                        className="p-2"
                      />
                    </Box>
                  )}
                </FormControl>
              </Box>
              <Box className="d-flex gap-16 w-100 pt-24 pb-24">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="weight-500 font14">
                    Does the shipment require gel packs?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      id="btnWcEditNewPieceIsGelPacksRequireYes"
                      variant={isGelPacksRequire ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      size="small"
                      onClick={() => {
                        setIsGelPacksRequire(true);
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      id="btnWcEditNewPieceIsGelPacksRequireNo"
                      variant={!isGelPacksRequire ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      size="small"
                      onClick={() => {
                        setIsGelPacksRequire(false);
                        setDangerousGoodsData((prev: any) => ({
                          ...prev,
                          dgGelPackCount: null,
                          carrierToProvideGelPack: false,
                        }));
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                  {isGelPacksRequire && (
                    <Box className="d-flex flex-column gap-2 w-100 mt-2">
                      <TextField
                        label="Amount of gel packs"
                        id="txtWcEditNewPieceDgGelPackCount"
                        variant="outlined"
                        className="w-100 mt-3"
                        name="dgGelPackCount"
                        size="small"
                        value={dangerousGoodsData?.dgGelPackCount}
                        InputLabelProps={{
                          shrink: !!dangerousGoodsData?.dgGelPackCount,
                        }}
                        onChange={handleInputChange}
                        onKeyDown={handleDecimalValue}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="carrierToProvideGelPack"
                            size="small"
                            checked={
                              dangerousGoodsData?.carrierToProvideGelPack
                            }
                            onChange={handleInputChange}
                            id="cbWcEditNewPieceCarrierToProvideGelPack"
                          />
                        }
                        label="LFS/MNX to provide"
                        className="p-2"
                      />
                    </Box>
                  )}
                </FormControl>
              </Box>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Packing Group"
                    name="dgPackingGroup"
                    value={dangerousGoodsData?.dgPackingGroup}
                    id="txtWcEditNewPieceDgPackingGroup"
                    size="small"
                    onChange={handleInputChange}
                    variant="outlined"
                    className="w-100"
                    InputLabelProps={{
                      shrink: !!dangerousGoodsData?.dgPackingGroup,
                    }}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Qty & Type of Packaging"
                    name="dgQuantityAndPackType"
                    id="txtWcEditNewPieceDgQuantityAndPackType"
                    size="small"
                    value={dangerousGoodsData?.dgQuantityAndPackType}
                    InputLabelProps={{
                      shrink: !!dangerousGoodsData?.dgQuantityAndPackType,
                    }}
                    onChange={handleInputChange}
                    variant="outlined"
                    className="w-100"
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    multiline
                    rows={4}
                    name="dgPackingInst"
                    id="txtWcEditNewPieceDgPackingInstr"
                    label="Packing Instructions"
                    size="small"
                    value={dangerousGoodsData?.dgPackingInst}
                    InputLabelProps={{
                      shrink: !!dangerousGoodsData?.dgPackingInst,
                    }}
                    onChange={handleInputChange}
                    variant="outlined"
                    placeholder="Enter any additional Packing instructions"
                    className="w-100"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    fullWidth
                    name="dgAuthorization"
                    label="Authorization"
                    size="small"
                    value={dangerousGoodsData?.dgAuthorization}
                    InputLabelProps={{
                      shrink: !!dangerousGoodsData?.dgAuthorization,
                    }}
                    onChange={handleInputChange}
                    variant="outlined"
                    id="txtWcEditNewPieceDgAuthorization"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Emergency Phone #"
                    className="w-100"
                    name="dgEmergencyPhone"
                    id="txtDgEmergencyPhone"
                    size="small"
                    value={dangerousGoodsData?.dgEmergencyPhone}
                    InputLabelProps={{
                      shrink: !!dangerousGoodsData?.dgEmergencyPhone,
                    }}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 25 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Title Signatory"
                    className="w-100"
                    name="dgSignatory"
                    id="txtDgSignatory"
                    size="small"
                    value={dangerousGoodsData?.dgSignatory}
                    InputLabelProps={{
                      shrink: !!dangerousGoodsData?.dgEmergencyPhone,
                    }}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 80 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <CustomDatePicker
                    handleDateChange={handleInputChange as any}
                    label="Date"
                    name="dgDate"
                    id="txtDgDate"
                    value={dangerousGoodsData?.dgDate}
                    size="small"
                    isClearable={true}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      </DialogContent>
      <DialogActions className={`d-flex w-100 ${"justify-content-end"}`}>
        <Box className="d-flex gap-4">
          <Button
            onClick={handleDialogClose}
            className="MuiButton Action-btn"
            variant="text"
            id="btnWcEditNewPieceCancel"
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSave();
            }}
            className="MuiButton Action-btn"
            id="btnWcEditNewPieceSave"
          >
            SAVE
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditDangerousGoods;
