import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import EnhancedTableHead from "../../shared/components/table-head-cell/enhanced-table-head";
import { sortAndUpdateTheData } from "../../shared/util/rate-estimate";
import useHealthCareDonorLogic from "../healthcare-donor/healthcare-donor.logic";
import { HealthCareDonorItemsDto } from "../../models/healthcare-donor-item-dto";
import AuthHeader from "../../shared/components/AuthHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../shared/util/date-utils";
import Loader from "../../shared/util/loader";
type Order = "desc" | "asc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof HealthCareDonorItemsDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

const headCells: HeadCell[] = [
  {
    id: "shipmentNumber",
    numeric: true,
    disablePadding: false,
    label: "ORDER #",
    minwidth: "84",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "DATE",
    minwidth: "120",
  },
  {
    id: "customerOrder",
    numeric: true,
    disablePadding: false,
    label: "CUSTOMER ORDER #",
    minwidth: "180",
  },
  {
    id: "origin",
    numeric: true,
    disablePadding: false,
    label: "ORIGIN",
    minwidth: "250",
  },
  {
    id: "destination",
    numeric: true,
    disablePadding: false,
    label: "DESTINATION",
    minwidth: "250",
  },
];

const HealthcareDonor = () => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof HealthCareDonorItemsDto>("shipmentNumber");
  const { healthCareDonor } = useHealthCareDonorLogic();
  const [healthCareDonorIDData, setHealthCareDonorIDData] = useState<
    HealthCareDonorItemsDto[]
  >([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const userDetails = localStorage.getItem("userDetails");

  // interface servicestyle {
  //   maxHeight: string;
  // }

  // const [servicestyle, setservicestyle] = useState<servicestyle>({
  //   maxHeight: "370px", // Default max height for desktop
  // });
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMediaQuery("(max-width:599px)");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof HealthCareDonorItemsDto
  ) => {
    const isAsc = orderBy === property && order === "desc";
    const newOrder = !isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  useEffect(() => {
    const _data = sortAndUpdateTheData(healthCareDonorIDData, order, orderBy);
    setHealthCareDonorIDData(_data);
  }, [order, orderBy]);

  useEffect(() => {
    getDonorIdData();
  }, [id]);

  const getDonorIdData = async () => {
    try {
      const params = {
        donorId: id,
        orderBy: orderBy,
        sorting: order,
      };
      setIsLoading(true);
      const response = await healthCareDonor(params);
      if (response.data && response.data.data) {
        const dataCount = response.data.data.length;
        setIsLoading(false);
        // Check if there is only one record
        if (dataCount === 1) {
          navigate(
            `/public/track-order?TrackingNumber=${response.data.data[0].shipmentNumber}`
          );
        } else {
          // Process and update the data if there are multiple records
          const _data = sortAndUpdateTheData(
            response.data.data,
            order,
            orderBy
          );
          setHealthCareDonorIDData(_data);
        }
      }
    } catch (err) {
      setHealthCareDonorIDData([]);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AuthHeader />
        <Box component="main" className="w-100 Main-container">
          {!userDetails && <Toolbar className="login-header" />}
          <div className={"active-order "}>
            <>
              <>
                <Box
                  className="Header-main pt-16"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  id="hcDITitle"
                >
                  <Typography
                    className="Main-Header "
                    variant="h4"
                    color={"primary"}
                  >
                    Donor ID #{id}
                  </Typography>
                </Box>
              </>

              <Box className="mt-24" sx={{ width: "100%" }}>
                <Paper
                  className="ActiveOrder Batch-grid"
                  sx={{ width: "100%" }}
                >
                  <TableContainer className="exportdata-grid">
                    {" "}
                    {/* Add class name for styling */}
                    <Table aria-labelledby="tableTitle">
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={healthCareDonorIDData.length}
                        headCells={headCells}
                      />
                      <TableBody>
                        {isLoading ? (
                          <Box className="d-flex justify-content-center align-items-center vh-100">
                            <Loader />
                          </Box>
                        ) : healthCareDonorIDData.length === 0 ? (
                          <TableRow
                            className="Nodata_found"
                            id="hcDIDataNotFound"
                          >
                            <TableCell
                              className="No-data-section"
                              colSpan={12}
                              align="center"
                              id="tdDataNotFound"
                            >
                              No data to display
                            </TableCell>
                          </TableRow>
                        ) : (
                          healthCareDonorIDData.map(
                            (row: HealthCareDonorItemsDto, index: any) => {
                              return isMobile ? (
                                <>
                                  <MobileCardView
                                    key={row.shipmentNumber}
                                    row={row}
                                  />
                                </>
                              ) : (
                                <TableRow hover key={row.shipmentNumber}>
                                  <TableCell
                                    style={{ minWidth: 100 }}
                                    id="hcDIOrder"
                                  >
                                    <Link
                                      to={`/public/track-order?TrackingNumber=${row?.shipmentNumber}`}
                                      className="primary"
                                      id={`hcDIbtn`}
                                    >
                                      {row?.shipmentNumber}
                                    </Link>
                                  </TableCell>
                                  <TableCell
                                    style={{ minWidth: 140 }}
                                    id="hcDIDate"
                                  >
                                    {formatDate(row.date)}
                                  </TableCell>
                                  <TableCell
                                    style={{ minWidth: 110 }}
                                    id="hcDICustmerOrder"
                                  >
                                    {row?.customerOrder}
                                  </TableCell>
                                  <TableCell
                                    style={{ minWidth: 60 }}
                                    id="hcDIOrigin"
                                  >
                                    {row?.origin}
                                  </TableCell>
                                  <TableCell
                                    style={{ minWidth: 60 }}
                                    id="hcDIDestination"
                                  >
                                    {row?.destination}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* )} */}
                </Paper>
              </Box>
            </>
          </div>
        </Box>
      </Box>
    </>
  );
};

// Mobile card view component
const MobileCardView: React.FC<{
  row: HealthCareDonorItemsDto;
}> = ({ row }) => {
  return (
    <Paper
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
      className={"Card_view"}
    >
      <Typography variant="body1" className="Field_info Order-head">
        <span className="field_label width-94">ORDER</span>
        <span className="Order_id Field_value">
          <Link
            to={`/public/track-order?TrackingNumber=${row?.shipmentNumber}`}
            className="primary"
            id={`hcDIbtn`}
          >
            {row?.shipmentNumber}
          </Link>
        </span>
      </Typography>
      <Typography variant="body1" className="Field_info" id="hcDIDate">
        <span className="field_label width-94">DATE</span>{" "}
        <span className="Field_value">{formatDate(row.date)}</span>
      </Typography>

      <Typography variant="body1" className="Field_info" id="hcDICustmerOrder">
        <span className="field_label width-94">CUSTOMER ORDER #</span>{" "}
        <span className="Field_value">{row.customerOrder}</span>
      </Typography>
      <Typography variant="body1" className="Field_info" id="hcDIOrigin">
        <span className="field_label width-94">ORIGIN</span>{" "}
        <span className="Field_value detail-info">{row.origin}</span>
      </Typography>
      <Typography variant="body1" className="Field_info" id="hcDIDestination">
        <span className="field_label width-94">DESTINATION</span>{" "}
        <span className="Field_value detail-info">{row.destination}</span>
      </Typography>
    </Paper>
  );
};

export default HealthcareDonor;
