import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { ChargesItemDto } from "../../../models/track-order-item-dto";

interface ChargesProps {
  charges: ChargesItemDto[];
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof ChargesItemDto;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ChargesItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const Charges: React.FC<ChargesProps> = ({ charges }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof ChargesItemDto>("name");
  const [chargesData, setChargesData] = useState<ChargesItemDto[]>([]);
  const [notData, setNotData] = useState<any>("");

  useEffect(() => {
    if (charges.length !== 0) {
      setChargesData(charges);
    } else {
      setNotData(
        "(no charges are available, charges are typically calculated after delivery)"
      );
    }
  }, [charges]);

  const formatNumber = (num: number): string => {
    return num.toFixed(2);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] === null) {
      return -1;
    }

    if (a[orderBy] === null) {
      return 1;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof ChargesItemDto>(
    order: Order,

    orderBy: Key
  ): (a: ChargesItemDto, b: ChargesItemDto) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedChargesData = chargesData
    .slice()
    .sort(getComparator(order, orderBy));

  const totalAmount = sortedChargesData.reduce(
    (sum, row) => sum + row.amount,
    0
  );
  const totalTaxAmount = sortedChargesData.reduce(
    (sum, row) => sum + row.taxAmount,
    0
  );
  const totalNetAmount = sortedChargesData.reduce(
    (sum, row) => sum + row.netAmount,
    0
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ChargesItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const ChargesHeadCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Charge",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
    },
    {
      id: "taxAmount",
      numeric: false,
      disablePadding: false,
      label: "Tax",
    },
    {
      id: "netAmount",
      numeric: false,
      disablePadding: false,
      label: "Net",
    },
    {
      id: "currencyID",
      numeric: false,
      disablePadding: false,
      label: "Cur",
    },
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: "Invoice",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Stat",
    },
  ];

  function ChargesTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof ChargesItemDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {ChargesHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={
                ["amount", "taxAmount", "netAmount"].includes(headCell.id)
                  ? "right"
                  : "left"
              }
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box>
      <TableContainer>
        <div className="RouteTable">
          <Table className="RouteTablebody" aria-labelledby="tableTitle">
            <ChargesTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={chargesData.length}
            />
            <TableBody>
              {sortedChargesData.length === 0 ? (
                <TableRow id="trCharges">
                  <TableCell
                    className="Datanofound"
                    colSpan={12}
                    align="center"
                    id="tdDataNotFound"
                  >
                    {notData}
                  </TableCell>
                  {/* Adjust the colspan according to the number of columns in your table */}
                </TableRow>
              ) : (
                sortedChargesData.map((row, index) => {
                  // const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={row.name} id="trCharges">
                      <TableCell className="auto-width" id="tdCharge">
                        {row.name}
                      </TableCell>
                      <TableCell className="auto-width" id="tdDescrition">
                        {row.description}
                      </TableCell>
                      <TableCell className="auto-width text-end" id="tdAmount">
                        {formatNumber(row.amount)}
                      </TableCell>
                      <TableCell className="auto-width text-end" id="tdTax">
                        {formatNumber(row.taxAmount)}
                      </TableCell>
                      <TableCell className="auto-width text-end" id="tdNet">
                        {formatNumber(row.netAmount)}
                      </TableCell>
                      <TableCell className="auto-width" id="tdCurrency">
                        {row.currencyID}
                      </TableCell>
                      <TableCell className="auto-width" id="tdInvoice">
                        {row.invoiceNumber}
                      </TableCell>
                      <TableCell className="auto-width" id="tdStatus">
                        {row.status}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
              {sortedChargesData.length === 0 ? (
                ""
              ) : (
                <TableRow hover className="Total_field">
                  <TableCell className="auto-width"></TableCell>
                  <TableCell className="auto-width"></TableCell>
                  <TableCell className="auto-width Total_data text-end">
                    {formatNumber(totalAmount)}
                  </TableCell>
                  <TableCell className="auto-width Total_data text-end">
                    {formatNumber(totalTaxAmount)}
                  </TableCell>
                  <TableCell className="auto-width Total_data text-end">
                    {formatNumber(totalNetAmount)}
                  </TableCell>
                  <TableCell className="auto-width"></TableCell>
                  <TableCell className="auto-width"></TableCell>
                  <TableCell className="auto-width"></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </Box>
  );
};

export default Charges;
