// src/components/OntimePerformance.tsx
import React, { useState, useCallback, useEffect } from "react";

import { formatDateTZ } from "../../shared/util/date-tz-utils";

import {
  Button,
  Typography,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  TableSortLabel,
  Box,
  useMediaQuery,
  SelectChangeEvent,
  Grid,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";

import { Link } from "react-router-dom";
import {
  OntimePerformanceDto,
  OntimePerformanceRequestDto,
} from "../../models/ontime-perfomance-dto";
import useAccounntsLogic from "../../shared/lookup/accounts/accounts.logic";
import useOntimePerformanceLogic from "./ontime-performance.logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import useServiceLogic from "../../shared/lookup/service/service.logic";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CommonPagination from "../../shared/components/CustomPagination";
import Loader from "../../shared/util/loader";
import { formatDate } from "../../shared/util/date-utils";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import moment from "moment";
type Order = "desc" | "asc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof OntimePerformanceDto;
  label: string;
  numeric: boolean;
  minWidth: string;
}

const headCells: HeadCell[] = [
  {
    id: "shipmentNumber",
    numeric: true,
    disablePadding: false,
    label: "ORDER #",
    minWidth: "110",
  },
  {
    id: "serviceCode",
    numeric: true,
    disablePadding: false,
    label: "SVC",
    minWidth: "70",
  },
  {
    id: "officeCode",
    numeric: true,
    disablePadding: false,
    label: "OFC",
    minWidth: "70",
  },
  {
    id: "accountNumber",
    numeric: true,
    disablePadding: false,
    label: "ACCOUNT",
    minWidth: "110",
  },
  {
    id: "pickupAirportID",
    numeric: true,
    disablePadding: false,
    label: "ORIG",
    minWidth: "90",
  },
  {
    id: "deliveryAirportID",
    numeric: true,
    disablePadding: false,
    label: "DEST",
    minWidth: "90",
  },
  {
    id: "quotedDeliveryDateTime",
    numeric: true,
    disablePadding: false,
    label: "QUOTED",
    minWidth: "130",
  },
  {
    id: "actualDeliveryDateTime",
    numeric: true,
    disablePadding: false,
    label: "ACTUAL",
    minWidth: "130",
  },
  {
    id: "countControllable",
    numeric: true,
    disablePadding: false,
    label: "CTR",
    minWidth: "70",
  },
  {
    id: "countUncontrollable",
    numeric: true,
    disablePadding: false,
    label: "UCT",
    minWidth: "70",
  },
  {
    id: "countUnknown",
    numeric: true,
    disablePadding: false,
    label: "UNK",
    minWidth: "70",
  },
  {
    id: "qualityControlName",
    numeric: true,
    disablePadding: false,
    label: "REASON",
    minWidth: "150",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof OntimePerformanceDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface OntimePerformanceProps {
  ontimePerformanceType: string;
}

const OntimePerformance: React.FC<OntimePerformanceProps> = ({
  ontimePerformanceType,
}: OntimePerformanceProps) => {
  const [accounts, setAccounts] = useState<string[]>([]);
  const [searchFormState, setSearchFormState] = useState({
    accountNumber: "All",
    startDate: "",
    endDate: "",
    serviceID: "All",
  });
  const { account } = useAccounntsLogic();
  const [format, setFormat] = useState<string>("Detail");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] =
    useState<keyof OntimePerformanceDto>("shipmentNumber");
  const [serviceData, setServiceData] = useState<any>([]);
  // const [notData, setNotData] = useState<any>("");
  //const [resetTrigger, setResetTrigger] = useState(false);
  const [onTimeData, setOnTimeData] = useState<OntimePerformanceDto[]>([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const isLoading = false;
  //const [isShowFooter, setShowFooter] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  // const [selectAll, setSelectAll] = useState(false);
  const { loading, ontimeError, getData, exportData } =
    useOntimePerformanceLogic();
  const open = Boolean(anchorEl);
  const token = useDecodeJWT();
  const { service } = useServiceLogic();
  const isMobile = useMediaQuery("(max-width:599px)");

  //Reset the search on batch type change
  useEffect(() => {
    setSearchFormState({
      accountNumber: "All",
      startDate: "",
      endDate: "",
      serviceID: "All",
    });
    // setResetTrigger(true);
  }, [ontimePerformanceType]);

  //  handle Change Page
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  // interface MenuStyles {
  //   maxHeight: string;
  // }

  // const [menuStyles, setMenuStyles] = useState<MenuStyles>({
  //   maxHeight: "200px", // Default max height for desktop
  // });

  const menuStyles = {
    maxHeight: "200px", // Default max height for desktop
  };

  //  Rows Change Page
  const handleChangeRowsPerPage = (pageSize: number) => {
    setRowsPerPage(pageSize);
    setPage(0);
  };

  // Sorting
  const descendingComparator = (
    a: OntimePerformanceDto,
    b: OntimePerformanceDto,
    orderBy: keyof OntimePerformanceDto
  ) => {
    const aValue = a[orderBy] ?? "";
    const bValue = b[orderBy] ?? "";
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  };

  function getComparator<Key extends keyof OntimePerformanceDto>(
    order: Order,

    orderBy: Key
  ): (a: OntimePerformanceDto, b: OntimePerformanceDto) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedImportedData = onTimeData
    .slice()
    .sort(getComparator(order, orderBy));

  const paginatedData =
    rowsPerPage > 0
      ? sortedImportedData.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : sortedImportedData;

  //  Table
  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof OntimePerformanceDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow className="Table-row">
          {headCells.map((headCell) => (
            <TableCell
              // style={{ minWidth: headCell.minWidth }}
              style={{ minWidth: `${headCell.minWidth}px` }} // Ensure px unit
              width={headCell.minWidth}
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleClickExport = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  // Format Dropdown
  const handleFormatChange = (event: SelectChangeEvent<string>) => {
    setFormat(event.target.value);
  };

  // Service Dropdown
  useEffect(() => {
    getService();
  }, []);

  // Service Dropdown
  const getService = async () => {
    try {
      const param: any = {
        companyID: 15693,
        userGUID: token.userGUID,
        serviceAvailType: "All",
        baseDateTime: new Date(),
        origCountryID: "",
        origCityName: "",
        origStateProvID: "",
        origPostalCode: "",
        origAirportID: "",
        destCountryID: "",
        destCityName: "",
        destStateProvID: "",
        destPostalCode: "",
        destAirportID: "",
      };
      const response = await service(param);
      if (response.data && response.data.data) {
        setServiceData(response.data.data);
      } else {
        setServiceData([]);
      }
    } catch {
      setServiceData([]);
    }
  };

  // Accounts Dropdown
  const getAccounts = useCallback(async () => {
    try {
      const param = {
        userId: token.UserId,
        companyID: 0,
        useIncludeAllItemrId: false,
      };

      const response = await account(param);
      if (response.data && response.data.data) {
        setAccounts(response.data.data);
      } else {
        setAccounts([]);
        //setNotData("Data not found");
      }
    } catch {
      setAccounts([]);
      // setNotData("Data not found");
    }
  }, [token.UserId]);

  //No data
  useEffect(() => {
    getAccounts();
    //setNotData("Data not found");
  }, [getAccounts]);

  //  handle account change
  const handleAccountChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setSearchFormState({
      ...searchFormState,
      [name]: value,
    });
  };

  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const { endDate } = searchFormState;

    if (name == "startDate" && !endDate) {
      setSearchFormState({
        ...searchFormState,
        [name]: value,
        endDate: value,
      });
    } else {
      setSearchFormState({
        ...searchFormState,
        [name]: value,
      });
    }
  };

  // handle submit
  const handleDisplayReport = async (currentPageData: number = 0) => {
    try {
      setPage(0);
      const { accountNumber, startDate, endDate, serviceID } = searchFormState;
      const param = {
        companyID: 15693,
        startDate: startDate ? moment(startDate).format("yyyy-MM-DD") : null,
        endDate: endDate ? moment(endDate).format("yyyy-MM-DD") : null,
        officeID: null,
        serviceID: serviceID === "All" ? undefined : parseInt(serviceID),
        accountNumber: accountNumber === "All" ? "" : accountNumber,
        customerGroupID: null,
        shipperUserID: null,
        customerClassID: null,
        regionGUID: null,
        dateType: "P",
        showLateOnly: true,
        orderBy: orderBy,
      };

      const response: OntimePerformanceDto[] = await getData(param);
      if (response) {
        setOnTimeData(response);
        setTotalRecord(response.length);
      }
    } catch {
      setOnTimeData([]);
      setTotalRecord(0);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (selectedItems.length <= 50) {
      if (checked && selectedItems.length == 50) {
        window.alert("You can not select more than 50 rows");
      } else {
        setSelectedItems((prev) => {
          const updatedItems = checked
            ? [...prev, name]
            : prev.filter((id) => id !== name);

          // setSelectAll(updatedItems.length === onTimeData.length);

          return updatedItems;
        });
      }
    }
  };

  //Request Sort
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof OntimePerformanceDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Export Functionality
  const handleExportPDF = async () => {
    handleExportClose();
    if (onTimeData.length === 0) {
      return;
    }
    const { accountNumber, startDate, endDate, serviceID } = searchFormState;

    const param: OntimePerformanceRequestDto = {
      companyID: 15693,
      startDate: startDate ? moment(startDate).format("yyyy-MM-DD") : null,
      endDate: endDate ? moment(endDate).format("yyyy-MM-DD") : null,
      officeID: null,
      serviceID: serviceID === "All" ? undefined : parseInt(serviceID),
      accountNumber: accountNumber === "All" ? "" : accountNumber,
      customerGroupID: null,
      shipperUserID: null,
      customerClassID: null,
      regionGUID: null,
      dateType: "P",
      showLateOnly: true,
      orderBy: orderBy,
      Format: format,
    };
    await exportData(param, "PDF");
    handleExportClose();
  };

  const handleExportXLS = async () => {
    if (onTimeData.length === 0) {
      handleExportClose();
      return;
    }
    const { accountNumber, startDate, endDate, serviceID } = searchFormState;

    const param: OntimePerformanceRequestDto = {
      companyID: 15693,
      startDate: startDate ? moment(startDate).format("yyyy-MM-DD") : null,
      endDate: endDate ? moment(endDate).format("yyyy-MM-DD") : null,
      officeID: null,
      serviceID: serviceID === "All" ? undefined : parseInt(serviceID),
      accountNumber: accountNumber === "All" ? "" : accountNumber,
      customerGroupID: null,
      shipperUserID: null,
      customerClassID: null,
      regionGUID: null,
      dateType: "P",
      showLateOnly: true,
      orderBy: orderBy,
      Format: format,
    };
    await exportData(param, "XLS");
    handleExportClose();
  };

  return (
    <div className="active-order onTimePerformance">
      <Box>
        <Typography variant="body1" className="Ontime-header">
          <span className="common-header">Ontime Performance</span>
          <span>
            <div>
              <Button
                variant="outlined"
                className="export-btn"
                id="btnExport"
                onClick={handleClickExport}
              >
                Export
                <ArrowDropDownIcon className="dropdown-icon" />
              </Button>
              <Menu
                id="ddOpOptions"
                open={open}
                anchorEl={anchorEl}
                onClose={handleExportClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="format-options">
                  <MenuItem onClick={handleExportPDF} id="btnExportPdf">
                    Export PDF
                  </MenuItem>
                  <MenuItem onClick={handleExportXLS} id="btnExportXls">
                    Export XLS
                  </MenuItem>
                </div>
              </Menu>
            </div>
          </span>
        </Typography>
      </Box>
      <Box className="d-flex flex-column w-100 border mt-2 p16 rounded mb-16">
        <Typography variant="h6" className="header-sub-title Search-lbl pb-8">
          Report Criteria
        </Typography>
        <Grid container spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <div className="small-cal-view">
                <CustomDatePicker
                  id="txtReadyStartDate"
                  handleDateChange={handleTextFieldChange as any}
                  label="Ready Date - Start"
                  name="startDate"
                  value={searchFormState?.startDate}
                  isClearable={true}
                />
              </div>
              {/* <TextField
                className="w-100"
                size="small"
                id="datetime-start-local"
                label="Ready Date - Start"
                type="date"
                name="startDate"
                InputLabelProps={{ shrink: true }}
                onChange={handleTextFieldChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <img
                          src={calendarIcon}
                          alt="Calendar icon"
                          style={{ maxWidth: "100%" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <div className="small-cal-view">
                <CustomDatePicker
                  handleDateChange={handleTextFieldChange as any}
                  id="txtReadyEndDate"
                  label="Ready Date - End"
                  name="endDate"
                  value={searchFormState?.endDate}
                  isClearable={true}
                />
              </div>
              {/* <TextField
                className="w-100"
                size="small"
                id="datetime-end-local"
                label="Ready Date - End"
                type="date"
                name="endDate"
                value={searchFormState?.endDate}
                InputLabelProps={{ shrink: true }}
                onChange={handleTextFieldChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <img
                          src={calendarIcon}
                          alt="Calendar icon"
                          style={{ maxWidth: "100%" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Grid container spacing={2} className="mt-0">
          <Grid item xs={6} sm={8 / 3} md={2} lg={2}>
            <FormControl size="small" className="w-100">
              <InputLabel id="demo-select-small-label">Service</InputLabel>
              <Select
                id="ddOpService"
                label="Service"
                value={searchFormState.serviceID || "All"}
                onChange={(e) =>
                  setSearchFormState({
                    ...searchFormState,
                    serviceID: e.target.value,
                  })
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {serviceData.length > 0 &&
                  serviceData.map((service: any) => (
                    <MenuItem
                      key={service.serviceGUID}
                      value={service.serviceID}
                    >
                      {service.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={8 / 3} md={2} lg={2}>
            <FormControl size="small" className="w-100">
              <InputLabel id="demo-select-small-label">Account</InputLabel>

              <Select
                labelId="demo-select-small-label"
                id="ddOpAccountnumber"
                value={searchFormState?.accountNumber}
                onChange={handleAccountChange}
                label="Account"
                name="accountNumber"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {accounts.length > 0 &&
                  accounts.map((account: any) => (
                    <MenuItem
                      key={account.userAccountRowGUID}
                      value={account.accountNumber}
                    >
                      {account.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8 / 3} md={2} lg={2}>
            <FormControl size="small" className="w-100">
              <InputLabel id="demo-select-small-label">Format</InputLabel>

              <Select
                label="Format"
                value={format}
                onChange={handleFormatChange}
                id="ddOpFormat"
              >
                <MenuItem value="Detail" id="liDetail">
                  Detail
                </MenuItem>
                <MenuItem value="Summary" id="liSummary">
                  Summary
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            sm={4}
            lg={2}
            md={3}
            xs={12}
            sx={{
              paddingLeft: {
                xs: "0px", // For xs screen size
                sm: "24px", // For sm screen size and up
              },
            }}
          >
            <Button
              className="w-100"
              id="btnDisplayReport"
              variant="contained"
              color="primary"
              onClick={() => {
                handleDisplayReport();
                // setShowFooter(false);
                //setSelectAll(false);
                setSelectedItems([]);
              }}
            >
              Display Report
            </Button>
          </Grid>
        </Grid>
      </Box>
      <div>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : ontimeError ? (
          <Typography>Error: {ontimeError}</Typography>
        ) : (
          <Paper sx={{ width: "100%", mb: 5 }} className="ActiveOrder">
            <TableContainer className="table-container1">
              <Table stickyHeader aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={onTimeData.length}
                />
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : paginatedData.length === 0 ? (
                    <TableRow id="trOntimeDataNotFound">
                      <TableCell
                        colSpan={headCells.length}
                        align="center"
                        className="table-section"
                        id="tdDataNotFound"
                      >
                        <div className="No_data">
                          <Box className="no_data_section_field">
                            <b className="no-data-header">
                              {isMobile
                                ? "No Invoices Found. "
                                : "No Data to Display"}
                            </b>
                            <p className="no-data-para">
                              {isMobile
                                ? "Please ensure you have entered a correct date or order #."
                                : "Please enter Report Criteria and click “Display Report”."}
                            </p>
                          </Box>
                        </div>
                        {/* <div className="no-data-border">
                          <p className="no-data-border-bold">
                            {isMobile
                              ? "No Invoices Found. "
                              : "No Data to Display"}
                          </p>
                          <h6>
                            {isMobile
                              ? "Please ensure you have entered a correct date or order #."
                              : "Please enter Report Criteria and click “Display Report”."}
                          </h6>
                        </div> */}
                      </TableCell>
                    </TableRow>
                  ) : (
                    paginatedData.map(
                      (row: OntimePerformanceDto, index: any) => {
                        return isMobile ? (
                          <>
                            <MobileCardView
                              key={row.shipmentGUID}
                              row={row}
                              selectedItems={selectedItems}
                              handleChange={handleChange}
                            />
                          </>
                        ) : (
                          <TableRow
                            hover
                            id="trOntime"
                            key={row.shipmentNumber}
                            className={
                              selectedItems.includes(row.shipmentGUID as string)
                                ? "row-highlight"
                                : ""
                            }
                          >
                            <TableCell
                              style={{ minWidth: 110 }}
                              id="tdShipmentNumber"
                            >
                              <Link
                                id="lbtnOrderId"
                                to={`/track-order?ShipmentGUID=${row?.shipmentGUID}`}
                                className="primary"
                              >
                                {row?.shipmentNumber}
                              </Link>
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 70 }}
                              id="tdServiceCode"
                            >
                              {row?.serviceCode}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 70 }}
                              id="tdOrderState"
                            >
                              {row.orderState}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 110 }}
                              id="tdAccountNumber"
                            >
                              {row?.accountNumber}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 90 }}
                              id="tdPickupAirportID"
                            >
                              {row?.pickupAirportID}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 90 }}
                              id="tdDeliveryAirportID"
                            >
                              {row?.deliveryAirportID}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 130 }}
                              id="tdQuotedDeliveryDateTime"
                            >
                              <Box className="weight-500">
                                {formatDate(row.quotedDeliveryDateTime)}
                              </Box>
                              <Box className="pod_datetime">
                                {formatDateTZ(row.quotedDeliveryDateTime)}
                              </Box>
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 130 }}
                              id="tdActualDeliveryDateTime"
                            >
                              <Box className="weight-500">
                                {formatDate(row.actualDeliveryDateTime)}
                              </Box>
                              <Box className="pod_datetime">
                                {formatDateTZ(row.actualDeliveryDateTime)}
                              </Box>
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 70 }}
                              id="tdCountControllable"
                            >
                              {row.countControllable}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 70 }}
                              id="tdCountUncontrollable"
                            >
                              {row.countUncontrollable}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 70 }}
                              id="tdCountUnknown"
                            >
                              {row.countUnknown}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: 150 }}
                              id="tdQualityControlName"
                            >
                              {row.qualityControlName}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <CommonPagination
              count={totalRecord}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </div>
    </div>
  );
};

<div className="container-fluid onTimePerformance active-order">
  {/* <div className="header-1">
        <Typography variant="h4" color="primary" className="ontime-title">
          Ontime Performance
        </Typography>
        <div>
          <Button
            variant="outlined"
            className="export-btn"
            onClick={handleClickExport}
          >
            Export
            <ArrowDropDownIcon className="dropdown-icon" />
          </Button>
          <Menu
            id="basic-menu"
            open={open}
            anchorEl={anchorEl}
            onClose={handleExportClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div className="format-options">
              <MenuItem onClick={handleExportPDF}>Export PDF</MenuItem>
              <MenuItem onClick={handleExportXLS}>Export XLS</MenuItem>
            </div>
          </Menu>
        </div>
      </div>

      <div className="content-ontime">
        <div className="report-criteria">
          <Typography className="report-criteria-text">
            Report Criteria
          </Typography>
        </div>

        <div className="date-pickers">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="Date-picker">
              <TextField
                className="w-100"
                size="small"
                id="datetime-start-local"
                label="Ready Date - Start"
                type="date"
                name="startDate"
                InputLabelProps={{ shrink: true }}
                onChange={handleTextFieldChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <CalendarTodayIcon className="icon-small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="Date-picker">
              <TextField
                className="w-100"
                size="small"
                id="datetime-end-local"
                label="Ready Date - End"
                type="date"
                name="endDate"
                value={searchFormState?.endDate}
                InputLabelProps={{ shrink: true }}
                onChange={handleTextFieldChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <CalendarTodayIcon className="icon-small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </LocalizationProvider>
        </div>
        <div className="Select-box">
          <div className="select-row">
            <FormControl className="select-dropdown" size="small">
              <InputLabel>Service</InputLabel>
              <Select
                label="Service"
                value={searchFormState.serviceID || "All"}
                onChange={(e) =>
                  setSearchFormState({
                    ...searchFormState,
                    serviceID: e.target.value,
                  })
                }
              >
                <MenuItem value="All">All</MenuItem>
                {serviceData.length > 0 &&
                  serviceData.map((service: any) => (
                    <MenuItem
                      key={service.serviceGUID}
                      value={service.serviceID}
                    >
                      {service.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className="select-dropdown" size="small">
              <InputLabel>Account</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={searchFormState?.accountNumber}
                onChange={handleAccountChange}
                label="Account"
                name="accountNumber"
              >
                <MenuItem value="All">All</MenuItem>
                {accounts.length > 0 &&
                  accounts.map((account: any) => (
                    <MenuItem
                      key={account.userAccountRowGUID}
                      value={account.accountNumber}
                    >
                      {account.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="format-row">
            <FormControl className="select-dropdown-format" size="small">
              <InputLabel>Format</InputLabel>
              <Select
                label="Format"
                value={format}
                onChange={handleFormatChange}
              >
                <MenuItem value="Detail">Detail</MenuItem>
                <MenuItem value="Summary">Summary</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="button-row">
            <Button
              variant="contained"
              color="primary"
              className="display-report-btn"
              onClick={() => {
                handleDisplayReport();
                setShowFooter(false);
                setSelectAll(false);
                setSelectedItems([]);
              }}
            >
              Display Report
            </Button>
          </div>
        </div>
      </div> */}
</div>;

const MobileCardView: React.FC<{
  row: OntimePerformanceDto;
  selectedItems: any;
  handleChange: any;
}> = ({ row, selectedItems, handleChange }) => {
  return (
    <Paper
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
      className={
        selectedItems.includes(row.shipmentGUID as string)
          ? "row-highlight Card_view"
          : "Card_view"
      }
    >
      <Typography variant="h6" className="Order-head Field_info">
        <span className="field_label" style={{ paddingBottom: "7px" }}>
          ORDER #
        </span>
        <span className="Order_id Field_value" style={{ paddingTop: "10px" }}>
          <Link
            to={`/track-order?ShipmentGUID=${row?.shipmentGUID}`}
            className="primary"
          >
            {row?.shipmentNumber}
          </Link>
        </span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">SVC</span>{" "}
        <span className="Field_value">{row.serviceCode}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">OFC</span>{" "}
        <span className="Field_value">{row.orderState}</span>
      </Typography>

      <Typography variant="body1" className="Field_info">
        <span className="field_label">ACCOUNT</span>{" "}
        <span className="Field_value">{row.accountNumber}</span>
      </Typography>

      <Typography variant="body1" className="Field_info">
        <span className="field_label">ORIG</span>{" "}
        <span className="Field_value"> {row?.pickupAirportID}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">DEST</span>{" "}
        <span className="Field_value">{row?.deliveryAirportID}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">QUOTED</span>{" "}
        <span className="Field_value">
          {" "}
          <Box className="weight-500">
            {formatDate(row.actualDeliveryDateTime)}
          </Box>
          <Box className="pod_datetime">
            {formatDateTZ(row.actualDeliveryDateTime)}
          </Box>
        </span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">ACTUAL</span>{" "}
        <span className="Field_value">
          <Box className="weight-500">
            {formatDate(row.actualDeliveryDateTime)}
          </Box>
          <Box className="pod_datetime">
            {formatDateTZ(row.actualDeliveryDateTime)}
          </Box>
        </span>
      </Typography>

      <Typography variant="body1" className="Field_info">
        <span className="field_label">CTR</span>{" "}
        <span className="Field_value">{row.countControllable}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">UCT</span>{" "}
        <span className="Field_value">{row.countUncontrollable}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">UNK</span>{" "}
        <span className="Field_value">{row.countUncontrollable}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">REASON</span>{" "}
        <span className="Field_value">{row.countUncontrollable}</span>
      </Typography>
    </Paper>
  );
};

export default OntimePerformance;
