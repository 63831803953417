import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../../config/constants";
import { ShipmentPieceDto } from "../../../../models/shipment-piece-dto";


// Action Types for insertShipmentPiece
const INSERTSHIPMENTPIECE_REQUEST = "INSERTSHIPMENTPIECE_REQUEST";
const INSERTSHIPMENTPIECE_SUCCESS = "INSERTSHIPMENTPIECE_SUCCESS";
const INSERTSHIPMENTPIECE_FAILURE = "INSERTSHIPMENTPIECE_FAILURE";

// Action Creators for insertShipmentPiece
export const insertShipmentPieceRequest = () => ({
  type: INSERTSHIPMENTPIECE_REQUEST,
});

export const insertShipmentPieceSuccess = () => ({
  type: INSERTSHIPMENTPIECE_SUCCESS,
});

export const insertShipmentPieceFailure = (error: string) => ({
  type: INSERTSHIPMENTPIECE_FAILURE,
  payload: error,
});

// Initial State for insertShipmentPiece
export interface InsertShipmentPieceState {
  isAuthenticated: boolean;
  insertShipmentPieceLoading: boolean;
  insertShipmentPieceError: string | null;
}

const initialAuthState: InsertShipmentPieceState = {
  isAuthenticated: false,
  insertShipmentPieceLoading: false,
  insertShipmentPieceError: null,
};

// Define the interface for the API response


// Reducer for Authentication
const insertShipmentPieceReducer = (
  state = initialAuthState,
  action: any
): InsertShipmentPieceState => {
  switch (action.type) {
    case INSERTSHIPMENTPIECE_REQUEST:
      return { ...state, insertShipmentPieceLoading: true, insertShipmentPieceError: null };
    case INSERTSHIPMENTPIECE_SUCCESS:
      return { ...state, insertShipmentPieceLoading: false, isAuthenticated: true };
    case INSERTSHIPMENTPIECE_FAILURE:
      return { ...state, insertShipmentPieceLoading: false, insertShipmentPieceError: action.payload };
    default:
      return state;
  }
};

// Thunk for insertShipmentPiece
export const insertShipmentPiece =
  (param: ShipmentPieceDto) =>
  async (dispatch: Dispatch) => {
    dispatch(insertShipmentPieceRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/InsertShipmentPiece`,
        param
      );
      dispatch(insertShipmentPieceSuccess());
      const data:any = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(insertShipmentPieceFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(insertShipmentPieceFailure(error.response.data.message));
      } else {
        dispatch(insertShipmentPieceFailure(error.message));
      }
    }
  };

export default insertShipmentPieceReducer;
