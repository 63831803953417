import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../../config/constants";
import { ShipmentPieceDto } from "../../../../models/shipment-piece-dto";


// Action Types for updateShipmentPiece
const UPDATESHIPMENTPIECE_REQUEST = "UPDATESHIPMENTPIECE_REQUEST";
const UPDATESHIPMENTPIECE_SUCCESS = "UPDATESHIPMENTPIECE_SUCCESS";
const UPDATESHIPMENTPIECE_FAILURE = "UPDATESHIPMENTPIECE_FAILURE";

// Action Creators for updateShipmentPiece
export const updateShipmentPieceRequest = () => ({
  type: UPDATESHIPMENTPIECE_REQUEST,
});

export const updateShipmentPieceSuccess = () => ({
  type: UPDATESHIPMENTPIECE_SUCCESS,
});

export const updateShipmentPieceFailure = (error: string) => ({
  type: UPDATESHIPMENTPIECE_FAILURE,
  payload: error,
});

// Initial State for updateShipmentPiece
export interface UpdateShipmentPieceState {
  isAuthenticated: boolean;
  updateShipmentPieceLoading: boolean;
  updateShipmentPieceError: string | null;
}

const initialAuthState: UpdateShipmentPieceState = {
  isAuthenticated: false,
  updateShipmentPieceLoading: false,
  updateShipmentPieceError: null,
};

// Define the interface for the API response


// Reducer for Authentication
const updateShipmentPieceReducer = (
  state = initialAuthState,
  action: any
): UpdateShipmentPieceState => {
  switch (action.type) {
    case UPDATESHIPMENTPIECE_REQUEST:
      return { ...state, updateShipmentPieceLoading: true, updateShipmentPieceError: null };
    case UPDATESHIPMENTPIECE_SUCCESS:
      return { ...state, updateShipmentPieceLoading: false, isAuthenticated: true };
    case UPDATESHIPMENTPIECE_FAILURE:
      return { ...state, updateShipmentPieceLoading: false, updateShipmentPieceError: action.payload };
    default:
      return state;
  }
};

// Thunk for updateShipmentPiece
export const updateShipmentPiece =
  (param: ShipmentPieceDto) =>
  async (dispatch: Dispatch) => {
    dispatch(updateShipmentPieceRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/UpdateShipmentPieces`,
        param
      );
      dispatch(updateShipmentPieceSuccess());
      const data:any = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(updateShipmentPieceFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(updateShipmentPieceFailure(error.response.data.message));
      } else {
        dispatch(updateShipmentPieceFailure(error.message));
      }
    }
  };

export default updateShipmentPieceReducer;
