import {
  CRYOCELL_ACCOUNT,
  DATETIME_REF_TYPE,
  DAY_ICE_ACCOUNT,
  DAY_ICE_REF_TYPE,
} from "../../config/constants";
import moment from "moment";
export const deliverdOptions = [
  { value: 2, displayName: "Last 2 Days" },
  { value: 7, displayName: "Last 7 Days" },
  { value: 10, displayName: "Last 10 Days" },
  { value: 30, displayName: "Last 30 Days" },
  { value: 60, displayName: "Last 60 Days" },
];

export function capitalizeFirstLetter(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const isDisplayDateTime = (referenceType: any, selectAccount: any) => {
  if (
    (referenceType === DATETIME_REF_TYPE &&
      selectAccount &&
      parseInt(selectAccount) === CRYOCELL_ACCOUNT) ||
    (DAY_ICE_REF_TYPE.includes(parseInt(referenceType)) &&
      selectAccount &&
      DAY_ICE_ACCOUNT.includes(parseInt(selectAccount)))
  ) {
    return true;
  }
  return false;
};

export const formatRefValue = (refValue: any) => {
  if (!refValue) return ""; // If refValue is empty, return an empty string

  // Check if the first character is '(' and the last character is ')'
  const startsWithParenthesis = refValue.startsWith("(");
  const endsWithParenthesis = refValue.endsWith(")");

  // Add parentheses only if they are not present
  return `${startsWithParenthesis ? "" : "("}${refValue}${
    endsWithParenthesis ? "" : ")"
  }`;
};

export const handleReferenceUpdate = (
  referenceTypeID: any,
  mySetting: any,
  accountNumber: string | undefined,
  referenceFieldName: string
) => {
  if (isDisplayDateTime(referenceTypeID, accountNumber)) {
    const dateFieldName = `dateTime${referenceFieldName}`;
    const dateValue = mySetting?.[dateFieldName];

    const combineValue = `${
      dateValue
        ? `${moment(dateValue).format("MM/DD/YYYY hh:mm A")} ${formatRefValue(
            mySetting?.[referenceFieldName]
          )}`
        : mySetting?.[referenceFieldName]
    }`;

    // Set the combined value back to the reference field dynamically
    return combineValue;
  } else {
    const fieldValue = mySetting?.[referenceFieldName];
    if (fieldValue) {
      const [dateTime, restValue] = fieldValue?.split("(");
      if (restValue) {
        const startsWithParenthesis = restValue?.startsWith("(");
        const endsWithParenthesis = restValue?.endsWith(")");

        if (endsWithParenthesis && !startsWithParenthesis) {
          return restValue?.slice(0, -1);
        }
        return formatRefValue(restValue);
      } else {
        const startsWithParenthesis = fieldValue?.startsWith("(");
        const endsWithParenthesis = fieldValue?.endsWith(")");

        if (endsWithParenthesis && !startsWithParenthesis) {
          return fieldValue?.slice(0, -1);
        }
        return fieldValue;
      }
    } else {
      return "";
    }
  }
};

export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getUniqueConcatenatedAddresses = (data: any[]) => {
  return data.map((address) => {
    const parts = [
      address.agencyAcronym?.trim()
        ? `${address.agencyAcronym.trim()} -`
        : null,
      address.companyName?.trim(),
      address.addressLine1?.trim(),
      address.city?.trim(),
      [address.state?.trim(), address.zipCode?.trim()]
        .filter((part) => part && part.length > 0)
        .join(" "),
    ].filter((part) => part && part.length > 0);
    const displayName = parts
      .join(" ")
      .replace(/\s--,\s*/, " - ")
      .replace(/,\s*$/, "");
    return {
      ...address,
      displayName,
    };
  });
};

export const validateMsgStringHasCommentCharecter =
  "Please avoid using // or -- in your instructions. These characters are not allowed. Kindly revise your input and try again.";
export const checkStringHasCommentCharecter = (str: string) => {
  if (str.includes("//") || str.includes("--")) {
    return true;
  }
  return false;
};

export const isReleaseNoteVisible = (notesData: any) => {
  if (notesData) {
    const validFrom = new Date(notesData?.validFrom);
    const validTo = new Date(notesData?.validTo);
    const currentDate = new Date();

    // Check if current date is within the range
    if (currentDate >= validFrom && currentDate <= validTo) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
