import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import useCommodityLogic from "../../lookup/commodity/commodity.logic";
import {
  ContentItemDto,
  PackageTypeItemDto,
} from "../../../models/long-form-item-dto";
import usePackageTypeLogic from "../../lookup/package-type/package-type.logic";
import useContentLogic from "../../lookup/contents/contents.logic";
import { MAX_COMPANY_ID } from "../../../config/constants";
import useCurrencyLogic from "../../lookup/currency/currency.logic";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import {
  handleDecimalValue,
  handleNumericValue,
} from "../../util/numeric-value";
import { UpdateEditOrderDto } from "../../../models/edit-active-order-dto";
import EditPiecesDialog from "./EditPiecesDialog";
import useGetShipmentPieceLogic from "../../lookup/shipment-piece/get-shipment-piece/get-shipment-piece.logic";
import { ShipmentPieceDto } from "../../../models/shipment-piece-dto";

import moment from "moment";
interface PackageInfoProps {
  shipmentGUID: string;
  formErrors: any;
  setFormErrors: (errors: any) => void;
  updateEditOrderData: UpdateEditOrderDto;
  isAlert: boolean;
  handleInputChange: (updatedData: any) => void;
  setEditablePiecesData: React.Dispatch<
    React.SetStateAction<ShipmentPieceDto[]>
  >;
  setAllPiecesData: React.Dispatch<React.SetStateAction<ShipmentPieceDto[]>>;
}

const EditPackageInfo: React.FC<PackageInfoProps> = ({
  shipmentGUID,
  formErrors,
  setFormErrors,
  updateEditOrderData,
  isAlert,
  handleInputChange,
  setEditablePiecesData,
  setAllPiecesData,
}) => {
  const { accountNumber } = updateEditOrderData;
  const [commodities, setCommodities] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { commodity } = useCommodityLogic();
  const { packageType, packageTypeDependancy, calculateDimWtAPI } =
    usePackageTypeLogic();
  const { content } = useContentLogic();
  const [packageTypes, setPackageTypes] = useState<PackageTypeItemDto[]>([]);
  const [contents, setContents] = useState<ContentItemDto[]>([]);
  const [open, setOpen] = useState(false);
  const { currency } = useCurrencyLogic();
  const [piecesData, setPiecesData] = useState<ShipmentPieceDto[]>([]);
  const { getShipmentPiece } = useGetShipmentPieceLogic();

  const [packageTypeDependancyCustID, setPackageTypeDependancyCustID] =
    useState<any>(null);
  const [currencies, setCurrencies] = useState<
    {
      currencyID: string;
      shortName: string;
      name: string;
      description: string;
      symbolText: string;
      symbolImage: string;
    }[]
  >([]);

  const getCommodity = useCallback(async () => {
    try {
      setLoading(true);
      // const param = {
      //   companyID: MAX_COMPANY_ID,
      // };

      const response = await commodity();
      if (response.data && response.data.data) {
        setCommodities(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const param = { accountNumber };

        const response = await packageTypeDependancy(param);
        if (response.data && response.data.data) {
          // Handle the response data
          const allPackagingAvailable =
            response.data.data?.allPackagingAvailable;
          const customerID = allPackagingAvailable
            ? 0
            : response.data.data?.customerID;
          setPackageTypeDependancyCustID({
            customerID: customerID,
            apiCustomerID: response.data.data?.customerID,
          });
          getPackageType(customerID);
        }
      } catch (err) {
        // Handle errors
      } finally {
        setLoading(false);
      }
    };

    if (accountNumber) {
      // Ensure the effect runs only when accountNumber is valid
      fetchData();
    }
  }, [accountNumber]);

  const getPackageType = useCallback(async (customerID: number) => {
    try {
      setLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
        showDeleted: false,
        customerID: customerID,
        includeBlank: true,
      };

      const response = await packageType(param);
      if (response.data && response.data.data) {
        setPackageTypes(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const getContents = useCallback(async () => {
    try {
      setLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
        contentsReferenceGroupGUID: "",
      };

      const response = await content(param);
      if (response.data && response.data.data) {
        setContents(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCommodity();

    getContents();
  }, [getCommodity, getPackageType, getContents]);

  useEffect(() => {
    getCurrency();
  }, []);
  useEffect(() => {
    getShipmentPieceList();
  }, [packageTypeDependancyCustID]);

  const getDimWt = async (
    paramsData: any,
    updateItem: any,
    updateItemIndex: number,
    updateDirectState: boolean = false
  ) => {
    try {
      setLoading(true);
      const response = await calculateDimWtAPI(paramsData);

      if (response.data && response.data) {
        return (updateItem[updateItemIndex].dimWeight = response.data.data);
      }
    } catch (err) {
    } finally {
      setLoading(false); // Ensure loading is always false
    }
  };

  // const removeDuplicateContents = (contents: ContentItemDto[]) => {
  //   const seen = new Set();
  //   return contents.filter((item) => {
  //     // Check if properName is null or undefined before calling toLowerCase
  //     const lowerCaseProperName = item.properName?.toLowerCase();
  //     if (!lowerCaseProperName) {
  //       return false; // Skip item if properName is null or undefined
  //     }

  //     if (seen.has(lowerCaseProperName)) {
  //       return false; // Skip if already seen
  //     }
  //     seen.add(lowerCaseProperName);
  //     return true;
  //   });
  // };

  // const uniqueContents = useMemo(
  //   () => removeDuplicateContents(contents),
  //   [contents]
  // );
  const [searchInput, setSearchInput] = useState("");

  const handlePiecesSelectChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const syntheticEvent = {
      target: {
        name: name,
        value: value,
      },
    } as ChangeEvent<HTMLInputElement>;
    handlePiecesInputChange(syntheticEvent, index); // Call the handler
  };

  const getShipmentPieceList = async () => {
    try {
      const param = {
        shipmentGUID: shipmentGUID,
      };
      const response = await getShipmentPiece(param);
      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        const updatedPiecesData = response.data.data.map((piece: any) => ({
          ...piece,
          height: piece.height === 0 ? null : piece.height, // Update height to null if it is 0
          weight: piece.weight === 0 ? null : piece.weight, // Update weight to null if it is 0
          width: piece.weight === 0 ? null : piece.width, // Update weight to null if it is 0
          length: piece.weight === 0 ? null : piece.length, // Update weight to null if it is 0
          declaredValue: piece.weight === 0 ? null : piece.declaredValue, // Update weight to null if it is 0
          isEdit: false, // Set isEdit flag to false by default
        }));

        if (
          piecesData &&
          piecesData.length === 1 &&
          !piecesData[0]?.shipmentPieceID
        ) {
          // let param = {
          //   ...piecesData[0],
          //   shipmentGUID: updateEditOrderData?.shipmentGUID,
          // };
          // const response = await insertShipmentPiece(param);
          //merge default piece on add/edit new piece
          const mergePieces = [...piecesData, ...updatedPiecesData];
          setPiecesData(mergePieces);
        } else {
          setPiecesData(updatedPiecesData);
        }
      } else {
        const defaultPiece = [
          {
            contents: updateEditOrderData?.contents || "",
            weight: updateEditOrderData?.weight,
            // dimWeight: calculateDimWeight(
            //   "US",
            //   "US",
            //   updateEditOrderData?.pieces || 1,
            //   updateEditOrderData?.length || 0,
            //   updateEditOrderData?.width || 0,
            //   updateEditOrderData?.height || 0,
            //   "IN",
            //   updateEditOrderData?.weightUOM
            // ),
            weightUOM: updateEditOrderData?.weightUOM,
            length: updateEditOrderData?.length || 0,
            width: updateEditOrderData?.width || 0,
            height: updateEditOrderData?.height || 0,
            sizeUOM: updateEditOrderData?.sizeUOM,
            commodity: updateEditOrderData?.commodity,
            declaredValue: updateEditOrderData?.declaredValue || 0.0,
            declaredValueCurrencyID:
              updateEditOrderData?.declaredValueCurrencyID,
            quantity: updateEditOrderData?.quantity || 1,
            isEdit: true,
          },
        ];
        const puReadtDate = moment(updateEditOrderData.puReadyDateTime).format(
          "YYYY-MM-DDTHH:mm:ss.SSS"
        );
        const params = {
          puReadyDateTime: puReadtDate,
          serviceID: updateEditOrderData?.serviceID,
          origCountryID: updateEditOrderData?.puCountryID,
          destCountryID: updateEditOrderData?.delCountryID,
          length: updateEditOrderData?.length || 0,
          width: updateEditOrderData?.width || 0,
          height: updateEditOrderData?.height || 0,
          sizeUOM: updateEditOrderData?.sizeUOM || "IN",
          weightUOM: updateEditOrderData?.weightUOM,
          qunatity: updateEditOrderData?.pieces || 1,
          customerID: packageTypeDependancyCustID?.apiCustomerID || 0,
        };
        getDimWt(params, defaultPiece, 0);
        setPiecesData(defaultPiece as any);
      }
    } catch (err) {}
  };

  useEffect(() => {
    setAllPiecesData(piecesData);
    const editablePiecesData = piecesData.filter((piece) => piece.isEdit);
    setEditablePiecesData(editablePiecesData);
  }, [piecesData]);

  const getCurrency = async () => {
    try {
      const response = await currency();
      if (response.data && response.data.data) {
        setCurrencies(response.data.data);
      }
    } catch (err) {}
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  interface MenuStyles {
    maxHeight: string;
    maxWidth: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
    maxWidth: "48%",
  });

  const isXs = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed

  useEffect(() => {
    if (isXs) {
      setMenuStyles((prevStyles) => ({
        ...prevStyles,
        maxWidth: "80%",
      }));
    } else {
      setMenuStyles((prevStyles) => ({
        ...prevStyles,
        maxWidth: "48%",
      }));
    }
  }, [isXs]);

  const handlePiecesInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    // Create a copy of piecesData
    const updatedPieceDetails = [...piecesData!];
    // Handle specific logic for "count"
    if (name === "quantity" && parseFloat(value) < 1) {
      return;
    }
    if (name === "weight") {
      const errorKey = `allPiecesData[${index}].${name}`;
      // Example logic to check and delete error from formErrors
      if (formErrors[errorKey]) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[errorKey];
          return updatedErrors;
        });
      }
    }
    // Handle specific logic for "packagingTypeGUID_Outer"
    if (name === "contents") {
      const selectedPackage = contents.find((pkg) => pkg.properName === value);
      if (selectedPackage) {
        updatedPieceDetails[index] = {
          ...updatedPieceDetails[index],
          dgUNNumber: selectedPackage.unNumber,
          dgCommodityClass: selectedPackage.commodityClass,
          isDangerousGoods: selectedPackage.isDangerousGoods,
          isRadioactive: selectedPackage.isRadioactive,
          dgProperName:selectedPackage.properName
        };
      }
    }
    // Update the general field value
    updatedPieceDetails[index] = {
      ...updatedPieceDetails[index],
      [name]: value,
      isEdit: true, // Mark as e
    };
    // Update state or handle the updated data
    setPiecesData(updatedPieceDetails);
  };
  const measurementUnit = updateEditOrderData?.sizeUOM?.toLowerCase() || "in";
  return (
    <Box className="d-flex flex-column w-100 border rounded">
      <Typography variant="h6" className="Sub-header p16">
        Package Information
      </Typography>
      {piecesData.map((piece, index) => (
        <Box className={`${index >= 1 ? "bcg-grey p16" : "p16"}`}>
          <Box key={piece.shipmentPieceID} className=" ">
            <Typography variant="body1" className="pb-8 piece-info">
              Piece {index + 1}
            </Typography>
            <Box className="d-flex gap-2 Divider pb-16 mb-16">
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <TextField
                    id="txtQuantity"
                    type="number"
                    variant="outlined"
                    label="Quantity"
                    className="w-100"
                    name="quantity"
                    defaultValue={piece.quantity}
                    onChange={(e) => handlePiecesInputChange(e, index)}
                    onKeyDown={handleNumericValue}
                    value={piece.quantity}
                    inputProps={{ min: 1, maxLength: 4 }}
                    size="small"
                    InputProps={{
                      readOnly: isAlert,
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={8}>
                  <TextField
                    variant="outlined"
                    id="txtWeight"
                    size="small"
                    label="Weight"
                    className="w-100"
                    name="weight"
                    defaultValue={piece.weight}
                    value={piece.weight}
                    onKeyDown={handleDecimalValue}
                    onChange={(e) => handlePiecesInputChange(e, index)}
                    error={!!formErrors[`allPiecesData[${index}].weight`]}
                    InputProps={{
                      endAdornment: !!formErrors[
                        `allPiecesData[${index}].weight`
                      ] && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                      readOnly: isAlert,
                    }}
                    inputProps={{ min: 0, maxLength: 4 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <FormControl className="w-100" size="small">
                    <InputLabel>Unit</InputLabel>
                    <Select
                      variant="outlined"
                      className="w-100"
                      id="ddWeightUOM"
                      label="Unit"
                      name="weightUOM"
                      value={piece.weightUOM || ""}
                      onChange={(e) => handlePiecesInputChange(e, index)}
                      defaultValue={piece.weightUOM}
                      disabled={isAlert}
                    >
                      <MenuItem value="LB" id="liEoLb">
                        LB
                      </MenuItem>
                      <MenuItem value="KG" id="liEoKg">
                        KG
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Typography variant="body1" className="piece-info pb-8">
              Dimensions
            </Typography>
            <Box className="d-flex gap-2 mt-2 Divider pb-16">
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <TextField
                    size="small"
                    id="txtLength"
                    variant="outlined"
                    label={`Length (${measurementUnit})`}
                    className="w-100"
                    name="length"
                    defaultValue={piece.length || ""}
                    value={piece.length || ""}
                    onChange={(e) => handlePiecesInputChange(e, index)}
                    // InputLabelProps={{ shrink: !!piece.length }}
                    onKeyDown={handleNumericValue}
                    InputProps={{
                      readOnly: isAlert,
                    }}
                    inputProps={{ min: 0, maxLength: 4 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label={`Width (${measurementUnit})`}
                    id="txtWidth"
                    className="w-100"
                    name="width"
                    defaultValue={piece.width || ""}
                    // InputLabelProps={{ shrink: !!piece.width }}
                    value={piece.width || ""}
                    onKeyDown={handleNumericValue}
                    onChange={(e) => handlePiecesInputChange(e, index)}
                    InputProps={{
                      readOnly: isAlert,
                    }}
                    inputProps={{ min: 0, maxLength: 4 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label={`Height (${measurementUnit})`}
                    className="w-100"
                    id="txtHeight"
                    name="height"
                    defaultValue={piece.height || ""}
                    value={piece.height || ""}
                    // InputLabelProps={{ shrink: !!piece.height }}
                    onKeyDown={handleNumericValue}
                    onChange={(e) => handlePiecesInputChange(e, index)}
                    InputProps={{
                      readOnly: isAlert,
                    }}
                    inputProps={{ min: 0, maxLength: 4 }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Typography variant="body1" className="pt-16 piece-info pb-8">
              Contents & Value
            </Typography>
            <Box className="d-flex gap-2">
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <FormControl className="w-100">
                    <Autocomplete
                      id="ddContent"
                      options={
                        searchInput.trim() === ""
                          ? contents
                              .filter(
                                (item, index, self) =>
                                  item.properName && // Ensure properName is not null or undefined
                                  self.findIndex(
                                    (t) =>
                                      t.properName?.toLowerCase() ===
                                      item.properName?.toLowerCase()
                                  ) === index // Remove duplicates by properName
                              )
                              .sort((a, b) => {
                                const properNameA = a.properName || "";
                                const properNameB = b.properName || "";

                                const isAlphabetA = /^[A-Za-z]/.test(
                                  properNameA
                                );
                                const isAlphabetB = /^[A-Za-z]/.test(
                                  properNameB
                                );
                                const isNumberA = /^\d/.test(properNameA);
                                const isNumberB = /^\d/.test(properNameB);

                                // Sort by alphabet first
                                if (isAlphabetA && isAlphabetB) {
                                  return properNameA.localeCompare(properNameB);
                                }
                                // Place alphabet entries above special characters and integers
                                if (isAlphabetA) return -1;
                                if (isAlphabetB) return 1;

                                // Place integers at the bottom
                                if (isNumberA && isNumberB) return 0;
                                if (isNumberA) return 1;
                                if (isNumberB) return -1;

                                // Place special characters above integers but below alphabets
                                return properNameA.localeCompare(properNameB);
                              })
                          : contents
                              .filter(
                                (item, index, self) =>
                                  item.properName &&
                                  self.findIndex(
                                    (t) =>
                                      t.properName?.toLowerCase() ===
                                      item.properName?.toLowerCase()
                                  ) === index
                              )
                              .filter((item) =>
                                item.properName
                                  ?.toLowerCase()
                                  .includes(searchInput.toLowerCase())
                              )
                      }
                      getOptionLabel={(option) => option.properName || ""}
                      value={
                        contents.find(
                          (item) => item.properName === piece.contents
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        handlePiecesSelectChange(
                          {
                            target: {
                              name: "contents",
                              value: newValue?.properName || "",
                            },
                          },
                          index
                        );
                        setSearchInput(""); // Reset search input to display all items
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contents"
                          variant="outlined"
                          className="w-100 mb-2 hcAutoComplete"
                          inputProps={{
                            ...params.inputProps,
                            maxLength: 100,
                          }}
                          disabled={isAlert}
                          onChange={(e) => setSearchInput(e.target.value)} // Update search input
                          onBlur={(e) => {
                            if (e.target.value.trim() === "")
                              setSearchInput(""); // Reset on blur if empty
                          }}
                        />
                      )}
                      renderOption={(props, option, state) => {
                        const isSelected = option.properName === piece.contents;
                        return (
                          <>
                            {state.index === 0 && (
                              <MenuItem
                                disabled
                                className="dropdown-grid Table-head"
                              >
                                <TableHead>
                                  <TableRow className="table-fixed">
                                    <TableCell className="weight-500 font14 width70 thead-cell">
                                      UN #
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width-name pr-10 thead-cell">
                                      Proper Name
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width70 thead-cell">
                                      Class
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width70 thead-cell">
                                      DG
                                    </TableCell>
                                    <TableCell className="weight-500 font14 width70 thead-cell">
                                      RA
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                              </MenuItem>
                            )}
                            <li
                              {...props}
                              style={{
                                padding: "0px 16px",
                                lineHeight: "1.2",
                                backgroundColor: isSelected
                                  ? "#04638614"
                                  : "inherit",
                                fontWeight: isSelected ? "bold" : "normal",
                              }}
                              className="custom-dropdown-item"
                            >
                              <TableRow className="table-fixed">
                                <TableCell className="width70 Info-wrap">
                                  {option.unNumber}
                                </TableCell>
                                <TableCell className="width-name pr-10 Info-wrap">
                                  {option.properName}
                                </TableCell>
                                <TableCell className="width70 Info-wrap">
                                  {option.commodityClass}
                                </TableCell>
                                <TableCell className="width70 Info-wrap">
                                  {option.isDangerousGoods ? "True" : "False"}
                                </TableCell>
                                <TableCell className="width70 Info-wrap">
                                  {option.isRadioactive ? "True" : "False"}
                                </TableCell>
                              </TableRow>
                            </li>
                          </>
                        );
                      }}
                      sx={{ width: "100%" }}
                      PopperComponent={(props) => (
                        <Popper
                          {...props}
                          placement="bottom-end"
                          style={{
                            zIndex: 1300,
                            width: "50%",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item lg={5} md={5} sm={4} xs={4}>
                          <FormControl className="w-100" size="small">
                            <InputLabel>Currency</InputLabel>
                            <Select
                              id="ddCurrency"
                              variant="outlined"
                              className="w-100"
                              label="Contents"
                              name="declaredValueCurrencyID"
                              onChange={(e) =>
                                handlePiecesInputChange(e, index)
                              }
                              defaultValue={piece.declaredValueCurrencyID}
                              disabled={isAlert}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },

                                PaperProps: {
                                  style: menuStyles,
                                },
                              }}
                            >
                              {currencies.length > 0 &&
                                currencies.map((item) => (
                                  <MenuItem
                                    key={item.currencyID}
                                    value={item.currencyID}
                                  >
                                    {item.shortName}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={7} sm={8} md={7} xs={8}>
                          <TextField
                            size="small"
                            variant="outlined"
                            id="txtValue"
                            label="Value"
                            className="w-100"
                            name="declaredValue"
                            defaultValue={
                              piece.declaredValue ? piece.declaredValue : ""
                            }
                            value={
                              piece.declaredValue ? piece.declaredValue : ""
                            }
                            onChange={(e) => handlePiecesInputChange(e, index)}
                            inputProps={{ maxLength: 8 }}
                            onKeyDown={handleDecimalValue}
                            InputProps={{
                              readOnly: isAlert,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      {index === piecesData.length - 1 && !isAlert && (
                        <Button
                          variant="outlined"
                          id="btnAddEdit"
                          className="rounded add-edit_btn width100px"
                          onClick={handleClickOpen}
                          sx={{ padding: "5px 22px!important", width: "100%" }}
                        >
                          ADD/EDIT PIECE
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ))}

      <EditPiecesDialog
        open={open}
        handleClose={handleClose}
        shipmentGUID={shipmentGUID}
        setPiecesData={setPiecesData}
        piecesData={piecesData}
        getShipmentPieceList={getShipmentPieceList}
        accessModule="editOrder"
        accountNumber={accountNumber}
        packageTypeDependancyCustID={packageTypeDependancyCustID}
        updateEditOrderData={updateEditOrderData}
      />
    </Box>
  );
};

export default EditPackageInfo;
