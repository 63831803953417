import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import useAccounntsLogic from "../../lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../hook/use-decodeJWT";
import { MySettingDto } from "../../../models/my-setting-dto";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";

interface AccountSettingsProps {
  mySetting: MySettingDto;
  handleInputChange: (updatedData: any) => void;
  formErrors: any;
}

const AccountSettings: React.FC<AccountSettingsProps> = ({
  mySetting,
  handleInputChange,
  formErrors,
}) => {
  const isUPSPremierAgent =
    localStorage.getItem("isUPSPremierAgent") === "true";

  const goldPlatinumUserAccount = "6W1259"; //just to set the priority
  const { account, accountForUPS } = useAccounntsLogic();
  const [accounts, setAccounts] = useState<any>([]);
  const token = useDecodeJWT();

  useEffect(() => {
    if (!isUPSPremierAgent) {
      getAccounts();
    }
  }, []);

  useEffect(() => {
    if (isUPSPremierAgent) {
      getAccounts();
    }
  }, [mySetting?.billToType]);

 
  const getAccounts = async () => {
    try {
      let response: any;
      if (isUPSPremierAgent) {
        let groupName = "UPS Premier Silver Accounts";
        if (mySetting?.billToType === "G") {
          groupName = "UPS Premier Gold Accounts";
        } else if (mySetting?.billToType === "P") {
          groupName = "UPS Premier Platinum Accounts";
        }
        const param = {
          userId: token.UserId,
          groupName: groupName,
          isBillable: true,
          viewOrders: false,
        };
        response = await accountForUPS(param);
      } else {
        const param = {
          userId: token.UserId,
          companyID: 0,
          useIncludeAllItemrId: false,
        };
        response = await account(param);
      }
      if (response.data && response.data.data) {
        setAccounts(response.data.data);
      } else {
        setAccounts([]);
      }
    } catch (err) {
      setAccounts([]);
    }
  };

  const menuStyles = {
    maxHeight: "200px", // Default max height for desktop
  };

  const accountsAutoComplete = useMemo(() => {
    // if (mySetting?.billToType === "G" || mySetting?.billToType === "P") {
    //   const _prefixAccount = {
    //     label: goldPlatinumUserAccount,
    //     id: goldPlatinumUserAccount,
    //   };
    //   return [_prefixAccount];
    // }
    return accounts.map((account: any) => ({
      label: account?.displayName,
      id: account?.accountNumber,
    }));
  }, [accounts, mySetting?.billToType]);

  const isDisbaleAccount = useMemo(() => {
    const isGoldPlatinum =
      mySetting?.billToType === "G" || mySetting?.billToType === "P";
    if (isGoldPlatinum) {
      let defaultAccount;
      const checkAccount = accounts.find(
        (x: any) => x.accountNumber === goldPlatinumUserAccount
      );
      if (checkAccount) {
        defaultAccount = checkAccount?.accountNumber;
      } else {
        defaultAccount = accounts[0]?.accountNumber;
      }

      handleInputChange({
        target: {
          value: defaultAccount,
          name: "accountNumber",
        },
      });
      handleInputChange({
        target: {
          value: defaultAccount,
          name: "accountNumberShipper",
        },
      });
    }
    if (
      mySetting?.billToType == "A" &&
      mySetting?.accountNumber == goldPlatinumUserAccount
    ) {
      handleInputChange({
        target: {
          value: "",
          name: "accountNumber",
        },
      });

      handleInputChange({
        target: {
          value: "",
          name: "accountNumberShipper",
        },
      });
    }
    return isGoldPlatinum;
  }, [mySetting?.billToType, accounts]);

  const BillingOptions = useMemo(() => {
    if (isUPSPremierAgent) {
      return [
        {
          value: "P",
          label: "UPS Premier Platinum Accounts",
        },
        {
          value: "G",
          label: "UPS Premier Gold Accounts",
        },
        {
          value: "A",
          label: "UPS Premier Silver Accounts",
        },
      ];
    } else {
      return [
        {
          value: "S",
          label: "SHIPPER",
        },
        {
          value: "C",
          label: "CONSIGNEE",
        },
        {
          value: "3",
          label: "3RD PARTY",
        },
      ];
    }
  }, [isUPSPremierAgent]);

  const selectedBillAcc = useMemo(() => {
    const checkAccount = accounts.find(
      (x: any) => x.accountNumber === mySetting?.accountNumber
    );
    if (checkAccount) {
      return {
        label: checkAccount?.displayName,
        id: checkAccount?.accountNumber,
      };
    }
    return null;
  }, [mySetting.accountNumber, accounts, isDisbaleAccount]);

  const selectedShipAcc = useMemo(() => {
    const checkAccount = accounts.find(
      (x: any) => x.accountNumber === mySetting?.accountNumberShipper
    );
    if (checkAccount) {
      return {
        label: checkAccount?.displayName,
        id: checkAccount?.accountNumber,
      };
    }
    return null;
  }, [mySetting.accountNumberShipper, accounts, isDisbaleAccount]);

  return (
    <Box className="p16 border rounded">
      <Typography className="sub_header">Account settings</Typography>
      <Grid container spacing={2}>
        <Grid item lg={12 / 5} md={6} xs={6}>
          <TextField
            id="txtLogonName"
            name="logonName"
            label="User Id"
            variant="outlined"
            className="w-100"
            inputProps={{ maxLength: 50 }}
            size="small"
            defaultValue={mySetting.logonName}
            onChange={handleInputChange}
            error={!!formErrors.logonName}
            disabled 
            InputProps={{
              endAdornment: !!formErrors.logonName && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  ></InfoRoundedIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item lg={12 / 5} md={6} xs={6}>
          <TextField
            id="txtPassword"
            name="password"
            label="New Password"
            variant="outlined"
            className="w-100"
            size="small"
            type="password"
            value={mySetting.password}
            onChange={handleInputChange}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
        <Grid item lg={12 / 5} md={6} xs={6}>
          <TextField
            id="txtFirstName"
            name="firstName"
            label="First Name"
            variant="outlined"
            className="w-100"
            size="small"
            defaultValue={mySetting.firstName}
            onChange={handleInputChange}
            error={!!formErrors.firstName}
            inputProps={{ maxLength: 20 }}
            InputProps={{
              endAdornment: !!formErrors.firstName && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  ></InfoRoundedIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item lg={12 / 5} md={6} xs={6}>
          <TextField
            id="txtlastName"
            name="lastName"
            label="Last Name"
            variant="outlined"
            className="w-100"
            size="small"
            defaultValue={mySetting.lastName}
            onChange={handleInputChange}
            error={!!formErrors.lastName}
            inputProps={{ maxLength: 20 }}
            InputProps={{
              endAdornment: !!formErrors.lastName && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  ></InfoRoundedIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          lg={12 / 5}
          md={6}
          xs={6}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
            },
          }}
        ></Grid>
      </Grid>
      <Grid container spacing={2} className="mt-0">
        <Grid item lg={4} md={4} sm={6} xs={6}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small-label">Bill To</InputLabel>
            <Select
              className="w-100"
              labelId="demo-select-small-label"
              id="ddBillToType"
              label="Bill To"
              name="billToType"
              defaultValue={mySetting.billToType}
              onChange={handleInputChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },

                PaperProps: {
                  style: menuStyles,
                },
              }}
            >
              {BillingOptions.length > 0 &&
                BillingOptions.map((_opt: any) => (
                  <MenuItem key={_opt.value} value={_opt.value}>
                    {_opt.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={6}>
          <FormControl size="small" fullWidth>
            {!isUPSPremierAgent && (
              <>
                <InputLabel id="Bill Acct">Bill Acct # </InputLabel>
                <Select
                  className="w-100"
                  id="ddBillAccount"
                  label="Bill Acct #"
                  name="accountNumber"
                  defaultValue={mySetting.accountNumber}
                  onChange={handleInputChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },

                    PaperProps: {
                      style: menuStyles,
                    },
                  }}
                >
                  {accounts.length > 0 &&
                    accounts.map((account: any) => (
                      <MenuItem
                        key={account.userAccountRowGUID}
                        value={account.accountNumber}
                      >
                        {account.displayName}
                      </MenuItem>
                    ))}
                </Select>
              </>
            )}

            {isUPSPremierAgent && (
              <>
                <Autocomplete
                  disabled={isDisbaleAccount}
                  disablePortal
                  size="small"
                  options={accountsAutoComplete}
                  getOptionLabel={(option: any) => option.label}
                  value={selectedBillAcc}
                  id="ddBillAccount"
                  onChange={(event, newValue: any) => {
                    //setSelectAccount(newValue);
                    handleInputChange({
                      target: {
                        value: newValue?.id,
                        name: "accountNumber",
                      },
                    });
                  }}
                  className="w-100"
                  renderInput={(params) => (
                    <TextField {...params} label="Bill Acct #" />
                  )}
                />
              </>
            )}
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <FormControl size="small" fullWidth>
            {!isUPSPremierAgent && (
              <>
                <InputLabel id="demo-select-small-label">
                  Shipper Acct #
                </InputLabel>
                <Select
                  className="w-100"
                  labelId="demo-select-small-label"
                  id="txtShipperName"
                  label="Shipper Acct #"
                  name="accountNumberShipper"
                  defaultValue={mySetting.accountNumberShipper}
                  onChange={handleInputChange}
                  error={!!formErrors.accountNumberShipper}
                  inputProps={{ maxLength: 50 }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },

                    PaperProps: {
                      style: menuStyles,
                    },
                  }}
                >
                  {accounts.length > 0 &&
                    accounts.map((account: any) => (
                      <MenuItem
                        key={account.userAccountRowGUID}
                        value={account.accountNumber}
                      >
                        {account.displayName}
                      </MenuItem>
                    ))}
                </Select>
              </>
            )}

            {isUPSPremierAgent && (
              <>
                <Autocomplete
                  size="small"
                  disabled={
                    isDisbaleAccount && !formErrors.accountNumberShipper
                  }
                  readOnly={
                    !!formErrors.accountNumberShipper &&
                    (mySetting.billToType == "G" || mySetting.billToType == "P")
                      ? true
                      : false
                  }
                  disablePortal
                  options={accountsAutoComplete}
                  getOptionLabel={(option: any) => option.label}
                  value={selectedShipAcc}
                  id="txtShipperName"
                  onChange={(event, newValue: any) => {
                    //setSelectAccount(newValue);
                    handleInputChange({
                      target: {
                        value: newValue?.id,
                        name: "accountNumberShipper",
                      },
                    });
                  }}
                  className="w-100"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Shipper Acct #"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: !!formErrors.accountNumberShipper
                              ? "#d32f2f"
                              : undefined,
                          },
                          "&:hover fieldset": {
                            borderColor: !!formErrors.accountNumberShipper
                              ? "#d32f2f"
                              : undefined,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: !!formErrors.accountNumberShipper
                              ? "#d32f2f"
                              : undefined,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: !!formErrors.accountNumberShipper
                            ? "#d32f2f"
                            : undefined,
                        },
                        "& .MuiInputBase-input": {
                          color: !!formErrors.accountNumberShipper
                            ? "#d32f2f"
                            : "inherit",
                        },
                      }}
                    />
                  )}
                />
              </>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountSettings;
