import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { fetchRecoveryData, handleSubmitPwd } from "./change-password.reducer";

const useChangePasswordLogic = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state: RootState) => state.auth);

  const handleRecoveryData = async (param: { GUID: string | null }) => {
    try {
      const response = await dispatch(fetchRecoveryData(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleSubmit = async (param: {
    GUID: string | null;
    Password: string;
  }) => {
    try {
      const response = await dispatch(handleSubmitPwd(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    loading,
    error,
    recoverData: handleRecoveryData,
    submit: handleSubmit,
  };
};
export default useChangePasswordLogic;
