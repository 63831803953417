import React, { useState, FC, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Box,
  TableRow,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../util/loader";
import { PieceItemDto } from "../../../models/piece-item-dto";
import NewPiecesDialog from "./new-piece-dialog";
import editicon from "../../../assets/images/logo/edit-icon.svg";
import deleteicon from "../../../assets/images/logo/delete-icon.svg";
import { sortAndUpdateTheData } from "../../util/rate-estimate";

import StaticTableHead from "../table-head-cell/static-table-head";

// Define the prop types for the MyDialog component
interface MyDialogProps {
  open: boolean;
  handleClose: () => void;
  onPieceChange: (pieces: any) => void;
  packageData: any;
  longFormData?: any;
  accountNumber?: number;
  packageTypeDependancyCustID?: any;
}

type Order = "desc" | "asc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof PieceItemDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

const headCells: HeadCell[] = [
  {
    id: "contents",
    numeric: false,
    disablePadding: false,
    label: "Contents",
    minwidth: "200",
  },
  {
    id: "reference",
    numeric: false,
    disablePadding: false,
    label: "Refer",
    minwidth: "110",
  },
  {
    id: "weight",
    numeric: false,
    disablePadding: false,
    label: "Weight",
    minwidth: "110",
  },
  {
    id: "length",
    numeric: false,
    disablePadding: false,
    label: "Size",
    minwidth: "180",
  },
  {
    id: "packagingType",
    numeric: false,
    disablePadding: false,
    label: "Packaging",
    minwidth: "180",
  },
  {
    id: "dimWeight",
    numeric: false,
    disablePadding: false,
    label: "Temp",
    minwidth: "100",
  },
  {
    id: "isDangerousGoods",
    numeric: false,
    disablePadding: false,
    label: "DG",
    minwidth: "60",
  },
  {
    id: "isRadioactive",
    numeric: false,
    disablePadding: false,
    label: "RG",
    minwidth: "60",
  },
  {
    id: "dgUNNumber",
    numeric: false,
    disablePadding: false,
    label: "UN #",
    minwidth: "100",
  },
  {
    id: "dgCommodityClass",
    numeric: false,
    disablePadding: false,
    label: "Class",
    minwidth: "100",
  },
  {
    id: "dgProperName",
    numeric: false,
    disablePadding: false,
    label: "Commodity (PPN)",
    minwidth: "180",
  },
];

const PiecesDialog: FC<MyDialogProps> = ({
  open,
  handleClose,
  onPieceChange,
  packageData,
  longFormData,
  accountNumber,
  packageTypeDependancyCustID,
}) => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof PieceItemDto>("contents");
  const [isLoading, setIsLoading] = useState(false);
  const [piecesData, setPiecesData] = useState<PieceItemDto[]>([]);
  const [DialogOpen, setDialogOpen] = useState(false);
  const [currentPieceIndex, setCurrentPieceIndex] = useState<number | null>(
    null
  );

  const initialPieceData: PieceItemDto = {
    contents: "",
    reference: "",
    weight: "",
    dimWeight: 0.0,
    weightUOM: "LB",
    length: undefined,
    width: undefined,
    height: undefined,
    sizeUOM: "IN",
    quantity: 1,
    declaredValue: undefined,
    declaredValueCurrencyID: "USD",
    packagingTypeGUID_Outer: "",
    packagingTypeGUID_Inner: "",
    isDangerousGoods: false,
    isRadioactive: false,
    dgUNNumber: "",
    dgCommodityClass: "",
    dgProperName: "",
    dgDryIceWeight: undefined,
    dgGelPackCount: undefined,
    dgPackingGroup: "",
    dgQuantityAndPackType: "",
    dgPackingInstr: "",
    dgAuthorization: "",
    packagingType: "",
    bagGUID: "",
    bagGUID2: "",
    count: 1,
    insertUserID: undefined,
    oBagGUID: "",
    oBagGUID2: "",
    countryOfOrigin: "",
    hsCode: "",
    carrierWaybillNumber: "",
    temperatureType: "",
    carrierToProvideDryIce: false,
    carrierToProvideGelPack: false,
    isDryIceRequire: false,
    isGelPacksRequire: false,
  };

  const [currentPiece, setCurrentPiece] =
    useState<PieceItemDto>(initialPieceData);

  useEffect(() => {
    setCurrentPiece(initialPieceData);
  }, [DialogOpen]);

  useEffect(() => {
    //soth data
    const sortedData = sortAndUpdateTheData(piecesData, order, orderBy);
    setPiecesData(sortedData);
  }, [order, orderBy]);

  useEffect(() => {
    setPiecesData(packageData || []);
  }, [packageData]);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentPieceIndex(null);
  };

  const handleNewPieceAdded = (newPiece: PieceItemDto) => {
    if (currentPieceIndex !== null && currentPieceIndex >= 0) {
      // Edit existing piece
      setPiecesData((prevData) =>
        prevData.map((piece, index) =>
          index === currentPieceIndex ? newPiece : piece
        )
      );
      setCurrentPieceIndex(null);
    } else {
      // Add new piece
      setPiecesData((prevData) => [...prevData, newPiece]);
    }
    setDialogOpen(false);
  };

  const handleEdit = (piece: PieceItemDto, index: number) => {
    setCurrentPieceIndex(index);
    setCurrentPiece(piece);
    setDialogOpen(true);
  };

  const handleDelete = (index: number) => {
    const confirm = window.confirm(
      "Are you sure you want to remove this piece?"
    );
    if (confirm) {
      setPiecesData((prevData) => prevData.filter((_, i) => i !== index));
    }
  };

  const handleSave = (piecesDetails: PieceItemDto[]) => {
    if (piecesDetails.length > 0) {
      onPieceChange(piecesDetails);
    } else {
      onPieceChange([]);
    }
    handleClose();
  };

  return (
    <Dialog
      maxWidth={"xl"}
      open={open}
      style={{ height: "88%" }}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className="Popup-header" id="responsive-dialog-title">
        Piece Detail
      </DialogTitle>
      <DialogContent>
        <div className="container-fluid Piecesgrid code-form">
          <>
            <Box sx={{ width: "100%" }}>
              <Paper
                className="ActiveOrder Pieces-grid-table"
                sx={{ width: "100%", mb: 2 }}
              >
                <TableContainer className="table-container">
                  <Table aria-labelledby="tableTitle">
                    <StaticTableHead headCells={headCells} />
                    <TableBody>
                      {isLoading ? (
                        <TableRow>
                          <TableCell className="" colSpan={13} align="center">
                            <Loader />
                          </TableCell>
                        </TableRow>
                      ) : piecesData.length === 0 ? (
                        <TableRow>
                          <TableCell className="" colSpan={13}>
                            No data to display
                          </TableCell>
                        </TableRow>
                      ) : (
                        piecesData.map((row: PieceItemDto, index: any) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                className="Info-wrap"
                                style={{ minWidth: 200 }}
                              >
                                {row.contents}
                              </TableCell>
                              <TableCell style={{ minWidth: 110 }}>
                                {row.reference}
                              </TableCell>
                              <TableCell style={{ minWidth: 110 }}>
                                {row.weight && row.weightUOM
                                  ? `${row.weight} ${row.weightUOM}`
                                  : ""}
                              </TableCell>
                              <TableCell style={{ minWidth: 180 }}>
                                {row.length && row.width && row.height
                                  ? `${row.length} X ${row.width} X ${row.height} ${row.sizeUOM}`
                                  : ""}
                              </TableCell>
                              <TableCell style={{ minWidth: 180 }}>
                                {row.packagingType}
                              </TableCell>
                              <TableCell style={{ minWidth: 60 }}></TableCell>
                              <TableCell style={{ minWidth: 60 }}>
                                <Checkbox
                                  className="grid_chkbx"
                                  id="cbPieceIsDangerousGoods"
                                  checked={row.isDangerousGoods}
                                />
                              </TableCell>
                              <TableCell style={{ minWidth: 60 }}>
                                <Checkbox
                                  className="grid_chkbx"
                                  checked={row.isRadioactive}
                                  id="cbPieceIsRadioactive"
                                />
                              </TableCell>
                              <TableCell style={{ minWidth: 100 }}>
                                {row.dgUNNumber}
                              </TableCell>
                              <TableCell style={{ minWidth: 100 }}>
                                {row.dgCommodityClass}
                              </TableCell>
                              <TableCell style={{ minWidth: 180 }}>
                                {row.dgProperName}
                              </TableCell>
                              <TableCell style={{ minWidth: 40 }}>
                                <Button
                                  onClick={() => handleEdit(row, index)}
                                  id="btnPieceEdit"
                                >
                                  <img src={editicon} alt="Edit icon" />{" "}
                                </Button>
                              </TableCell>
                              {index !== 0 && (
                                <TableCell style={{ minWidth: 40 }}>
                                  <Button
                                    onClick={() => handleDelete(index)}
                                    id="btnPieceDelete"
                                  >
                                    <img src={deleteicon} alt="Delete icon" />
                                  </Button>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </>
        </div>
      </DialogContent>
      <DialogActions className="d-flex w-100 justify-content-between">
        <Button
          variant="text"
          className="align-center fw-bold"
          onClick={handleClickOpen}
          id="btnPieceNew"
        >
          NEW
          <AddIcon />
        </Button>
        <NewPiecesDialog
          open={DialogOpen}
          piece={currentPiece}
          handleClose={handleDialogClose}
          onAddNewPiece={handleNewPieceAdded}
          currentPieceIndex={currentPieceIndex}
          longFormData={longFormData}
          accountNumber={accountNumber}
          packageTypeDependancyCustID={packageTypeDependancyCustID}
        />
        <Box className="d-flex gap-4">
          <Button
            onClick={() => handleSave(piecesData)}
            className="MuiButton Action-btn"
            variant="text"
            id="btnPieceClose"
          >
            CLOSE
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PiecesDialog;
