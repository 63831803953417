import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";

// Action Types for Account
const ACCOUNT_REQUEST = "ACCOUNT_REQUEST";
const ACCOUNT_SUCCESS = "ACCOUNT_SUCCESS";
const ACCOUNT_FAILURE = "ACCOUNT_FAILURE";

// Action Creators for Account
export const accountRequest = () => ({
  type: ACCOUNT_REQUEST,
});

export const accountSuccess = (data: any) => ({
  type: ACCOUNT_SUCCESS,
  payload: data,
});

export const accountFailure = (error: string) => ({
  type: ACCOUNT_FAILURE,
  payload: error,
});

// Initial State for Account
export interface AccountState {
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  accounts: any;
}

const initialAuthState: AccountState = {
  isAuthenticated: false,
  loading: false,
  error: null,
  accounts: [],
};

// Define the interface for the API response
interface AccountResponse {
  success: boolean;
  message: string;
  data: {
    userAccountRowGUID: string;
    accountNumber: string;
    userID: number;
    displayName: string;
    operationsAlertEmail: string;
    isBillable: boolean;
    viewOrders: boolean;
  };
  statusCode: string;
}

// Reducer for Authentication
const accountReducer = (
  state = initialAuthState,
  action: any
): AccountState => {
  switch (action.type) {
    case ACCOUNT_REQUEST:
      return { ...state, loading: true, error: null };
    case ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        accounts: action.payload,
      };
    case ACCOUNT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// Thunk for Account
export const account =
  (param: {
    userId: number;
    companyID: number;
    useIncludeAllItemrId: boolean;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(accountRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetAccounts`,
        param
      );

      const data: AccountResponse = response.data;
      // Assuming Account was successful if no error occurred
      dispatch(accountSuccess(response));
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(accountFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(accountFailure(error.response.data.message));
      } else {
        dispatch(accountFailure(error.message));
      }
    }
  };

export const accountForUPS =
  (param: {
    userId: number;
    groupName: string | string[];
    isBillable: boolean;
    viewOrders: boolean;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(accountRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetUserCustomerAccounts`,
        param
      );

      const data: AccountResponse = response.data;
      // Assuming Account was successful if no error occurred
      dispatch(accountSuccess(response));
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(accountFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(accountFailure(error.response.data.message));
      } else {
        dispatch(accountFailure(error.message));
      }
    }
  };

export default accountReducer;
