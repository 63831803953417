import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { TrackOrderItemDto } from "../../models/track-order-item-dto";

// Action Types for Track
const TRACK_REQUEST = "TRACK_REQUEST";
const TRACK_SUCCESS = "TRACK_SUCCESS";
const TRACK_FAILURE = "TRACK_FAILURE";

// Action Creators for track
export const trackRequest = () => ({
  type: TRACK_REQUEST,
});

export const trackSuccess = () => ({
  type: TRACK_SUCCESS,
});

export const trackFailure = (error: string) => ({
  type: TRACK_FAILURE,
  payload: error,
});

// Initial State for Track
export interface TrackState {
  isAuthenticated: boolean;
  trackLoading: boolean;
  trackError: string | null;
}

const initialAuthState: TrackState = {
  isAuthenticated: false,
  trackLoading: false,
  trackError: null,
};

// Define the interface for the API response
interface TrackResponse {
  success: boolean;
  message: string;
  data: Array<TrackOrderItemDto>;
  statusCode: string;
}

// Reducer for Authentication
const trackReducer = (state = initialAuthState, action: any): TrackState => {
  switch (action.type) {
    case TRACK_REQUEST:
      return { ...state, trackLoading: true, trackError: null };
    case TRACK_SUCCESS:
      return { ...state, trackLoading: false, isAuthenticated: true };
    case TRACK_FAILURE:
      return { ...state, trackLoading: false, trackError: action.payload };
    default:
      return state;
  }
};

// Thunk for Active Order
export const track =
  (param: { trackingNumber: string; shipmentGUID: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(trackRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/TrackOrder`,
        param
      );
      // Assuming  Active Order was successful if no error occurred
      dispatch(trackSuccess());
      const data: TrackResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(trackFailure(data.message));
        return { data };
      }
      // return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(trackFailure(error.response.data.message));
        return error.response;
      } else {
        dispatch(trackFailure(error.message));
      }
    }
  };

export default trackReducer;
