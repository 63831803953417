import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";

// Action Types for Content
const NOTES_REQUEST = "NOTES_REQUEST";
const NOTES_SUCCESS = "NOTES_SUCCESS";
const NOTES_FAILURE = "NOTES_FAILURE";
const NOTES_UPDATE_SUCCESS = "NOTES_UPDATE_SUCCESS";

// Action Creators for notes
export const notesRequest = () => ({
  type: NOTES_REQUEST,
});

export const notesSuccess = (data: any) => ({
  type: NOTES_SUCCESS,
  payload: data,
});

export const notesUpdateSuccess = (data: any) => ({
  type: NOTES_UPDATE_SUCCESS,
  payload: data,
});

export const notesFailure = (error: string) => ({
  type: NOTES_FAILURE,
  payload: error,
});

// Initial State for notes
export interface NotesState {
  isAuthenticated: boolean;
  notesLoading: boolean;
  notesError: string | null;
  releaseNotesData: any;
  isAlreadyViewed: boolean;
}

const initialAuthState: NotesState = {
  isAuthenticated: false,
  notesLoading: false,
  notesError: null,
  releaseNotesData: [],
  isAlreadyViewed: false,
};

// Define the interface for the API response
interface NotesResponse {
  success: boolean;
  message: string;
  data: keyof any;
  statusCode: string;
}

// Reducer for Authentication
const releaseNotesReducer = (
  state = initialAuthState,
  action: any
): NotesState => {
  switch (action.type) {
    case NOTES_REQUEST:
      return { ...state, notesLoading: true, notesError: null };
    case NOTES_SUCCESS:
      return {
        ...state,
        notesLoading: false,
        isAuthenticated: true,
        isAlreadyViewed: false,
        releaseNotesData: action.payload,
      };

    case NOTES_UPDATE_SUCCESS:
      return {
        ...state,
        isAlreadyViewed: true,
      };
    case NOTES_FAILURE:
      return { ...state, notesLoading: false, notesError: action.payload };
    default:
      return state;
  }
};

// Thunk for Content
export const releaseNotes =
  (param: { isPopup: boolean }) => async (dispatch: Dispatch) => {
    dispatch(notesRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/GetReleaseNotes`,
        param
      );
      // Assuming Account was successful if no error occurred

      const data: NotesResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        dispatch(notesSuccess(response));
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(notesFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(notesFailure(error.response.data.message));
      } else {
        dispatch(notesFailure(error.message));
      }
    }
  };

// Thunk for Content
export const updateReleaseNotes =
  (param: { userId: number | string; validFrom?: string; validTo?: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(notesRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/InsertTrackReleaseNote`,
        param
      );
      // Assuming Account was successful if no error occurred

      const data: NotesResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        dispatch(notesUpdateSuccess(response));
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(notesFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(notesFailure(error.response.data.message));
      } else {
        dispatch(notesFailure(error.message));
      }
    }
  };

export const releaseNotesContents =
  (param: { techRelNoteGUID: string }) => async (dispatch: Dispatch) => {
    dispatch(notesRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/TechnologyReleaseNoteContent`,
        param
      );
      // Assuming Account was successful if no error occurred

      const data: NotesResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        dispatch(notesSuccess(response));
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(notesFailure(data.message));
      }
      return response.data;
    } catch (error: any) {}
  };

export default releaseNotesReducer;
