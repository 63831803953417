import { combineReducers } from "redux";
import authReducer from "../../modules/auth/login.reducer";
import notificationReducer from "./notification.reducer";
import accountReducer from "../lookup/accounts/accounts.reducer";
import activeOrdersReducer from "../../modules/active-orders/active-orders.reducer";
import longFormReducer from "../../modules/long-form-orders/long-form-order.reducer";
import commodityReducer from "../lookup/commodity/commodity.reducer";
import packageTypeReducer from "../lookup/package-type/package-type.reducer";
import contentReducer from "../lookup/contents/contents.reducer";
import unDetailsReducer from "../lookup/UN-details/unDetails.reducer";
import addressReducer from "../lookup/address/address.redux";
import countryReducer from "../lookup/country/country.reducer";
import zipCodeReducer from "../lookup/zip-code/zip-code.reducer";
import currencyReducer from "../lookup/currency/currency.reducer";
import searchOrdersReducer from "../../modules/search-orders/search-orders.reducer";
import serviceReducer from "../lookup/service/service.reducer";
import customerAddressReducer from "../customer/address/customer-address.redux";
import longFormOrderReducer from "../../modules/long-form-orders/long-form-order-create.reducer";
import userInfoReducer from "../auth/user-info/user-info.reducer";

import batchPrintsReducer from "../../modules/batch-prints/batch-prints.reducer";
import trackReducer from "../../modules/track-order/track-order.reducer";
import unauthTrackReducer from "../../modules/track-order/track-order.unauth.reducer";
import addressBookReducer from "../../modules/address-book/address-book.reducer";
import multipleFileUploadReducer from "../document-upload/multiple-document-upload.redux";
import documentTypeReducer from "../lookup/document-type/document-type.reducer";
import timezoneReducer from "../lookup/timezone/timezone.reducer";
import signupReducer from "../../modules/sign-up/signup.reducer";
import verifyUserReducer from "../../modules/sign-up/verify-user.reducer";
import multiOrderReducer from "../../modules/multi-order/multi-form-order-create.reducer";
import referenceReducer, {
  reference,
} from "../lookup/reference/reference.reducer";
import supportSignupReducer from "../../modules/support-sign-in/support-sign in.reducer";
import editOrderReducer from "../../modules/edit-active-order/edit-active-order.reducer";
import rateEstimateReducer from "../../modules/rate-estimate/rate-estimate.reducer";
import termsAndConditionsReducer from "../../modules/terms-and-conditions/terms-and-conditions.reducer";
import deleteShipmentPieceReducer from "../lookup/shipment-piece/delete-shipment-piece/delete-shipment-piece.reducer";
import getShipmentPieceReducer from "../lookup/shipment-piece/get-shipment-piece/get-shipment-piece.reducer";
import insertShipmentPieceReducer from "../lookup/shipment-piece/insert-shipment-piece/insert-shipment-piece.reducer";
import updateShipmentPieceReducer from "../lookup/shipment-piece/update-shipment-piece/update-shipment-piece.reducer";
import updateEditOrderReducer from "../../modules/edit-active-order/update-edit-active-order.reducer";
import searchUNDetailsReducer from "../lookup/searchUn-details/searchUNDetails.reducer";
import exportDataReducer from "../../modules/export-data/export-data.reducer";
import officeReducer from "../lookup/office/office.reducer";
import invoicesReducer from "../../modules/invoices/invoices.reducer";
import userReferenceGroupReducer from "../lookup/user-reference-group/user-reference-group.reducer";
import referenceTypeReducer from "../lookup/reference/reference-type.reducer";
import mySettingsReducer from "../../modules/my-settings/my-settings.reducer";
import willCallReducer from "../../modules/will-call-orders/will-call-orders.reducer";
import longFormSaveOrderReducer from "../../modules/long-form-orders/long-form-order-save.reducer";
import routeRecommendationsReducer from "../../modules/route-preview/route-recommendations.reducer";
import timezoneFilterReducer from "../lookup/timezone/timezone-filter.reducer";
import multipleFileUploadWithoutLoaderReducer from "../document-upload/multiple-document-without-loader-upload.redux";
import getDangerousGoodsReducer from "../../modules/track-order/track-order-get-dangerous-good.reducer";
import { updateDangerousGoods } from "../../modules/track-order/track-order-edit-dangerous-good.reducer";
import attributesReducer from "../lookup/attributes/attributes.reducer";
import healthCareDonorReducer from "../../modules/healthcare-donor/healthcare-donor.reducer";
import healthCareReducer from "../../modules/healthcare-form/healthcare-form.reducer";
import bookOrderWithoutRouteReducer from "../../modules/route-preview/book-order-without-route.reducer";
import bookOrderWithRouteReducer from "../../modules/route-preview/book-order-with-route.reducer";
import longFormReOrderReducer from "../../modules/long-form-orders/long-form-re-order-create.reducer";
import bookReOrderWithRouteReducer from "../../modules/route-preview/book-re-order-with-route.reducer";
import releaseNotesReducer from "../lookup/release-notes/release-notes.reducer";
const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  acconts: accountReducer,
  activeOrders: activeOrdersReducer,
  longForm: longFormReducer,
  commodity: commodityReducer,
  packageType: packageTypeReducer,
  content: contentReducer,
  unDetails: unDetailsReducer,
  address: addressReducer,
  country: countryReducer,
  zipCode: zipCodeReducer,
  currency: currencyReducer,
  searchOrders: searchOrdersReducer,
  service: serviceReducer,
  customerAddress: customerAddressReducer,
  longFormOrder: longFormOrderReducer,
  userInfo: userInfoReducer,
  batchPrints: batchPrintsReducer,
  track: trackReducer,
  unauthTrack: unauthTrackReducer,
  multipleFileUpload: multipleFileUploadReducer,
  documentType: documentTypeReducer,
  addressBooks: addressBookReducer,
  timezone: timezoneReducer,
  signup: signupReducer,
  verifyUser: verifyUserReducer,
  multiOrder: multiOrderReducer,
  reference: referenceReducer,
  supportSignup: supportSignupReducer,
  editOrder: editOrderReducer,
  rateEstimate: rateEstimateReducer,
  deleteShipmentPiece: deleteShipmentPieceReducer,
  getShipmentPiece: getShipmentPieceReducer,
  insertShipmentPiece: insertShipmentPieceReducer,
  updateShipmentPiece: updateShipmentPieceReducer,
  updateEditOrder: updateEditOrderReducer,
  searchUNDetails: searchUNDetailsReducer,
  exportData: exportDataReducer,
  office: officeReducer,
  termsAndConditions: termsAndConditionsReducer,
  invoices: invoicesReducer,
  userReferenceGroup: userReferenceGroupReducer,
  referenceType: referenceTypeReducer,
  mySettings: mySettingsReducer,
  willCall: willCallReducer,
  longFormSaveOrder: longFormSaveOrderReducer,
  routeRecommendations: routeRecommendationsReducer,
  timezoneFilter: timezoneFilterReducer,
  multipleFileUploadWithoutLoader: multipleFileUploadWithoutLoaderReducer,
  getDangerousGoods: getDangerousGoodsReducer,
  updateDangerousGoods: updateDangerousGoods,
  attributes: attributesReducer,
  healthCareDonor: healthCareDonorReducer,
  healthCare: healthCareReducer,
  bookOrderWithoutRoute: bookOrderWithoutRouteReducer,
  bookOrderWithRoute: bookOrderWithRouteReducer,
  longFormReOrder: longFormReOrderReducer,
  bookReOrderWithRoute: bookOrderWithRouteReducer,
  bookReOrderWithoutRoute: bookReOrderWithRouteReducer,
  releaseNotes: releaseNotesReducer,
  // Add other reducers here
});

export default rootReducer;
