import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { RouteInfoItemDto } from "../../../models/track-order-item-dto";

import LaunchIcon from "@mui/icons-material/Launch";
import {
  formatAirline,
  formatArrive,
  formatDepart,
} from "../../util/formate-route-info";
import { Button } from "@mui/material";

interface RouteInfoProps {
  routeInfo: RouteInfoItemDto[];
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof RouteInfoItemDto;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof RouteInfoItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const RouteInfo: React.FC<RouteInfoProps> = ({ routeInfo }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof RouteInfoItemDto>("sequenceNumber");
  const [routeData, setRouteData] = useState<RouteInfoItemDto[]>([]);
  const [notData, setNotData] = useState<any>("");

  useEffect(() => {
    if (routeInfo.length !== 0) {
      setRouteData(routeInfo);
    } else {
      setNotData("(no routing is currently available for this shipment)");
    }
  }, [routeInfo]);

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] === null) {
      return -1;
    }

    if (a[orderBy] === null) {
      return 1;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof RouteInfoItemDto>(
    order: Order,

    orderBy: Key
  ): (a: RouteInfoItemDto, b: RouteInfoItemDto) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedRouteData = routeData.slice().sort(getComparator(order, orderBy));

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RouteInfoItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const RoutingInformationHeadCells: readonly HeadCell[] = [
    {
      id: "statusDisplay",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "carrierName",
      numeric: false,
      disablePadding: false,
      label: "Airline",
    },
    {
      id: "departHubID",
      numeric: false,
      disablePadding: false,
      label: "Depart",
    },
    {
      id: "arriveHubID",
      numeric: false,
      disablePadding: false,
      label: "Arrive",
    },
    {
      id: "waybillNumber",
      numeric: false,
      disablePadding: false,
      label: "MAWB",
    },
    {
      id: "arriveHubID",
      numeric: false,
      disablePadding: false,
      label: "Track",
    },
  ];

  const handleTrackFlight = (trackingPrefix: string) => {
    const options = "noopener,noreferrer";
    window.open(trackingPrefix, "_blank", options);
  };

  function RoutingInformationEnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof RouteInfoItemDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {RoutingInformationHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box>
      <TableContainer>
        <div className="RouteTable">
          <Table className="RouteTablebody" aria-labelledby="tableTitle">
            <RoutingInformationEnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={routeData.length}
            />
            <TableBody>
              {sortedRouteData.length === 0 ? (
                <TableRow id="trRouting">
                  <TableCell
                    className="Datanofound"
                    colSpan={12}
                    align="center"
                    id="tdDataNotFound"
                  >
                    {notData}
                  </TableCell>
                  {/* Adjust the colspan according to the number of columns in your table */}
                </TableRow>
              ) : (
                sortedRouteData.map((row, index) => {
                  //const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={row.sequenceNumber} id="trRouting">
                      <TableCell className="width110" id="tdStatus">
                        <Paper className="active-status" elevation={0}>
                          <Typography
                            className="status"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {row.statusDisplay}
                          </Typography>
                        </Paper>
                      </TableCell>
                      <TableCell className="width-750" id="tdAirline">
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: formatAirline(row),
                          }}
                        ></Box>
                      </TableCell>
                      <TableCell className="width160" id="tdDepart">
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: formatDepart(row),
                          }}
                        ></Box>
                      </TableCell>
                      <TableCell className="width160" id="tdArrive">
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: formatArrive(row),
                          }}
                        ></Box>
                      </TableCell>
                      <TableCell className="width160" id="tdMAWB">
                        <Typography>{row.waybillNumber}</Typography>
                      </TableCell>
                      <TableCell className="width160">
                        {row.trackingPrefix !== null && (
                          <Button
                            variant="outlined"
                            className="track_flight_btn"
                            onClick={() =>
                              handleTrackFlight(row.trackingPrefix)
                            }
                            endIcon={<LaunchIcon />}
                            id="btnTrackFlight"
                          >
                            Track Flight
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </Box>
  );
};

export default RouteInfo;
