import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../../config/constants";


// Action Types for getShipmentPiece
const GETSHIPMENTPIECE_REQUEST = "GETSHIPMENTPIECE_REQUEST";
const GETSHIPMENTPIECE_SUCCESS = "GETSHIPMENTPIECE_SUCCESS";
const GETSHIPMENTPIECE_FAILURE = "GETSHIPMENTPIECE_FAILURE";

// Action Creators for getShipmentPiece
export const getShipmentPieceRequest = () => ({
  type: GETSHIPMENTPIECE_REQUEST,
});

export const getShipmentPieceSuccess = () => ({
  type: GETSHIPMENTPIECE_SUCCESS,
});

export const getShipmentPieceFailure = (error: string) => ({
  type: GETSHIPMENTPIECE_FAILURE,
  payload: error,
});

// Initial State for getShipmentPiece
export interface GetShipmentPieceState {
  isAuthenticated: boolean;
  getShipmentPieceLoading: boolean;
  getShipmentPieceError: string | null;
}

const initialAuthState: GetShipmentPieceState = {
  isAuthenticated: false,
  getShipmentPieceLoading: false,
  getShipmentPieceError: null,
};

// Define the interface for the API response


// Reducer for Authentication
const getShipmentPieceReducer = (
  state = initialAuthState,
  action: any
): GetShipmentPieceState => {
  switch (action.type) {
    case GETSHIPMENTPIECE_REQUEST:
      return { ...state, getShipmentPieceLoading: true, getShipmentPieceError: null };
    case GETSHIPMENTPIECE_SUCCESS:
      return { ...state, getShipmentPieceLoading: false, isAuthenticated: true };
    case GETSHIPMENTPIECE_FAILURE:
      return { ...state, getShipmentPieceLoading: false, getShipmentPieceError: action.payload };
    default:
      return state;
  }
};

// Thunk for getShipmentPiece
export const getShipmentPiece =
  (param: {
    shipmentGUID:string
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(getShipmentPieceRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetShipmentPiece`,
        param
      );
      dispatch(getShipmentPieceSuccess());
      const data:any = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(getShipmentPieceFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(getShipmentPieceFailure(error.response.data.message));
      } else {
        dispatch(getShipmentPieceFailure(error.message));
      }
    }
  };

export default getShipmentPieceReducer;
