import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import editicon from "../../assets/images/logo/Edit_icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import useReferenceGroupLogic from "./reference-group-logic";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import deleteicon from "../../assets/images/logo/delete-icon.svg";
import {
  ReferenceGroupDto,
  ReferenceGroupChildDto,
} from "../../models/reference-group-item-dto";

const ReferenceGroup: React.FC = () => {
  // const [openRowIds, setOpenRowIds] = useState<string[]>([]);
  const [isDialog, setIsDialog] = useState<boolean>(false);
  const [isChildDialog, setIsChildDialog] = useState<boolean>(false);
  const token = useDecodeJWT();
  const {
    GetReferenceGroup,
    AddReferenceGroup,
    UpdateReferenceGroup,
    DeleteReferenceGroup,
    GetReferenceChildGroup,
    AddReferenceChildGroup,
    DeleteReferenceChildGroup,
    error,
  } = useReferenceGroupLogic();
  const [parentTable, setParentTable] = useState<ReferenceGroupDto[]>([]);
  const [childTable, setchildTable] = useState<ReferenceGroupChildDto[]>([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [valueError, setValueError] = useState(false);
  const [value, setValue] = useState("");
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [sorting, setSorting] = useState<"asc" | "desc">("asc");
  const [sortingChild, setSortingChild] = useState<"asc" | "desc">("asc");
  const [submitError, setSubmitError] = useState<any>("");

  // const [value, setValue] = useState("")
  const [currentReferenceGroupGUID, setCurrentReferenceGroupGUID] = useState<
    string | null
  >(null);
  const [openRowId, setOpenRowId] = useState<string | null>(null);

  const handleExpandClick = (id: string) => {
    setOpenRowId((prevOpenRowId) => (prevOpenRowId === id ? null : id));
  };

  const handleTemplateClose = () => {
    setIsDialog(false);
    setName("");
    setDescription("");
    setCurrentReferenceGroupGUID(null);
  };

  const handleDialog = () => {
    setIsDialog(true);
    setNameError(false);
    setDescriptionError(false);
  };

  const handleTemplateChildClose = () => {
    setIsChildDialog(false);
    setValue("");
  };

  const handleChildDialog = () => {
    setIsChildDialog(true);
    setValueError(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Update the corresponding state based on the input field name
    if (name === "name") {
      setName(value);
      if (value) setNameError(false);
    } else if (name === "description") {
      setDescription(value);
      if (value) setDescriptionError(false);
    } else if (name === "value") {
      setValue(value);
      if (value) setValueError(false);
    }
  };

  useEffect(() => {
    fetchReferenceGroup();
    // eslint-disable-next-line
  }, []); // Add dependency array to prevent infinite loop

  const handleSortRequest = (property: string) => {
    const isAsc =  sorting === "asc";
    const newSorting = isAsc ? "desc" : "asc";
    setSorting(newSorting);
    setOrderBy(property);
    fetchReferenceGroup(property, newSorting);
  };

  const handleSortNameRequest = (property: string) => {
    const isAsc =  sortingChild === "asc";
    const newSorting = isAsc ? "desc" : "asc";
    setSortingChild(newSorting);
    setOrderBy(property);
    fetchReferenceChildGroup(property, newSorting);
  };

  const fetchReferenceGroup = async (
    orderBy: string = "",
    sorting: "asc" | "desc" = "asc"
  ) => {
    try {
      const params = {
        UserGUID: token.UserGUID,
        userGroupGUID: "",
        includeBlank: false,
        orderBy: orderBy,
        sorting: sorting,
        pagesize: 10,
        pageNo: 1,
      };

      const response = await GetReferenceGroup(params);
      if (response.data) {
        setParentTable(response.data);
      }
      setSubmitError("");
      if (!response.success) {
        setSubmitError(response.message);
      }
    } catch { }
  };

  const validateErrors = () => {
    let hasErrors = false;
    if (!name) {
      setNameError(true);
      hasErrors = true;
    }
    if (!description) {
      setDescriptionError(true);
      hasErrors = true;
    }
    return hasErrors;
  };

  const validateChildErrors = () => {
    let hasErrors = false;
    if (!value) {
      setValueError(true);
      hasErrors = true;
    }
    return hasErrors;
  };

  const fetchAddReferenceGroup = async () => {
    if (validateErrors()) {
      return;
    }
    try {
      const params = {
        name: name,
        description: description,
        userGUID: token.UserGUID,
        userGroupGUID: "",
      };
      const response = await AddReferenceGroup(params);
      fetchReferenceGroup();
      setSubmitError("");
      if (!response.success) {
        setSubmitError(response.message);
      }
    } catch { }
    handleTemplateClose();
    setDescriptionError(false);
    setNameError(false);
  };

  const fetchUpdateReferenceGroup = async () => {
    if (validateErrors()) {
      return;
    }
    try {
      const params = {
        name: name,
        description: description,
        referenceGroupGUID: currentReferenceGroupGUID,
      };
      const response = await UpdateReferenceGroup(params);
      fetchReferenceGroup();
      setSubmitError("");
      if (!response.success) {
        setSubmitError(response.message);
      }
    } catch { }
    handleTemplateClose();
    setDescriptionError(false);
    setNameError(false);
  };
  const setEdit = (row: ReferenceGroupDto) => {
    setCurrentReferenceGroupGUID(row.referenceGroupGUID);
    setName(row.name);
    setDescription(row.description);
    setIsDialog(true);
    setDescriptionError(false);
    setNameError(false);
  };

  const fetchDeleteReferenceGroup = async (referenceGroupGUID: string) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this reference group?"
    );
    if (confirm && referenceGroupGUID) {
      try {
        const params = {
          referenceGroupGUID: referenceGroupGUID,
        };
        const response = await DeleteReferenceGroup(params);
        fetchReferenceGroup();
        setSubmitError("");
        if (!response.success) {
          setSubmitError(response.message);
        }
      } catch { }
      handleTemplateClose();
    }
  };

  useEffect(() => {
    if (openRowId) {
      fetchReferenceChildGroup();
    }
    // eslint-disable-next-line
  }, [openRowId]);

  const fetchReferenceChildGroup = async (
    orderBy: string = "",
    sorting: "asc" | "desc" = "asc"
  ) => {
    try {
      const params = {
        referenceGroupGUID: openRowId,
        includeBlank: true,
        orderBy: orderBy,
        sorting: sorting,
        pagesize: 10,
        pageNo: 1,
      };

      const response = await GetReferenceChildGroup(params);
      if (response.data) {
        setchildTable(response.data);
      }
      setSubmitError("");
      if (!response.success) {
        setSubmitError(response.message);
      }
    } catch { }
  };

  const fetchAddReferenceChildGroup = async () => {
    if (validateChildErrors()) {
      return;
    }
    try {
      const params = {
        value: value,
        referenceGroupGUID: openRowId,
      };
      const response = await AddReferenceChildGroup(params);
      fetchReferenceChildGroup();
      setSubmitError("");
      if (!response.success) {
        setSubmitError(response.message);
      }
    } catch { }
    handleTemplateChildClose();
  };

  const fetchDeleteReferenceChildGroup = async (
    referenceGroupItemGUID: string
  ) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this reference group?"
    );
    if (confirm && referenceGroupItemGUID) {
      try {
        const params = {
          referenceGroupItemGUID: referenceGroupItemGUID,
        };
        const response = await DeleteReferenceChildGroup(params);
        fetchReferenceChildGroup();
        setSubmitError("");
        if (!response.success) {
          setSubmitError(response.message);
        }
      } catch { }
    }
  };

  const handleClick = () => {
    if (currentReferenceGroupGUID) {
      fetchUpdateReferenceGroup();
    } else {
      fetchAddReferenceGroup();
    }
  };
  return (
    <>
      <div className="container-fluid active-order">
        <Box className="pt-16 pb-8">
          <Typography className="Main-Header" variant="h4" color="primary">
            Reference Group
          </Typography>
          {submitError && (
            <Alert variant="filled" severity="error" className="mt-2 mb-2" id="txtRgSubmitError">
              {submitError ? submitError : error}
            </Alert>
          )}
        </Box>
        <Typography className="sub-header">
          Use this page to maintain a list of Reference Groups. Press the + to
          expand the list of values for each group.
        </Typography>
      </div>
      <Dialog
        maxWidth="lg"
        open={isDialog}
        onClose={handleTemplateClose}
        style={{ maxHeight: "90%" }}
      >
        <DialogTitle className="dialogtitle">
          Add/Edit Reference Group
          <IconButton
            aria-label="close"
            onClick={handleTemplateClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            id="btnRgClose"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="parent-dialog">
          <Box display="flex" gap={2}>
            <TextField
              name="name"
              label="Name"
              size="small"
              fullWidth
              margin="normal"
              value={name}
              onChange={handleInputChange}
              error={nameError}
              className="text-fields"
              inputProps={{ maxLength: 50 }}
              autoComplete="off"
            />
            <TextField
              name="description"
              label="Description"
              size="small"
              fullWidth
              margin="normal"
              value={description}
              onChange={handleInputChange}
              error={descriptionError}
              className="text-fields"
              inputProps={{ maxLength: 500 }}
              autoComplete="off"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="right" marginTop={2}>
            <Button
              className="reset_btn"
              style={{ marginRight: "16px" }}
              onClick={handleTemplateClose}
            >
              Cancel
            </Button>
            <Button
              className="reset_btn"
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadOutlinedIcon />}
              onClick={handleClick}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="lg"
        fullWidth
        open={isChildDialog}
        onClose={handleTemplateChildClose}
        style={{ maxHeight: "90%" }}
      >
        <DialogTitle className="dialogtitle">
          Add Value
          <IconButton
            aria-label="close"
            onClick={handleTemplateChildClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            id="btnRgAddClose"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            name="value"
            label="Value"
            size="small"
            fullWidth
            margin="normal"
            value={value}
            error={valueError}
            onChange={handleInputChange}
            inputProps={{ maxLength: 32 }}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="right" marginTop={2}>
            <Button
              className="reset_btn"
              style={{ marginRight: "16px" }}
              onClick={handleTemplateChildClose}
              id="btnParentCancel"
            >
              Cancel
            </Button>
            <Button
              className="reset_btn"
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadOutlinedIcon />}
              onClick={fetchAddReferenceChildGroup}
              id="btnParentSave"
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Box className="plr-25" sx={{ marginBottom: "16px" }}>
        <TableContainer
          component={Paper}
          className="table-container-reference mt-8"
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            sx={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow className="Table-row">
                <TableCell
                  className="padding0"
                  sx={{ width: "120px!important", padding: "0!important" }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleDialog}
                    className="add-new-btn"
                    id="btnParentNew"
                  >
                    + New
                  </Button>
                </TableCell>
                <TableCell
                  onClick={() => handleSortRequest("Name")}
                  className="padding0"
                  sx={{
                    cursor: "pointer",
                    width: "200px!important",
                  }}
                >
                  <div className="refer-value">
                    <span className="refer-txt"> NAME</span>
                    <span className="sort-icon small-icon">
                      {orderBy === "Name" ? (
                        sorting === "asc" ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownOutlinedIcon />
                        )
                      ) : (
                        <>
                          <ArrowDropDownOutlinedIcon />
                        </>
                      )}
                    </span>
                  </div>
                </TableCell>
                <TableCell
                  className="plr0 Desctable"
                  onClick={() => handleSortRequest("Description")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="refer-value">
                    <span className="refer-txt"> DESCRIPTION</span>
                    <span className="sort-icon small-icon">
                      {orderBy === "Description" ? (
                        sorting === "asc" ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownOutlinedIcon />
                        )
                      ) : (
                        <>
                          <ArrowDropDownOutlinedIcon />
                        </>
                      )}
                    </span>
                  </div>
                </TableCell>
                <TableCell sx={{ width: "50px!important" }}></TableCell>
                {/* Empty header for expand icon column */}
              </TableRow>
            </TableHead>
            <TableBody>
              {parentTable.length === 0 ? (
                <TableRow className="Refergroup-found" id="trParentDataNotFound">
                  <TableCell
                    className="No-data-section"
                    colSpan={12}
                    align="center"
                    id="tdDataNotFound"
                  >
                    <Box className="reference-no-data-field">
                      <Box>
                        <b className="no-data-header">
                          {"No Reference Groups Found"}
                        </b>
                        <p className="no-data-para mt-16 mb-0">
                          {
                            "To add a reference group, please click the “New” button."
                          }
                        </p>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                parentTable.map((row) => (
                  <React.Fragment key={row.referenceGroupGUID}>
                    <TableRow id="trParentData">
                      <TableCell
                        className="plr0 ptb0 verticaltop"
                        sx={{ width: "120px", marginTop: "1px" }}
                        id="tdEditUpdateBtn"
                      >
                        <button className="mr-10 spanicon">
                          <img
                            className="Delete-icon"
                            src={editicon}
                            alt="Delete icon"
                            id="btnParentEdit"
                            onClick={() => setEdit(row)}
                          />
                        </button>
                        <button className="spanicon">
                          <img
                            className="Delete-icon w14h18"
                            src={deleteicon}
                            alt="Delete icon"
                            id="btnParentDelete"
                            onClick={() =>
                              fetchDeleteReferenceGroup(row.referenceGroupGUID)
                            }
                          />
                        </button>
                      </TableCell>
                      <TableCell
                        className="plr0 ptb10 verticaltop"
                        sx={{ width: "200px" }}
                        id="tdParentName"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell className="plr0 ptb10 verticaltop Desctable" id="tdParentDescription">
                        {row.description}
                      </TableCell>
                      <TableCell className="plr0 ptb10" sx={{ width: "50px" }} id="tdParentExpand">
                        <IconButton
                          id="btnRgExpand"
                          size="small"
                          onClick={() =>
                            handleExpandClick(row.referenceGroupGUID)
                          }
                        >
                          {openRowId === row.referenceGroupGUID ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownOutlinedIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="borderbottom0 greybcg"
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        colSpan={4}
                      >
                        <Collapse
                          in={openRowId === row.referenceGroupGUID}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box>
                            <TableContainer>
                              <Table sx={{ tableLayout: "fixed" }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      className="plr0 ptb10"
                                      style={{
                                        width: "120px",
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      className="plr0 ptb10"
                                      style={{
                                        width: "120px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Button
                                        className="add-new-btn"
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleChildDialog}
                                        id="btnChildNew"
                                      >
                                        + New
                                      </Button>
                                    </TableCell>
                                    {/* <TableCell>Value</TableCell> */}
                                    <TableCell
                                      onClick={() =>
                                        handleSortNameRequest("Name")
                                      }
                                      className="table-cell-desrciption ptb10 value-th"
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div className="refer-value">
                                        <span className="refer-txt">VALUE</span>

                                        <span className="sort-icon small-icon">
                                          {orderBy === "Name" ? (
                                            sortingChild === "asc" ? (
                                              <ArrowDropUpIcon
                                                sx={{
                                                  width: "3px",
                                                }}
                                              />
                                            ) : (
                                              <ArrowDropDownOutlinedIcon />
                                            )
                                          ) : (
                                            <>
                                              <ArrowDropDownOutlinedIcon />
                                            </>
                                          )}
                                        </span>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {childTable.length === 0 ? (
                                    <TableRow className="Nodata_found refer-no-data" id="trChildDataNotFound">
                                      <TableCell
                                        className="plr0 ptb10"
                                        
                                        style={{
                                          width: "120px",
                                        }}
                                      ></TableCell>
                                      <TableCell
                                        id="tdDataNotFound"
                                        className="No-data-section whitebcg"
                                        colSpan={12}
                                        align="center"
                                      >
                                        <Box className="import_no_data_field_reference p16">
                                          <Box>
                                            <b className="no-data-header">
                                              {
                                                "No Reference Groups Values Found"
                                              }
                                            </b>
                                            <p className="no-data-para mt-16 mb-0">
                                              {
                                                "To add a reference group value, please click the “New” button."
                                              }
                                            </p>
                                          </Box>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    childTable.map((row) => (
                                      <React.Fragment
                                        key={row.referenceGroupItemGUID}
                                      >
                                        <TableRow className="refer-group" id="trChildDataNotFound">
                                          <TableCell className="plr0 ptb10"></TableCell>
                                          <TableCell
                                            className="plr0 ptb10"
                                            sx={{ textAlign: "center" }}
                                            id="tdDeleteBtn"
                                          >
                                            <DeleteIcon
                                              onClick={() =>
                                                fetchDeleteReferenceChildGroup(
                                                  row.referenceGroupItemGUID
                                                )
                                              }
                                            />
                                          </TableCell>
                                          <TableCell className="ptb10" id="tdChildValue">
                                            {row.value}
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    ))
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ReferenceGroup;
