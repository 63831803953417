import { Box, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import React from "react";
import { EditOrderDto } from "../../../models/edit-active-order-dto";

interface EditReferenceProps {
  orderDetails: EditOrderDto;
  onReferenceDataChange: (updatedData: EditOrderDto) => void;
  isAlert: boolean;
}

const EditReference: React.FC<EditReferenceProps> = ({
  orderDetails,
  onReferenceDataChange,
  isAlert,
}) => {
  const [references, setReferences] = useState({
    ...orderDetails,
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setReferences((prevReferences) => ({
      ...prevReferences,
      [name]: value,
    }));
    onReferenceDataChange({
      ...references,
      [name]: value,
    });
  };

  const referenceLabels = [
    {
      label:
        orderDetails.referenceLabel ||
        orderDetails.referencetypeName ||
        "REFER",
      value: references.reference,
      name: "reference",
      data: references.reference,
    },
    {
      label: orderDetails.referenceLabel1,
      value: references.referenceValue1,
      name: "referenceValue1",
      data: references.referenceValue1,
    },
    {
      label: orderDetails.referenceLabel2,
      value: references.referenceValue2,
      name: "referenceValue2",
      data: references.referenceValue2,
    },
    {
      label: orderDetails.referenceLabel3,
      value: references.referenceValue3,
      name: "referenceValue3",
      data: references.referenceValue3,
    },
    {
      label: orderDetails.referenceLabel4,
      value: references.referenceValue4,
      name: "referenceValue4",
      data: references.referenceValue4,
    },
    {
      label: orderDetails.referenceLabel5,
      value: references.referenceValue5,
      name: "referenceValue5",
      data: references.referenceValue5,
    },
    {
      label: orderDetails.referenceLabel6,
      value: references.referenceValue6,
      name: "referenceValue6",
      data: references.referenceValue6,
    },
    {
      label: orderDetails.referenceLabel7,
      value: references.referenceValue7,
      name: "referenceValue7",
      data: references.referenceValue7,
    },
    {
      label: orderDetails.referenceLabel8,
      value: references.referenceValue8,
      name: "referenceValue8",
      data: references.referenceValue8,
    },
  ];

  const validReferences = referenceLabels.filter((ref) => ref.label !== null);

  return (
    <Box className="d-flex flex-column border p16 rounded">
      <Typography variant="h6" className="Sub-header">
        References
      </Typography>
      {validReferences.map((ref, index) => (
        <TextField
          id={`txtReferences`}
          size="small"
          key={index}
          variant="outlined"
          label={
            ref.label
              ? ref.label.replace(/&amp;/g, "&")
              : `Reference ${index + 1}`
          }
          name={ref.name}
          value={ref.value ?? ""}
          onChange={handleInputChange}
          className="w-100 mt-16"
          InputProps={{
            readOnly: isAlert,
          }}
          inputProps={{ maxLength: 100 }}
        />
      ))}
    </Box>
  );
};

export default EditReference;
