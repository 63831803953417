import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import useAccounntsLogic from "../../lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../hook/use-decodeJWT";

import useReferenceLogic from "../../lookup/reference/reference.logic";
import React from "react";

interface BillingAndReferProps {
  editWillCallOrderData: any;
  handleInputChange: (updatedData: any) => void;
  formErrors: any;
}

interface ReferenceData {
  reference1: any[];
  reference2: any[];
  reference3: any[];
  reference4: any[];
  reference5: any[];
  reference6?: any[];
  reference7?: any[];
  reference8?: any[];
  reference9?: any[];
}

// interface servicestyle {
//   maxHeight: string;
// }

const BillingAndRefer: React.FC<BillingAndReferProps> = ({
  editWillCallOrderData,
  handleInputChange: handleInputChangeCallBack,
  formErrors,
}) => {
  const goldPlatinumUserAccount = "6W1259";
  const isUPSPremierAgent =
    localStorage.getItem("isUPSPremierAgent") === "true";
  const [selectAccount, setSelectAccount] = useState(
    editWillCallOrderData.accountNumber
  );
  const [billToType, setBillToType] = useState(
    editWillCallOrderData.billToType
  );
  const [accounts, setAccounts] = useState<any>([]);
  const { account, accountForUPS } = useAccounntsLogic();
  const { reference } = useReferenceLogic();
  const token = useDecodeJWT();
  const [references, setReferences] = useState({
    reference1: editWillCallOrderData.reference,
    reference2: editWillCallOrderData.reference2,
    reference3: editWillCallOrderData.reference3,
    reference4: editWillCallOrderData.reference4,
    reference5: editWillCallOrderData.reference5,
    reference6: editWillCallOrderData?.reference6,
    reference7: editWillCallOrderData?.reference7,
    reference8: editWillCallOrderData?.reference8,
    reference9: editWillCallOrderData?.reference9,
  });
  const [referenceData, setReferenceData] = useState<ReferenceData>({
    reference1: [],
    reference2: [],
    reference3: [],
    reference4: [],
    reference5: [],
    reference6: [],
    reference7: [],
    reference8: [],
    reference9: [],
  });

  // const [servicestyle, setservicestyle] = useState<servicestyle>({
  //   maxHeight: "250px", // Default max height for desktop
  // });

  const servicestyle = {
    maxHeight: "250px", // Default max height for desktop
  };

  const hint = React.useRef("");
  const handleChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    if (name === "accountNumber") {
      setSelectAccount(value);
    }
    if (name === "billToType") {
      if (isUPSPremierAgent) {
        if (value == "A") {
          const e = {
            target: {
              value: "",
              name: "accountNumber",
            },
          } as SelectChangeEvent<string>;
          handleInputChangeCallBack(e);
        }
      }
      setBillToType(value);
    }
    handleInputChangeCallBack(e);
  };

  const handleInputChange =
    (name: string) => (event: React.ChangeEvent<{}>, newValue: any) => {
      const value = newValue ? newValue.replace("\r", "") : "";
      setReferences((prev) => ({ ...prev, [name]: value }));
      const target = {
        target: {
          value: value,
          name: name,
        },
      };
      handleInputChangeCallBack(target);
    };

  useEffect(() => {
    if (!isUPSPremierAgent) {
      getAccounts();
    }
    fetchReferenceData();
  }, [token.UserId]);

  useEffect(() => {
    if (isUPSPremierAgent) {
      getAccounts();
    }
  }, [billToType]);

  const getAccounts = async () => {
    try {
      let response: any;
      if (isUPSPremierAgent) {
        let groupName = "UPS Premier Silver Accounts";
        if (billToType === "G") {
          groupName = "UPS Premier Gold Accounts";
        } else if (billToType === "P") {
          groupName = "UPS Premier Platinum Accounts";
        }
        const param = {
          userId: token.UserId,
          groupName: groupName,
          isBillable: true,
          viewOrders: false,
        };
        response = await accountForUPS(param);
      } else {
        const param = {
          userId: token.UserId,
          companyID: 0,
          useIncludeAllItemrId: false,
        };
        response = await account(param);
      }

      if (response.data && response.data.data) {
        setAccounts(response.data.data);
        const data = response.data.data;
        let _selectedAccount = selectAccount;
        if (billToType == "G" || billToType == "P") {
          const _goldPlatinumAccount = data.find(
            (x: any) => x.accountNumber == goldPlatinumUserAccount
          );
          if (_goldPlatinumAccount) {
            _selectedAccount = _goldPlatinumAccount?.accountNumber;
          } else {
            _selectedAccount = data[0]?.accountNumber;
          }

          setSelectAccount(_selectedAccount);
        }
      } else {
        setAccounts([]);
      }
    } catch (err) {
      setAccounts([]);
    }
  };

  const handleReferenceChange =
    (name: string) => (event: React.ChangeEvent<{}>, value: any | null) => {
      const selectedValue =
        value && typeof value === "object" ? value.value : value;
      const newValue = selectedValue ? selectedValue.replace("\r", "") : "";

      setReferences((prev) => ({ ...prev, [name]: newValue }));

      const target = {
        target: {
          value: newValue,
          name: name,
        },
      };
      handleInputChangeCallBack(target);
    };

  const fetchReferenceData = () => {
    const referenceGuids = [
      { name: "reference1", guid: editWillCallOrderData.referenceGroupGUID },
      { name: "reference2", guid: editWillCallOrderData.referenceGroupGUID2 },
      { name: "reference3", guid: editWillCallOrderData.referenceGroupGUID3 },
      { name: "reference4", guid: editWillCallOrderData.referenceGroupGUID4 },
      { name: "reference5", guid: editWillCallOrderData.referenceGroupGUID5 },
      { name: "reference6", guid: editWillCallOrderData.referenceGroupGUID6 },
      { name: "reference7", guid: editWillCallOrderData.referenceGroupGUID7 },
      { name: "reference8", guid: editWillCallOrderData.referenceGroupGUID8 },
      { name: "reference9", guid: editWillCallOrderData.referenceGroupGUID9 },
    ];

    referenceGuids.forEach(({ name, guid }) => {
      if (guid) {
        getReference(guid, name);
      }
    });
  };

  const getReference = async (referenceGroupGUID: string, refName: string) => {
    try {
      const param = {
        referenceGroupGUID: referenceGroupGUID,
      };
      const response = await reference(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setReferenceData((prev: any) => ({
          ...prev,
          [refName]: res,
        }));
      }
    } catch (err) {
      setReferenceData((prev: any) => ({
        ...prev,
        [refName]: [],
      }));
    }
  };

  const referenceLabels = [
    {
      label:
        editWillCallOrderData.referenceLabel ||
        editWillCallOrderData.referencetypeName ||
        "REFER",
      value: references.reference1,
      name: "reference1",
      data: referenceData.reference1,
    },
    {
      label:
        editWillCallOrderData.referenceLabel2 ||
        editWillCallOrderData.referencetypeName2,
      value: references.reference2,
      name: "reference2",
      data: referenceData.reference2,
    },
    {
      label:
        editWillCallOrderData.referenceLabel3 ||
        editWillCallOrderData.referencetypeName3,
      value: references.reference3,
      name: "reference3",
      data: referenceData.reference3,
    },
    {
      label:
        editWillCallOrderData.referenceLabel4 ||
        editWillCallOrderData.referencetypeName4,
      value: references.reference4,
      name: "reference4",
      data: referenceData.reference4,
    },
    {
      label:
        editWillCallOrderData.referenceLabel5 ||
        editWillCallOrderData.referencetypeName5,
      value: references.reference5,
      name: "reference5",
      data: referenceData.reference5,
    },
    {
      label:
        editWillCallOrderData.referenceLabel6 ||
        editWillCallOrderData.referencetypeName6,
      value: references.reference6,
      name: "reference6",
      data: referenceData.reference6,
    },
    {
      label:
        editWillCallOrderData.referenceLabel7 ||
        editWillCallOrderData.referencetypeName7,
      value: references.reference7,
      name: "reference7",
      data: referenceData.reference7,
    },
    {
      label:
        editWillCallOrderData.referenceLabel8 ||
        editWillCallOrderData.referencetypeName8,
      value: references.reference8,
      name: "reference8",
      data: referenceData.reference8,
    },
    {
      label:
        editWillCallOrderData.referenceLabel9 ||
        editWillCallOrderData.referencetypeName9,
      value: references.reference9,
      name: "reference9",
      data: referenceData.reference9,
    },
  ];

  const validReferences = referenceLabels.filter((ref) => ref.label !== null);
  const renderLabel = (ref: any, index: number): string => {
    return ref.label ?? `Reference ${index + 1}`;
  };

  const accountsAutoComplete = useMemo(() => {
    // if (billToType === "G" || billToType === "P") {
    //   const _prefixAccount = {
    //     label: goldPlatinumUserAccount,
    //     id: goldPlatinumUserAccount,
    //   };
    //   return [_prefixAccount];
    // }
    return accounts.map((account: any) => ({
      label: account?.displayName,
      id: account?.accountNumber,
    }));
  }, [accounts, billToType]);

  const isDisbaleAccount = useMemo(() => {
    const isGoldPlatinum = billToType === "G" || billToType === "P";
    if (isGoldPlatinum) {
      let defaultAccount;
      const checkAccount = accounts.find(
        (x: any) => x.accountNumber === goldPlatinumUserAccount
      );
      if (checkAccount) {
        defaultAccount = checkAccount?.accountNumber;
      } else {
        defaultAccount = accounts[0]?.accountNumber;
      }

      handleChange({
        target: {
          value: defaultAccount,
          name: "accountNumber",
        },
      } as SelectChangeEvent<string>);
      // onDeliveryDataChange({ ["accountNumber"]: defaultAccount });
    }

    return isGoldPlatinum;
  }, [billToType, accounts]);

  const BillingOptions = useMemo(() => {
    if (isUPSPremierAgent) {
      return [
        {
          value: "P",
          label: "UPS Premier Platinum Accounts",
        },
        {
          value: "G",
          label: "UPS Premier Gold Accounts",
        },
        {
          value: "A",
          label: "UPS Premier Silver Accounts",
        },
      ];
    } else {
      return [
        {
          value: "S",
          label: "SHIPPER",
        },
        {
          value: "C",
          label: "CONSIGNEE",
        },
        {
          value: "3",
          label: "3RD PARTY",
        },
      ];
    }
  }, [isUPSPremierAgent]);

  const selectedBillAcc = useMemo(() => {
    const checkAccount = accounts.find(
      (x: any) => x.accountNumber === selectAccount
    );
    if (checkAccount) {
      return {
        label: checkAccount?.displayName,
        id: checkAccount?.accountNumber,
      };
    }
    if (
      !isDisbaleAccount &&
      isUPSPremierAgent &&
      !checkAccount &&
      selectAccount
    ) {
      if (!["P", "G", "A"].includes(billToType as any))
        handleChange({
          target: {
            value: "",
            name: "accountNumber",
          },
        } as SelectChangeEvent<string>);
    }
    return null;
  }, [selectAccount, accounts, isDisbaleAccount, billToType]);
  return (
    <Box className="d-flex flex-column border p16 rounded">
      <Typography variant="h6" className="Sub-header">
        Billing & Reference
      </Typography>
      <Typography variant="body1" className="pb-8">
        Billing
      </Typography>
      <Box className="d-flex gap-16 Divider pb-16">
        <Select
          variant="outlined"
          id="ddBillToType"
          defaultValue={editWillCallOrderData.billToType}
          className="w-100"
          onChange={handleChange}
          value={billToType}
          name="billToType"
          error={!!formErrors.billToType}
        >
          {BillingOptions.length > 0 &&
            BillingOptions.map((_opt: any) => (
              <MenuItem key={_opt.value} value={_opt.value}>
                {_opt.label}
              </MenuItem>
            ))}
        </Select>
        <FormControl size="medium" fullWidth>
          {!isUPSPremierAgent && (
            <>
              <InputLabel>Select Account</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="ddAccount"
                value={editWillCallOrderData.accountNumber}
                label="Select Account"
                onChange={handleChange}
                name="accountNumber"
                defaultValue={editWillCallOrderData.accountNumber}
                fullWidth
                className="w-100"
                error={!!formErrors.accountNumber}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: servicestyle,
                  },
                }}
              >
                {accounts.length > 0 &&
                  accounts.map((account: any) => (
                    <MenuItem
                      key={account.userAccountRowGUID}
                      value={account.accountNumber}
                    >
                      {account.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}

          {isUPSPremierAgent && (
            <>
              <Autocomplete
                disabled={isDisbaleAccount && !formErrors.accountNumber}
                readOnly={
                  !!formErrors.accountNumber &&
                  (billToType == "G" || billToType == "P")
                    ? true
                    : false
                }
                disablePortal
                options={accountsAutoComplete}
                getOptionLabel={(option: any) => option.label}
                value={selectedBillAcc}
                id="ddAccount"
                onChange={(event, newValue: any) => {
                  handleChange({
                    target: {
                      name: "accountNumber",
                      value: newValue?.id || "",
                    },
                  } as SelectChangeEvent<string>);
                }}
                className="w-100"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Account"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: !!formErrors.accountNumber
                            ? "#d32f2f"
                            : undefined,
                        },
                        "&:hover fieldset": {
                          borderColor: !!formErrors.accountNumber
                            ? "#d32f2f"
                            : undefined,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: !!formErrors.accountNumber
                            ? "#d32f2f"
                            : undefined,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: !!formErrors.accountNumber
                          ? "#d32f2f"
                          : undefined,
                      },
                      "& .MuiInputBase-input": {
                        color: !!formErrors.accountNumber
                          ? "#d32f2f"
                          : "inherit",
                      },
                    }}
                  />
                )}
              />
            </>
          )}
        </FormControl>
      </Box>

      <Typography variant="body1" className="mt-16 sub-infolbl">
        References
      </Typography>
      {validReferences.map((ref, index) => (
        <Box key={index} className="d-flex mt-12">
          <Autocomplete
            disablePortal
            id={`txtReferences`}
            options={ref.data ?? []}
            className="w-100 mt-16 hcAutoComplete"
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.value
            }
            value={references[ref.name as keyof typeof references] || ""} // Ensure non-null value
            onChange={handleReferenceChange(ref.name)}
            onInputChange={handleInputChange(ref.name)}
            filterOptions={(x) => x} // Disable filtering
            // freeSolo
            sx={{ width: 300 }}
            renderInput={(params) => (
              <Box sx={{ position: "relative" }}>
                <Typography
                  sx={{
                    position: "absolute",
                    opacity: 0.5,
                    left: 14,
                    top: 16,
                  }}
                >
                  {hint.current}
                </Typography>
                <TextField
                  {...params}
                  label={renderLabel(ref, index)}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 100,
                  }}
                />
              </Box>
            )}
          />
        </Box>
      ))}
    </Box>
  );
};

export default BillingAndRefer;
