import axios from "axios";
import { API_BASE_URL } from "./constants";
import { loaderManager } from "../shared/util/loader-manager";

let activeRequests = 0;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (activeRequests === 0 && config.url) {
      loaderManager.setLoading(true); // Show loader only when starting the first request
    }
    activeRequests++;

    const userData = localStorage.getItem("userDetails");
    const parseUserData = JSON.parse(userData || "{}");
    const skipAuthToken =
      config.url && config.url.includes("Lookup/GetCountry") ? true : false;

    if (parseUserData && parseUserData.token) {
      if (!skipAuthToken) {
        const token = parseUserData.token;
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    activeRequests--;
    if (activeRequests === 0) {
      loaderManager.setLoading(false); // Hide loader when all requests are finished
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    activeRequests--;
    if (activeRequests === 0) {
      loaderManager.setLoading(false); // Hide loader when all requests are finished
    }

    // handleError({ response: { status: 403 } });
    return response;
  },
  (error) => {
    activeRequests--;
    if (activeRequests === 0) {
      loaderManager.setLoading(false); // Hide loader when all requests are finished
    }

    const { status } = error.response || {};
    const { code } = error || {};
    if (status === 401) {
      localStorage.clear();
      window.location.replace("/"); // Redirect to login page on unauthorized error
    } else if (status === 400 || status === 403 || code == "ERR_NETWORK") {
      if (status === 400 || status === 403) {
        handleError(error);
      } else {
        handleError({ response: { status: 403 } });
      }
    }

    // if (error.response) {
    //   console.error(
    //     "Response error",
    //     error.response.status,
    //     error.response.data
    //   );
    // } else if (error.request) {
    //   console.error("Request error", error.request);
    // } else {
    //   console.error("Error", error.message);
    // }

    return Promise.reject(error);
  }
);

function handleError(error: any) {
  const { response } = error;
  const status = response?.status;

  switch (status) {
    case 400:
      displayToast(response?.data?.message || "Bad Request", "error");
      break;
    case 403:
      displayToast(
        "You do not have permission to access this resource. To gain access, please contact MNX Support at helpdesk@mnx.com or contact your Sales Representative.",
        "error"
      );
      break;
    default:
  }
}

function displayToast(message: string, severity: "error" | "info" | "success") {
  if (typeof window !== "undefined" && window.dispatchEvent) {
    const event = new CustomEvent("showToast", {
      detail: { message, severity },
    });
    window.dispatchEvent(event);
  }
}

export default axiosInstance;
