import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Select,
  InputLabel,
  FormControl,
  Button,
  InputAdornment,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Alert,
  FormHelperText,
  Grid,
} from "@mui/material";
import logo from "../../assets/images/logo/mxweb-logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CountryItemDto } from "../../models/country-item-dto";
import { SignupItemDto } from "../../models/SignupItemDto";
import useCountryLogic from "../../shared/lookup/country/country.logic";
import useTimezoneLogic from "../../shared/lookup/timezone/timezone.logic";
import { TimezoneItemDto } from "../../models/timezone-item-dto";
import useSignupLogic from "./signup.logic";

// interface MenuStyles {
//   maxHeight: string;
//   maxWidth: string;
//   Width: string;
// }

// interface servicestyle {
//   maxHeight: string;
// }

const SignUp: React.FC = () => {
  const [submitError, setSubmitError] = useState<string>("");
  const [param, setParam] = useState<SignupItemDto>({
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobileNumber: "",
    faxNumber: "",
    logonName: "",
    password: "",
    confirmPassword: "",
    accountNumber: "",
    countryID: "",
    timezoneID: "",
  });

  const [formErrors, setFormErrors] = useState<SignupItemDto>({
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobileNumber: "",
    faxNumber: "",
    logonName: "",
    password: "",
    confirmPassword: "",
    accountNumber: "",
    countryID: "",
    timezoneID: "",
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
  const [timezoneData, setTimezoneData] = useState<TimezoneItemDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(true);
  const { country } = useCountryLogic();
  const { timezone } = useTimezoneLogic();
  const { signupError, signup } = useSignupLogic();

  const getCountry = async () => {
    try {
      setIsLoading(true);
      const param = {};
      const response = await country(param);
      if (response.data && response.data.data) {
        setCountryData(response.data.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setCountryData([]);
    }
  };

  const getTimezone = async () => {
    try {
      setIsLoading(true);
      const param = {};
      const response = await timezone(param);
      if (response.data && response.data.data) {
        setTimezoneData(response.data.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setTimezoneData([]);
    }
  };

  useEffect(() => {
    getCountry();
    getTimezone();
  }, []);

  const toggleShowPassword = (field: string) => {
    if (field === "password") {
      setShowPassword((prev) => !prev);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword((prev) => !prev);
    }
  };
  //  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
  //   maxHeight: "250px", // Default max height for desktop
  //   maxWidth: "180px",
  //   Width: "180px",
  // });
  const menuStyles = {
    maxHeight: "250px", // Default max height for desktop
    maxWidth: "180px",
    Width: "180px",
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormErrors({ ...formErrors, [e.target.name]: "" });
    setParam({ ...param, [e.target.name]: e.target.value });
    if (e.target.name === "password") {
      setShowSuggestion(true);
    }
  };

  const handleChangeAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormErrors({ ...formErrors, [e.target.name]: "" });

    setParam({ ...param, [e.target.name]: e.target.value });
  };

  const handleChangeSelect = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    if (name) {
      setFormErrors({ ...formErrors, [name]: "" });
      setParam({ ...param, [name]: value });
    }
  };

  const validate = () => {
    let errors: SignupItemDto = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      mobileNumber: "",
      faxNumber: "",
      logonName: "",
      password: "",
      confirmPassword: "",
      accountNumber: "",
      countryID: "",
      timezoneID: "",
    };
    let isValid = true;

    // Email regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Password regex pattern: at least 1 lowercase, 1 uppercase, 1 number, and minimum 8 characters
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#+-]{8,}$/;
    // Phone number regex pattern: only digits and length of 10
    const phoneNumberPattern = /^\d{10}$/;
    // Check if required fields are filled
    if (!param.firstName) {
      errors.firstName = "First Name is required.";
      isValid = false;
    }
    if (!param.lastName) {
      errors.lastName = "Last Name is required.";
      isValid = false;
    }
    if (!param.mobileNumber) {
      errors.mobileNumber = "Phone Number is required.";
      isValid = false;
    }
    if (!param.emailAddress) {
      errors.emailAddress = "Email is required.";
      isValid = false;
    }
    if (!param.logonName) {
      errors.logonName = "User ID is required.";
      isValid = false;
    }
    if (!param.password) {
      errors.password = "Please enter Password.";
      isValid = false;
    }
    if (!param.confirmPassword) {
      errors.confirmPassword = "Please re-enter your password.";
      isValid = false;
    }
    if (!param.accountNumber) {
      errors.accountNumber = "Account Number is required.";
      isValid = false;
    }
    if (!param.countryID) {
      errors.countryID = "Country is required.";
      isValid = false;
    }
    if (!param.timezoneID) {
      errors.timezoneID = "Time Zone is required.";
      isValid = false;
    }

    // Email validation
    if (param.emailAddress && !emailPattern.test(param.emailAddress)) {
      errors.emailAddress = "Please enter a valid email address!";
      isValid = false;
    }

    // Phone number validation
    if (param.mobileNumber && !phoneNumberPattern.test(param.mobileNumber)) {
      errors.mobileNumber = "Please enter a valid 10-digit phone number!";
      isValid = false;
    }

    // Password validation
    if (param.password && !passwordPattern.test(param.password)) {
      errors.password =
        "1 lowercase, 1 uppercase, 1 number(s). Minimum Length is 8.";
      setShowSuggestion(false);
      isValid = false;
    }

    // Confirm password validation
    if (param.confirmPassword && param.password !== param.confirmPassword) {
      errors.confirmPassword = "Passwords do not match!";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    try {
      if (validate()) {
        setIsLoadingBtn(true);
        const response = await signup(param);

        if (response.data && response.data.data) {
          const res = response.data;

          if (res.data === "Success") {
            navigate("/confirm-sign-up");
            setSubmitError("");
          } else if (
            res.data.trim().toLowerCase().replace(/\s+/g, "") ===
            "theuseridalreadyexists,pleasechooseanotheruserid."
          ) {
            setSubmitError(res.data);
          } else if (signupError) {
            setSubmitError(signupError);
          }
        } else {
          if (!response.success) {
            setSubmitError(response.message);
          }
        }
      }
      setIsLoadingBtn(false);
    } catch (err: any) {
      setSubmitError(err);
      // setTimeout(() => {
      //   setErrorAlert(false);
      // }, 5000);
    } finally {
      setIsLoadingBtn(false);
    }
  };

  const navigate = useNavigate();
  const handlePrivacyStatementClick = () => {
    navigate("/privacy-statement");
  };
  const handleSupportSignIn = () => {
    navigate("/support-sign-in");
  };

  const toPascalCase = (str: any) => {
    return str
      .replace(
        /\w\S*/g,
        (word: any) =>
          word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
      )
      .replace(/\s+/g, "");
  };

  return (
    <div>
      <Container className="login-section" maxWidth="sm">
        {/* Logo */}
        <Box className="lognin-image">
          <img src={logo} alt="Company Logo" style={{ maxWidth: "100%" }} />
        </Box>

        <Box my={1}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            textAlign="start"
            className="sign-in-title"
          >
            New User Request
          </Typography>
          <div className="form-border">
            <Typography className="Sub_info_text">
              Use the following form to request a User ID. Complete the required
              fields and press the submit button at the bottom of the page. For
              more information regarding the sign-up process, please contact
              your sales representative.
            </Typography>
            {submitError && (
              <Alert
                variant="filled"
                severity="error"
                className="mt-2 mb-2"
                id="txtSuError"
              >
                {submitError}
              </Alert>
            )}
            <Grid container spacing={2} className="mt-0">
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  id="txtFirstName"
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{
                    maxLength: 50,
                  }}
                  value={param.firstName}
                  onChange={handleChange}
                  error={!!formErrors.firstName}
                  helperText={formErrors.firstName}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  id="txtLastName"
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  className="w-100"
                  value={param.lastName}
                  onChange={handleChange}
                  error={!!formErrors.lastName}
                  helperText={formErrors.lastName}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <TextField
                  id="txtEmail"
                  name="emailAddress"
                  label="Email"
                  variant="outlined"
                  size="small"
                  className="w-100"
                  value={param.emailAddress}
                  onChange={handleChange}
                  error={!!formErrors.emailAddress}
                  helperText={formErrors.emailAddress}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <TextField
                  id="txtPhoneNumber"
                  name="mobileNumber"
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  className="w-100"
                  value={param.mobileNumber}
                  onChange={handleChange}
                  error={!!formErrors.mobileNumber}
                  helperText={formErrors.mobileNumber}
                  inputProps={{
                    maxLength: 25,
                  }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <TextField
                  id="txtFaxNumber"
                  name="faxNumber"
                  label="Fax (Optional)"
                  variant="outlined"
                  size="small"
                  className="w-100"
                  value={param.faxNumber}
                  onChange={handleChange}
                  error={!!formErrors.faxNumber}
                  helperText={formErrors.faxNumber}
                  inputProps={{
                    maxLength: 25,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  sx={{ marginBottom: "3px" }}
                  id="txtUserId"
                  name="logonName"
                  label="Enter a User ID"
                  className="w-100"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="small"
                  // helperText="SUGGESTION: Your email address is recommended."
                  value={param.logonName}
                  onChange={handleChange}
                  onInput={handleChange}
                  error={!!formErrors.logonName}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
                {formErrors.logonName && (
                  <FormHelperText error className="suggestion_txt">
                    {formErrors.logonName}
                  </FormHelperText>
                )}
                <FormHelperText className="suggestion_txt">
                  SUGGESTION: Your email address is recommended.
                </FormHelperText>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  sx={{ marginBottom: "3px" }}
                  id="txtPassword"
                  name="password"
                  label="New Password"
                  variant="outlined"
                  className="w-100"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  size="small"
                  value={param.password}
                  onChange={handleChange}
                  onInput={handleChange}
                  error={!!formErrors.password}
                  inputProps={{
                    maxLength: 20,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          id="btnpasswordtoggle"
                          aria-label="toggle password visibility"
                          onClick={() => toggleShowPassword("password")}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formErrors.password && (
                  <FormHelperText
                    error
                    sx={{ marginLeft: "14px", marginTop: "0px" }}
                  >
                    {formErrors.password}
                  </FormHelperText>
                )}
                {showSuggestion && (
                  <FormHelperText sx={{ marginLeft: "14px", marginTop: "0px" }}>
                    1 lowercase, 1 uppercase, 1 number(s). Minimum Length is 8.
                  </FormHelperText>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtConfirmPassword"
                  name="confirmPassword"
                  label="Re-enter New Password"
                  className="w-100"
                  variant="outlined"
                  fullWidth
                  type={showConfirmPassword ? "text" : "password"}
                  size="small"
                  value={param.confirmPassword}
                  onChange={handleChange}
                  error={!!formErrors.confirmPassword || !!formErrors.password}
                  helperText={formErrors.confirmPassword}
                  inputProps={{
                    maxLength: 20,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => toggleShowPassword("confirmPassword")}
                          edge="end"
                          id="btnSuConfirmVisiblilty"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtAccountNumber"
                  name="accountNumber"
                  label="Enter Your Account Number"
                  variant="outlined"
                  fullWidth
                  size="small"
                  //helperText="Note: Your email address domain must match a valid email domain for the account."
                  value={param.accountNumber}
                  onChange={handleChangeAccount}
                  error={!!formErrors.accountNumber}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
                {formErrors.accountNumber && (
                  <FormHelperText error style={{ marginLeft: "14px" }}>
                    {formErrors.accountNumber}
                  </FormHelperText>
                )}
                <FormHelperText className="suggestion_txt">
                  Note: Your email address domain must match a valid email
                  domain for the account.
                </FormHelperText>
              </Grid>
            </Grid>

            {/* <FormHelperText sx={{marginLeft : "14px", marginTop : "0px"}}>
                1 lowercase, 1 uppercase, 1 number(s). Minimum Length is 8
            </FormHelperText> */}

            <Grid container spacing={2} className="mt-0">
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <FormControl
                  className="w-100"
                  size="small"
                  error={!!formErrors.countryID}
                >
                  <InputLabel id="country-label">Country</InputLabel>
                  <Select
                    id="ddSignupCountry"
                    labelId="country-label"
                    label="Country"
                    name="countryID"
                    value={param.countryID}
                    onChange={handleChangeSelect}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {countryData?.map((country) => (
                      <MenuItem
                        className="wrap-text"
                        key={country.countryID}
                        value={country.countryID}
                        id={`li${toPascalCase(country.name)}`}
                      >
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{formErrors.countryID}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <FormControl
                  className="w-100"
                  size="small"
                  error={!!formErrors.timezoneID}
                >
                  <InputLabel id="timeZone-label">Time Zone</InputLabel>
                  <Select
                    id="ddSvrTimeZone"
                    labelId="timeZone-label"
                    label="Time Zone"
                    name="timezoneID"
                    value={param.timezoneID}
                    onChange={handleChangeSelect}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {timezoneData?.map((timezone) => (
                      <MenuItem
                        key={timezone.timezoneID}
                        className="wrap-text"
                        value={timezone.timezoneID}
                        id={`li${toPascalCase(timezone.countryName)}`}
                      >
                        {`${timezone.timezoneID} (${timezone.countryName})`}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{formErrors.timezoneID}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <LoadingButton
                  type="submit"
                  id="btnSubmit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={isLoadingBtn}
                  onClick={handleSubmit}
                >
                  Send New User Request
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        </Box>

        <Box>
          <Box
            className="Privacy_info forgot_btn signup_info"
            maxWidth="sm"
            sx={{
              display: "flex",
              gap: "64px",
              width: "100%",
              marginTop: "auto",
            }}
          >
            <Button
              className="text"
              onClick={handlePrivacyStatementClick}
              id="btnPrivacyStatement"
            >
              Privacy Statement
            </Button>
            <Button
              className="text-custom"
              onClick={handleSupportSignIn}
              id="btnSupportSignIn"
            >
              Support Sign in
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default SignUp;
