import React, { createContext, useContext, useState, ReactNode } from "react";
import { Snackbar, Alert, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type ToastContextType = {
  showToast: (
    message: string,
    severity: "error" | "warning" | "info" | "success",

    duration?: number // Optional duration parameter
  ) => void;
  handleClose: () => void;
  toast: any;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toast, setToast] = useState({
    open: false,
    message: "",
    // message:
    //   "You do not have permission to access this resource. To gain access, please contact MNX Support at helpdesk@mnx.com or contact your Sales Representative.",
    severity: "info" as "error" | "warning" | "info" | "success",

    duration: 15000, // Default duration of 3 seconds
  });

  const showToast = (
    message: string,
    severity: "error" | "warning" | "info" | "success",

    duration = 15000 // Default auto-hide duration
  ) => {
    setToast({
      open: true,
      message,
      severity,
      duration,
    });
  };

  const handleClose = () => setToast((prev) => ({ ...prev, open: false }));

  return (
    <ToastContext.Provider value={{ showToast, handleClose, toast }}>
      <>
        <Snackbar
          open={toast.open}
          autoHideDuration={15000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{
              width: { xs: "100%", sm: "70%" },
              bgcolor: "#d32f2f",
              color: "white",
              "& .MuiAlert-icon": {
                color: "white",
              },
            }}
          >
            {toast?.message}
          </Alert>
        </Snackbar>

        {children}
      </>
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
