import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { handleSubmitEmail } from "./forgot-password.reducer";

const useForgotPasswordLogic = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state: RootState) => state.auth);

  const handleEmail = async (param: { emailAddress: string | null }) => {
    try {
      const response = await dispatch(handleSubmitEmail(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    loading,
    error,
    handleEmailApi: handleEmail,
  };
};
export default useForgotPasswordLogic;
