import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import {
  getMasterItems,
  exportData,
  getMasterItemsById,
} from "./export-data.reducer";

import { exportDataSearchCriteriaDto } from "../../models/export-data-dto";

const useExportDataLogic = () => {
  const dispatch = useDispatch();
  const { exportDataError, exportDataLoading } = useSelector(
    (state: RootState) => state.exportData
  );

  const handleGetMasterItems = async () => {
    try {
      const response = await dispatch(getMasterItems() as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleGetMasterItemsById = async (exportMasterID: number) => {
    try {
      const response = await dispatch(
        getMasterItemsById(exportMasterID) as any
      );
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleExportData = async (param: exportDataSearchCriteriaDto) => {
    try {
      const response = await dispatch(exportData(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    exportDataError,
    exportDataLoading,
    getMasterItems: handleGetMasterItems,
    exportData: handleExportData,
    getMasterItemsById: handleGetMasterItemsById,
  };
};

export default useExportDataLogic;
