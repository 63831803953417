import { Dispatch } from "redux"; // Import Dispatch type
import { API_BASE_URL } from "../../config/constants";
import {
  ReferenceGroupDto,
  ReferenceGroupChildDto,
} from "../../models/reference-group-item-dto";
import axiosInstance from "../../config/axios-interceptor";

// Action Types for REFERENCE_GROUP
const REFERENCE_GROUP_REQUEST = "auth/REFERENCE_GROUP_REQUEST";
const REFERENCE_GROUP_SUCCESS = "auth/REFERENCE_GROUP_SUCCESS";
const REFERENCE_GROUP_FAILURE = "auth/REFERENCE_GROUP_FAILURE";

// Action Creators for referenceGroup
export const referenceGroupRequest = () => ({
  type: REFERENCE_GROUP_REQUEST,
});

export const referenceGroupSuccess = () => ({
  type: REFERENCE_GROUP_SUCCESS,
});

export const referenceGroupFailure = (error: string) => ({
  type: REFERENCE_GROUP_FAILURE,
  payload: error,
});

export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  loading: false,
  error: null,
};

interface referenceGroupResponse {
  success: boolean;
  message: string;
  totalCount: number;
  pageCount: number;
  data: Array<ReferenceGroupDto>;
  statusCode: string;
}

const authReducer = (state = initialAuthState, action: any): AuthState => {
  switch (action.type) {
    case REFERENCE_GROUP_REQUEST:
      return { ...state, loading: true, error: null };
    case REFERENCE_GROUP_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true };
    case REFERENCE_GROUP_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const handleGetReferenceGroup =
  (param: {
    UserGUID: string;
    userGroupGUID: string;
    includeBlank: boolean;
    orderBy: string;
    sorting: string;
    pagesize: number;
    pageNo: number;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(referenceGroupRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Customer/GetReferenceGroup`,
        param
      );
      dispatch(referenceGroupSuccess());
      const data: referenceGroupResponse = response.data;
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return error.response.data;
        // dispatch(referenceGroupFailure(error.response.data.message));
      } else {
        dispatch(referenceGroupFailure(error.message));
      }
    }
  };

export const handleAddReferenceGroup =
  (param: {
    name: string;
    description: string;
    userGUID: string;
    userGroupGUID: string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(referenceGroupRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Customer/AddReferenceGroup`,
        param
      );
      dispatch(referenceGroupSuccess());
      const data: referenceGroupResponse = response.data;
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return error.response.data;
        // dispatch(referenceGroupFailure(error.response.data.message));
      } else {
        dispatch(referenceGroupFailure(error.message));
      }
    }
  };

export const handleDeleteReferenceGroup =
  (param: { referenceGroupGUID: string | null }) =>
  async (dispatch: Dispatch) => {
    dispatch(referenceGroupRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Customer/DeleteReferenceGroup`,
        param
      );
      dispatch(referenceGroupSuccess());
      const data: referenceGroupResponse = response.data;
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return error.response.data;
        //dispatch(referenceGroupFailure(error.response.data.message));
      } else {
        dispatch(referenceGroupFailure(error.message));
      }
    }
  };

export const handleUpdateReferenceGroup =
  (param: {
    referenceGroupGUID: string | null;
    name: string;
    description: string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(referenceGroupRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Customer/UpdateReferenceGroup`,
        param
      );
      dispatch(referenceGroupSuccess());
      const data: referenceGroupResponse = response.data;
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return error.response.data;
        // dispatch(referenceGroupFailure(error.response.data.message));
      } else {
        dispatch(referenceGroupFailure(error.message));
      }
    }
  };

export const handleGetReferenceChildGroup =
  (param: {
    referenceGroupGUID: string | null;
    includeBlank: boolean;
    orderBy: string;
    sorting: string;
    pagesize: number;
    pageNo: number;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(referenceGroupRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Customer/GetReferenceGroupValue`,
        param
      );
      dispatch(referenceGroupSuccess());
      const data: ReferenceGroupChildDto = response.data;
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return error.response.data;
        // dispatch(referenceGroupFailure(error.response.data.message));
      } else {
        dispatch(referenceGroupFailure(error.message));
      }
    }
  };
export const handleAddReferenceGroupItem =
  (param: { value: string; referenceGroupGUID: string | null }) =>
  async (dispatch: Dispatch) => {
    dispatch(referenceGroupRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Customer/AddReferenceGroupItem`,
        param
      );
      dispatch(referenceGroupSuccess());
      const data: referenceGroupResponse = response.data;
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return error.response.data;
        // dispatch(referenceGroupFailure(error.response.data.message));
      } else {
        dispatch(referenceGroupFailure(error.message));
      }
    }
  };
export const handleDeleteReferenceGroupItem =
  (param: { referenceGroupItemGUID: string | null }) =>
  async (dispatch: Dispatch) => {
    dispatch(referenceGroupRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Customer/DeleteReferenceGroupItem`,
        param
      );
      dispatch(referenceGroupSuccess());
      const data: referenceGroupResponse = response.data;
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return error.response.data;
      } else {
        dispatch(referenceGroupFailure(error.message));
      }
    }
  };

export default authReducer;
