import { Box, Typography, Button, Grid } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import { MAX_MAP_API_KEY } from "../../config/constants";
import flighticon from "../../assets/images/trackorder/flight-icon.svg";
import caricon from "../../assets/images/trackorder/car-icon.svg";
import { LoadingButton } from "@mui/lab";
import arrowicon from "../../assets/images/logo/img_arrowright_white_a700.svg";
import React, { useEffect, useState, useRef } from "react";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";
import useRouteRecommendationsLogic from "./route-recommendations.logic";
import { RouteRecommendation } from "../../models/route-recommendation-item-dto";
import {
  calculateHoursDifference,
  formatDateAndTime,
  formatDateAndTimeAndPlusMinute,
  formatDateToMonthDay,
  formatTime,
} from "../../shared/util/date-utils";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import FlagIcon from "@mui/icons-material/Flag";
import pickupIcon from "./map_marker.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { formatPhoneNumber } from "../../shared/util/phone-number-format";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import useMultipleFileUploadLogic from "../../shared/document-upload/multiple-document-upload.logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import FlairLoading from "../../shared/components/route-preview/flair-loading";
import useBookOrderWithoutRouteLogic from "./book-order-without-route.logic";
import useBookOrderWithRouteLogic from "./book-order-with-route.logic";
import useBookReOrderWithoutRouteLogic from "./book-re-order-without-route.logic";
import useBookReOrderWithRouteLogic from "./book-re-order-with-route.logic";
interface RoutePreviewProps {
  onBackToLongform: (data: boolean) => void;
  isRouteRecommendationPage: boolean;
  fileUploadData: any[];
}

const RoutePreview: React.FC<RoutePreviewProps> = ({
  onBackToLongform,
  isRouteRecommendationPage,
  fileUploadData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date();
  const [sessionLongformData, setSessionLongformData] =
    useState<LongFormRequestDTO>({});
  const [sessionLong2formData, setSessionLong2formData] =
    useState<LongFormRequestDTO>({});
  const [routeRecommendationsData, setRouteRecommendationData] =
    useState<RouteRecommendation>();
  const { routeRecommendations } = useRouteRecommendationsLogic();
  const [isDeliveryTimeLess, setIsDeliveryTimeLess] = useState(false);
  const [isDeliveryTimeGrater, setIsDeliveryTimeGrater] = useState(false);
  const [isGroundDeliveryTimeLess, setIsGroundDeliveryTimeLess] =
    useState(false);
  const [isGroundDeliveryTimeGreater, setIsGroundDeliveryTimeGreater] =
    useState(false);
  const [selectedPickup, setSelectedPickup] = useState<any>(null);
  const [selectedDelivery, setSelectedDelivery] = useState<any>(null);
  const { multipleFileUpload } = useMultipleFileUploadLogic();
  const token = useDecodeJWT();
  // const { timezoneFilter } = useTimezoneFilterLogic();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAX_MAP_API_KEY,
  });
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 41.8781, // Default to Chicago
    lng: -87.6298,
  });
  const [pickupLocation, setPickupLocation] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [deliveryLocation, setDeliveryLocation] =
    useState<google.maps.LatLngLiteral | null>(null);

  const navigate = useNavigate();
  const [pickupTimezone, setpickupTimezone] = useState("");
  const [deliveryTimezone, setDeliveryTimezone] = useState("");
  // Google Maps options
  const mapOptions: google.maps.MapOptions = {
    fullscreenControl: false, // Disable fullscreen control
    streetViewControl: false, // Disable street view control
    mapTypeControl: false, // Disable map type control (e.g., satellite view)
    zoomControl: true, // Keep zoom control
    gestureHandling: "auto", // Set drag behavior (e.g., 'greedy' or 'cooperative')
    draggable: true, // Enable dragging
  };
  const [estDeliveryTitle, setEstDeliveryTitle] = useState("Est. Delivery");
  const [requestedDeliveryTitle, setRequestedDeliveryTitle] = useState(
    "REQUESTED DELIVERY TIME"
  );
  const [agentRequestGUID, setAgentRequestGUID] = useState("");
  const [selectedRoute, setSelectedRoute] = useState(true);
  const [selectedGround, setSelectedGround] = useState(false);
  const [groundRecommendationsData, setGroundRecommendationsData] =
    useState<RouteRecommendation>();
  const { bookOrderWithoutRoute } = useBookOrderWithoutRouteLogic();
  const { bookOrderWithRoute } = useBookOrderWithRouteLogic();
  const { BookReOrderWithoutRoute } = useBookReOrderWithoutRouteLogic();
  const { bookReOrderWithRoute } = useBookReOrderWithRouteLogic();

  // Use AbortController to cancel previous API requests
  const abortControllerRef = useRef<AbortController | null>(null);
  const fieldsToCompare: (keyof LongFormRequestDTO)[] = [
    "puReadyTime",
    "delRequestTime",
    "commodity",
    "dimWeight",
    "weight",
    "puAddress1",
    "puCityName",
    "puStateProvID",
    "puPostalCode",
    "puTimezoneID",
    "puCountryID",
    "puAirportID",
    "delAddress1",
    "delCityName",
    "delStateProvID",
    "delPostalCode",
    "delTimezoneID",
    "delCountryID",
    "delAirportID",
    "delRequestTime",
    "delRequestAsap",
    "serviceGUID",
  ];

  const fieldsToCompareTimezone: (keyof LongFormRequestDTO)[] = [
    "puTimezoneID",
    "delTimezoneID",
    "puReadyTime",
    "delRequestTime",
    "delRequestAsap",
    "accountNumber",
  ];
  const getSvgString = () => {
    // Render SVG to static markup
    const svgString = ReactDOMServer.renderToStaticMarkup(
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="green"
      >
        <FlagIcon style={{ fontSize: 24, color: "green" }} />
      </svg>
    );

    // Encode SVG string
    const encodedSvg = encodeURIComponent(svgString)
      .replace(/'/g, "%27") // Fix for single quote
      .replace(/"/g, "%22"); // Fix for double quotes
    return `data:image/svg+xml;charset=utf-8,${encodedSvg}`;
  };
  const deliveryIcon = getSvgString();
  const [showDeliveryDetails, setShowDeliveryDetails] = useState(false);
  const [showPickupDetails, setShowPickupDetails] = useState(false);
  // const files: any = sessionStorage.getItem("longFormFiles");
  useEffect(() => {
    const savedData: any = sessionStorage.getItem("longFormData");
    if (savedData && isRouteRecommendationPage) {
      try {
        const parseSavedData = JSON.parse(savedData);
        if (parseSavedData && Object.keys(parseSavedData).length > 0) {
          setSessionLongformData(parseSavedData); // Set the session data first
          setSessionLong2formData(parseSavedData);
          if (
            !parseSavedData.delRequestAsap &&
            parseSavedData.orderTimeOut &&
            parseSavedData.timeOutWindow
          ) {
            setRequestedDeliveryTitle("DELIVERY TIME OUT");
          } else {
            setRequestedDeliveryTitle("REQUESTED DELIVERY TIME");
          }
        }
      } catch (error) {
        console.error("Error parsing longFormData from sessionStorage", error);
      }
    }
  }, [isRouteRecommendationPage]);

  useEffect(() => {
    // Update center based on pickup or delivery location if available
    if (pickupLocation) {
      setCenter(pickupLocation);
    } else if (deliveryLocation) {
      setCenter(deliveryLocation);
    }
  }, [pickupLocation, deliveryLocation]);

  // New useEffect to watch for changes in sessionLongformData and then call getRouteRecommendations
  useEffect(() => {
    const previousData: any = sessionStorage.getItem("previousLongformData");
    const parsePreviousData = previousData ? JSON.parse(previousData) : null;
    if (sessionLongformData && Object.keys(sessionLongformData).length > 0) {
      // Check for differences in the specified fields
      const hasDifferences = fieldsToCompareTimezone.some((field) => {
        return sessionLongformData[field] !== parsePreviousData?.[field];
      });
      if (hasDifferences) {
        if (
          sessionLongformData.puStandardAbbr !==
          sessionLongformData.delStandardAbbr
        ) {
          if (sessionLongformData.puStandardAbbr) {
            getPickupTimezoneFilter(sessionLongformData.puStandardAbbr);
          }
          if (sessionLongformData.delStandardAbbr) {
            getDeliveryTimezoneFilter(sessionLongformData.delStandardAbbr);
          }
        } else {
          getPickupAndDeliveryTimezoneFilter(
            sessionLongformData.puStandardAbbr || ""
          );
        }
      }
    }
  }, [sessionLongformData]);

  useEffect(() => {
    const previousData: any = sessionStorage.getItem("previousLongformData");
    const parsePreviousData = previousData ? JSON.parse(previousData) : null;

    if (sessionLong2formData && Object.keys(sessionLong2formData).length > 0) {
      // Define fields to compare
      const hasDifferences = fieldsToCompare.some((field) => {
        return sessionLong2formData[field] !== parsePreviousData?.[field];
      });
      // If there are differences, update sessionStorage and call the API
      if (hasDifferences) {
        // Cancel the previous API call if still ongoing
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        // Create a new AbortController for the new API call
        setIsLoading(true);
        const newAbortController = new AbortController();
        abortControllerRef.current = newAbortController;
        getRouteRecommendations(newAbortController.signal);
      }
    }
  }, [sessionLong2formData]);

  useEffect(() => {
    const previousData: any = sessionStorage.getItem("previousLongformData");
    const parsePreviousData = previousData ? JSON.parse(previousData) : null;
    if (sessionLongformData && Object.keys(sessionLongformData).length > 0) {
      const hasDifferences = fieldsToCompare.some((field) => {
        return sessionLongformData[field] !== parsePreviousData?.[field];
      });
      if (hasDifferences) {
        const fetchLocations = async () => {
          const pickup: any = await getAddressFromGoogleMap(
            sessionLongformData.puPostalCode || "",
            sessionLongformData.puCountryID || "",
            sessionLongformData.puCityName || "",
            sessionLongformData.puAddress1 || ""
          );
          const delivery: any = await getAddressFromGoogleMap(
            sessionLongformData.delPostalCode || "",
            sessionLongformData.delCountryID || "",
            sessionLongformData.delCityName || "",
            sessionLongformData.delAddress1 || ""
          );

          setPickupLocation(pickup);
          setDeliveryLocation(delivery);
        };
        fetchLocations();
      }
    }
  }, [sessionLongformData, isLoaded]);

  const getPickupTimezoneFilter = async (timeZoneID: string) => {
    try {
      if (timeZoneID) {
        setpickupTimezone(timeZoneID || "");
        setSelectedPickup({
          type: "pickup",
          time: sessionLongformData.puReadyNow
            ? formatDateAndTime(
                moment(currentDate).format("YYYY-MM-DDTHH:mm:ss"),
                timeZoneID
              )
            : formatDateAndTime(sessionLongformData.puReadyTime, timeZoneID),
        });
      }
    } catch (err) {}
  };

  const getPickupAndDeliveryTimezoneFilter = async (timeZoneID: string) => {
    try {
      if (timeZoneID) {
        setpickupTimezone(timeZoneID || "");
        setDeliveryTimezone(timeZoneID || "");
        setSelectedPickup({
          type: "pickup",
          time: sessionLongformData.puReadyNow
            ? formatDateAndTime(
                moment(currentDate).format("YYYY-MM-DDTHH:mm:ss"),
                timeZoneID
              )
            : formatDateAndTime(sessionLongformData.puReadyTime, timeZoneID),
        });
        setSelectedDelivery({
          type: "delivery",
          time: !sessionLongformData.delRequestAsap
            ? formatDateAndTimeAndPlusMinute(
                sessionLongformData.delRequestTime,
                timeZoneID,
                sessionLongformData.orderTimeOut
                  ? sessionLongformData.timeOutWindow || 0
                  : 0
              )
            : "As soon as possible",
        });
      }
    } catch (err) {}
  };

  const getDeliveryTimezoneFilter = async (timeZoneID: string) => {
    try {
      if (timeZoneID) {
        setDeliveryTimezone(timeZoneID || "");
        setSelectedDelivery({
          type: "delivery",
          time: !sessionLongformData.delRequestAsap
            ? formatDateAndTimeAndPlusMinute(
                sessionLongformData.delRequestTime,
                timeZoneID,
                sessionLongformData.orderTimeOut
                  ? sessionLongformData.timeOutWindow || 0
                  : 0
              )
            : "As soon as possible",
        });
      }
    } catch (err) {}
  };

  const FlightIcon = ({ iataID }: { iataID: string }) => {
    const imageUrl = require(`../../assets/images/airline/Iata${iataID}.png`);
    return <img src={imageUrl} alt="flight icon" />;
  };

  const getAddressFromGoogleMap = async (
    postalCode: string,
    countryId: string,
    cityName: string,
    addressLine1: string
  ) => {
    const apiKey = MAX_MAP_API_KEY; // Replace with your API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      addressLine1
    )},${encodeURIComponent(cityName)},${encodeURIComponent(
      postalCode
    )},${encodeURIComponent(countryId)}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK") {
        const results = data.results;
        if (results.length > 0) {
          return results[0].geometry.location; // Return latitude and longitude
        } else {
          throw new Error("No results found");
        }
      } else {
        throw new Error(data.status);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const getRouteRecommendations = async (signal?: AbortSignal) => {
    try {
      setIsLoading(true);
      setRouteRecommendationData(undefined);
      setGroundRecommendationsData(undefined);
      handleSelectFlight(true);
      setIsDeliveryTimeLess(false);
      setIsDeliveryTimeGrater(false);
      setIsGroundDeliveryTimeLess(false);
      setIsGroundDeliveryTimeGreater(false);
      const response = await routeRecommendations(sessionLongformData, signal);
      if (response.data && Number(response.data.statusCode) == 200) {
        const res = response.data.data;
        if (res.routeRecommendations && res.routeRecommendations.length > 0) {
          const routeRecommendations = res.routeRecommendations[0];
          const filteredGroundData = res.routeRecommendations.find(
            (rec: any) => rec.serviceID === 1007
          );
          if (sessionLongformData.serviceID === 1007) {
            setRouteRecommendationData(undefined);
            handleSelectGround(true);
          } else {
            setRouteRecommendationData(routeRecommendations);
          }
          setGroundRecommendationsData(filteredGroundData);
          setAgentRequestGUID(res?.agentRequestGUID);
          routeRecommendations.qdtSweepUsed = routeRecommendations.qdtSweepUsed
            ? 1
            : 0;
          if (
            sessionLongformData.qdtSweep &&
            routeRecommendations.qdtSweepUsed == 1
          ) {
            setEstDeliveryTitle("Delivery Sweep");
          }
          if (!sessionLongformData?.delRequestAsap) {
            if (
              moment(routeRecommendations?.estDeliveryTime).isSameOrBefore(
                moment(sessionLongformData?.delRequestTime)
              )
            ) {
              setIsDeliveryTimeLess(true);
            }
            if (
              moment(routeRecommendations?.estDeliveryTime).isAfter(
                moment(sessionLongformData?.delRequestTime)
              )
            ) {
              setIsDeliveryTimeGrater(true);
            }
            if (
              moment(filteredGroundData?.estDeliveryTime).isSameOrBefore(
                moment(sessionLongformData?.delRequestTime)
              )
            ) {
              setIsGroundDeliveryTimeLess(true);
            }
            if (
              moment(filteredGroundData?.estDeliveryTime).isAfter(
                moment(sessionLongformData?.delRequestTime)
              )
            ) {
              setIsGroundDeliveryTimeGreater(true);
            }
          }
        } else {
          setRouteRecommendationData(undefined);
          setAgentRequestGUID(response?.data.data.agentRequestGUID);
          setIsLoading(false);
        }
      } else {
        setRouteRecommendationData(undefined);
        setAgentRequestGUID(response?.data.data.agentRequestGUID); 
        setIsLoading(false);
      }
      setIsLoading(false); // Ensure loading is false when the request finishes
    } catch (err) {
      setRouteRecommendationData(undefined);
      setGroundRecommendationsData(undefined);
    }
  };

  function formatDate(date: any): string {
    // Check if date is undefined or null
    if (!date) return "";

    // Convert date to Date object if it is not already
    const dateObj = date instanceof Date ? date : new Date(date);

    // Check if the date is valid
    if (isNaN(dateObj.getTime())) return "";

    const day = String(dateObj.getDate()).padStart(2, "0");
    const monthAbbreviations = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const month = monthAbbreviations[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const containerStyle = {
    width: "100%",
  };

  const lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 3,
  };

  function generateCurvedPath(
    start: google.maps.LatLngLiteral,
    end: google.maps.LatLngLiteral
  ): google.maps.LatLngLiteral[] {
    const curvature = 3; // Adjust for how "curved" the path is (0.5 for half circle)
    const numPoints = 100; // Number of points along the curve

    const lat1 = (start.lat * Math.PI) / 180;
    const lon1 = (start.lng * Math.PI) / 180;
    const lat2 = (end.lat * Math.PI) / 180;
    const lon2 = (end.lng * Math.PI) / 180;

    const arcPoints: google.maps.LatLngLiteral[] = [];

    for (let i = 0; i <= numPoints; i++) {
      const fraction = i / numPoints;

      const A = Math.sin((1 - fraction) * curvature) / Math.sin(curvature);
      const B = Math.sin(fraction * curvature) / Math.sin(curvature);

      const x =
        A * Math.cos(lat1) * Math.cos(lon1) +
        B * Math.cos(lat2) * Math.cos(lon2);
      const y =
        A * Math.cos(lat1) * Math.sin(lon1) +
        B * Math.cos(lat2) * Math.sin(lon2);
      const z = A * Math.sin(lat1) + B * Math.sin(lat2);

      const lat = Math.atan2(z, Math.sqrt(x * x + y * y)) * (180 / Math.PI);
      const lon = Math.atan2(y, x) * (180 / Math.PI);

      arcPoints.push({ lat, lng: lon });
    }

    return arcPoints;
  }

  let curvedPathCoordinates: google.maps.LatLngLiteral[] = [];

  if (pickupLocation && deliveryLocation) {
    // Call generateCurvedPath only when both locations are available
    curvedPathCoordinates = generateCurvedPath(
      pickupLocation,
      deliveryLocation
    );
  }

  const handleSelectFlight = (bol: boolean) => {
    setSelectedRoute(bol);
    setSelectedGround(false);
  };

  const handleSelectGround = (bol: boolean) => {
    setSelectedGround(bol);
    setSelectedRoute(false);
  };

  const handleBackToLongform = () => {
    sessionStorage.setItem(
      "previousLongformData",
      JSON.stringify(sessionLong2formData)
    );
    onBackToLongform(false);
  };

  const handleSubmit = async (type: string) => {
    try {
      const flightLegs = routeRecommendationsData?.routeFlightLegs || [];
      const flightInfoArray = flightLegs.map(
        (flightLeg) => `${flightLeg.carrierCode}${flightLeg.flightNumber}`
      );
      const tempDocumentGUID: any = sessionStorage.getItem("tempDocumentGUID");
      const flightInfoString =
        flightInfoArray.length > 0
          ? flightInfoArray.join(", ")
          : flightInfoArray[0] || ""; // If there's only one item, use it directly
      sessionLongformData.flight = type === "submit" ? flightInfoString : "";
      sessionLongformData.tempDocumentGUID = tempDocumentGUID;
      const updatedRecommendationGUID = selectedRoute
        ? routeRecommendationsData?.recommendationGUID
        : groundRecommendationsData?.recommendationGUID;
      const updatedServiceName = selectedRoute
        ? sessionLongformData.serviceName
        : "Expedited Ground Delivery";
      const updatedServiceGUID = selectedRoute
        ? sessionLongformData.serviceGUID
        : sessionLongformData.serviceEGDGUID;
      sessionLongformData.recommendationGUID =
        type === "submit" ? updatedRecommendationGUID : "";
      sessionLongformData.serviceName = updatedServiceName;
      sessionLongformData.serviceGUID = updatedServiceGUID;
      sessionLongformData.agentRequestGUID = agentRequestGUID;
      let response;
      if (sessionLongformData.israteEstimate === false) {
        response =
          type === "submit"
            ? await bookOrderWithRoute(sessionLongformData)
            : await bookOrderWithoutRoute(sessionLongformData);
      } else {
        response =
          type === "submit"
            ? await bookReOrderWithRoute(sessionLongformData)
            : await BookReOrderWithoutRoute(sessionLongformData);
      }
      if (response.data) {
        const res = response.data;
        const filesToSend = Object.values(fileUploadData).map(
          (item) => item.file
        );
        const url = `/track-order?ShipmentGUID=${res.data.shipmentGUID}`;
        if (filesToSend.length > 0) {
          handleUploadAllFiles(filesToSend, res.data.shipmentGUID);
        } else {
          navigate(url);
        }
      }
    } catch (err) {
    } finally {
      sessionStorage.removeItem("longFormData");
      sessionStorage.removeItem("previousLongformData");
    }
  };

  const handleUploadAllFiles = async (filesToSend: any, shipmentGUID: any) => {
    const url = `/track-order?ShipmentGUID=${shipmentGUID}`;
    await Promise.all(
      filesToSend.map(async (file: any) => {
        if (file) {
          const fileParam = {
            shipmentGUID: shipmentGUID,
            insertUserID: token.UserId,
            files: [file], // Send the current file
          };
          return handleFileUpload(fileParam); // Call upload for each file
        } else {
          return { success: false };
        }
      })
    );
    navigate(url);
  };

  const handleFileUpload = async (data: any) => {
    try {
      let response: any;
      response = await multipleFileUpload(data);
      if (response) {
        // const res = response.data.data;
        return { success: true };
      }
    } catch (err) {
      return { success: false };
    }
  };

  return (
    <>
      <div className="container-fluid track-order Track-screen Route-preview-screen">
        <Box
          className="Header-main pt-16"
          display="flex"
          alignItems="start"
          justifyContent="space-between"
        >
          <Typography
            className="Main-Header side-by-side-header"
            variant="h4"
            color={"primary"}
          >
            Route Preview
          </Typography>
          <Typography className="info-text" variant="h6">
            The information contained in this rate estimation tool is not
            intended and should not be used or construed as a cost associated
            with the shipment. This information is provided solely for
            informational purposes. Actual costs could change due to a variety
            of factors, including, but not limited to accessorial charges,
            duties/VAT, etc.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" className="info_lbl route-head">
            Route Overview
          </Typography>
        </Box>
        <Box>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              className="d-flex w-100 Map-view Route-map"
              sx={{ height: "100%" }}
            >
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={5}
                  options={mapOptions}
                >
                  {/* Pickup Marker */}
                  {pickupLocation && (
                    <Marker
                      position={pickupLocation}
                      icon={pickupIcon}
                      onClick={() =>
                        setSelectedPickup({
                          type: "pickup",
                          time: sessionLongformData.puReadyNow
                            ? formatDateAndTime(
                                moment(currentDate).format(
                                  "YYYY-MM-DDTHH:mm:ss"
                                ),
                                pickupTimezone
                              )
                            : formatDateAndTime(
                                sessionLongformData.puReadyTime,
                                pickupTimezone
                              ),
                        })
                      }
                    />
                  )}
                  {/* Delivery Marker */}
                  {deliveryLocation && (
                    <Marker
                      position={deliveryLocation}
                      icon={{
                        url: deliveryIcon,
                        scaledSize: new window.google.maps.Size(30, 30), // Adjust size as needed
                      }}
                      onClick={() =>
                        setSelectedDelivery({
                          type: "delivery",
                          time: !sessionLongformData.delRequestAsap
                            ? formatDateAndTimeAndPlusMinute(
                                sessionLongformData.delRequestTime,
                                deliveryTimezone,
                                sessionLongformData.orderTimeOut
                                  ? sessionLongformData.timeOutWindow || 0
                                  : 0
                              )
                            : "As soon as possible",
                        })
                      }
                    />
                  )}

                  {/* Optional: Polyline connecting pickup and delivery */}
                  <Polyline
                    path={curvedPathCoordinates}
                    options={{
                      strokeColor: "#2A7A98",
                      strokeOpacity: 0, // Hide the solid line
                      strokeWeight: 0, // Optional: Ensure the line is not visible,
                      icons: [
                        {
                          icon: lineSymbol,
                          offset: "0",
                          repeat: "20px",
                        },
                      ],
                    }}
                  />
                  {/* InfoWindow for Pickup */}

                  {selectedPickup &&
                    selectedPickup.type === "pickup" &&
                    pickupLocation && (
                      <InfoWindow
                        position={pickupLocation}
                        onCloseClick={() => setSelectedPickup(null)}
                        options={{
                          pixelOffset: new window.google.maps.Size(0, 100), // Adjust this value to position below the marker
                        }}
                      >
                        <div className="map-pinpoint">
                          <div>
                            <h5 className="map-lbl">PICKUP READY TIME</h5>
                            <p className="mb-0 date-lbl">
                              {selectedPickup.time}
                            </p>
                            <a
                              className="pick_lbl mt-8 mb-8"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowPickupDetails(!showPickupDetails); // Toggle Pickup details
                              }}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Pickup Details
                              {showPickupDetails ? (
                                <ArrowDropUpIcon
                                  className="arrow-icon"
                                  sx={{ marginLeft: "4px" }}
                                />
                              ) : (
                                <ArrowDropDownIcon className="arrow-icon" />
                              )}
                            </a>
                            {showPickupDetails && (
                              <div>
                                <p className="mb-0 map-lbl">Pickup</p>
                                <p className="mb-0 comp_name">
                                  {sessionLongformData.puCompanyName}
                                </p>
                                <p className="mb-0 comp_name_dtl">
                                  {sessionLongformData.puAddress1}{" "}
                                </p>
                                {sessionLongformData?.puAddress2 && (
                                  <p className="mb-0 comp_name_dtl">
                                    {sessionLongformData.puAddress2}{" "}
                                  </p>
                                )}
                                {sessionLongformData?.puAddress3 && (
                                  <p className="mb-0 comp_name_dtl">
                                    {sessionLongformData.puAddress3}{" "}
                                  </p>
                                )}
                                <p className="mb-0 comp_name_dtl">
                                  {sessionLongformData.puCityName},{" "}
                                  {sessionLongformData.puCountryID}{" "}
                                  {sessionLongformData.puPostalCode}
                                </p>
                                {sessionLongformData.puPhoneNumber && (
                                  <p className="mb-0 Phone-num-lbl">
                                    <PhoneIphoneIcon className="Add-icon" />
                                    <span className="phonenum">
                                      {formatPhoneNumber(
                                        sessionLongformData.puPhoneNumber || ""
                                      )}
                                    </span>
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </InfoWindow>
                    )}

                  {/* InfoWindow for Delivery */}
                  {selectedDelivery &&
                    selectedDelivery.type === "delivery" &&
                    deliveryLocation && (
                      <InfoWindow
                        position={deliveryLocation}
                        onCloseClick={() => setSelectedDelivery(null)}
                        options={{
                          pixelOffset: new window.google.maps.Size(0, 95), // Adjust this value to position below the marker
                        }}
                      >
                        <div className="map-pinpoint">
                          <div>
                            <h5 className="map-lbl">
                              {requestedDeliveryTitle}
                            </h5>
                            <p className="mb-0 date-lbl">
                              {selectedDelivery.time}
                            </p>
                            <a
                              className="pick_lbl mt-8 mb-8"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowDeliveryDetails(!showDeliveryDetails); // Toggle delivery details
                              }}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Delivery Details
                              {showDeliveryDetails ? (
                                <ArrowDropUpIcon
                                  className="arrow-icon"
                                  sx={{ marginLeft: "4px" }}
                                />
                              ) : (
                                <ArrowDropDownIcon className="arrow-icon" />
                              )}
                            </a>
                            {showDeliveryDetails && (
                              <div>
                                <p className="mb-0 map-lbl">Delivery</p>
                                <p className="mb-0 comp_name">
                                  {sessionLongformData.delCompanyName}
                                </p>
                                <p className="mb-0 comp_name_dtl">
                                  {sessionLongformData.delAddress1}{" "}
                                </p>
                                {sessionLongformData?.delAddress2 && (
                                  <p className="mb-0 comp_name_dtl">
                                    {sessionLongformData.delAddress2}{" "}
                                  </p>
                                )}
                                {sessionLongformData?.delAddress3 && (
                                  <p className="mb-0 comp_name_dtl">
                                    {sessionLongformData.delAddress3}{" "}
                                  </p>
                                )}
                                <p className="mb-0 comp_name_dtl">
                                  {sessionLongformData.delCityName},{" "}
                                  {sessionLongformData.delCountryID}{" "}
                                  {sessionLongformData.delPostalCode}
                                </p>
                                {sessionLongformData.delPhoneNumber && (
                                  <p className="mb-0 Phone-num-lbl">
                                    <PhoneIphoneIcon className="Add-icon" />
                                    <span className="phonenum">
                                      {formatPhoneNumber(
                                        sessionLongformData.delPhoneNumber || ""
                                      )}
                                    </span>
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </InfoWindow>
                    )}
                </GoogleMap>
              )}
            </Box>
          </Grid>
        </Box>
        <Box className="mt-16">
          <Typography variant="h5" className="info_lbl route-head">
            Route Preview
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <Box className="d-flex Left-route-section">
                <Box className="route-headlbl plr8 Blue-main-head">
                  MNX Recommended Route
                </Box>
                <Box className="Route-status-section">
                  {!routeRecommendationsData &&
                    !groundRecommendationsData &&
                    !isLoading && (
                      <Box className="d-flex flex-column Map-view p16">
                        <span className="no-route-txt">
                          No Recommended Route
                        </span>
                        <p className="route-text-lbl">
                          The system is not able to recommend any routes at the
                          moment. Please allow an MNX Logistics Coordinator to
                          choose your route.
                        </p>
                        <Button
                          className="assign-route"
                          endIcon={<img src={arrowicon} alt="icon right" />}
                          onClick={() => handleSubmit("assingRoute")}
                          id="btnAssignRouteToMe"
                        >
                          Assign route for me
                        </Button>
                      </Box>
                    )}
                  {/* flair loading section strat */}
                  {isLoading && <FlairLoading isLoading={isLoading} />}
                  {/* flair loading section end*/}

                  {routeRecommendationsData && (
                    <div
                      className={`Map-view ${
                        selectedRoute ? "route-selected" : ""
                      }`}
                    >
                      <div className="d-flex flight-route p16">
                        <div className="d-flex prefer-route-left">
                          <div className="Routesec-1 align-center">
                            <span className="logo-img">
                              <FlightIcon
                                iataID={
                                  routeRecommendationsData.routeFlightLegs[0]
                                    ?.carrierCode
                                }
                              />
                            </span>
                            <span className="flight_id">
                              {routeRecommendationsData.routeFlightLegs[0]
                                ?.carrierCode +
                                routeRecommendationsData.routeFlightLegs[0]
                                  .flightNumber}
                            </span>
                          </div>
                          <div className="Routesec-2">
                            <div className="d-flex colflexview info-route-left">
                              <span className="del_date">
                                {formatDateToMonthDay(
                                  routeRecommendationsData.routeFlightLegs[0]
                                    .departureDateTime
                                )}
                              </span>
                              <span
                                className="del_time"
                                style={{ marginRight: "10px" }}
                              >
                                {formatTime(
                                  routeRecommendationsData.routeFlightLegs[0]
                                    .departureDateTime,
                                  pickupTimezone || ""
                                )}
                              </span>
                              <span className="del_date location_lbl">
                                {
                                  routeRecommendationsData.routeFlightLegs[0]
                                    .departureAirport
                                }
                              </span>
                            </div>
                            <div className="flight-row">
                              <span>&nbsp;</span>
                              <div className="border-wrapper">
                                <div className="border-wrapper-dot-left"></div>
                                <img
                                  className="bordered-image"
                                  src={flighticon}
                                  alt="Phone icon"
                                />
                                <div className="border-wrapper-dot-right"></div>
                              </div>
                              <span className="del_date location_lbl">
                                {routeRecommendationsData.routeFlightLegs
                                  .length > 1
                                  ? "Connection"
                                  : "Direct"}
                              </span>
                            </div>
                            <div
                              className="colflexview d-flex info-route-right"
                              style={{ marginLeft: "10px" }}
                            >
                              <span className="flex-end d-flex del_date">
                                {formatDateToMonthDay(
                                  routeRecommendationsData.routeFlightLegs[0]
                                    .arrivalDateTime
                                )}
                              </span>
                              <span className="del_time">
                                {" "}
                                {formatTime(
                                  routeRecommendationsData.routeFlightLegs[
                                    routeRecommendationsData.routeFlightLegs
                                      .length - 1
                                  ].arrivalDateTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                              <span className="flex-end d-flex del_date location_lbl">
                                {
                                  routeRecommendationsData.routeFlightLegs[
                                    routeRecommendationsData.routeFlightLegs
                                      .length - 1
                                  ].arrivalAirport
                                }
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="Routesec-3 colflexview d-flex">
                          <span className="del_date">{estDeliveryTitle}</span>
                          {isDeliveryTimeLess && (
                            <>
                              <span className="mt4 green-val">
                                {formatDateAndTime(
                                  routeRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </>
                          )}
                          {isDeliveryTimeGrater && (
                            <>
                              <span className="mt4 red-val">
                                {formatDateAndTime(
                                  routeRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </>
                          )}
                          {sessionLongformData.delRequestAsap && (
                            <>
                              <span className="mt4 black-val">
                                {formatDateAndTime(
                                  routeRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="v-progress flight-progress">
                        <ul>
                          <li className="v-progress-item completed">
                            <div>
                              <span className="route-live-status">
                                Flight Departure -{" "}
                                {routeRecommendationsData.routeFlightLegs[0]
                                  .carrierCode +
                                  routeRecommendationsData.routeFlightLegs[0]
                                    .flightNumber}
                              </span>
                              <span className="route-live-lbl">
                                {
                                  routeRecommendationsData.routeFlightLegs[0]
                                    .departureAirport
                                }{" "}
                                -{" "}
                                {formatTime(
                                  routeRecommendationsData.routeFlightLegs[0]
                                    .departureDateTime,
                                  pickupTimezone || ""
                                )}
                              </span>
                            </div>
                          </li>

                          <li className="v-progress-item">
                            <div>
                              <span className="route-live-status">
                                Flight Arrival
                              </span>
                              <span className="route-live-lbl">
                                {
                                  routeRecommendationsData.routeFlightLegs[
                                    routeRecommendationsData.routeFlightLegs
                                      .length - 1
                                  ].arrivalAirport
                                }{" "}
                                -{" "}
                                {formatTime(
                                  routeRecommendationsData.routeFlightLegs[
                                    routeRecommendationsData.routeFlightLegs
                                      .length - 1
                                  ].arrivalDateTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="flight-bcg">
                        <div className="ptb-8 plr-16 d-flex flight-route align-center ">
                          <div className="d-flex colflexview">
                            <span className="route-left">
                              This route was chosen based on your
                            </span>
                            <span className="route-left">preferences.</span>
                          </div>

                          <div className="d-flex align-center">
                            {sessionLongformData.mxwebEstCharges && (
                              <div className="d-flex colflexview Border-right-lbl pr-16">
                                <span
                                  className="del_date"
                                  style={{ letterSpacing: "0.15px" }}
                                >
                                  Est. Rate
                                </span>
                                <span className="del_time">
                                  ${routeRecommendationsData.routeQuote}
                                </span>
                              </div>
                            )}
                            <Box
                              sx={{
                                display: { xs: "none", sm: "block" }, // Hides on mobile (xs) and shows on other devices (sm and above)
                              }}
                            >
                              {!selectedRoute ? (
                                <Button
                                  variant="outlined"
                                  className="rounded ml-16"
                                  id="btnSelectFlightRoute"
                                  onClick={() => handleSelectFlight(true)}
                                >
                                  SELECT ROUTE
                                </Button>
                              ) : (
                                <LoadingButton
                                  id="btnSelectedFlightRoute"
                                  variant="contained"
                                  color="primary"
                                  loadingPosition="end"
                                  className="d-flex rounded ml-16 "
                                  startIcon={<CheckCircleRoundedIcon />}
                                  onClick={() => handleSelectFlight(true)}
                                >
                                  ROUTE SELECTED
                                </LoadingButton>
                              )}
                            </Box>
                          </div>
                        </div>
                        <Box
                          className="mlr-16 mb-8"
                          sx={{
                            display: { xs: "block", sm: "none" }, // Hides on mobile (xs) and shows on other devices (sm and above)
                          }}
                        >
                          {!selectedRoute ? (
                            <Button
                              variant="outlined"
                              className="rounded width100"
                              id="btnSelectFlightRoute"
                              onClick={() => handleSelectFlight(true)}
                            >
                              SELECT ROUTE
                            </Button>
                          ) : (
                            <LoadingButton
                              id="btnSelectedFlightRoute"
                              variant="contained"
                              color="primary"
                              loadingPosition="end"
                              className="d-flex rounded width100"
                              startIcon={<CheckCircleRoundedIcon />}
                              onClick={() => handleSelectFlight(true)}
                            >
                              ROUTE SELECTED
                            </LoadingButton>
                          )}
                        </Box>
                      </div>
                    </div>
                  )}
                  {routeRecommendationsData && (
                    <div className="Map-view p16 mt-8">
                      <p className="mb-0 route-lbl">Route Timeline</p>
                      <div className="connecting-flight-section">
                        <div className="d-flex">
                          <div className="Routesec-2 mlr-0 flight-route-time">
                            <div className="d-flex colflexview">
                              <span className="del_date">
                                {formatDateToMonthDay(
                                  routeRecommendationsData.estPickupTime
                                )}
                              </span>
                              <span
                                className="del_time"
                                style={{ marginRight: "10px" }}
                              >
                                {formatTime(
                                  routeRecommendationsData.estPickupTime,
                                  pickupTimezone || ""
                                )}
                              </span>
                              <span className="del_date location_lbl">
                                Pickup
                              </span>
                            </div>
                            <div className="flight-row">
                              <span>&nbsp;</span>
                              <div className="border-wrapper car-drop">
                                <div className="border-wrapper-dot-left car-drop-left"></div>
                                <img
                                  className="bordered-image"
                                  src={caricon}
                                  alt="Phone icon"
                                />
                                <div className="border-wrapper-dot-right car-drop-right"></div>
                              </div>
                              <span className="del_date location_lbl">
                                {calculateHoursDifference(
                                  routeRecommendationsData.estPickupTime,
                                  routeRecommendationsData.estDropTime
                                )}
                              </span>
                            </div>
                            <div
                              className="colflexview d-flex"
                              style={{ marginLeft: "10px" }}
                            >
                              <span className="flex-end d-flex del_date">
                                {formatDateToMonthDay(
                                  routeRecommendationsData.estDropTime
                                )}
                              </span>
                              <span className="del_time">
                                {formatTime(
                                  routeRecommendationsData.estDropTime,
                                  pickupTimezone || ""
                                )}
                              </span>
                              <span className="flex-end d-flex del_date location_lbl">
                                Drop Lockout
                              </span>
                            </div>
                          </div>
                          {routeRecommendationsData.routeFlightLegs.length ==
                            1 && (
                            <div className="flight-row route-flight">
                              <span>&nbsp;</span>
                              <div className="border-wrapper wrap-left">
                                <div className="border-wrapper-dot-left dot-left"></div>
                                <div className="label-container">
                                  <span className="flight-label">
                                    {formatTime(
                                      routeRecommendationsData
                                        .routeFlightLegs[0].departureDateTime,
                                      pickupTimezone || ""
                                    )}
                                  </span>
                                </div>

                                <img
                                  className="bordered-image"
                                  src={flighticon}
                                  alt="Phone icon"
                                />
                                <div className="label-container-right">
                                  <span className="flight-label">
                                    {formatTime(
                                      routeRecommendationsData
                                        .routeFlightLegs[0].arrivalDateTime,
                                      deliveryTimezone || ""
                                    )}
                                  </span>
                                </div>

                                <div className="border-wrapper-dot-right dot-right"></div>
                              </div>
                              <span className="del_date location_lbl">
                                {routeRecommendationsData.routeFlightLegs[0]
                                  .carrierCode +
                                  routeRecommendationsData.routeFlightLegs[0]
                                    .flightNumber}
                              </span>
                            </div>
                          )}
                          {routeRecommendationsData.routeFlightLegs.length >
                            1 && (
                            <div className="flight-row route-flight">
                              <span>&nbsp;</span>
                              <div className="border-wrapper wrap-left">
                                <div className="border-wrapper-dot-left dot-left"></div>
                                <div className="label-container">
                                  <span className="flight-label">
                                    {formatTime(
                                      routeRecommendationsData
                                        .routeFlightLegs[0].departureDateTime,
                                      pickupTimezone || ""
                                    )}
                                  </span>
                                </div>

                                <img
                                  className="bordered-image"
                                  src={flighticon}
                                  alt="Phone icon"
                                />
                                <div className="label-container-right">
                                  <span className="flight-label">
                                    {formatTime(
                                      routeRecommendationsData.routeFlightLegs[
                                        routeRecommendationsData.routeFlightLegs
                                          .length - 1
                                      ].arrivalDateTime,
                                      deliveryTimezone || ""
                                    )}
                                  </span>
                                </div>

                                <div className="border-wrapper-dot-right dot-right"></div>
                              </div>

                              <span className="del_date location_lbl">
                                {routeRecommendationsData.routeFlightLegs.map(
                                  (flightLeg, index, array) => (
                                    <React.Fragment
                                      key={
                                        flightLeg.routeRecommendationFlightLegsGUID
                                      }
                                    >
                                      {flightLeg.carrierCode +
                                        flightLeg.flightNumber}
                                      {index < array.length - 1 && " - "}{" "}
                                    </React.Fragment>
                                  )
                                )}
                              </span>
                            </div>
                          )}

                          <div
                            className="Routesec-2 mlr-0 flight-route-time"
                            style={{ width: "40%", justifyContent: "flex-end" }}
                          >
                            <div className="d-flex colflexview">
                              <span className="del_date">
                                {formatDateToMonthDay(
                                  routeRecommendationsData.estRecoverTime
                                )}
                              </span>
                              <span
                                className="del_time"
                                style={{ marginRight: "10px" }}
                              >
                                {formatTime(
                                  routeRecommendationsData.estRecoverTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                              <span className="del_date location_lbl">
                                Est. Recover
                              </span>
                            </div>
                            <div className="flight-row">
                              <span>&nbsp;</span>
                              <div className="border-wrapper car-drop">
                                <div className="border-wrapper-dot-left car-drop-left"></div>
                                <img
                                  className="bordered-image"
                                  src={caricon}
                                  alt="Phone icon"
                                />
                                <div className="border-wrapper-dot-right car-drop-right"></div>
                              </div>
                              <span className="del_date location_lbl">
                                {calculateHoursDifference(
                                  routeRecommendationsData.estRecoverTime,
                                  routeRecommendationsData.estDeliveryTime
                                )}
                              </span>
                            </div>
                            <div
                              className="colflexview d-flex"
                              style={{ marginLeft: "10px" }}
                            >
                              <span className="flex-end d-flex del_date">
                                {formatDateToMonthDay(
                                  routeRecommendationsData.estDeliveryTime
                                )}
                              </span>
                              <span className="del_time">
                                {formatTime(
                                  routeRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                              <span className="flex-end d-flex del_date location_lbl">
                                Delivery
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="v-progress mt-16">
                        <ul>
                          <li className="v-progress-item completed">
                            <div>
                              <span className="route-live-status">Pickup</span>
                              <span className="route-live-lbl">
                                {formatDateAndTime(
                                  routeRecommendationsData.estPickupTime,
                                  pickupTimezone || ""
                                )}
                              </span>
                            </div>
                          </li>

                          <li className="v-progress-item">
                            <div>
                              <span className="route-live-status">
                                Drop Lockout
                              </span>
                              <span className="route-live-lbl">
                                {
                                  routeRecommendationsData.routeFlightLegs[0]
                                    .departureAirport
                                }{" "}
                                -{" "}
                                {formatDateAndTime(
                                  routeRecommendationsData.estDropTime,
                                  pickupTimezone || ""
                                )}
                              </span>
                            </div>
                          </li>
                          {routeRecommendationsData.routeFlightLegs.map(
                            (flightLeg, index, array) => (
                              <li className="v-progress-item">
                                <div>
                                  <span className="route-live-status">
                                    Flight Departure -{" "}
                                    {flightLeg.carrierCode +
                                      flightLeg.flightNumber}
                                  </span>
                                  {index === 0 && (
                                    <span className="route-live-lbl">
                                      {
                                        routeRecommendationsData
                                          .routeFlightLegs[0].departureAirport
                                      }{" "}
                                      -{" "}
                                      {formatDateAndTime(
                                        routeRecommendationsData
                                          .routeFlightLegs[0].departureDateTime,
                                        pickupTimezone || ""
                                      )}
                                    </span>
                                  )}
                                </div>
                              </li>
                            )
                          )}
                          <li className="v-progress-item">
                            <div>
                              <span className="route-live-status">
                                Flight Arrival
                              </span>
                              <span className="route-live-lbl">
                                {
                                  routeRecommendationsData.routeFlightLegs[
                                    routeRecommendationsData.routeFlightLegs
                                      .length - 1
                                  ].arrivalAirport
                                }{" "}
                                -{" "}
                                {formatDateAndTime(
                                  routeRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </div>
                          </li>
                          <li className="v-progress-item">
                            <div>
                              <span className="route-live-status">
                                Estimated Revoery
                              </span>
                              <span className="route-live-lbl">
                                {
                                  routeRecommendationsData.routeFlightLegs[0]
                                    .arrivalAirport
                                }{" "}
                                -{" "}
                                {formatDateAndTime(
                                  routeRecommendationsData.estRecoverTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </div>
                          </li>
                          <li className="v-progress-item delivered">
                            <div>
                              <span className="route-live-status">
                                Final Delivery
                              </span>
                              <span className="route-live-lbl">
                                {formatDateAndTime(
                                  routeRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {/* Ground Only */}
                  {!routeRecommendationsData && groundRecommendationsData && (
                    <Grid container>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        {groundRecommendationsData && (
                          <div className="ground-route">
                            <div
                              className={`Map-view ${
                                selectedGround ? "route-selected" : ""
                              }`}
                            >
                              <div className="d-flex flight-route p16">
                                <div className="d-flex prefer-route-left">
                                  <div className="Routesec-1 align-center">
                                    <span className="logo-img ground-img">
                                      <img src={caricon} alt="Phone icon" />
                                    </span>
                                    <span className="flight_id textCenter">
                                      Drive
                                    </span>
                                  </div>
                                  <div className="Routesec-2">
                                    <div className="d-flex colflexview info-route-left">
                                      <span className="del_date">
                                        {formatDateToMonthDay(
                                          groundRecommendationsData.estPickupTime
                                        )}
                                      </span>
                                      <span
                                        className="del_time"
                                        style={{ marginRight: "10px" }}
                                      >
                                        {formatTime(
                                          groundRecommendationsData.estPickupTime,
                                          pickupTimezone || ""
                                        )}
                                      </span>
                                      <span className="del_date location_lbl">
                                        Pickup
                                      </span>
                                    </div>
                                    <div className="flight-row">
                                      <span>&nbsp;</span>
                                      <div className="border-wrapper">
                                        <div className="border-wrapper-dot-left"></div>
                                        <img
                                          className="bordered-image"
                                          src={caricon}
                                          alt="Phone icon"
                                        />
                                        <div className="border-wrapper-dot-right"></div>
                                      </div>
                                      <span className="del_date location_lbl">
                                        {calculateHoursDifference(
                                          groundRecommendationsData.estPickupTime,
                                          groundRecommendationsData.estDeliveryTime
                                        )}
                                      </span>
                                    </div>
                                    <div
                                      className="colflexview d-flex info-route-right"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <span className="flex-end d-flex del_date">
                                        {formatDateToMonthDay(
                                          groundRecommendationsData.estDeliveryTime
                                        )}
                                      </span>
                                      <span className="del_time">
                                        {" "}
                                        {formatTime(
                                          groundRecommendationsData.estDeliveryTime,
                                          deliveryTimezone || ""
                                        )}
                                      </span>
                                      <span className="flex-end d-flex del_date location_lbl">
                                        Delivery
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="Routesec-3 colflexview d-flex">
                                  <span className="del_date">
                                    {estDeliveryTitle}
                                  </span>
                                  {isGroundDeliveryTimeLess && (
                                    <>
                                      <span className="mt4 green-val">
                                        {formatDateAndTime(
                                          groundRecommendationsData.estDeliveryTime,
                                          deliveryTimezone || ""
                                        )}
                                      </span>
                                    </>
                                  )}
                                  {isGroundDeliveryTimeGreater && (
                                    <>
                                      <span className="mt4 red-val">
                                        {formatDateAndTime(
                                          groundRecommendationsData.estDeliveryTime,
                                          deliveryTimezone || ""
                                        )}
                                      </span>
                                    </>
                                  )}

                                  {sessionLongformData.delRequestAsap && (
                                    <>
                                      <span className="mt4 black-val">
                                        {formatDateAndTime(
                                          groundRecommendationsData.estDeliveryTime,
                                          deliveryTimezone || ""
                                        )}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="v-progress flight-progress">
                                <ul>
                                  <li className="v-progress-item completed">
                                    <div>
                                      <span className="route-live-status">
                                        Pick up
                                      </span>
                                      <span className="route-live-lbl">
                                        {formatTime(
                                          groundRecommendationsData.estPickupTime,
                                          pickupTimezone || ""
                                        )}
                                      </span>
                                    </div>
                                  </li>

                                  <li className="v-progress-item">
                                    <div>
                                      <span className="route-live-status">
                                        Delivery
                                      </span>
                                      <span className="route-live-lbl">
                                        {formatTime(
                                          groundRecommendationsData.estDeliveryTime,
                                          deliveryTimezone || ""
                                        )}
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="flight-bcg">
                                <div className="ptb-8 plr-16 d-flex align-center justifycontentend flex-column-mobile">
                                  <div className="d-flex align-center flex-column-mobile full-width-mb">
                                    {sessionLongformData.mxwebEstCharges && (
                                      <div className="d-flex colflexview Border-right-lbl pr-16 full-width-xs mob-bott-8">
                                        <span
                                          className="del_date"
                                          style={{ letterSpacing: "0.15px" }}
                                        >
                                          Est. Rate
                                        </span>
                                        <span className="del_time">
                                          $
                                          {groundRecommendationsData.routeQuote}
                                        </span>
                                      </div>
                                    )}
                                    {!selectedGround ? (
                                      <Button
                                        sx={{
                                          height: "36px",
                                          marginRight: { xs: 0 },
                                        }}
                                        variant="outlined"
                                        className="rounded ml-16 mobleft-0 width100"
                                        id="btnSelectGroundRoute"
                                        onClick={() => handleSelectGround(true)}
                                      >
                                        SELECT ROUTE
                                      </Button>
                                    ) : (
                                      <LoadingButton
                                        sx={{
                                          height: "36px",
                                          marginRight: { xs: 0 },
                                        }}
                                        id="btnSelectedGroundRoute"
                                        variant="contained"
                                        color="primary"
                                        loadingPosition="end"
                                        className="d-flex rounded ml-16 mobleft-0 width100"
                                        startIcon={<CheckCircleRoundedIcon />}
                                        onClick={() => handleSelectGround(true)}
                                      >
                                        ROUTE SELECTED
                                      </LoadingButton>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <Box className="route-headlbl1 plr8">Order Details</Box>
              <Box className="d-flex flex-column Map-view p16">
                <Box className="d-flex gap-4 mb-8">
                  <Box className="d-flex flex-1 flex-column align-items-start pl-8 pr-8">
                    <Typography className="track_lbl">READY DATE</Typography>
                    <Typography className="other_dtl_summary">
                      {sessionLongformData.puReadyNow
                        ? formatDate(currentDate)
                        : formatDate(sessionLongformData?.puReadyTime)}
                    </Typography>
                  </Box>

                  <Box className="d-flex flex-column align-items-start pl-8 pr-8">
                    <Typography className="track_lbl">PIECES</Typography>
                    <Typography className="other_dtl_summary">
                      {sessionLongformData?.pieces}
                    </Typography>
                  </Box>

                  <Box className="d-flex flex-column align-items-start pl-8 pr-8">
                    <Typography className="track_lbl">WEIGHT</Typography>
                    <Typography className="other_dtl_summary">
                      {sessionLongformData?.weight}{" "}
                      {sessionLongformData?.weightUOM}
                    </Typography>
                  </Box>
                </Box>

                <Box className="d-flex flex-column align-items-start pl-8 pr-8 mb-8">
                  <Typography className="track_lbl">SERVICE</Typography>
                  <Typography className="other_dtl_summary">
                    {selectedRoute
                      ? sessionLongformData?.serviceName
                      : "Expedited Ground Delivery"}
                  </Typography>
                </Box>

                <Box className="d-flex flex-column align-items-start pl-8 pr-8 mb-8">
                  <Typography className="track_lbl">REFER</Typography>
                  <Typography
                    className={`other_dtl_summary ${
                      sessionLongformData.reference ? "" : "pb-2"
                    }`}
                  >
                    {sessionLongformData?.reference
                      ? sessionLongformData?.reference
                      : ""}
                  </Typography>
                </Box>

                <Box className="d-flex flex-column align-items-start pl-8 pr-8 mb-8">
                  <Typography className="track_lbl">COMMODITY</Typography>
                  <Typography
                    className={`other_dtl_summary ${
                      sessionLongformData.commodity ? "" : "pb-2"
                    }`}
                  >
                    {sessionLongformData?.commodity}
                  </Typography>
                </Box>

                <Box className="d-flex flex-column align-items-start justify-center pl-8 pr-8">
                  <Typography className="track_lbl">CONTENTS</Typography>
                  <Typography
                    className={`other_dtl_summary ${
                      sessionLongformData.contents ? "" : "pb-2"
                    }`}
                  >
                    {sessionLongformData.contents
                      ? sessionLongformData.contents
                      : ""}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* Route and ground */}
          {routeRecommendationsData && groundRecommendationsData && (
            <Grid container className="ground-top">
              <Grid item lg={7} md={12} sm={12} xs={12}>
                {/* Ground section start*/}
                {groundRecommendationsData && (
                  <Box className="route-headlbl2 plr8 mt-1 pt-8">
                    Ground Alternative Route
                  </Box>
                )}
                {groundRecommendationsData && (
                  <div className="ground-route">
                    <div
                      className={`Map-view ${
                        selectedGround ? "route-selected" : ""
                      }`}
                    >
                      <div className="d-flex flight-route p16">
                        <div className="d-flex prefer-route-left">
                          <div className="Routesec-1 align-center">
                            <span className="logo-img ground-img">
                              <img src={caricon} alt="Phone icon" />
                            </span>
                            <span className="flight_id textCenter">Drive</span>
                          </div>
                          <div className="Routesec-2">
                            <div className="d-flex colflexview info-route-left">
                              <span className="del_date">
                                {formatDateToMonthDay(
                                  groundRecommendationsData.estPickupTime
                                )}
                              </span>
                              <span
                                className="del_time"
                                style={{ marginRight: "10px" }}
                              >
                                {formatTime(
                                  groundRecommendationsData.estPickupTime,
                                  pickupTimezone || ""
                                )}
                              </span>
                              <span className="del_date location_lbl">
                                Pickup
                              </span>
                            </div>
                            <div className="flight-row">
                              <span>&nbsp;</span>
                              <div className="border-wrapper">
                                <div className="border-wrapper-dot-left"></div>
                                <img
                                  className="bordered-image"
                                  src={caricon}
                                  alt="Phone icon"
                                />
                                <div className="border-wrapper-dot-right"></div>
                              </div>
                              <span className="del_date location_lbl">
                                {calculateHoursDifference(
                                  groundRecommendationsData.estPickupTime,
                                  groundRecommendationsData.estDeliveryTime
                                )}
                              </span>
                            </div>
                            <div
                              className="colflexview d-flex info-route-right"
                              style={{ marginLeft: "10px" }}
                            >
                              <span className="flex-end d-flex del_date">
                                {formatDateToMonthDay(
                                  groundRecommendationsData.estDeliveryTime
                                )}
                              </span>
                              <span className="del_time">
                                {" "}
                                {formatTime(
                                  groundRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                              <span className="flex-end d-flex del_date location_lbl">
                                Delivery
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="Routesec-3 colflexview d-flex">
                          <span className="del_date">{estDeliveryTitle}</span>
                          {isGroundDeliveryTimeLess && (
                            <>
                              <span className="mt4 green-val">
                                {formatDateAndTime(
                                  groundRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </>
                          )}
                          {isGroundDeliveryTimeGreater && (
                            <>
                              <span className="mt4 red-val">
                                {formatDateAndTime(
                                  groundRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </>
                          )}

                          {sessionLongformData.delRequestAsap && (
                            <>
                              <span className="mt4 black-val">
                                {formatDateAndTime(
                                  groundRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="v-progress flight-progress">
                        <ul>
                          <li className="v-progress-item completed">
                            <div>
                              <span className="route-live-status">Pick up</span>
                              <span className="route-live-lbl">
                                {formatTime(
                                  groundRecommendationsData.estPickupTime,
                                  pickupTimezone || ""
                                )}
                              </span>
                            </div>
                          </li>

                          <li className="v-progress-item">
                            <div>
                              <span className="route-live-status">
                                Delivery
                              </span>
                              <span className="route-live-lbl">
                                {formatTime(
                                  groundRecommendationsData.estDeliveryTime,
                                  deliveryTimezone || ""
                                )}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="flight-bcg">
                        <div className="ptb-8 plr-16 d-flex align-center justifycontentend flex-column-mobile">
                          <div className="d-flex align-center flex-column-mobile full-width-mb">
                            {sessionLongformData.mxwebEstCharges && (
                              <div className="d-flex colflexview Border-right-lbl pr-16 full-width-xs mob-bott-8">
                                <span
                                  className="del_date"
                                  style={{ letterSpacing: "0.15px" }}
                                >
                                  Est. Rate
                                </span>
                                <span className="del_time">
                                  ${groundRecommendationsData.routeQuote}
                                </span>
                              </div>
                            )}
                            {!selectedGround ? (
                              <Button
                                sx={{
                                  height: "36px",
                                  marginRight: { xs: 0 },
                                }}
                                variant="outlined"
                                className="rounded ml-16 mobleft-0 width100"
                                id="btnSelectGroundRoute"
                                onClick={() => handleSelectGround(true)}
                              >
                                SELECT ROUTE
                              </Button>
                            ) : (
                              <LoadingButton
                                sx={{
                                  height: "36px",
                                  marginRight: { xs: 0 },
                                }}
                                id="btnSelectedGroundRoute"
                                variant="contained"
                                color="primary"
                                loadingPosition="end"
                                className="d-flex rounded ml-16 mobleft-0 width100"
                                startIcon={<CheckCircleRoundedIcon />}
                                onClick={() => handleSelectGround(true)}
                              >
                                ROUTE SELECTED
                              </LoadingButton>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Ground section end*/}
              </Grid>
            </Grid>
          )}
          {(isLoading || routeRecommendationsData) &&
          sessionLongformData.serviceID != 1007 ? (
            <Box>
              <p className="Status_label mb-0 p8">
                Please review the recommended route and confirm your order.
              </p>
              <p className="Status_label mb-0 plr8 pt-0">
                Alternatively, you can request that a route be assigned manually
                by an MNX employee.
              </p>
              <p className="assign-routelbl plr8">
                <a href="#" onClick={() => handleSubmit("assignRoute")}>
                  Please assign a route for me.
                </a>
              </p>
            </Box>
          ) : null}
        </Box>
      </div>
      <div className="container-fluid">
        <Box className="d-flex justify-content-end gap-2 w-100 Divider-top pt-16 pb-16">
          <Button
            className="text-custom"
            onClick={() => handleBackToLongform()}
            style={{ whiteSpace: "nowrap" }}
            id="btnBackToSignIn"
          >
            Back
          </Button>
          <LoadingButton
            id="btnRpSubmit"
            variant="contained"
            color="primary"
            loadingPosition="end"
            disabled={isLoading}
            className="d-flex rounded me-2"
            endIcon={<img src={arrowicon} alt="icon right" />}
            onClick={() =>
              isLoading || routeRecommendationsData || groundRecommendationsData
                ? handleSubmit("submit")
                : handleSubmit("assignRoute")
            }
          >
            {isLoading || routeRecommendationsData || groundRecommendationsData
              ? "CONFIRM ORDER"
              : "Assign route for me"}
          </LoadingButton>
        </Box>
      </div>
    </>
  );
};

export default RoutePreview;
