import React, { useEffect } from "react";
import "./App.css";
import "./assets/css/style.css";
import { Provider } from "react-redux";
import store from "./config/store";
import Routing from "./router/Routing";
import Notification from "./shared/util/notification";
import ErrorBoundary from "./shared/components/ErrorBoundary";
import { LoaderProvider } from "./shared/util/loader-context";
//import { REACT_APP_AHA_ACCOUNT_NAME, REACT_APP_AHA_APPLICATION_ID } from "./config/constants";
import getAhawidget from "./ahaWidget";
import withAhawidget from "./shared/components/withAhaWidget";
import { ToastProvider } from "./shared/context/ToastContext";
const App: React.FC = () => {
  useEffect(() => {
    getAhawidget();
  }, []);

  return (
    <Provider store={store}>
      <LoaderProvider>
        <ToastProvider>
          <Notification />
          <ErrorBoundary>
            <Routing />
          </ErrorBoundary>
        </ToastProvider>
      </LoaderProvider>
    </Provider>
  );
};
export default withAhawidget(App);
