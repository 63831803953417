import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React, { useEffect, useState } from "react";
import CodeDialog from "../code-dialog/code-dialog";
import useAddressLogic from "../../lookup/address/address.logic";
import { AddressItemDto } from "../../../models/address-item-dto";
import { CountryItemDto } from "../../../models/country-item-dto";
import { ZipCodeItemDto } from "../../../models/zip-code-item-dto";
import useZipCodeLogic from "../../lookup/zip-code/zip-code.logic";
import { useForm } from "react-hook-form";
import { MySettingItemDto } from "../../../models/long-form-item-dto";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import { handleNumericValue } from "../../util/numeric-value";
import { checkStringHasCommentCharecter } from "../../util/common";

interface PickupAddressProps {
  onPickUpDataChange: (updatedData: any) => void;
  DefaultData: MySettingItemDto;
  formErrors: any;
  errorCodePickup: any;
  onZipCodeDataChange: (errors: any, country: string) => void;
  setFormErrors: (errors: any) => void;
  setErrorCodePickup: (errors: any) => void;
  countryData: CountryItemDto[];
  onRequestTimeChange: (errors: any) => void;
  SetPickupZipCodeError: (errors: any) => void;
  pickupZipCodeError: any;
  addressDataList?: any;
  isLimitedRole: boolean;
}

const LongFormPickupAddress: React.FC<PickupAddressProps> = ({
  onPickUpDataChange,
  DefaultData,
  formErrors,
  onZipCodeDataChange,
  setFormErrors,
  countryData,
  errorCodePickup,
  setErrorCodePickup,
  onRequestTimeChange,
  pickupZipCodeError,
  SetPickupZipCodeError,
  addressDataList,
  isLimitedRole,
}) => {
  const [open, setOpen] = React.useState(false);
  const { address } = useAddressLogic();
  const { zipCode } = useZipCodeLogic();
  const [addressData, setAddressData] = useState<AddressItemDto[]>([]);
  const [isShowAddress3, setIsShowAddress3] = useState<boolean>(false);
  const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);
  const [pickupTimezoneID, setPickupTimezoneID] = useState("");
  const [pickupStandardAbbrID, setPickupStandardAbbrID] = useState("");
  const [pickupCity, setPickupCity] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isCityTextField, setIsCityTextField] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressItemDto | null>(
    null
  );
  const [selectedCountry, setSelectedCountry] = useState(
    DefaultData?.pickupCountryID
  );
  const [selectedZipCode, setSelectedZipCode] = useState<string>(
    DefaultData?.pickupPostalCode
  );
  const [disableSelectedAddress, setDisableSelectedAddress] = useState(false);
  const { register } = useForm();
  const [formData, setFormData] = useState({
    pickupAddress1: "",
    pickupAddress2: "",
    pickupAddress3: "",
    pickupCityName: "",
    pickupPostalCode: "",
    pickupCountryID: "",
    pickupStateProvID: "",
    pickupCompanyName: "",
    pickupInstructions: "",
    pickupAirportID: "",
    pickupEmailAddress: "",
    addressCode: DefaultData?.addressCode || "",
    pickupPhoneNumber: "",
    puKeep: false,
    pickupPersonToSee: "",
    pickupTimezoneID: "",
    puAddressGUID: "",
    pickupReadyTime: "",
    pickupReadyDay: 0,
    standardAbbr: "",
  });
  const userGroupGUID: any = localStorage.getItem("userGroupGUID");
  const errorControls = [
    {
      name: "pickupCompanyName",
      value: "puCompanyName",
    },
    {
      name: "pickupCityName",
      value: "puCityName",
    },
    {
      name: "pickupPostalCode",
      value: "puPostalCode",
    },
    {
      name: "pickupAirportID",
      value: "puAirportID",
    },
    {
      name: "pickupInstructions",
      value: "puInstructions",
    },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //    getAddress(addressDataList);
  // }, [addressDataList]);

  useEffect(() => {
    getAddress();
  }, []);

  useEffect(() => {
    getDefaultZipCode();
  }, []);

  useEffect(() => {
    if (DefaultData) {
      setSelectedCountry(DefaultData.pickupCountryID);
      setSelectedZipCode(DefaultData.pickupPostalCode);
      setPickupTimezoneID(
        DefaultData?.pickupTimezoneID ?? DefaultData?.timezoneID
      );
      setPickupCity(DefaultData?.pickupCityName || "");
      setFormData({
        pickupAddress1: DefaultData.pickupAddress1 || "",
        pickupAddress2: DefaultData.pickupAddress2 || "",
        pickupAddress3: DefaultData.pickupAddress3 || "",
        pickupCityName: DefaultData.pickupCityName || "",
        pickupCountryID: DefaultData.pickupCountryID || "",
        pickupStateProvID: DefaultData.pickupStateProvID || "",
        pickupCompanyName: DefaultData.pickupCompanyName || "",
        pickupInstructions: DefaultData.pickupInstructions || "",
        pickupAirportID: DefaultData.pickupAirportID || "",
        pickupPhoneNumber: DefaultData.pickupPhoneNumber || "",
        pickupPersonToSee: DefaultData?.pickupPersonToSee || "",
        puKeep: false,
        pickupPostalCode: DefaultData?.pickupPostalCode || "",
        pickupEmailAddress: DefaultData?.pickupEmailAddress,
        addressCode: DefaultData?.pickupAddressCode || "",
        pickupTimezoneID: pickupTimezoneID,
        puAddressGUID: "",
        pickupReadyTime: DefaultData.pickupReadyTime || "",
        pickupReadyDay: 0,
        standardAbbr: pickupStandardAbbrID,
      });
    }
  }, [DefaultData]);

  useEffect(() => {
    onPickUpDataChange({
      ...formData,
    });
  }, [formData]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      pickupTimezoneID: pickupTimezoneID,
      standardAbbr: pickupStandardAbbrID,
    }));
    onPickUpDataChange({
      ...formData,
      pickupTimezoneID: pickupTimezoneID,
      standardAbbr: pickupStandardAbbrID,
    });
  }, [pickupTimezoneID, pickupStandardAbbrID]);

  useEffect(() => {
    if (selectedAddress) {
      const combinedInstructions =
        (DefaultData.pickupInstructions ? DefaultData.pickupInstructions : "") +
        (DefaultData.pickupInstructions && selectedAddress.pickupInstructions
          ? ","
          : "") +
        (selectedAddress.pickupInstructions
          ? selectedAddress.pickupInstructions
          : "");
      const maxLength = 2000;
      const finalInstructions =
        combinedInstructions.length > maxLength
          ? combinedInstructions.slice(0, maxLength - 1)
          : combinedInstructions;

      setSelectedCountry(selectedAddress.countryID);
      setSelectedZipCode(selectedAddress.postalCode);
      setPickupCity(selectedAddress.cityName);
      setFormData((prevLongFormData) => ({
        ...prevLongFormData,
        pickupAddress1: selectedAddress.address1 || "",
        pickupAddress2: selectedAddress.address2 || "",
        pickupAddress3: selectedAddress.address3 || "",
        pickupCityName: selectedAddress.cityName || "",
        pickupPostalCode: selectedAddress.postalCode || "",
        pickupCountryID: selectedAddress.countryID || "",
        pickupStateProvID: selectedAddress.stateProvID || "",
        pickupCompanyName: selectedAddress.companyName || "",
        pickupInstructions: finalInstructions,
        pickupAirportID: selectedAddress.airportID || "",
        pickupEmailAddress: selectedAddress.emailAddress || "",
        addressCode: selectedAddress.addressCode || "",
        pickupPhoneNumber: selectedAddress.phoneNumber || "",
        pickupTimezoneID: selectedAddress.timezoneID || "",
        puAddressGUID: selectedAddress.addressGUID,
        pickupReadyTime: selectedAddress.pickupReadyTime || "",
        pickupReadyDay: selectedAddress.pickupReadyDay || 0,
        dropOff: selectedAddress.dropOff,
      }));

      onPickUpDataChange({
        pickupAddress1: selectedAddress.address1 || "",
        pickupAddress2: selectedAddress.address2 || "",
        pickupAddress3: selectedAddress.address3 || "",
        pickupCityName: selectedAddress.cityName || "",
        pickupPostalCode: selectedAddress.postalCode || "",
        pickupCountryID: selectedAddress.countryID || "",
        pickupStateProvID: selectedAddress.stateProvID || "",
        pickupCompanyName: selectedAddress.companyName || "",
        pickupInstructions: finalInstructions,
        pickupAirportID: selectedAddress.airportID || "",
        pickupEmailAddress: selectedAddress.emailAddress || "",
        addressCode: selectedAddress.addressCode || "",
        pickupPhoneNumber: selectedAddress.phoneNumber || "",
        pickupTimezoneID: selectedAddress.timezoneID || "",
        puAddressGUID: selectedAddress.addressGUID,
        pickupReadyTime: selectedAddress.pickupReadyTime || "",
        pickupReadyDay: selectedAddress.pickupReadyDay || 0,
        dropOff: selectedAddress.dropOff,
      });

      getZipCode(false);
    }
  }, [selectedAddress]);

  const menuStyles = {
    maxHeight: "230px", // Default max height for desktop
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setErrorCodePickup(""); // Clear error if data is found
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    onPickUpDataChange({
      ...formData,
      [name]: value,
    });
    let errorChange: any = errorControls.find((ec) => ec.name === name);

    if (name == "pickupInstructions") {
      const isError = checkStringHasCommentCharecter(value);
      if (!isError) {
        handleError(name);
      }
    } else {
      if (errorChange) {
        handleError(name);
      }
    }
  };

  const handleError = (name: string) => {
    let updatedName = name;
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange.name === updatedName) {
      updatedName = errorChange.value;
      if (formErrors[updatedName]) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[updatedName];
          return updatedErrors;
        });
      }
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
    onPickUpDataChange({
      ...formData,
      [name]: checked,
    });
  };

  const handleCountryChange = (e: any) => {
    const selectedCountryValue = e.target.value;
    setSelectedCountry(selectedCountryValue);
    onPickUpDataChange({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (selectedCountryValue === "AE") {
      removeError("pickupPostalCode");
    }

    handleZipCodeCountryChange(selectedCountryValue);
  };

  useEffect(() => {
    const zipCodeDataWithCity = zipCodeData.filter((s) => s.cityName !== null);
    if (
      selectedCountry != "US" &&
      (zipCodeData.length == 0 || zipCodeDataWithCity.length < 1)
    ) {
      setIsCityTextField(true);
    } else {
      setIsCityTextField(false);
    }

    setFormData((prevLongFormData) => ({
      ...prevLongFormData,
      pickupCountryID: selectedCountry,
    }));
    onPickUpDataChange((prevLongFormData: any) => ({
      ...prevLongFormData,
      pickupCountryID: selectedCountry,
    }));
  }, [selectedCountry, zipCodeData]);

  const handleZipCodeCountryChange = async (countryID: string) => {
    try {
      const param = {
        postalCode: selectedZipCode,
        countryID: countryID,
      };
      if (!param.postalCode) {
        return;
      }
      const response = await zipCode(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setZipCodeData(res);
        onZipCodeDataChange(res, countryID);
        if (res.length > 0) {
          setPickupTimezoneID(res[0].timezoneID);
          setPickupStandardAbbrID(res[0].standardAbbr);
          const cityExists = res.some(
            (r: { cityName: string }) => r.cityName === pickupCity
          );
          if (!cityExists) {
            setPickupCity(res[0].cityName);
          }

          setFormData((prevLongFormData) => ({
            ...prevLongFormData,
            pickupCityName: res[0].cityName,
            pickupPostalCode: res[0].postalCode,
            pickupCountryID: res[0].countryID,
            pickupStateProvID: res[0].stateProvID,
            pickupTimezoneID: res[0].timezoneID,
            pickupAirportID: res[0].airportID,
            standardAbbr: res[0].standardAbbr,
          }));
          onPickUpDataChange((prevLongFormData: any) => ({
            ...prevLongFormData,
            pickupCityName: res[0].cityName,
            pickupPostalCode: res[0].postalCode,
            pickupCountryID: res[0].countryID,
            pickupStateProvID: res[0].stateProvID,
            pickupTimezoneID: res[0].timezoneID,
            pickupAirportID: res[0].airportID,
            standardAbbr: res[0].standardAbbr,
          }));
          if (res[0].airportID) {
            removeError("pickupAirportID");
          }
          if (res[0].cityName) {
            removeError("pickupCityName");
          }
          return res[0];
        }
      }
      if (selectedZipCode && countryID === "US") {
        SetPickupZipCodeError(
          "You have entered an invalid Pickup Zip Code. Please ensure you have entered the proper Zip Code. If you continue to experience issues, please contact MNX."
        );
      }
      setPickupCity("");
      setFormData((prevLongFormData) => ({
        ...prevLongFormData,
        pickupCityName: "",
        pickupCountryID: countryID,
        pickupAirportID: "",
        pickupStateProvID: "",
      }));
      onPickUpDataChange((prevLongFormData: any) => ({
        ...prevLongFormData,
        pickupCityName: "",
        pickupCountryID: countryID,
        pickupAirportID: "",
        pickupStateProvID: "",
      }));
    } catch (err) {
      setZipCodeData([]);
    }
  };

  const handleCityInputChange = (event: any) => {
    setPickupCity(event.target.value);
    if (event.target.value) {
      removeError("pickupCityName");
    }
  };
  const handleCityChange = (event: any) => {
    setPickupCity(event.target.value);
    let name = event.target.name;
    onPickUpDataChange({
      ...formData,
      [name]: event.target.value,
    });
    setFormData((prevLongFormData) => ({
      ...prevLongFormData,
      [name]: event.target.value,
    }));
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange) {
      handleError(name);
    }
  };

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedZipCode(event.target.value);
    onPickUpDataChange({
      ...formData,
      [event.target.name]: event.target.value,
    });
    let errorChange: any = errorControls.find(
      (ec) => ec.name === event.target.name
    );
    if (errorChange) {
      handleError(event.target.name);
    }
  };

  const handleZipCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      try {
        const res = await getZipCode(true); // Wait for getZipCode to complete
        if (res) {
          const cityExists = res.some(
            (r: { cityName: string }) => r.cityName === pickupCity
          );
          setPickupTimezoneID(res.timezoneID);
          setPickupStandardAbbrID(res.standardAbbr);
          onPickUpDataChange((prevLongFormData: any) => ({
            ...prevLongFormData,
            pickupCityName: (!cityExists ? res.cityName : pickupCity) || "",
            pickupPostalCode: res.postalCode,
            pickupCountryID: res.countryID,
            pickupStateProvID: res.stateProvID,
            pickupTimezoneID: res.timezoneID,
            pickupAirportID: res.airportID,
            standardAbbr: res.standardAbbr,
          }));
          setFormData((prevLongFormData) => ({
            ...prevLongFormData,
            pickupCityName: (!cityExists ? res.cityName : pickupCity) || "",
            pickupPostalCode: res.postalCode,
            pickupCountryID: res.countryID,
            pickupStateProvID: res.stateProvID,
            pickupTimezoneID: res.timezoneID,
            pickupAirportID: res.airportID,
            standardAbbr: res.standardAbbr,
          }));
          if (res.airportID) {
            removeError("pickupAirportID");
          }
          if (res.cityName) {
            removeError("pickupCityName");
          }
        } else {
          setZipCodeData([]);
          setFormData((prevLongFormData) => ({
            ...prevLongFormData,
            pickupCityName: "",
            pickupAirportID: "",
            pickupPostalCode: "",
          }));

          onPickUpDataChange((prevLongFormData: any) => ({
            ...prevLongFormData,
            pickupCityName: "",
            pickupAirportID: "",
          }));
          setPickupCity("");
          setPickupTimezoneID("");
        }
      } catch (error) {}
    }
  };

  const handleZipCodeFocusOut = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    try {
      const res = await getZipCode(true); // Wait for getZipCode to complete
      if (res) {
        const cityExists = res.some(
          (r: { cityName: string }) => r.cityName === pickupCity
        );
        setPickupTimezoneID(res.timezoneID);
        setPickupStandardAbbrID(res.standardAbbr);
        onPickUpDataChange((prevLongFormData: any) => ({
          ...prevLongFormData,
          pickupCityName: (!cityExists ? res.cityName : pickupCity) || "",
          pickupPostalCode: res.postalCode,
          pickupCountryID: res.countryID,
          pickupStateProvID: res.stateProvID,
          pickupTimezoneID: res.timezoneID,
          pickupAirportID: res.airportID,
          standardAbbr: res.standardAbbr,
        }));
        setFormData((prevLongFormData) => ({
          ...prevLongFormData,
          pickupCityName: (!cityExists ? res.cityName : pickupCity) || "",
          pickupPostalCode: res.postalCode,
          pickupCountryID: res.countryID,
          pickupStateProvID: res.stateProvID,
          pickupTimezoneID: res.timezoneID,
          pickupAirportID: res.airportID,
          standardAbbr: res.standardAbbr,
        }));
        if (res.airportID) {
          removeError("pickupAirportID");
        }
        if (res.cityName) {
          removeError("pickupCityName");
        }
      } else {
        setZipCodeData([]);
        setFormData((prevLongFormData) => ({
          ...prevLongFormData,
          pickupCityName: "",
          pickupAirportID: "",
        }));

        onPickUpDataChange((prevLongFormData: any) => ({
          ...prevLongFormData,
          pickupCityName: "",
          pickupAirportID: "",
        }));
        // if(!event.target.value){
        setFormData((prevLongFormData) => ({
          ...prevLongFormData,
          pickupPostalCode: selectedZipCode,
        }));

        onPickUpDataChange((prevLongFormData: any) => ({
          ...prevLongFormData,
          pickupPostalCode: selectedZipCode,
        }));
        setPickupCity("");
        setPickupTimezoneID("");
        // }
      }
    } catch (error) {}
  };

  const onSelectAddress = (address: any) => {
    if (isLimitedRole) {
      setDisableSelectedAddress(true);
    }
    setSelectedAddress(address);
    setSelectedCountry(address.countryID);
    setSelectedZipCode(address.postalCode);
    setPickupCity(address.cityName);
    onRequestTimeChange(address);
    if (address.postalCode || address.countryID === "AE") {
      removeError("pickupPostalCode");
    }
    if (address.cityName) {
      removeError("pickupCityName");
    }
    if (address.airportID) {
      removeError("pickupAirportID");
    }
    if (address.companyName) {
      removeError("pickupCompanyName");
    }
    setZipCodeData([]);
  };

  const getZipCode = async (IsUpdate: boolean) => {
    try {
      if (selectedZipCode) {
        const param = {
          postalCode: selectedZipCode,
          countryID: selectedCountry,
        };
        const response = await zipCode(param);
        if (response.data && response.data.data) {
          const res = response.data.data;
          onZipCodeDataChange(res, selectedCountry);
          if (res.length > 0) {
            setZipCodeData(res);
            setSelectedCountry(res[0].countryID);
            setPickupTimezoneID(res[0].timezoneID);
            setPickupStandardAbbrID(res[0].standardAbbr);
            const cityExists = res.some(
              (r: { cityName: string }) => r.cityName === pickupCity
            );
            if (!cityExists) {
              setPickupCity(res[0].cityName);
            }

            setFormData((prevLongFormData) => ({
              ...prevLongFormData,
              pickupCityName:
                (!cityExists ? res[0].cityName : pickupCity) || "",
              pickupPostalCode: res[0].postalCode,
              pickupCountryID: res[0].countryID,
              pickupStateProvID: res[0].stateProvID,
              pickupTimezoneID: res[0].timezoneID,
              pickupAirportID: IsUpdate
                ? res[0].airportID
                : selectedAddress?.airportID || "",
              standardAbbr: res[0].standardAbbr,
            }));
            onPickUpDataChange((prevLongFormData: any) => ({
              ...prevLongFormData,
              pickupCityName:
                (!cityExists ? res[0].cityName : pickupCity) || "",
              pickupPostalCode: res[0].postalCode,
              pickupCountryID: res[0].countryID,
              pickupStateProvID: res[0].stateProvID,
              pickupTimezoneID: res[0].timezoneID,
              pickupAirportID: IsUpdate
                ? res[0].airportID
                : selectedAddress?.airportID || "",
              standardAbbr: res[0].standardAbbr,
            }));
            if (res[0].airportID) {
              removeError("pickupAirportID");
            }
            if (res[0].cityName) {
              removeError("pickupCityName");
            }
            return res[0];
          }
        }
      }
      if (selectedZipCode && selectedCountry == "US") {
        SetPickupZipCodeError(
          "You have entered an invalid Pickup Zip Code. Please ensure you have entered the proper Zip Code. If you continue to experience issues, please contact MNX."
        );
      }
    } catch (err) {
      setZipCodeData([]);
    }
  };

  const getDefaultZipCode = async () => {
    try {
      if (selectedZipCode) {
        const param = {
          postalCode: selectedZipCode,
          countryID: selectedCountry,
        };
        const response = await zipCode(param);
        if (response.data && response.data.data) {
          const res = response.data.data;
          const timezone = res.find(
            (x: any) => x.cityName === DefaultData?.pickupCityName
          );
          setPickupStandardAbbrID(timezone?.standardAbbr);
          onZipCodeDataChange(res, selectedCountry);
          if (res.length > 0) {
            setZipCodeData(res);
          }
        }
      }
    } catch (err) {
      setZipCodeData([]);
    }
  };

  const getAddress = async (addressDataList = null): Promise<any[]> => {
    try {
      const param = {
        addressCode: formData.addressCode,
        companyName: "",
        cityName: "",
        stateProvID: "",
        countryID: "",
        postalCode: "",
        userGroupGUID: userGroupGUID,
      };
      let response: any;
      //To avoid multiple request on load
      if (addressDataList) {
        response = addressDataList;
      } else {
        response = await address(param);
      }

      if (response.data && response.data.data) {
        const res = response.data.data;
        if (!param.addressCode) {
          setAddressData(res);
        }

        if (param.addressCode && res.length > 0) {
          setPickupCity(res[0].cityName);
          onSelectAddress(res[0]);
          setFormData({
            pickupAddress1: res[0].address1 || "",
            pickupAddress2: res[0].address2 || "",
            pickupAddress3: res[0].address3 || "",
            pickupCityName: res[0].cityName || "",
            pickupPostalCode: res[0].postalCode || "",
            pickupCountryID: res[0].countryID || "",
            pickupStateProvID: res[0].stateProvID || "",
            pickupCompanyName: res[0].companyName || "",
            pickupInstructions: res[0].pickupInstructions || "",
            pickupAirportID: res[0].airportID || "",
            pickupEmailAddress: res[0].emailAddress || "",
            addressCode: res[0].addressCode || "",
            pickupPhoneNumber: res[0].phoneNumber || "",
            puKeep: false,
            pickupPersonToSee: "",
            pickupTimezoneID: res[0].timezoneID || "",
            puAddressGUID: res[0].addressGUID || "",
            pickupReadyTime: res[0].pickupReadyTime || "",
            pickupReadyDay: res[0].pickupReadyDay || 0,
            standardAbbr: pickupStandardAbbrID,
          });
        }
        return res;
      } else {
        setAddressData([]);
        return [];
      }
    } catch (err) {
      setAddressData([]);
      return [];
    } finally {
    }
  };
  const handleAddressCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" || event.key === "Tab") {
      const { addressCode } = formData;

      if (addressCode.trim().length > 0) {
        try {
          setErrorCodePickup(""); // Clear error if data is found
          const response = await getAddress(); // Await getAddress properly
          if (response.length === 0) {
            setErrorCodePickup("Invalid Address Code entered."); // Set error message
          } else {
            setErrorCodePickup(""); // Clear error if data is found
            if (isLimitedRole) {
              setDisableSelectedAddress(true);
            }
          }
        } catch (error) {
          setErrorCodePickup(""); // Set error message for unexpected errors
        }
      }
    }
  };

  const getUniqueConcatenatedAddresses = (data: any[]) => {
    return data.map((address) => {
      const parts = [
        address.companyName?.trim(),
        address.address1?.trim(),
        address.cityName?.trim(),
        address.countryID?.trim(),
      ].filter((part) => part && part.length > 0);

      const displayName = parts.join(", ");

      return {
        ...address,
        displayName,
      };
    });
  };
  const customFilterOptions = (options: any[], { inputValue }: any) => {
    const trimmedInputValue = inputValue.trim().toLowerCase();

    // Filter options based on displayName and ensure uniqueness
    const filteredOptions = options.filter((option) =>
      option.displayName.toLowerCase().includes(trimmedInputValue)
    );

    // Create a map to ensure unique options based on displayName
    const uniqueFilteredOptions = filteredOptions.reduce(
      (acc: any[], option) => {
        if (!acc.some((item) => item.displayName === option.displayName)) {
          acc.push(option);
        }
        return acc;
      },
      []
    );

    return uniqueFilteredOptions;
  };

  // Handling input change in Autocomplete
  const handleInputChange1 = (event: React.ChangeEvent<{}>, value: string) => {
    return value.trim();
  };

  // Handling address selection
  const handleAddressChange = (
    event: React.ChangeEvent<{}>,
    selectedAddressValue: any
  ) => {
    setErrorCodePickup(null);
    if (isLimitedRole) {
      setDisableSelectedAddress(true);
    }
    if (selectedAddressValue) {
      if (selectedAddressValue.countryID) {
        removeError("pickupPostalCode");
      }
      if (selectedAddressValue.postalCode) {
        removeError("pickupPostalCode");
      }
      if (selectedAddressValue.cityName) {
        removeError("pickupCityName");
      }
      if (selectedAddressValue.airportID) {
        removeError("pickupAirportID");
      }
      if (selectedAddressValue.companyName) {
        removeError("pickupCompanyName");
      }
      setSelectedAddress(selectedAddressValue);
      setSelectedCountry(selectedAddressValue.countryID);
      setSelectedZipCode(selectedAddressValue.postalCode);
      onRequestTimeChange(selectedAddressValue);
      setPickupCity(selectedAddressValue.cityName);
      setZipCodeData([]);
    }else{
      setSelectedAddress(selectedAddressValue);
    }
  };

  const removeError = (controlName: string) => {
    let errorChange: any = errorControls.find((ec) => ec.name === controlName);
    if (errorChange) {
      handleError(controlName);
    }
  };

  // Filtering out the selected address
  const filteredOptions = getUniqueConcatenatedAddresses(addressData).filter(
    (option) => option !== selectedAddress
  );
  return (
    <>
      <Box className="d-flex flex-column w-100 border rounded detail-info-section">
        <Typography variant="h6" className="Sub-header">
          Pickup Details
        </Typography>
        <Box className="d-flex flex-column gap-12">
          <FormControl size="medium">
            <Autocomplete
              id="txtPickupAddressCode"
              // freeSolo
              className="hcAutoComplete"
              options={filteredOptions}
              getOptionLabel={(option) => option.displayName || ""}
              filterOptions={customFilterOptions}
              onInputChange={handleInputChange1}
              onChange={handleAddressChange}
              value={selectedAddress || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address Book (Optional)"
                  variant="outlined"
                />
              )}
              sx={{ width: isMobile ? "100%" : undefined }}
            />
          </FormControl>

          <Box className="d-flex gap-2">
            <TextField
              variant="outlined"
              label="Code (Optional)"
              id="txtAddressCode"
              className="w-100"
              {...register("addressCode")}
              name="addressCode"
              onChange={handleInputChange}
              value={
                formData.addressCode ||
                DefaultData?.rateEstimateData?.pickupAddressCode
              }
              InputLabelProps={{
                shrink:
                  !!formData.addressCode ||
                  DefaultData?.rateEstimateData?.pickupAddressCode,
              }}
              inputProps={{ maxLength: 52 }}
              error={errorCodePickup}
              onKeyDown={handleAddressCodeKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickOpen}
                      id="btnPickupCodeDialog"
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CodeDialog
              open={open}
              handleClose={handleClose}
              addressType="pickupAddress"
              onSelectAddress={onSelectAddress}
            />

            <TextField
              id="txtPickupCompanyName"
              variant="outlined"
              label="Company Name"
              {...register("pickupCompanyName")}
              name="pickupCompanyName"
              onChange={handleInputChange}
              className="w-100"
              disabled={disableSelectedAddress}
              // value={formData.pickupCompanyName}
              InputLabelProps={{
                shrink:
                  !!formData.pickupCompanyName ||
                  DefaultData?.rateEstimateData?.pickupCompanyName,
              }}
              value={
                formData.pickupCompanyName ||
                DefaultData?.rateEstimateData?.pickupCompanyName
              }
              error={!!formErrors.puCompanyName}
              inputProps={{ maxLength: 50 }}
              InputProps={{
                endAdornment: !!formErrors.puCompanyName && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    ></InfoRoundedIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TextField
            variant="outlined"
            id="txtPickupAddress1"
            label="Address Line 1"
            {...register("pickupAddress1")}
            value={formData.pickupAddress1}
            name="pickupAddress1"
            onChange={handleInputChange}
            inputProps={{ maxLength: 50 }}
            disabled={disableSelectedAddress}
          />
          <Box className="d-flex align-center gap-8">
            <TextField
              className={isShowAddress3 ? "w-100" : "width-90"}
              variant="outlined"
              id="txtPickupAddress2"
              label="Address Line 2  (Optional)"
              {...register("pickupAddress2")}
              name="pickupAddress2"
              value={formData.pickupAddress2}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
              disabled={disableSelectedAddress}
            />
            {!isShowAddress3 && (
              <IconButton
                id="btnLookupAdd"
                onClick={() => setIsShowAddress3(true)}
              >
                <AddIcon className="Add-icon" />
              </IconButton>
            )}
          </Box>
          {isShowAddress3 && (
            <TextField
              variant="outlined"
              label="Address Line 3 (Optional)"
              name="pickupAddress3"
              id="txtPickupAddress3"
              value={formData.pickupAddress3}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
              disabled={disableSelectedAddress}
            />
          )}
          <Box className="d-flex gap-2">
            <TextField
              variant="outlined"
              {...register("pickupPostalCode")}
              name="pickupPostalCode"
              id="txtPickupPostalCode"
              label="Zip"
              className="w-100"
              onChange={handleZipCodeChange}
              onKeyDown={handleZipCodeKeyDown}
              inputProps={{ maxLength: 10 }}
              onBlur={handleZipCodeFocusOut}
              disabled={disableSelectedAddress}
              value={selectedZipCode}
              error={pickupZipCodeError || !!formErrors.puPostalCode}
              InputLabelProps={{ shrink: !!selectedZipCode }}
              InputProps={{
                endAdornment: !!formErrors.puPostalCode && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    />
                  </InputAdornment>
                ),
              }}
            />
            {isCityTextField ? (
              <TextField
                variant="outlined"
                label="City"
                id="txtPickupCityName"
                {...register("pickupCityName")}
                name="pickupCityName"
                className="w-100"
                value={pickupCity}
                onChange={handleCityInputChange}
                onBlur={handleCityChange}
                disabled={disableSelectedAddress}
                error={!!formErrors.puCityName}
                InputProps={{
                  endAdornment: !!formErrors.puCityName && (
                    <InputAdornment position="end" disablePointerEvents={true}>
                      <InfoRoundedIcon
                        style={{ color: red[500] }}
                        fontSize="small"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <FormControl size="medium" className="w-100">
                <InputLabel id="demo-select-small-label">City</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="ddPickupCityName"
                  value={pickupCity} // Ensure this matches your state variable
                  label="City"
                  {...register("pickupCityName")}
                  name="pickupCityName"
                  fullWidth
                  onChange={handleCityChange}
                  disabled={disableSelectedAddress}
                  sx={{ width: isMobile ? "100%" : undefined }}
                  error={!!formErrors.puCityName}
                >
                  {zipCodeData.length > 0 &&
                    zipCodeData.map((city: any) => (
                      <MenuItem key={city.cityName} value={city.cityName}>
                        {city.cityName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box className="d-flex gap-2">
            <TextField
              variant="outlined"
              label="State"
              id="txtPickupState"
              {...register("pickupStateProvID")}
              name="pickupStateProvID"
              className="w-100"
              value={formData.pickupStateProvID}
              disabled={disableSelectedAddress}
              onChange={handleInputChange}
              inputProps={{ maxLength: 3 }}
            />

            {/* <Select variant="outlined" label="Country" className="w-100"></Select> */}
            <FormControl size="medium" className="w-100">
              <InputLabel id="demo-select-small-label">Country</InputLabel>
              <Select
                labelId="demo-select-small-label"
                // id="demo-select-small"
                id="ddPickupCountry"
                value={selectedCountry || ""} // Ensure this matches your state variable
                label="Country"
                {...register("pickupCountryID")}
                name="pickupCountryID"
                fullWidth
                onChange={handleCountryChange}
                disabled={disableSelectedAddress}
                sx={{ width: isMobile ? "100%" : undefined }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                {countryData.length > 0 &&
                  countryData.map((country: any) => (
                    <MenuItem key={country.countryID} value={country.countryID}>
                      {country.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="d-flex gap-2">
            <TextField
              variant="outlined"
              label="See (Optional)"
              id="txtPickupPersonToSee"
              className="w-100"
              name="pickupPersonToSee"
              disabled={disableSelectedAddress}
              value={formData.pickupPersonToSee}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              variant="outlined"
              label="Airport"
              {...register("pickupAirportID")}
              name="pickupAirportID"
              id="txtPickupAirport"
              value={formData.pickupAirportID}
              onChange={handleInputChange}
              disabled={disableSelectedAddress}
              className="w-100"
              inputProps={{
                maxLength: 3,
                onKeyPress: (event) => {
                  const charCode = event.charCode;
                  // Allow only letters (A-Z, a-z)
                  if (!/^[a-zA-Z]+$/.test(String.fromCharCode(charCode))) {
                    event.preventDefault();
                  }
                },
                onPaste: (event: React.ClipboardEvent) => {
                  const paste = event.clipboardData.getData("text");
                  // Prevent pasting if the pasted content contains non-letter characters
                  if (!/^[a-zA-Z]+$/.test(paste)) {
                    event.preventDefault();
                  }
                },
              }}
              error={!!formErrors.puAirportID}
              InputProps={{
                endAdornment: !!formErrors.puAirportID && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    ></InfoRoundedIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TextField
            variant="outlined"
            label="Instructions (Optional)"
            inputProps={{ maxLength: 2000 }}
            id="txtPickupInstructions"
            {...register("pickupInstructions")}
            name="pickupInstructions"
            placeholder="Enter any additional instructions (Optional)."
            value={formData.pickupInstructions}
            onChange={handleInputChange}
            multiline
            rows={4}
            error={!!formErrors.puInstructions}
          />
        </Box>
        <Box className="d-flex flex-column mt-3">
          <Typography variant="h6" className="Sub-header">
            Pickup Contact
          </Typography>
          <Box className="d-flex gap-12">
            <TextField
              variant="outlined"
              label="Phone (Optional)"
              id="txtPickupPhoneNumber"
              {...register("pickupPhoneNumber")}
              value={formData.pickupPhoneNumber}
              onChange={handleInputChange}
              onKeyDown={handleNumericValue}
              name="pickupPhoneNumber"
              className="w-100"
              inputProps={{ maxLength: 25 }}
            />
            <TextField
              variant="outlined"
              label="Email Address (Optional)"
              id="txtPickupEmail"
              className="w-100"
              {...register("pickupEmailAddress")}
              name="pickupEmailAddress"
              value={formData.pickupEmailAddress}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                name="puKeep"
                checked={formData.puKeep}
                id="cbPuKeep"
                onChange={handleCheckboxChange}
                disabled={isLimitedRole}
              />
            }
            label="Save to address book"
            className="pt-8 ms-2"
          />
        </Box>
      </Box>
    </>
  );
};

export default LongFormPickupAddress;
