import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";


// Action Types for reference
const REFERENCE_REQUEST = "REFERENCE_REQUEST";
const REFERENCE_SUCCESS = "REFERENCE_SUCCESS";
const REFERENCE_FAILURE = "REFERENCE_FAILURE";

// Action Creators for reference
export const referenceRequest = () => ({
  type: REFERENCE_REQUEST,
});

export const referenceSuccess = () => ({
  type: REFERENCE_SUCCESS,
});

export const referenceFailure = (error: string) => ({
  type: REFERENCE_FAILURE,
  payload: error,
});

// Initial State for reference
export interface ReferenceState {
  isAuthenticated: boolean;
  referenceLoading: boolean;
  referenceError: string | null;
}

const initialAuthState: ReferenceState = {
  isAuthenticated: false,
  referenceLoading: false,
  referenceError: null,
};

// Define the interface for the API response

// Reducer for Authentication
const referenceReducer = (
  state = initialAuthState,
  action: any
): ReferenceState => {
  switch (action.type) {
    case REFERENCE_REQUEST:
      return { ...state, referenceLoading: true, referenceError: null };
    case REFERENCE_SUCCESS:
      return { ...state, referenceLoading: false, isAuthenticated: true };
    case REFERENCE_FAILURE:
      return { ...state, referenceLoading: false, referenceError: action.payload };
    default:
      return state;
  }
};

// Thunk for reference
export const reference =
  (param: {
    referenceGroupGUID:string
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(referenceRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetReference`,
        param
      );
      dispatch(referenceSuccess());
      const data:any = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(referenceFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(referenceFailure(error.response.data.message));
      } else {
        dispatch(referenceFailure(error.message));
      }
    }
  };

export default referenceReducer;
