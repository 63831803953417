import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MySettingDto } from "../../../models/my-setting-dto";
import { useDecodeJWT } from "../../hook/use-decodeJWT";
import useUserReferenceGroupLogic from "../../lookup/user-reference-group/user-reference-group.logic";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import useReferenceTypeLogic from "../../lookup/reference/reference-type.logic";
import CustomDatePicker from "../CustomDatePicker";
import { isDisplayDateTime } from "../../util/common";

import { DATETIME_REF_TYPE, DAY_ICE_REF_TYPE } from "../../../config/constants";
interface ReferenceSettingsProps {
  mySetting: MySettingDto;
  handleInputChange: (updatedData: any) => void;
  formErrors: any;
}

const ReferenceSettings: React.FC<ReferenceSettingsProps> = ({
  mySetting,
  handleInputChange,
  formErrors,
}) => {
  const token = useDecodeJWT();
  const { userReferenceGroup } = useUserReferenceGroupLogic();
  const { referenceType } = useReferenceTypeLogic();
  const [userReferenceGroupData, setUserReferenceGroupData] = useState<any>([]);
  const [referenceTypeData, setReferenceTypeData] = useState<any>([]);

  useEffect(() => {
    getUserReferenceGroup();
    getUserReferenceType();
  }, []);

  const menuStyles = {
    maxHeight: "240px", // Default max height for desktop
  };

  const getUserReferenceGroup = async () => {
    try {
      const param = {
        userGUID: token.UserGUID,
      };
      const response = await userReferenceGroup(param);
      if (response.data && response.data.data) {
        setUserReferenceGroupData(response.data.data);
      } else {
        setUserReferenceGroupData([]);
      }
    } catch (err) {
      setUserReferenceGroupData([]);
    }
  };

  const getUserReferenceType = async () => {
    try {
      const param: any = {
        referenceTypeID: null,
        code: null,
      };
      const response = await referenceType(param);
      if (response.data && response.data.data) {
        setReferenceTypeData(response.data.data);
      } else {
        setReferenceTypeData([]);
      }
    } catch (err) {
      setReferenceTypeData([]);
    }
  };

  const handleDateParsing = (
    dateTimeStr: any,
    valueType: any,
    referenceType: any
  ) => {
    if (isDisplayDateTime(referenceType, mySetting?.accountNumber)) {
      const [dateTime, restValue] =
        dateTimeStr && dateTimeStr != null && dateTimeStr != "null"
          ? dateTimeStr?.split("(")
          : [];

      if (valueType === "dateTime" && dateTime && dateTimeStr?.includes("(")) {
        const parsedDate = dateTime ? new Date(dateTime.trim()) : null;
        return parsedDate;
      } else {
        if (valueType === "dateTime") {
          return null;
        }
        const endsWithParenthesis = restValue?.endsWith(")");

        if (endsWithParenthesis) {
          return restValue?.slice(0, -1);
        } else {
          return valueType === "dateTime"
            ? restValue
            : restValue || dateTimeStr;
        }
      }
    } else {
      const startsWithParenthesis = dateTimeStr?.startsWith("(");
      const endsWithParenthesis = dateTimeStr?.endsWith(")");

      if (endsWithParenthesis && !startsWithParenthesis) {
        return dateTimeStr?.slice(0, -1);
      }
      return dateTimeStr;
    }
  };

  const getDefaultLable = (referenceType: any) => {
    if (referenceType === DATETIME_REF_TYPE) {
      return "Event Date & Time";
    } else if (DAY_ICE_REF_TYPE.includes(parseInt(referenceType))) {
      return "Last Tissue On Ice/Recovery End";
    }
    return `Value`;
  };
  return (
    <>
      <Box className="p16 border rounded">
        <Typography className="sub_header padding0 mb-16">
          Customized References
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={12 / 5} md={4} sm={6} xs={6}>
            <Typography className="Refer_lbl">Main Reference</Typography>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtReferences"
                  name="referenceLabel"
                  label="label"
                  variant="outlined"
                  className="w-100"
                  defaultValue={mySetting.referenceLabel}
                  onChange={handleInputChange}
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              {isDisplayDateTime(
                mySetting.referenceTypeID,
                mySetting?.accountNumber
              ) && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="small-cal-view">
                      <CustomDatePicker
                        id={`txtCRYDateTime1`}
                        handleDateChange={handleInputChange}
                        label={getDefaultLable(mySetting.referenceTypeID)}
                        name={`dateTimereference`}
                        value={handleDateParsing(
                          mySetting.reference,
                          "dateTime",
                          mySetting.referenceTypeID
                        )} // Ensure non-null value
                        isClearable={true}
                        showTimeSelect={true}
                      />
                    </div>
                  </Grid>
                </>
              )}

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsValue1"
                  name="reference"
                  label={
                    isDisplayDateTime(
                      mySetting.referenceTypeID,
                      mySetting?.accountNumber
                    )
                      ? "Notes"
                      : "Value"
                  }
                  defaultValue={handleDateParsing(
                    mySetting.reference,
                    "",
                    mySetting.referenceTypeID
                  )} // Ensure non-null value
                  onChange={handleInputChange}
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceType1"
                    label="Type"
                    name="referenceTypeID"
                    defaultValue={mySetting.referenceTypeID}
                    onChange={handleInputChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {referenceTypeData.length > 0 &&
                      referenceTypeData.map((service: any) => (
                        <MenuItem
                          key={service.referenceTypeID}
                          value={service.referenceTypeID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Group</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceGroup1"
                    label="Group"
                    name="referenceGroupGUID"
                    defaultValue={mySetting.referenceGroupGUID}
                  >
                    <MenuItem value="">(None)</MenuItem>
                    {mySetting.referenceGroupValue && (
                      <MenuItem
                        key={mySetting.referenceGroupGUID}
                        value={mySetting.referenceGroupGUID}
                      >
                        {mySetting.referenceGroupValue}
                      </MenuItem>
                    )}
                    {userReferenceGroupData.length > 0 &&
                      userReferenceGroupData.map((service: any) => (
                        <MenuItem
                          key={service.referenceGroupGUID}
                          value={service.referenceGroupGUID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12 / 5} md={4} sm={6} xs={6}>
            <Typography className="Refer_lbl">Reference #1</Typography>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsLabel2"
                  name="referenceLabel2"
                  label="label"
                  defaultValue={mySetting.referenceLabel2}
                  onChange={handleInputChange}
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              {isDisplayDateTime(
                mySetting.referenceTypeID2,
                mySetting?.accountNumber
              ) && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="small-cal-view">
                      <CustomDatePicker
                        id={`txtCRYDateTime2`}
                        handleDateChange={handleInputChange}
                        label={getDefaultLable(mySetting.referenceTypeID2)}
                        name={`dateTimereference2`}
                        value={handleDateParsing(
                          mySetting.reference2,
                          "dateTime",
                          mySetting.referenceTypeID2
                        )}
                        isClearable={true}
                        showTimeSelect={true}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsValue2"
                  name="reference2"
                  label={
                    isDisplayDateTime(
                      mySetting.referenceTypeID2,
                      mySetting?.accountNumber
                    )
                      ? "Notes"
                      : "Value"
                  }
                  defaultValue={handleDateParsing(
                    mySetting.reference2,
                    "",
                    mySetting.referenceTypeID2
                  )}
                  // defaultValue={mySetting.reference2}
                  onChange={handleInputChange}
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceType2"
                    label="Type"
                    name="referenceTypeID2"
                    defaultValue={mySetting.referenceTypeID2}
                    onChange={handleInputChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {referenceTypeData.length > 0 &&
                      referenceTypeData.map((service: any) => (
                        <MenuItem
                          key={service.referenceTypeID}
                          value={service.referenceTypeID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Group</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceGroup2"
                    name="referenceGroupGUID2"
                    defaultValue={mySetting.referenceGroupGUID2}
                    onChange={handleInputChange}
                    label="Group"
                  >
                    <MenuItem value="">(None)</MenuItem>
                    {mySetting.referenceGroupValue2 && (
                      <MenuItem
                        key={mySetting.referenceGroupGUID2}
                        value={mySetting.referenceGroupGUID2}
                      >
                        {mySetting.referenceGroupValue2}
                      </MenuItem>
                    )}
                    {userReferenceGroupData.length > 0 &&
                      userReferenceGroupData.map((service: any) => (
                        <MenuItem
                          key={service.referenceGroupGUID}
                          value={service.referenceGroupGUID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12 / 5} md={4} sm={6} xs={6}>
            <Typography className="Refer_lbl">Reference #2</Typography>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsLabel3"
                  name="referenceLabel3"
                  label="label"
                  defaultValue={mySetting.referenceLabel3}
                  onChange={handleInputChange}
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              {isDisplayDateTime(
                mySetting.referenceTypeID3,
                mySetting?.accountNumber
              ) && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="small-cal-view">
                      <CustomDatePicker
                        id={`txtCRYDateTime3`}
                        handleDateChange={handleInputChange}
                        label={getDefaultLable(mySetting.referenceTypeID3)}
                        name={`dateTimereference3`}
                        value={handleDateParsing(
                          mySetting.reference3,
                          "dateTime",
                          mySetting.referenceTypeID3
                        )} // Ensure non-null value
                        isClearable={true}
                        showTimeSelect={true}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsValue3"
                  name="reference3"
                  defaultValue={handleDateParsing(
                    mySetting.reference3,
                    "",
                    mySetting.referenceTypeID3
                  )}
                  onChange={handleInputChange}
                  label={
                    isDisplayDateTime(
                      mySetting.referenceTypeID3,
                      mySetting?.accountNumber
                    )
                      ? "Notes"
                      : "Value"
                  }
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceType3"
                    label="Type"
                    name="referenceTypeID3"
                    defaultValue={mySetting.referenceTypeID3}
                    onChange={handleInputChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {referenceTypeData.length > 0 &&
                      referenceTypeData.map((service: any) => (
                        <MenuItem
                          key={service.referenceTypeID}
                          value={service.referenceTypeID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Group</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceGroup3"
                    label="Group"
                    name="referenceGroupGUID3"
                    defaultValue={mySetting.referenceGroupGUID3}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">(None)</MenuItem>
                    {mySetting.referenceGroupValue3 && (
                      <MenuItem
                        key={mySetting.referenceGroupGUID3}
                        value={mySetting.referenceGroupGUID3}
                      >
                        {mySetting.referenceGroupValue3}
                      </MenuItem>
                    )}
                    {userReferenceGroupData.length > 0 &&
                      userReferenceGroupData.map((service: any) => (
                        <MenuItem
                          key={service.referenceGroupGUID}
                          value={service.referenceGroupGUID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12 / 5} md={4} sm={6} xs={6}>
            <Typography className="Refer_lbl">Reference #3</Typography>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsLabel4"
                  name="referenceLabel4"
                  label="label"
                  variant="outlined"
                  defaultValue={mySetting.referenceLabel4}
                  onChange={handleInputChange}
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              {isDisplayDateTime(
                mySetting.referenceTypeID4,
                mySetting?.accountNumber
              ) && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="small-cal-view">
                      <CustomDatePicker
                        id={`txtCRYDateTime4`}
                        handleDateChange={handleInputChange}
                        label={getDefaultLable(mySetting.referenceTypeID4)}
                        name={`dateTimereference4`}
                        value={handleDateParsing(
                          mySetting.reference4,
                          "dateTime",
                          mySetting.referenceTypeID4
                        )} // Ensure non-null value
                        isClearable={true}
                        showTimeSelect={true}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsValue4"
                  name="reference4"
                  label={
                    isDisplayDateTime(
                      mySetting.referenceTypeID4,
                      mySetting?.accountNumber
                    )
                      ? "Notes"
                      : "Value"
                  }
                  variant="outlined"
                  className="w-100"
                  size="small"
                  defaultValue={handleDateParsing(
                    mySetting.reference4,
                    "",
                    mySetting.referenceTypeID4
                  )}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceType4"
                    label="Type"
                    name="referenceTypeID4"
                    defaultValue={mySetting.referenceTypeID4}
                    onChange={handleInputChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {referenceTypeData.length > 0 &&
                      referenceTypeData.map((service: any) => (
                        <MenuItem
                          key={service.referenceTypeID}
                          value={service.referenceTypeID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Group</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceGroup4"
                    label="Group"
                    name="referenceGroupGUID4"
                    defaultValue={mySetting.referenceGroupGUID4}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">(None)</MenuItem>
                    {mySetting.referenceGroupValue4 && (
                      <MenuItem
                        key={mySetting.referenceGroupGUID4}
                        value={mySetting.referenceGroupGUID4}
                      >
                        {mySetting.referenceGroupValue4}
                      </MenuItem>
                    )}
                    {userReferenceGroupData.length > 0 &&
                      userReferenceGroupData.map((service: any) => (
                        <MenuItem
                          key={service.referenceGroupGUID}
                          value={service.referenceGroupGUID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12 / 5} md={4} sm={6} xs={6}>
            <Typography className="Refer_lbl">Reference #4</Typography>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsLabel5"
                  name="referenceLabel5"
                  defaultValue={mySetting.referenceLabel5}
                  onChange={handleInputChange}
                  label="label"
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              {isDisplayDateTime(
                mySetting.referenceTypeID5,
                mySetting?.accountNumber
              ) && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="small-cal-view">
                      <CustomDatePicker
                        id={`txtCRYDateTime5`}
                        handleDateChange={handleInputChange}
                        label={getDefaultLable(mySetting.referenceTypeID5)}
                        name={`dateTimereference5`}
                        value={handleDateParsing(
                          mySetting.reference5,
                          "dateTime",
                          mySetting.referenceTypeID5
                        )} // Ensure non-null value
                        isClearable={true}
                        showTimeSelect={true}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsValue5"
                  name="reference5"
                  defaultValue={handleDateParsing(
                    mySetting.reference5,
                    "",
                    mySetting.referenceTypeID5
                  )}
                  onChange={handleInputChange}
                  label={
                    isDisplayDateTime(
                      mySetting.referenceTypeID5,
                      mySetting?.accountNumber
                    )
                      ? "Notes"
                      : "Value"
                  }
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceType5"
                    label="Type"
                    name="referenceTypeID5"
                    defaultValue={mySetting.referenceTypeID5}
                    onChange={handleInputChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {referenceTypeData.length > 0 &&
                      referenceTypeData.map((service: any) => (
                        <MenuItem
                          key={service.referenceTypeID}
                          value={service.referenceTypeID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Group</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceGroup"
                    label="Group"
                    name="referenceGroupGUID5"
                    defaultValue={mySetting.referenceGroupGUID5}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">(None)</MenuItem>
                    {mySetting.referenceGroupValue5 && (
                      <MenuItem
                        key={mySetting.referenceGroupGUID5}
                        value={mySetting.referenceGroupGUID5}
                      >
                        {mySetting.referenceGroupValue5}
                      </MenuItem>
                    )}
                    {userReferenceGroupData.length > 0 &&
                      userReferenceGroupData.map((service: any) => (
                        <MenuItem
                          key={service.referenceGroupGUID}
                          value={service.referenceGroupGUID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12 / 5} md={4} sm={6} xs={6}>
            <Typography className="Refer_lbl">Reference #5</Typography>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsLabel6"
                  name="referenceLabel6"
                  defaultValue={mySetting.referenceLabel6}
                  onChange={handleInputChange}
                  label="label"
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              {isDisplayDateTime(
                mySetting.referenceTypeID6,
                mySetting?.accountNumber
              ) && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="small-cal-view">
                      <CustomDatePicker
                        id={`txtCRYDateTime6`}
                        handleDateChange={handleInputChange}
                        label={getDefaultLable(mySetting.referenceTypeID6)}
                        name={`dateTimereference6`}
                        value={handleDateParsing(
                          mySetting.reference6,
                          "dateTime",
                          mySetting.referenceTypeID6
                        )} // Ensure non-null value
                        isClearable={true}
                        showTimeSelect={true}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsValue6"
                  name="reference6"
                  defaultValue={handleDateParsing(
                    mySetting.reference6,
                    "",
                    mySetting.referenceTypeID6
                  )}
                  onChange={handleInputChange}
                  label={
                    isDisplayDateTime(
                      mySetting.referenceTypeID6,
                      mySetting?.accountNumber
                    )
                      ? "Notes"
                      : "Value"
                  }
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceType6"
                    label="Type"
                    name="referenceTypeID6"
                    defaultValue={mySetting.referenceTypeID6}
                    onChange={handleInputChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {referenceTypeData.length > 0 &&
                      referenceTypeData.map((service: any) => (
                        <MenuItem
                          key={service.referenceTypeID}
                          value={service.referenceTypeID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Group</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceGroup6"
                    label="Group"
                    name="referenceGroupGUID6"
                    defaultValue={mySetting.referenceGroupGUID6}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">(None)</MenuItem>
                    {mySetting.referenceGroupValue6 && (
                      <MenuItem
                        key={mySetting.referenceGroupGUID6}
                        value={mySetting.referenceGroupGUID6}
                      >
                        {mySetting.referenceGroupValue6}
                      </MenuItem>
                    )}
                    {userReferenceGroupData.length > 0 &&
                      userReferenceGroupData.map((service: any) => (
                        <MenuItem
                          key={service.referenceGroupGUID}
                          value={service.referenceGroupGUID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12 / 5} md={4} sm={6} xs={6}>
            <Typography className="Refer_lbl">Reference #6</Typography>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsLabel7"
                  name="referenceLabel7"
                  defaultValue={mySetting.referenceLabel7}
                  onChange={handleInputChange}
                  label="label"
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              {isDisplayDateTime(
                mySetting.referenceTypeID7,
                mySetting?.accountNumber
              ) && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="small-cal-view">
                      <CustomDatePicker
                        id={`txtCRYDateTime7`}
                        handleDateChange={handleInputChange}
                        label={getDefaultLable(mySetting.referenceTypeID7)}
                        name={`dateTimereference7`}
                        value={handleDateParsing(
                          mySetting.reference7,
                          "dateTime",
                          mySetting.referenceTypeID7
                        )} // Ensure non-null value
                        isClearable={true}
                        showTimeSelect={true}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsValue7"
                  name="reference7"
                  defaultValue={handleDateParsing(
                    mySetting.reference7,
                    "",
                    mySetting.referenceTypeID7
                  )}
                  onChange={handleInputChange}
                  label={
                    isDisplayDateTime(
                      mySetting.referenceTypeID7,
                      mySetting?.accountNumber
                    )
                      ? "Notes"
                      : "Value"
                  }
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceType7"
                    label="Type"
                    name="referenceTypeID7"
                    defaultValue={mySetting.referenceTypeID7}
                    onChange={handleInputChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {referenceTypeData.length > 0 &&
                      referenceTypeData.map((service: any) => (
                        <MenuItem
                          key={service.referenceTypeID}
                          value={service.referenceTypeID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Group</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceGroup7"
                    label="Group"
                    name="referenceGroupGUID7"
                    defaultValue={mySetting.referenceGroupGUID7}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">(None)</MenuItem>
                    {mySetting.referenceGroupValue7 && (
                      <MenuItem
                        key={mySetting.referenceGroupGUID7}
                        value={mySetting.referenceGroupGUID7}
                      >
                        {mySetting.referenceGroupValue7}
                      </MenuItem>
                    )}
                    {userReferenceGroupData.length > 0 &&
                      userReferenceGroupData.map((service: any) => (
                        <MenuItem
                          key={service.referenceGroupGUID}
                          value={service.referenceGroupGUID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12 / 5} md={4} sm={6} xs={6}>
            <Typography className="Refer_lbl">Reference #7</Typography>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsLabel8"
                  name="referenceLabel8"
                  defaultValue={mySetting.referenceLabel8}
                  onChange={handleInputChange}
                  label="label"
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              {isDisplayDateTime(
                mySetting.referenceTypeID8,
                mySetting?.accountNumber
              ) && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="small-cal-view">
                      <CustomDatePicker
                        id={`txtCRYDateTime8`}
                        handleDateChange={handleInputChange}
                        label={getDefaultLable(mySetting.referenceTypeID8)}
                        name={`dateTimereference8`}
                        value={handleDateParsing(
                          mySetting.reference8,
                          "dateTime",
                          mySetting.referenceTypeID8
                        )} // Ensure non-null value
                        isClearable={true}
                        showTimeSelect={true}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsValue8"
                  name="reference8"
                  defaultValue={handleDateParsing(
                    mySetting.reference8,
                    "",
                    mySetting.referenceTypeID8
                  )}
                  onChange={handleInputChange}
                  label={
                    isDisplayDateTime(
                      mySetting.referenceTypeID8,
                      mySetting?.accountNumber
                    )
                      ? "Notes"
                      : "Value"
                  }
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceType8"
                    label="Type"
                    name="referenceTypeID8"
                    defaultValue={mySetting.referenceTypeID8}
                    onChange={handleInputChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {referenceTypeData.length > 0 &&
                      referenceTypeData.map((service: any) => (
                        <MenuItem
                          key={service.referenceTypeID}
                          value={service.referenceTypeID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Group</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceGroup8"
                    label="Group"
                    name="referenceGroupGUID8"
                    defaultValue={mySetting.referenceGroupGUID8}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">(None)</MenuItem>
                    {mySetting.referenceGroupValue8 && (
                      <MenuItem
                        key={mySetting.referenceGroupGUID8}
                        value={mySetting.referenceGroupGUID8}
                      >
                        {mySetting.referenceGroupValue8}
                      </MenuItem>
                    )}
                    {userReferenceGroupData.length > 0 &&
                      userReferenceGroupData.map((service: any) => (
                        <MenuItem
                          key={service.referenceGroupGUID}
                          value={service.referenceGroupGUID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12 / 5} md={4} sm={6} xs={6}>
            <Typography className="Refer_lbl">Reference #8</Typography>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsLabel9"
                  name="referenceLabel9"
                  defaultValue={mySetting.referenceLabel9}
                  onChange={handleInputChange}
                  label="label"
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              {isDisplayDateTime(
                mySetting.referenceTypeID9,
                mySetting?.accountNumber
              ) && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="small-cal-view">
                      <CustomDatePicker
                        id={`txtCRYDateTime9`}
                        handleDateChange={handleInputChange}
                        label={getDefaultLable(mySetting.referenceTypeID9)}
                        name={`dateTimereference9`}
                        value={handleDateParsing(
                          mySetting.reference9,
                          "dateTime",
                          mySetting.referenceTypeID9
                        )} // Ensure non-null value
                        isClearable={true}
                        showTimeSelect={true}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="txtMsValue9"
                  name="reference9"
                  defaultValue={handleDateParsing(
                    mySetting.reference9,
                    "",
                    mySetting.referenceTypeID9
                  )}
                  onChange={handleInputChange}
                  label={
                    isDisplayDateTime(
                      mySetting.referenceTypeID9,
                      mySetting?.accountNumber
                    )
                      ? "Notes"
                      : "Value"
                  }
                  variant="outlined"
                  className="w-100"
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Type</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceType9"
                    label="Type"
                    name="referenceTypeID9"
                    defaultValue={mySetting.referenceTypeID9}
                    onChange={handleInputChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      PaperProps: {
                        style: menuStyles,
                      },
                    }}
                  >
                    {referenceTypeData.length > 0 &&
                      referenceTypeData.map((service: any) => (
                        <MenuItem
                          key={service.referenceTypeID}
                          value={service.referenceTypeID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl size="small" className="w-100">
                  <InputLabel id="demo-select-small-label">Group</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="ddMsReferenceGroup9"
                    label="Group"
                    name="referenceGroupGUID9"
                    defaultValue={mySetting.referenceGroupGUID9}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">(None)</MenuItem>
                    {mySetting.referenceGroupValue9 && (
                      <MenuItem
                        key={mySetting.referenceGroupGUID9}
                        value={mySetting.referenceGroupGUID9}
                      >
                        {mySetting.referenceGroupValue9}
                      </MenuItem>
                    )}
                    {userReferenceGroupData.length > 0 &&
                      userReferenceGroupData.map((service: any) => (
                        <MenuItem
                          key={service.referenceGroupGUID}
                          value={service.referenceGroupGUID}
                        >
                          {service.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className="mt-16  pb-32">
        <Box className="p16 border rounded">
          <Typography className="sub_header padding0 mb-16">
            Form Defaults
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={2} md={2} xs={6}>
              <TextField
                id="txtMsContentLables"
                name="contentsLabel"
                label="Content Lables"
                variant="outlined"
                defaultValue={mySetting.contentsLabel}
                error={!!formErrors.contentsLabel}
                className="w-100"
                onChange={handleInputChange}
                inputProps={{ maxLength: 15 }}
                size="small"
                InputProps={{
                  endAdornment: !!formErrors.contentsLabel && (
                    <InputAdornment position="end" disablePointerEvents={true}>
                      <InfoRoundedIcon
                        style={{ color: red[500] }}
                        fontSize="small"
                      ></InfoRoundedIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item lg={2} md={2} xs={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="demo-select-small-label">
                  Contents Value
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="ddMsContentsReferenceGroupGUID"
                  label="Contents Value"
                  name="ContentsReferenceGroupGUID"
                  defaultValue={mySetting.contentsReferenceGroupGUID}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">(None)</MenuItem>
                  {mySetting.contentsReferenceGroupValue && (
                    <MenuItem
                      key={mySetting.contentsReferenceGroupGUID}
                      value={mySetting.contentsReferenceGroupGUID}
                    >
                      {mySetting.contentsReferenceGroupValue}
                    </MenuItem>
                  )}
                  {userReferenceGroupData.length > 0 &&
                    userReferenceGroupData.map((service: any) => (
                      <MenuItem
                        key={service.referenceGroupGUID}
                        value={service.referenceGroupGUID}
                      >
                        {service.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={2} md={2} xs={6}>
              <FormControl size="small" className="w-100">
                <InputLabel id="demo-select-small-label">
                  Default Order Form
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="ddMsOrderFormType"
                  label="Default Order Form"
                  name="orderFormType"
                  defaultValue={mySetting.orderFormType}
                  onChange={handleInputChange}
                >
                  <MenuItem value="L">New Order</MenuItem>
                  {/* <MenuItem value="S">Short Form</MenuItem>
                  <MenuItem value="P">Parts Form</MenuItem>
                  <MenuItem value="X">From Preprint</MenuItem> */}
                  <MenuItem value="M">Multiple Orders</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ReferenceSettings;
