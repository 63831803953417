import React, { FC, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Checkbox,
  TableHead,
  TableSortLabel,
  Box,
} from "@mui/material";
import Loader from "../../util/loader";
import CommonPagination from "../CustomPagination";
import { UnDetailsItemDto } from "../../../models/long-form-item-dto";
import { visuallyHidden } from "@mui/utils";
import useUnDetailsLogic from "../../lookup/UN-details/unDetails.logic";
import { MAX_COMPANY_ID } from "../../../config/constants";
import { Link } from "react-router-dom";

interface MyDialogProps {
  open: boolean;
  handleClose: () => void;
  onSelectCommodity: (commodity: any) => void;
  maxWidth?: any;
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof UnDetailsItemDto;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "properName",
    numeric: false,
    disablePadding: false,
    label: "Proper Name",
  },
  {
    id: "unNumber",
    numeric: false,
    disablePadding: false,
    label: "UN #",
  },
  {
    id: "isDangerousGoods",
    numeric: false,
    disablePadding: false,
    label: "DG",
  },
  {
    id: "commodityClass",
    numeric: true,
    disablePadding: false,
    label: "Class",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof UnDetailsItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof UnDetailsItemDto) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CommodityListDialog: FC<MyDialogProps> = ({
  open,
  handleClose,
  onSelectCommodity,
  maxWidth,
}) => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof UnDetailsItemDto>("properName");
  const { unDetails } = useUnDetailsLogic();
  const [commodities, setCommodities] = useState<UnDetailsItemDto[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UnDetailsItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const getUnDetails = async () => {
    try {
      setLoading(true);
      const credentials = {
        companyID: MAX_COMPANY_ID,
        pageNumber: page + 1,
        pageSize: rowsPerPage === -1 ? totalRecord : rowsPerPage,
        sortColumn: orderBy,
        sortOrder: order,
      };

      const response = await unDetails(credentials);
      if (response.data && response.data.items) {
        setCommodities(response.data.items);
        setTotalRecord(response.data.totalRecords);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setCommodities([]);
      setTotalRecord(0);
    }
  };

  useEffect(() => {
    //Call the api on open modal instead of default page load
    if (open) {
      getUnDetails();
    }
  }, [order, orderBy, page, rowsPerPage, open]);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const onSelect = (selectedCommodity: UnDetailsItemDto) => {
    onSelectCommodity(selectedCommodity);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={maxWidth ?? "lg"}
      fullWidth
    >
      <DialogTitle className="Popup-header">Commodity List</DialogTitle>
      <DialogContent>
        <TableContainer className="d-flex flex-column w-100">
          <Table className="commodity-header">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={commodities.length}
            />
            <TableBody className="commodity-table">
              {loading ? (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <Loader />
                  </TableCell>
                </TableRow>
              ) : (
                commodities.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="width700">
                      <Link
                        to={""}
                        id="hlRowId"
                        className="primary properlbl"
                        onClick={(event: any) => {
                          event.preventDefault(); // Prevents the default behavior of the link
                          onSelect(row);
                        }}
                      >
                        {row.properName}
                      </Link>
                    </TableCell>
                    <TableCell className="width94">{row.unNumber}</TableCell>
                    <TableCell className="width94">
                      <Checkbox checked={row.isDangerousGoods} />
                    </TableCell>
                    <TableCell className="width94">
                      {row.commodityClass}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className="d-flex p-2 justify-content-end page-footer">
        <CommonPagination
          count={totalRecord}
          page={page}
          rowsPerPage={null}
          onPageChange={handleChangePage}
          onRowsPerPageChange={null}
        />
      </DialogActions>
      <DialogActions className="d-flex p16 justify-content-start">
        <Button
          onClick={handleClose}
          id="btnCommodityClose"
          color="primary"
          variant="outlined"
          className="d-flex rounded me-2 weight-500"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommodityListDialog;
