import React, { useEffect, useState } from "react";
import FlairLoading from "../route-preview/flair-loading";
import {
  calculateHoursDifference,
  formatDateAndTime,
  formatDateToMonthDay,
  formatTime,
} from "../../util/date-utils";
import caricon from "../../../assets/images/trackorder/car-icon.svg";
import flighticon from "../../../assets/images/trackorder/flight-icon.svg";
import { Box, Button, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import useRouteRecommendationsLogic from "../../../modules/route-preview/route-recommendations.logic";
import { RouteRecommendation } from "../../../models/route-recommendation-item-dto";
import {  routeRecommendationPayload } from "../../util/rate-estimate";

interface rateEstimateRouteProps {
  formik: any;
  serviceData:any;
  handleRouteServiceChange: (val:string) => void;
  rateEstimateData:any;
}

const RateEstimateRoutePreview: React.FC<rateEstimateRouteProps> = ({
  formik,serviceData,handleRouteServiceChange,rateEstimateData
}) => {
  const [isLoading,setIsLoading] = useState(false);
  const [routeRecommendationsData, setRouteRecommendationData] =
    useState<RouteRecommendation>();
    const [groundRecommendationsData, setGroundRecommendationsData] =
    useState<RouteRecommendation>();
  const [estDeliveryTitle] = useState<any>("Est. Delivery");
  const [sessionLongformData] = useState<any>();
  const [selectedRoute, setSelectedRoute] = useState(true);
  const [selectedGround, setSelectedGround] = useState(false);
  const { routeRecommendations } = useRouteRecommendationsLogic();

  const FlightIcon = ({ iataID }: { iataID: string }) => {
    const imageUrl = require(`../../../assets/images/airline/Iata${iataID}.png`);
    return <img src={imageUrl} alt="flight icon" />;
  };

  const handleSelectFlight = (bol: boolean) => {
    setSelectedRoute(bol);
    setSelectedGround(false);
    handleRouteServiceChange("flight")
  };

  const handleSelectGround = (bol: boolean) => {
    setSelectedGround(bol);
    setSelectedRoute(false);
    handleRouteServiceChange("ground")
  };
  
  useEffect(()=>{
    const findServiceCode = serviceData.find(
      (x: any) => x.serviceID === formik.values.serviceID
    );
    const serviceName = findServiceCode?.serviceCode;
    if(formik){
      if(serviceName == "FF" || serviceName === "CTX"){
        getRouteRecommendations();
      }
    }
  },[rateEstimateData])

  const getRouteRecommendations = async (signal?: AbortSignal) => {
    try {
      setIsLoading(true);
      const param = routeRecommendationPayload(formik,serviceData);
      const response = await routeRecommendations(param, signal);
      if (response.data && response.data.data) {
        const res = response.data.data;
        const routeRecommendations = res.routeRecommendations[0];
        const filteredGroundData = res.routeRecommendations.find(
          (rec: any) => rec.serviceID === 1007
        );
        setRouteRecommendationData(routeRecommendations);
        setGroundRecommendationsData(filteredGroundData);
      } else {
        setRouteRecommendationData(undefined);
      }
      setIsLoading(false); 
    } catch (err) {
      setRouteRecommendationData(undefined);
      setGroundRecommendationsData(undefined);
    }
  };

  return (
    <>
      {isLoading && (
        <Box className="d-flex flex-column w-100 border p16 rounded">
          <Typography variant="h6" className="sub-track-header">
            Route Options Preview
          </Typography>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Box className="route-headlbl2 plr8 mt-1 pt-8">
              MNX Recommended Route
            </Box>
          </Grid>
          <FlairLoading isLoading={isLoading} />
        </Box>
      )}

      {/* flair loading section end*/}
      {!isLoading && (
        <Box className="d-flex flex-column w-100 border p16 rounded">
          <Typography variant="h6" className="sub-track-header">
            Route Options Preview
          </Typography>
          {!routeRecommendationsData && !isLoading && (
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="route-headlbl2 plr8 mt-1 pt-8">
                  MNX Recommended Route
                </Box>
                <Box className="d-flex flex-column Map-view p16 pt-8 mt-1">
                  <span className="no-route-txt">No Recommended Route</span>
                  <p className="route-text-lbl">
                    The system is unable to recommend a route at the moment. If
                    you create an order from this quote, an MNX Logistics
                    Coordinator will assign the best routing base on your order
                    parameters.
                  </p>
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              {routeRecommendationsData && (
                <Box className="route-headlbl2 plr8 mt-1 pt-8">
                  MNX Recommended Route
                </Box>
              )}
              {routeRecommendationsData && (
                <div
                  className={`Map-view ${
                    selectedRoute ? "route-selected" : ""
                  }`}
                >
                  <div className="d-flex flight-route p16">
                    <div className="d-flex prefer-route-left">
                      <div className="Routesec-1 align-center">
                        <span className="logo-img">
                          <FlightIcon
                            iataID={
                              routeRecommendationsData.routeFlightLegs[0]
                                ?.carrierCode
                            }
                          />
                        </span>
                        <span className="flight_id">
                          {routeRecommendationsData.routeFlightLegs[0]
                            ?.carrierCode +
                            routeRecommendationsData.routeFlightLegs[0]
                              .flightNumber}
                        </span>
                      </div>
                      <div className="Routesec-2">
                        <div className="d-flex colflexview">
                          <span className="del_date">
                            {formatDateToMonthDay(
                              routeRecommendationsData.routeFlightLegs[0]
                                .departureDateTime
                            )}
                          </span>
                          <span
                            className="del_time"
                            style={{ marginRight: "10px" }}
                          >
                            {formatTime(
                              routeRecommendationsData.routeFlightLegs[0]
                                .departureDateTime,
                              formik.values?.pickupStandardAbbr || ""
                            )}
                          </span>
                          <span className="del_date location_lbl">
                            {
                              routeRecommendationsData.routeFlightLegs[0]
                                .departureAirport
                            }
                          </span>
                        </div>
                        <div className="flight-row">
                          <span>&nbsp;</span>
                          <div className="border-wrapper car-drop">
                            <div className="border-wrapper-dot-left car-drop-left"></div>
                            <img
                              className="bordered-image"
                              src={flighticon}
                              alt="Phone icon"
                            />
                            <div className="border-wrapper-dot-right car-drop-right"></div>
                          </div>
                          <span className="del_date location_lbl">
                            {routeRecommendationsData.routeFlightLegs.length > 1
                              ? "Connection"
                              : "Direct"}
                          </span>
                        </div>
                        <div
                          className="colflexview d-flex "
                          style={{ marginLeft: "10px" }}
                        >
                          <span className="flex-end d-flex del_date">
                            {formatDateToMonthDay(
                              routeRecommendationsData.routeFlightLegs[0]
                                .arrivalDateTime
                            )}
                          </span>
                          <span className="del_time">
                            {" "}
                            {formatTime(
                              routeRecommendationsData.routeFlightLegs[
                                routeRecommendationsData.routeFlightLegs
                                  .length - 1
                              ].arrivalDateTime,
                              formik.values?.deliveryStandardAbbr || ""
                            )}
                          </span>
                          <span className="flex-end d-flex del_date location_lbl">
                            {
                              routeRecommendationsData.routeFlightLegs[
                                routeRecommendationsData.routeFlightLegs
                                  .length - 1
                              ].arrivalAirport
                            }
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="Routesec-3 colflexview d-flex">
                      <span className="del_date">{estDeliveryTitle}</span>
                      <>
                        <span className="mt4 black-val text-truncate">
                          {formatDateAndTime(
                            routeRecommendationsData.estDeliveryTime,
                            formik.values?.deliveryStandardAbbr || ""
                          )}
                        </span>
                      </>
                    </div>
                  </div>
                  <div className="v-progress flight-progress">
                    <ul>
                      <li className="v-progress-item completed">
                        <div>
                          <span className="route-live-status">
                            Flight Departure -{" "}
                            {routeRecommendationsData.routeFlightLegs[0]
                              .carrierCode +
                              routeRecommendationsData.routeFlightLegs[0]
                                .flightNumber}
                          </span>
                          <span className="route-live-lbl">
                            {
                              routeRecommendationsData.routeFlightLegs[0]
                                .departureAirport
                            }{" "}
                            -{" "}
                            {formatTime(
                              routeRecommendationsData.routeFlightLegs[0]
                                .departureDateTime,
                              formik.values?.pickupStandardAbbr || ""
                            )}
                          </span>
                        </div>
                      </li>

                      <li className="v-progress-item">
                        <div>
                          <span className="route-live-status">
                            Flight Arrival
                          </span>
                          <span className="route-live-lbl">
                            {
                              routeRecommendationsData.routeFlightLegs[
                                routeRecommendationsData.routeFlightLegs
                                  .length - 1
                              ].arrivalAirport
                            }{" "}
                            -{" "}
                            {formatTime(
                              routeRecommendationsData.routeFlightLegs[
                                routeRecommendationsData.routeFlightLegs
                                  .length - 1
                              ].arrivalDateTime,
                              formik.values?.deliveryStandardAbbr || ""
                            )}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="flight-bcg">
                    <div className="ptb-8 plr-16 d-flex flight-route align-center ">
                      <div className="d-flex colflexview">
                        <span className="route-left">
                          This route was chosen based on your
                        </span>
                        <span className="route-left">preferences.</span>
                      </div>

                      <div className="d-flex align-center">
                        {sessionLongformData?.mxwebEstCharges && (
                          <div className="d-flex colflexview Border-right-lbl pr-16">
                            <span
                              className="del_date"
                              style={{ letterSpacing: "0.15px" }}
                            >
                              Est. Rate
                            </span>
                            <span className="del_time">
                              ${routeRecommendationsData?.routeQuote}
                            </span>
                          </div>
                        )}
                        <Box
                          sx={{
                            display: { xs: "none", sm: "block" }, // Hides on mobile (xs) and shows on other devices (sm and above)
                          }}
                        >
                          {!selectedRoute ? (
                            <Button
                              variant="outlined"
                              className="rounded ml-16"
                              id="btnSelectFlightRoute"
                              onClick={() => handleSelectFlight(true)}
                            >
                              SELECT ROUTE
                            </Button>
                          ) : (
                            <LoadingButton
                              id="btnSelectedFlightRoute"
                              variant="contained"
                              color="primary"
                              loadingPosition="start"
                              className="d-flex rounded ml-16 "
                              startIcon={<CheckCircleRoundedIcon />}
                              onClick={() => handleSelectFlight(true)}
                            >
                              ROUTE SELECTED
                            </LoadingButton>
                          )}
                        </Box>
                      </div>
                    </div>
                    <Box
                      className="mlr-16 mb-8"
                      sx={{
                        display: { xs: "block", sm: "none" }, // Hides on mobile (xs) and shows on other devices (sm and above)
                      }}
                    >
                      {!selectedRoute ? (
                        <Button
                          variant="outlined"
                          className="rounded width100"
                          id="btnSelectGroundRoute"
                          onClick={() => handleSelectFlight(true)}
                        >
                          SELECT ROUTE
                        </Button>
                      ) : (
                        <LoadingButton
                          id="btnSelectedGroundRoute"
                          variant="contained"
                          color="primary"
                          loadingPosition="start"
                          className="d-flex rounded width100"
                          startIcon={<CheckCircleRoundedIcon />}
                          onClick={() => handleSelectFlight(true)}
                        >
                          ROUTE SELECTED
                        </LoadingButton>
                      )}
                    </Box>
                  </div>
                </div>
              )}
            </Grid>

            <Grid item lg={6} md={12} sm={12} xs={12}>
              {/* Ground section start*/}
              {groundRecommendationsData && (
                <Box className="route-headlbl2 plr8 mt-1 pt-8">
                  Ground Alternative Route
                </Box>
              )}
              {groundRecommendationsData && (
                <div className="ground-route">
                  <div
                    className={`Map-view ${
                      selectedGround ? "route-selected" : ""
                    }`}
                  >
                    <div className="d-flex flight-route p16">
                      <div className="d-flex prefer-route-left">
                        <div className="Routesec-1 align-center">
                          <span className="logo-img ground-img">
                            <img src={caricon} alt="Phone icon" />
                          </span>
                          <span className="flight_id textCenter">Drive</span>
                        </div>
                        <div className="Routesec-2">
                          <div className="d-flex colflexview">
                            <span className="del_date">
                              {formatDateToMonthDay(
                                groundRecommendationsData.estPickupTime
                              )}
                            </span>
                            <span
                              className="del_time"
                              style={{ marginRight: "10px" }}
                            >
                              {formatTime(
                                groundRecommendationsData.estPickupTime,
                                formik.values?.pickupStandardAbbr || ""
                              )}
                            </span>
                            <span className="del_date location_lbl">
                              Pickup
                            </span>
                          </div>
                          <div className="flight-row">
                            <span>&nbsp;</span>
                            <div className="border-wrapper car-drop">
                              <div className="border-wrapper-dot-left car-drop-left"></div>
                              <img
                                className="bordered-image"
                                src={caricon}
                                alt="Phone icon"
                              />
                              <div className="border-wrapper-dot-right car-drop-right"></div>
                            </div>
                            <span className="del_date location_lbl">
                              {calculateHoursDifference(
                                groundRecommendationsData.estPickupTime,
                                groundRecommendationsData.estDeliveryTime
                              )}
                            </span>
                          </div>
                          <div
                            className="colflexview d-flex"
                            style={{ marginLeft: "10px" }}
                          >
                            <span className="flex-end d-flex del_date">
                              {formatDateToMonthDay(
                                groundRecommendationsData.estDeliveryTime
                              )}
                            </span>
                            <span className="del_time">
                              {" "}
                              {formatTime(
                                groundRecommendationsData.estDeliveryTime,
                                formik.values?.deliveryStandardAbbr || ""
                              )}
                            </span>
                            <span className="flex-end d-flex del_date location_lbl">
                              Delivery
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="Routesec-3 colflexview d-flex">
                        <span className="del_date">{estDeliveryTitle}</span>
                        <>
                          <span className="mt4 black-val text-truncate">
                            {formatDateAndTime(
                              groundRecommendationsData.estDeliveryTime,
                              formik.values?.deliveryStandardAbbr || ""
                            )}
                          </span>
                        </>
                      </div>
                    </div>

                    <div className="v-progress flight-progress">
                      <ul>
                        <li className="v-progress-item completed">
                          <div>
                            <span className="route-live-status">Pick up</span>
                            <span className="route-live-lbl">
                              {formatTime(
                                groundRecommendationsData.estPickupTime,
                                formik.values?.pickupStandardAbbr || ""
                              )}
                            </span>
                          </div>
                        </li>

                        <li className="v-progress-item">
                          <div>
                            <span className="route-live-status">Delivery</span>
                            <span className="route-live-lbl">
                              {formatTime(
                                groundRecommendationsData.estDeliveryTime,
                                formik.values?.deliveryStandardAbbr || ""
                              )}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="flight-bcg">
                      <div className="ptb-8 plr-16 d-flex align-center justifycontentend flex-column-mobile">
                        <div className="d-flex align-center flex-column-mobile full-width-mb">
                          {sessionLongformData?.mxwebEstCharges && (
                            <div className="d-flex colflexview Border-right-lbl pr-16 full-width-xs mob-bott-8">
                              <span
                                className="del_date"
                                style={{ letterSpacing: "0.15px" }}
                              >
                                Est. Rate
                              </span>
                              <span className="del_time">
                                ${groundRecommendationsData.routeQuote}
                              </span>
                            </div>
                          )}
                          {!selectedGround ? (
                            <Button
                              sx={{
                                height: "36px",
                                marginRight: { xs: 0 },
                              }}
                              variant="outlined"
                              className="rounded ml-16 mobleft-0 width100"
                              id="btnSelectGroundRoute"
                              onClick={() => handleSelectGround(true)}
                            >
                              SELECT ROUTE
                            </Button>
                          ) : (
                            <LoadingButton
                              sx={{
                                height: "36px",
                                marginRight: { xs: 0 },
                              }}
                              id="btnSelectedGroundRoute"
                              variant="contained"
                              color="primary"
                              loadingPosition="end"
                              className="d-flex rounded ml-16 mobleft-0 width100"
                              startIcon={<CheckCircleRoundedIcon />}
                              onClick={() => handleSelectGround(true)}
                            >
                              ROUTE SELECTED
                            </LoadingButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default RateEstimateRoutePreview;
