import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Grid,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { MySettingItemDto } from "../../../models/long-form-item-dto";
import CustomDatePicker from "../CustomDatePicker";
interface DeliveryAddressProps {
  deliveryData: any;
  onDeliveryDataChange: (updatedData: any) => void;
  formErrors: any;
  setFormErrors: (errors: any) => void;
  DefaultData?: MySettingItemDto;
  readyTimeData: any;
  handleInputChange: (updatedData: any) => void;
  editWillCallOrderData: any;
}

const DeliveryTime: React.FC<DeliveryAddressProps> = ({
  deliveryData,
  onDeliveryDataChange,
  formErrors,
  setFormErrors,
  DefaultData,
  readyTimeData,
  handleInputChange,
  editWillCallOrderData,
}) => {
  const [asap, setAsap] = useState(editWillCallOrderData.delRequestAsap);
  const [holdForPickup, setHoldForPickup] = useState(
    editWillCallOrderData.holdForPickup
  );
  const [deliveryWhen, setDeliveryWhen] = useState(
    editWillCallOrderData?.deliverWhen
  );

  const [deliveryReadyTime, setDeliveryReadyTime] = useState<any>("");

  useEffect(() => {
    const displayValue = editWillCallOrderData.delRequestAsap
      ? ""
      : (() => {
          const adjustedPickupTime = adjustDateToToday(
            editWillCallOrderData.deliveryRequestTime
          );
          // Check if adjustedPickupTime is a valid Date object
          if (
            adjustedPickupTime instanceof Date &&
            !isNaN(adjustedPickupTime.getTime())
          ) {
            const isFutureDate = adjustedPickupTime >= new Date();
            return isFutureDate
              ? getPickupReadyTime1(adjustedPickupTime)
              : null;
          }
          return null;
        })();
    setDeliveryReadyTime(displayValue);
  }, []);

  useEffect(() => {
    if (readyTimeData) {
      if (
        readyTimeData.deliveryRequestTime &&
        readyTimeData.deliveryRequestDay &&
        readyTimeData.deliveryRequestTime != "1900-01-01T00:00:00"
      ) {
        const deliveryReadyTime = readyTimeData.deliveryRequestTime;
        const deliveryReadyDay = readyTimeData.deliveryRequestDay;
        const newDate = new Date();
        const [hours, minutes] = deliveryReadyTime
          .split("T")[1]
          .split(":")
          .map(Number);
        newDate.setHours(hours, minutes, 0, 0);
        const finalDate = addDays(newDate, deliveryReadyDay);
        const formattedFinalDate = formatDateToCustomString(finalDate);
        deliveryData.delRequestAsap = false;
        setDeliveryReadyTime(formattedFinalDate);
        handleChange({
          target: { name: "deliveryRequestTime", value: formattedFinalDate },
        } as any);
      } else {
        if (!deliveryData.delRequestAsap) {
          // const date: any =
          //   deliveryData.willCall || deliveryData.delRequestAsap
          //     ? ""
          //     : (() => {
          //         const adjustedPickupTime = adjustDateToToday(
          //           DefaultData.deliveryRequestTime
          //         );
          //         if (
          //           adjustedPickupTime instanceof Date &&
          //           !isNaN(adjustedPickupTime.getTime())
          //         ) {
          //           const isFutureDate = adjustedPickupTime >= new Date();
          //           return isFutureDate
          //             ? getPickupReadyTime1(adjustedPickupTime)
          //             : null;
          //         }
          //         return null;
          //       })();
          // setDeliveryReadyTime(null);
        }
      }
    }
  }, [readyTimeData]);

  useCallback(() => {
    handleChange({
      target: { name: "deliveryRequestTime", value: deliveryReadyTime },
    } as any);
  }, [deliveryReadyTime]);

  // Helper function to format date
  const formatDateToCustomString = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    //const { name, value } = e.target;
    handleInputChange(e);
    // setDeliveryWhen(value);
    // onDeliveryDataChange({ [name]: value });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const { name, checked } = event.target;

    // if (name === "deliveryRequestAsap") {
    //   setAsap(checked);
    //   if (checked) {
    //     onDeliveryDataChange({ ...deliveryData, deliveryRequestTime: "" });
    //     setFormErrors((prevErrors: any) => {
    //       const { delRequestDate, ...rest } = prevErrors;
    //       return rest;
    //     });
    //   } else {
    //     // Clear the delivery request time when ASAP is unchecked
    //     setAsap(null);
    //     deliveryData.deliveryRequestTime = null;
    //     onDeliveryDataChange({ ...deliveryData, deliveryRequestTime: "" });
    //   }
    // } else if (name === "dropOff") {
    //   setHoldForPickup(checked);
    // }
    // onDeliveryDataChange({ ...deliveryData, [name]: checked });
    handleInputChange(event);
  };

  // Add pickupReadyDay to pickupReadyTime if available
  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const getPickupReadyTime1 = (adjustedPickupTime: Date) => {
    if (deliveryData.deliveryRequestDay && deliveryData.deliveryRequestTime) {
      const readyDate = new Date(deliveryData.deliveryRequestTime);
      return addDays(readyDate, deliveryData.deliveryRequestDay)
        .toISOString()
        .slice(0, 16);
    }
    const test = adjustedPickupTime;
    return test || "";
  };

  const adjustDateToToday = (dateStr: string): Date => {
    const originalDate = new Date(dateStr);
    // Get the current date and time
    const now = new Date();
    // Create a new Date object with today's date but the same time as the original date
    const adjustedDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      originalDate.getHours(),
      originalDate.getMinutes(),
      originalDate.getSeconds(),
      originalDate.getMilliseconds()
    );

    return adjustedDate;
  };

  useEffect(() => {
    setAsap(editWillCallOrderData.delRequestAsap ? true : false);
    setHoldForPickup(editWillCallOrderData.holdForPickup ? true : false);
    setDeliveryWhen(editWillCallOrderData.deliverWhen);
    // setPickupReadyTime(
    //   editWillCallOrderData.puReadyNow ? "" : editWillCallOrderData.puReadyTime
    // );
    setDeliveryReadyTime(editWillCallOrderData?.delRequestTime);
  }, [editWillCallOrderData]);

  return (
    <Box className="d-flex flex-column w-100 border rounded p16">
      <Typography
        variant="h6"
        className="Sub-header"
        sx={{ paddingBottom: "8px!important" }}
      >
        Requested Delivery Time
      </Typography>
      <Box className="d-flex gap-2 mb-2 h-42 ms-2">
        <FormControlLabel
          className="pr-8 chk_bx_50"
          control={
            <Checkbox
              checked={editWillCallOrderData.delRequestAsap}
              id="cbAsap"
              name="delRequestAsap"
              //disabled={editWillCallOrderData.isWillCall}
              onChange={handleCheckboxChange}
            />
          }
          label="ASAP"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={editWillCallOrderData.holdForPickup}
              name="holdForPickup"
              id="cbHoldForPu"
              // disabled={editWillCallOrderData.isWillCall}
              onChange={handleCheckboxChange}
            />
          }
          label="Hold for PU"
          className="chk_bx_50"
        />
      </Box>
      <Box className="d-flex gap-2"></Box>
      <Grid container spacing={2}>
        <Grid item lg={6} sm={12} md={6} xs={12}>
          <Select
            variant="outlined"
            id="ddDeliveryWhen"
            className="w-100"
            name="deliveryWhen"
            disabled={editWillCallOrderData.delRequestAsap}
            value={deliveryWhen}
            onChange={handleSelectChange}
          >
            <MenuItem value="B">Before</MenuItem>
            <MenuItem value="A">After</MenuItem>
            <MenuItem value="T">At</MenuItem>
          </Select>
        </Grid>
        <Grid item lg={6} sm={12} md={6} xs={12}>
          <CustomDatePicker
            handleDateChange={handleChange}
            label="Requested Delivery Time"
            id="txtDeliveryDate"
            name="deliveryRequestTime"
            value={deliveryReadyTime}
            isClearable={false}
            showError={
              !!formErrors.delRequestDate &&
              !editWillCallOrderData.delRequestAsap
            }
            minDate={new Date()}
            showTimeSelect={true}
            isDisable={editWillCallOrderData.delRequestAsap}
            isClearValue={
              editWillCallOrderData.delRequestAsap || !deliveryReadyTime
                ? true
                : false
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeliveryTime;
