import React, { useEffect, useRef, useState } from "react";

import { Box, Typography } from "@mui/material";
import arrowicon from "../../assets/images/logo/img_arrowright_white_a700.svg";
import { MySettingItemDto } from "../../models/long-form-item-dto";
import { MAX_COMPANY_ID } from "../../config/constants";
import Alert from "@mui/material/Alert";
import Loader from "../../shared/util/loader";
import useDefaultDataLogic from "../long-form-orders/long-form-order.logic";
import { LoadingButton } from "@mui/lab";

import RequestInfo from "../../shared/components/health-care/RequestInfo";
import OrderInfo from "../../shared/components/health-care/OrderInfo";
import {
  createHealthCareValidationSchema,
  HealthCareInitialValues,
} from "../../shared/util/validate-schema";
import { useFormik, FormikProvider } from "formik";
import DisplayError from "../../shared/components/health-care/display-error";
import GiftTissueInfo from "../../shared/components/health-care/GiftTissueInfo";
import useHealthCareLogic from "./healthcare-form.logic";
import { addDays } from "../../shared/util/common";
import {
  AddressList,
  healthCareDto,
  TissueOptions,
} from "../../models/health-care-dto";
import { sortAndUpdateTheData } from "../../shared/util/rate-estimate";
import moment from "moment";
import { getCurrentDateTimeByAbbr } from "../../shared/util/timezones";

const Healthcare = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { defaultData } = useDefaultDataLogic();
  const { healthCare, healthAddress, giftDetails } = useHealthCareLogic();
  const [defaultDatas, setDefaultDatas] = useState<MySettingItemDto>();
  const [originAddress, setOriginAddress] = useState("");
  const [originAddressData, setOriginAddressData] = useState<AddressList>([]);
  const [destinationAddressData, setDestinationAddressData] =
    useState<AddressList>([]);
  const [giftDetailsData, setGiftDetailsData] = useState<TissueOptions>([]);
  const errorRef: any = useRef(null);
  const [initialValues, setInitialValues] = useState(HealthCareInitialValues);
  const [successMessage, setSuccessMessage] = useState("");

  const isSubmitted = false;

  const HealCareValidationSchema =
    createHealthCareValidationSchema(originAddress);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: HealCareValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      saveHealthCareData();
    },
  });

  const saveHealthCareData = async () => {
    try {
      setIsLoading(true);
      const params: healthCareDto = mapDonorData(formik.values);

      const response = await healthCare(params);
      if (response.data && response.data.data) {
        setIsLoading(false);
        setSuccessMessage(
          `Your order with Donor ID ${params.donorID} has been submitted to our Healthcare Precision Logistics Team. Thank you!`
        );

        //reset the form and set ready time based on settings
        formik.resetForm();
        updateReadyTime(defaultDatas as any);
      }
    } catch {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        await Promise.all([
          getDefaultData(),
          getAddresses("O"), // origin address data
          getAddresses("D"), // destination address data
          getGiftDetails(),
        ]);
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    };

    fetchApiData();
  }, []);

  const getAddresses = async (addressType: string) => {
    try {
      setIsLoading(true);
      const param = {
        addressType: addressType,
      };
      const response = await healthAddress(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        const sortedData = sortAndUpdateTheData(res, "asc", "agencyAcronym");
        if (addressType === "O") {
          setOriginAddressData(sortedData);
        } else {
          setDestinationAddressData(sortedData);
        }
      }
      setIsLoading(false);
    } catch {
      if (addressType === "O") {
        setOriginAddressData([]);
      } else {
        setDestinationAddressData([]);
      }

      setIsLoading(false);
    }
  };

  const getGiftDetails = async () => {
    try {
      setIsLoading(true);

      const response = await giftDetails();
      if (response.data && response.data.data) {
        const res = response.data.data;
        const sortedData = sortAndUpdateTheData(res, "asc", "tissueOption");
        setGiftDetailsData(sortedData);
      }
      setIsLoading(false);
    } catch {
      setGiftDetailsData([]);
      setIsLoading(false);
    }
  };

  const getDefaultData = async () => {
    try {
      setIsLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
      };
      const response = await defaultData(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setDefaultDatas(res);

        updateReadyTime(res);
      }
      setIsLoading(false);
    } catch {
      setDefaultDatas(undefined);
      setIsLoading(false);
    }
  };

  const updateReadyTime = (res: MySettingItemDto) => {
    const {
      pickupReadyNow,
      pickupReadyDay,
      pickupReadyTime,
      accountNumber,
      emailAddress,
    } = res || {};

    let readyTime = "";

    if (pickupReadyNow) {
      readyTime = "";
    } else if (
      pickupReadyDay &&
      pickupReadyTime &&
      pickupReadyTime !== "1900-01-01T00:00:00"
    ) {
      readyTime = addDays(new Date(pickupReadyTime), pickupReadyDay)
        .toISOString()
        .slice(0, 16);
    } else if (pickupReadyTime && pickupReadyTime !== "1900-01-01T00:00:00") {
      const [hours, minutes] = pickupReadyTime.split("T")[1].split(":");

      // Get the current date
      const currentDate = new Date();

      // Set the hours and minutes to the current date
      currentDate.setHours(Number(hours), Number(minutes), 0, 0);

      readyTime = moment(currentDate).format("YYYY-MM-DDTHH:mm:ss");
    }

    setInitialValues({
      ...HealthCareInitialValues,
      readyNow: pickupReadyNow,
      readyTime: readyTime, // Set initialValues to include the API data
      accountNumber: accountNumber as any,
      userEmail: emailAddress as any,
    });
  };
  const handleSubmit = () => {
    setSuccessMessage("");
    formik.handleSubmit();
    errorRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      top: 50,
    });
  };

  function mapDonorData(data: any) {
    const standardAbbr = data.originAddress?.standardAbbr;
    const currentTime = getCurrentDateTimeByAbbr(standardAbbr);
    return {
      companyName: data.companyName,
      name: data.name,
      accountNumber: data.accountNumber,
      emailAddress: data.emailAddress,
      userEmail: data.userEmail,
      phone: data.phoneNumber,
      shipmentReadyTime: data?.readyNow
        ? moment(currentTime).format("YYYY-MM-DDTHH:mm:ss")
        : moment(data.readyTime).format("YYYY-MM-DDTHH:mm:ss"), // set default if empty
      originAgencyAcronym: data.originAddress.agencyAcronym,
      dgUNNumber: data.unNumber,
      donorID: data.donorId,
      originAddress: data.originAddress.displayName,
      alternateOriginAddress: data.alternatOriginAddress,
      timeofDeath: moment(data.timeOfDeath).format("YYYY-MM-DDTHH:mm:ss"),
      hazardousMaterials: "", // Placeholder as it's not in the input
      giftDetailQuantities: data.giftInfo.map((gift: any) => ({
        tissueOption: gift.giftIssue,
        destinationAddress: gift.alternativeDestination,
        destinationAgencyAcronym: gift.alternativeDestination
          .split(",")[0]
          .trim(), // Extracts acronym
        alternateDestinationAddress: gift.alternativeDestination2,
        quantity: parseInt(gift.quantity, 10),
      })),
    };
  }

  useEffect(() => {
    setOriginAddress(formik.values.originAddress);
  }, [formik.values.originAddress]);

  useEffect(() => {
    if (formik.values.readyNow) {
      formik.setFieldValue("readyTime", "");
    }
  }, [formik.values.readyNow]);

  return (
    <>
      <div className="order-form Multi-Order-form">
        <Box className="d-flex flex-column w-100 " ref={errorRef}>
          <Box className="d-flex">
            <Typography
              variant="h4"
              color="primary"
              className="d-flex font48 main-header"
              style={{ whiteSpace: "nowrap" }}
            >
              New Healthcare Order
            </Typography>
          </Box>
          {successMessage && (
            <>
              <Box className="w-100">
                <Alert variant="filled" severity="success" id="txtMfSuccess">
                  {successMessage}
                </Alert>
              </Box>
            </>
          )}

          {/* Error diaply block */}
          <>
            <div>
              <DisplayError formik={formik} />
            </div>
          </>

          {!isLoading ? (
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Box className="d-flex flex-column align-items-start">
                  <Typography
                    variant="h5"
                    color="primary"
                    className="pickup_section"
                  >
                    1. Request Information
                  </Typography>

                  <RequestInfo formik={formik} />

                  <Typography
                    variant="h5"
                    color="primary"
                    className="pickup_section"
                  >
                    2. Order Information
                  </Typography>

                  <OrderInfo
                    formik={formik}
                    originAddressData={originAddressData}
                  />

                  <Typography
                    variant="h5"
                    color="primary"
                    className="pickup_section"
                  >
                    3. Items
                  </Typography>
                  <GiftTissueInfo
                    formik={formik}
                    destinationAddressData={destinationAddressData}
                    giftDetailsData={giftDetailsData}
                  />
                </Box>
              </form>
            </FormikProvider>
          ) : (
            <Box>
              <Loader />
            </Box>
          )}
        </Box>
      </div>
      {!isSubmitted && (
        <div className="container-fluid">
          <Box className="d-flex justify-content-end gap-2 w-100 Divider-top pt-16 pb-16">
            <LoadingButton
              id="btnBookOrders"
              variant="contained"
              color="primary"
              loading={isLoading}
              loadingPosition="end"
              className="d-flex rounded me-2 width-min-100"
              endIcon={<img src={arrowicon} alt="icon right" />}
              onClick={handleSubmit}
            >
              BOOK ORDER
            </LoadingButton>
          </Box>
        </div>
      )}
    </>
  );
};

export default Healthcare;
