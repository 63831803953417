import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { AddressItemDto } from "../../../models/address-item-dto";
import useAddressLogic from "../../lookup/address/address.logic";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CodeDialog from "../code-dialog/code-dialog";
import { CountryItemDto } from "../../../models/country-item-dto";
import useCountryLogic from "../../lookup/country/country.logic";
import { ZipCodeItemDto } from "../../../models/zip-code-item-dto";
import { useForm } from "react-hook-form";
import useZipCodeLogic from "../../lookup/zip-code/zip-code.logic";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import { handleNumericValue } from "../../util/numeric-value";
import { EditWillCallRequestDTO } from "../../../models/edit-will-call-order-request-item-dto";

interface DeliveryAddressProps {
  onDeliveryDataChange: (updatedData: any) => void;
  editWillCallOrderData: EditWillCallRequestDTO;
  handleInputChange: (updatedData: any) => void;
  formErrors: any;
  onZipCodeDataChange: (errors: any) => void;
  setFormErrors: (errors: any) => void;
  onRequestTimeChange: any;
  deliveryZipCodeError: any;
  setErrors: (errors: any) => void;
}

const EditDeliveryAddress: React.FC<DeliveryAddressProps> = ({
  onDeliveryDataChange,
  editWillCallOrderData,
  handleInputChange,
  formErrors,
  onZipCodeDataChange,
  setFormErrors,
  onRequestTimeChange,
  deliveryZipCodeError,
  setErrors,
}) => {
  const [open, setOpen] = useState(false);
  const { address } = useAddressLogic();
  const { country } = useCountryLogic();
  const { zipCode } = useZipCodeLogic();
  const [addressData, setAddressData] = useState<AddressItemDto[]>([]);
  const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
  const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [selectedAddress, setSelectedAddress] = useState<AddressItemDto | null>(
    null
  );
  const [selectedZipCode, setSelectedZipCode] = useState<string>("");
  const [isShowAddress3, setIsShowAddress3] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const userGroupGUID: any = localStorage.getItem("userGroupGUID");
  const [deliveryCity, setDeliveryCity] = useState("");
  const { register } = useForm();
  const serviceAvailType = localStorage.getItem("serviceAvailType");

  const errorControls = [
    {
      name: "deliveryCompanyName",
      value: "delCompanyName",
    },
    {
      name: "deliveryCityName",
      value: "delCityName",
    },
    {
      name: "deliveryPostalCode",
      value: "delPostalCode",
    },
    {
      name: "delAirportID",
      value: "delAirportID",
    },
    {
      name: "serviceID",
      value: "serviceID",
    },
  ];

  useEffect(() => {
    getAddress();
    getCountry();
    getZipCode();
    //getDefaultZipCode();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddressChange = (e: any) => {
    const selectedAddressValue = e.target.value;
    if (selectedAddressValue.postalCode) {
      let errorChange: any = errorControls.find(
        (ec) => ec.name === "deliveryPostalCode"
      );
      if (errorChange) {
        handleError("deliveryPostalCode");
      }
    }
    if (selectedAddressValue.serviceID) {
      handleServiceError("serviceID");
    }
    setSelectedAddress(selectedAddressValue);
    // onDeliveryDataChange(selectedAddressValue);
    setSelectedCountry(selectedAddressValue.countryID);
    setSelectedZipCode(selectedAddressValue.postalCode);
    onRequestTimeChange(selectedAddressValue);
    setZipCodeData([]);
  };

  // interface MenuStyles {
  //   maxHeight: string;
  // }

  // const [menuStyles, setMenuStyles] = useState<MenuStyles>({
  //   maxHeight: "230px", // Default max height for desktop
  // });

  const menuStyles = {
    maxHeight: "230px", // Default max height for desktop
  };

  const getAddress = async () => {
    try {
      setIsLoading(true);
      const param = {
        addressCode: editWillCallOrderData.addressCode || "",
        companyName: "",
        cityName: "",
        stateProvID: "",
        countryID: "",
        postalCode: "",
        userGroupGUID: userGroupGUID,
      };
      const response = await address(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        if (!param.addressCode) {
          setAddressData(res);
        }
        if (param.addressCode && res.length > 0) {
          onSelectAddress(res[0]);
        }
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setAddressData([]);
    }
  };

  const handleError = (name: string) => {
    let updatedName = name;
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange.name === updatedName) {
      updatedName = errorChange.value;
      if (formErrors[updatedName]) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[updatedName];
          return updatedErrors;
        });
      }
    }
  };

  const handleServiceError = (name: string) => {
    let updatedName = name;
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange.name === updatedName) {
      updatedName = errorChange.value;
      if (formErrors[updatedName]) {
        setErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[updatedName];
          return updatedErrors;
        });
      }
    }
  };

  const handleCityChange = (event: any) => {
    setDeliveryCity(event.target.value);
    let name = event.target.name;
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange) {
      handleError(name);
    }
    handleInputChange({
      target: { name: "delCityName", value: event.target.value },
    } as any);
  };

  const getCountry = async () => {
    try {
      setIsLoading(true);
      const param = {};
      const response = await country(param);
      if (response.data && response.data.data) {
        setCountryData(response.data.data);
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setCountryData([]);
    }
  };

  const onSelectAddress = (address: any) => {
    setSelectedAddress(address);
    setSelectedCountry(address.countryID);
    setSelectedZipCode(address.postalCode);
    onRequestTimeChange(address);
    if (address.companyName) {
      let errorChange: any = errorControls.find(
        (ec) => ec.name === "deliveryCompanyName"
      );
      if (errorChange) {
        handleError("deliveryCompanyName");
      }
    }
    if (address.postalCode) {
      let errorChange: any = errorControls.find(
        (ec) => ec.name === "deliveryPostalCode"
      );
      if (errorChange) {
        handleError("deliveryPostalCode");
      }
    }
    if (address.serviceID) {
      handleServiceError("serviceID");
    }
    setZipCodeData([]);
  };

  const handleAddressCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" || event.key === "Tab") {
      const { addressCode } = editWillCallOrderData;
      if (addressCode.trim().length > 0) {
        await getAddress(); // Assuming getAddress is an async function
      }
    }
  };

  const getZipCode = async (addressCountry = "", addressZipCode = "") => {
    try {
      const param: any = {
        postalCode: addressZipCode
          ? addressZipCode
          : editWillCallOrderData.delPostalCode,
        countryID: addressCountry
          ? addressCountry
          : editWillCallOrderData.delCountryID,
      };

      const response = await zipCode(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        onZipCodeDataChange(res);
        if (res.length > 0) {
          setZipCodeData(res);
          return res;
        } else {
          setZipCodeData([]);
          return [];
        }
      }
    } catch (err) {
      setZipCodeData([]);
    }
  };

  const handleZipCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Tab" || event.key === "Enter") {
      const res = await getZipCode();
      // Wait for getZipCode to complete
      if (res.length > 0) {
        handleInputChange({
          target: { name: "delCityName", value: res[0].cityName },
        } as any);
        handleInputChange({
          target: { name: "delAirportID", value: res[0].airportID },
        } as any);
        handleInputChange({
          target: { name: "delStateProvID", value: res[0].stateProvID },
        } as any);
        handleInputChange({
          target: { name: "delCountryID", value: res[0].countryID },
        } as any);
        handleInputChange({
          target: { name: "deliveryTimezoneID", value: res[0].timezoneID },
        } as any);
      } else {
        handleInputChange({
          target: { name: "delCityName", value: "" },
        } as any);
        handleInputChange({
          target: { name: "delAirportID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "delStateProvID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "delCountryID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "deliveryTimezoneID", value: "" },
        } as any);
      }
    }
  };

  const handleZipCodeFocusOut = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const res = await getZipCode(); // Wait for getZipCode to complete
    if (res) {
      if (res.length > 0) {
        handleInputChange({
          target: { name: "delCityName", value: res[0].cityName },
        } as any);
        handleInputChange({
          target: { name: "delAirportID", value: res[0].airportID },
        } as any);
        handleInputChange({
          target: { name: "delStateProvID", value: res[0].stateProvID },
        } as any);

        handleInputChange({
          target: { name: "delCountryID", value: res[0].countryID },
        } as any);
        handleInputChange({
          target: { name: "deliveryTimezoneID", value: res[0].timezoneID },
        } as any);
      } else {
        handleInputChange({
          target: { name: "delCityName", value: "" },
        } as any);
        handleInputChange({
          target: { name: "delAirportID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "delStateProvID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "delCountryID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "deliveryTimezoneID", value: "" },
        } as any);
      }
    }
  };

  useEffect(() => {
    if (selectedAddress) {
      setSelectedCountry(selectedAddress.countryID);
      setSelectedZipCode(selectedAddress.postalCode);
      onDeliveryDataChange({
        delAddress1: selectedAddress.address1 || "",
        delAddress2: selectedAddress.address2 || "",
        delAddress3: selectedAddress.address3 || "",
        delCityName: selectedAddress.cityName || "",
        delPostalCode: selectedAddress.postalCode || "",
        delCountryID: selectedAddress.countryID || "",
        delStateProvID: selectedAddress.stateProvID || "",
        delCompanyName: selectedAddress.companyName || "",
        delInstructions: selectedAddress.pickupInstructions || "",
        delAirportID: selectedAddress.airportID || "",
        deliveryEmailAddress: selectedAddress.emailAddress || "",
        addressCode: selectedAddress.addressCode || "",
        delPhoneNumber: selectedAddress.phoneNumber || "",
        delTimezoneID: selectedAddress.timezoneID || "",
        delAddressGUID: selectedAddress.addressGUID,
        serviceID:
          serviceAvailType === "U" || serviceAvailType === "A"
            ? selectedAddress?.serviceID
            : 0,
        serviceGUID:
          serviceAvailType === "U"
            ? selectedAddress?.serviceGUID
            : serviceAvailType === "A"
            ? selectedAddress?.companyServiceGUID
            : "",
      });
      getZipCode(selectedAddress.countryID, selectedAddress.postalCode);
    }
  }, [selectedAddress]);

  return (
    <Box className="d-flex flex-column w-100 border rounded detail-info-section">
      <Typography variant="h6" className="Sub-header">
        Delivery Details
      </Typography>
      <Box className="d-flex flex-column gap-12">
        <FormControl size="medium">
          <InputLabel className="input-label" id="demo-select-small-label">
            Address Book (Optional)
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="txtDeliveryAddressBook"
            {...register("addressBook")}
            label="Address Book (Optional)"
            fullWidth
            onChange={handleAddressChange}
            inputProps={{ maxLength: 52 }}
            name="delAddressGUID"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },

              PaperProps: {
                style: menuStyles,
              },
            }}
            sx={{ width: isMobile ? "100%" : undefined }}
          >
            {addressData.length > 0 &&
              addressData.map((address: any) => (
                <MenuItem key={address.addressGUID} value={address}>
                  {address.companyName +
                    (address.address1 != "" ? ", " : "") +
                    address.address1 +
                    (address.cityName != "" ? ", " : "") +
                    address.cityName +
                    (address.cityName != "" ? ", " : "") +
                    address.countryID}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Box className="d-flex gap-16">
          <TextField
            id="txtDeliveryAddressCode"
            variant="outlined"
            label="Code (Optional)"
            className="w-100"
            {...register("addressCode")}
            name="addressCode"
            onChange={handleInputChange}
            onKeyDown={handleAddressCodeKeyDown}
            value={editWillCallOrderData.addressCode}
            InputLabelProps={{ shrink: !!editWillCallOrderData.addressCode }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickOpen}
                    id="btnDeliveryCodeDialog"
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CodeDialog
            open={open}
            handleClose={handleClose}
            addressType="deliveryDetails"
            onSelectAddress={onSelectAddress}
          />

          <TextField
            variant="outlined"
            label="Company Name"
            {...register("delCompanyName")}
            id="txtDeliveryCompanyName"
            className="w-100"
            name="delCompanyName"
            value={editWillCallOrderData.delCompanyName}
            InputLabelProps={{ shrink: !!editWillCallOrderData.delCompanyName }}
            onChange={handleInputChange}
            error={!!formErrors.delCompanyName}
            inputProps={{ maxLength: 50 }}
            InputProps={{
              endAdornment: !!formErrors.delCompanyName && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  ></InfoRoundedIcon>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TextField
          variant="outlined"
          label="Address Line 1"
          id="txtDeliveryAddress1"
          {...register("delAddress1")}
          value={editWillCallOrderData.delAddress1}
          InputLabelProps={{ shrink: !!editWillCallOrderData.delAddress1 }}
          name="delAddress1"
          onChange={handleInputChange}
          inputProps={{ maxLength: 50 }}
        />
        <Box className="d-flex align-center gap-8">
          <TextField
            variant="outlined"
            className={isShowAddress3 ? "w-100" : "width-90"}
            label="Address Line 2  (Optional)"
            id="txtDeliveryAddress2"
            {...register("delAddress2")}
            name="delAddress2"
            value={editWillCallOrderData.delAddress2}
            InputLabelProps={{ shrink: !!editWillCallOrderData.delAddress2 }}
            onChange={handleInputChange}
            inputProps={{ maxLength: 50 }}
          />
          {!isShowAddress3 && (
            <IconButton onClick={() => setIsShowAddress3(true)} id="btnLfdaAdd">
              <AddIcon className="Add-icon" />
            </IconButton>
          )}
        </Box>
        {isShowAddress3 && (
          <TextField
            variant="outlined"
            label="Address Line 3 (Optional)"
            id="txtDeliveryAddress3"
            name="delAddress3"
            value={editWillCallOrderData.delAddress3}
            InputLabelProps={{ shrink: !!editWillCallOrderData.delAddress3 }}
            onChange={handleInputChange}
            inputProps={{ maxLength: 50 }}
          />
        )}

        <Box className="d-flex gap-16">
          <TextField
            variant="outlined"
            {...register("delPostalCode")}
            name="delPostalCode"
            label="Zip"
            id="txtDeliveryPostalCode"
            className="w-100"
            onChange={handleInputChange}
            onKeyDown={handleZipCodeKeyDown}
            onBlur={handleZipCodeFocusOut}
            value={editWillCallOrderData.delPostalCode}
            InputLabelProps={{ shrink: !!editWillCallOrderData.delPostalCode }}
            error={!!formErrors.delPostalCode || deliveryZipCodeError}
            InputProps={{
              endAdornment: !!formErrors.delPostalCode && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  ></InfoRoundedIcon>
                </InputAdornment>
              ),
            }}
          />
          <FormControl size="medium" className="w-100">
            <InputLabel id="demo-select-small-label">City</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="ddDeliveryCity"
              value={editWillCallOrderData.delCityName} // Ensure this matches your state variable
              label="City"
              {...register("delCityName")}
              name="delCityName"
              fullWidth
              onChange={handleCityChange}
              sx={{ width: isMobile ? "100%" : undefined }}
              error={!!formErrors.delCityName}
            >
              {zipCodeData.length > 0 &&
                zipCodeData.map((city: any) => (
                  <MenuItem key={city.cityName} value={city.cityName}>
                    {city.cityName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box className="d-flex gap-16">
          <TextField
            variant="outlined"
            label="State"
            id="txtDeliveryState"
            {...register("delStateProvID")}
            name="delStateProvID"
            className="w-100"
            value={editWillCallOrderData.delStateProvID}
            InputLabelProps={{ shrink: !!editWillCallOrderData.delStateProvID }}
            onChange={handleInputChange}
            inputProps={{ maxLength: 3 }}
          />
          <FormControl size="medium" className="w-100">
            <InputLabel id="demo-select-small-label">Country</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="ddDeliveryCountry"
              value={editWillCallOrderData.delCountryID} // Ensure this matches your state variable
              label="Country"
              {...register("delCountryID")}
              name="delCountryID"
              fullWidth
              onChange={handleInputChange}
              sx={{ width: isMobile ? "100%" : undefined }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },

                PaperProps: {
                  style: menuStyles,
                },
              }}
            >
              {countryData.length > 0 &&
                countryData.map((country: any) => (
                  <MenuItem key={country.countryID} value={country.countryID}>
                    {country.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box className="d-flex gap-16">
          <TextField
            variant="outlined"
            label="Attention (Optional)"
            className="w-100"
            id="txtDeliveryAttention"
            {...register("delAttention")}
            name="delAttention"
            value={editWillCallOrderData.delAttention}
            InputLabelProps={{ shrink: !!editWillCallOrderData.delAttention }}
            onChange={handleInputChange}
            inputProps={{ maxLength: 50 }}
          />

          <TextField
            variant="outlined"
            label="Airport"
            {...register("delAirportID")}
            id="txtDeliveryAirport"
            name="delAirportID"
            value={editWillCallOrderData.delAirportID}
            onChange={(e) => {
              const re = /^[a-zA-Z\s]*$/;
              const value = e.target.value;
              if (re.test(value)) {
                handleInputChange(e);
              }
            }}
            className="w-100"
            inputProps={{ maxLength: 3 }}
            error={!!formErrors.delAirportID}
            InputProps={{
              endAdornment: !!formErrors.delAirportID && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TextField
          variant="outlined"
          label="Instructions (Optional)"
          id="txtDeliveryInstructions"
          {...register("delInstructions")}
          name="delInstructions"
          placeholder="Enter any additional instructions (Optional)."
          value={editWillCallOrderData.delInstructions}
          InputLabelProps={{ shrink: !!editWillCallOrderData.delInstructions }}
          onChange={handleInputChange}
          multiline
          rows={4}
          error={!!formErrors.delInstructions}
        />
      </Box>
      <Box className="d-flex flex-column mt-3">
        <Typography variant="h6" className="Sub-header">
          Delivery Contact
        </Typography>
        <Box className="d-flex gap-12">
          <TextField
            variant="outlined"
            id="txtDeliveryPhoneNumber"
            label="Phone (Optional)"
            {...register("delPhoneNumber")}
            value={editWillCallOrderData.delPhoneNumber}
            InputLabelProps={{ shrink: !!editWillCallOrderData.delPhoneNumber }}
            onChange={handleInputChange}
            onKeyDown={handleNumericValue}
            name="delPhoneNumber"
            className="w-100"
            inputProps={{ maxLength: 25 }}
          />
          <TextField
            variant="outlined"
            label="Email Address (Optional)"
            id="txtDeliveryEmail"
            className="w-100"
            {...register("deliveryEmailAddress")}
            name="delEmailAddress"
            value={editWillCallOrderData.delEmailAddress}
            InputLabelProps={{
              shrink: !!editWillCallOrderData.delEmailAddress,
            }}
            onChange={handleInputChange}
            inputProps={{ maxLength: 100 }}
          />
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              name="delKeep"
              id="cbDeliverySaveToAdressBook"
              checked={editWillCallOrderData.delKeep}
              onChange={handleInputChange}
            />
          }
          label="Save to address book"
          className="pt-8 ms-2"
        />
      </Box>
    </Box>
  );
};

export default EditDeliveryAddress;
