import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import { RootState } from "../../types/global";
import { hideNotification } from "../reducers/actions/notification.actions";
import { useToast } from "../context/ToastContext";
import { useLocation } from "react-router-dom";

const Notification: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { message, severity } = useSelector(
    (state: RootState) => state.notification
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(hideNotification());
  };

  const { showToast, handleClose: handleClose403, toast } = useToast();

  useEffect(() => {
    const handleShowToast = (event: CustomEvent) => {
      const { message, severity } = event.detail;
      showToast(message, severity);
    };

    window.addEventListener("showToast", handleShowToast as EventListener);

    return () => {
      window.removeEventListener("showToast", handleShowToast as EventListener);
    };
  }, [showToast]);

  useEffect(() => {
    if (toast.open) {
      handleClose403();
    }
  }, [location.pathname]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={severity || "info"}
        sx={{ width: "100%" }}
        id="txtNotiInfo"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
