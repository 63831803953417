import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import {
  releaseNotes,
  updateReleaseNotes,
  releaseNotesContents,
} from "./release-notes.reducer";

const useReleaseNotesLogic = () => {
  const dispatch = useDispatch();
  const { releaseNotesData, isAlreadyViewed } = useSelector(
    (state: RootState) => state.releaseNotes
  );

  const handleReleaseNotes = async (param: { isPopup: boolean }) => {
    try {
      const response = await dispatch(releaseNotes(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleUpdateReleaseNotes = async (param: {
    userId: number | string;
    validFrom?: string;
    validTo?: string;
  }) => {
    try {
      //console.log("parms", param);
      const response = await dispatch(updateReleaseNotes(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleReleaseNotesContents = async (param: {
    techRelNoteGUID: string;
  }) => {
    try {
      const response = await dispatch(releaseNotesContents(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    releaseNotes: handleReleaseNotes,
    updateReleaseNotes: handleUpdateReleaseNotes,
    releaseNotesContents: handleReleaseNotesContents,
    releaseNotesData,
    isAlreadyViewed,
  };
};

export default useReleaseNotesLogic;
