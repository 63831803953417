import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import useServiceLogic from "../../shared/lookup/service/service.logic";
import useAddressLogic from "../../shared/lookup/address/address.logic";
import { AddressItemDto } from "../../models/address-item-dto";
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { downloadAndSavePDF } from "../../shared/util/download-pdf";
import moment from "moment";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
type Order = "desc" | "asc";

//   const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
//   const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);

interface addressBooksProps {
  title: string;
  orderType: string;
  // other props
}

const ShipmentVerificationReport: React.FC<addressBooksProps> = ({
  title,
  orderType,
}) => {
  const currentDateTime = new Date();
  const [verificationRequestParams, setverificationRequestParams] = useState({
    pickupDate: currentDateTime,
    deliveryDate: null,
    origin: "None",
    destination: "None",
    serviceID: "None",
  });
  //const [order, setOrder] = React.useState<Order>("asc");

  const [isLoading, setIsLoading] = useState(false);
  // const [expanded, setExpanded] = useState(false);

  // const [accounts, setAccounts] = useState<any>([]);
  //const [addressBookData, setAddressBookData] = useState<AddressBookDto[]>([]);
  // const [selectedAddress, setSelectedAddress] = useState<
  //   AddressBookDto | undefined
  // >(undefined);
  const token = useDecodeJWT();
  //const [page, setPage] = useState(0);
  //const [rowsPerPage, setRowsPerPage] = useState(10);

  //const isMobile = useMediaQuery("(max-width:600px)");
  //const [isAddEditAddressModal, setAddEditAddressModal] = useState(false);
  const userGroupGUID: any = localStorage.getItem("userGroupGUID");

  // interface MenuStyles {
  //   maxHeight: string;
  // }

  // const [menuStyles, setMenuStyles] = useState<MenuStyles>({
  //   maxHeight: "200px", // Default max height for desktop
  // });

  const menuStyles = {
    maxHeight: "200px", // Default max height for desktop
  };

  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setdateErrors(null);
    setverificationRequestParams({
      ...verificationRequestParams,
      [name]: value,
    });
  };

  // const handleEdit = (row: any) => {
  //   //setAddEditAddressModal(true);
  //   //setSelectedAddress(row);
  // };

  // const handleDelete = async (addressGUID: string | undefined) => {
  //   const confirm = window.confirm(
  //     "Are you sure you want to delete this address?"
  //   );
  // };

  // const toggleExpand = () => {
  //   setExpanded(!expanded);
  // };

  // function EnhancedTableHead(props: EnhancedTableProps) {
  //   const { order, orderBy, onRequestSort } = props;
  //   const createSortHandler =
  //     (property: keyof AddressBookDto) =>
  //     (event: React.MouseEvent<unknown>) => {
  //       onRequestSort(event, property);
  //     };
  // }

  const [serviceData, setServiceData] = useState<any>([]);
  const { service } = useServiceLogic();
  const { address } = useAddressLogic();
  const [addressData, setAddressData] = useState<AddressItemDto[]>([]);
  const [dateErrors, setdateErrors] = useState<string | null>(null);

  useEffect(() => {
    getService();
    getAddress();
  }, []);

  // Service Dropdown
  const getService = async () => {
    try {
      const param: any = {
        companyID: 15693,
        userGUID: token.UserGUID,
        serviceAvailType: "U",
        baseDateTime: new Date(),
        origCountryID: "",
        origCityName: "",
        origStateProvID: "",
        origPostalCode: "",
        origAirportID: "",
        destCountryID: "",
        destCityName: "",
        destStateProvID: "",
        destPostalCode: "",
        destAirportID: "",
      };
      const response = await service(param);
      if (response.data && response.data.data) {
        setServiceData(response.data.data);
      } else {
        setServiceData([]);
      }
    } catch {
      setServiceData([]);
    }
  };

  const getAddress = async () => {
    try {
      setIsLoading(true);
      const param = {
        addressCode: "",
        companyName: "",
        cityName: "",
        stateProvID: "",
        countryID: "",
        postalCode: "",
        userGroupGUID: userGroupGUID,
      };
      const response = await address(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        if (!param.addressCode) {
          setAddressData(res);
        }

        if (param.addressCode && res.length > 0) {
        }
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      setAddressData([]);
    }
  };

  const DownloadReport = async () => {
    try {
      setdateErrors(null);
      if (
        verificationRequestParams.pickupDate == null &&
        verificationRequestParams.deliveryDate == null
      ) {
        setdateErrors("Date is required.");
        return;
      }
      const puPostalcode = addressData.find(
        (address: any) =>
          address.addressGUID === verificationRequestParams.origin
      )?.postalCode;

      const delPostalcode = addressData.find(
        (address: any) =>
          address.addressGUID === verificationRequestParams.destination
      )?.postalCode;

      const param = {
        userId: parseInt(token.UserId),
        pickupDateTime: verificationRequestParams.pickupDate
          ? moment(verificationRequestParams.pickupDate).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
          : null,
        puPostalCode: puPostalcode == undefined ? "" : puPostalcode,
        delPostalCode: delPostalcode == undefined ? "" : delPostalcode,
        puAirportID: "",
        delAirportID: "",
        deliveryDateTime: verificationRequestParams.deliveryDate
          ? moment(verificationRequestParams.deliveryDate).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
          : null,
        serviceID:
          verificationRequestParams?.serviceID === "None"
            ? null
            : parseInt(verificationRequestParams?.serviceID),
      };
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Report/api/ShipmentVerifiCationAtPickup`,
        param,
        { responseType: "arraybuffer" }
      );
      if (response.status === 200) {
        const pdfTitle = "mnxpickupreport.pdf";
        const result = await downloadAndSavePDF(response.data, pdfTitle);
        if (result.success) {
        }
      }
    } catch (error: any) {}
  };

  return (
    <div className="container-fluid active-order address-book">
      <>
        <Box
          className="Header-main mb-8 pt-16"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            className="Main-Header side-by-side-header"
            variant="h4"
            color={"primary"}
          >
            Shipment Verification Report
          </Typography>

          <Typography
            className="info-text"
            variant="h6"
            sx={{
              display: {
                xs: "none", // Hide on mobile devices
                sm: "flex", // Show on small and larger devices
                justifyContent: "left",
              },
            }}
          >
            Enter the Report Criteria and press the "Download Report” button.
          </Typography>
        </Box>

        {dateErrors && (
          <Box className="w-100">
            <Alert severity="error" variant="filled" id="txtSvrDateErrors">
              {dateErrors}
            </Alert>
          </Box>
        )}
        <Box className="d-flex flex-column w-100 border p16 rounded mb-16">
          <Typography variant="h6" className="header-sub-title">
            Report Criteria
          </Typography>

          <Grid
            container
            spacing={2}
            sx={{
              marginTop: {
                xs: 1, // 8px margin-top for mobile
                sm: 0, // 0 margin-top for larger screens
              },
            }}
          >
            <Grid item lg={12}>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <Grid container spacing={2}>
                    {/* First Row */}
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={11 / 2} xs={6}>
                          <div className="small-cal-view">
                            <CustomDatePicker
                              id="PickupDate"
                              handleDateChange={handleTextFieldChange as any}
                              label="Pickup Date"
                              name="pickupDate"
                              value={verificationRequestParams.pickupDate}
                              isClearable={true}
                            />
                          </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={11 / 2} xs={6}>
                          <div className="small-cal-view">
                            <CustomDatePicker
                              id="DeliveryDate"
                              handleDateChange={handleTextFieldChange as any}
                              label="Delivery Date"
                              name="deliveryDate"
                              value={verificationRequestParams.deliveryDate}
                              isClearable={true}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={11 / 4} md={11 / 4} sm={11 / 2} xs={6}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="demo-select-small-label">
                          Origin
                        </InputLabel>
                        <Select
                          id="ddSvrOrigin"
                          label="Origin"
                          value={verificationRequestParams.origin}
                          onChange={(e) =>
                            setverificationRequestParams({
                              ...verificationRequestParams,
                              origin: e.target.value,
                            })
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              style: menuStyles,
                            },
                          }}
                        >
                          <MenuItem value="None">(None)</MenuItem>
                          {addressData.length > 0 &&
                            addressData.map((address: any) => (
                              <MenuItem
                                key={address.addressGUID}
                                value={address.addressGUID}
                              >
                                {address.companyName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={11 / 4} md={11 / 4} sm={11 / 2} xs={6}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="demo-select-small-label">
                          Destination
                        </InputLabel>
                        <Select
                          id="ddSvrDestination"
                          label="Destination"
                          value={verificationRequestParams.destination}
                          onChange={(e) =>
                            setverificationRequestParams({
                              ...verificationRequestParams,
                              destination: e.target.value,
                            })
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              style: menuStyles,
                            },
                          }}
                        >
                          <MenuItem value="None">(None)</MenuItem>
                          {addressData.length > 0 &&
                            addressData.map((address: any) => (
                              <MenuItem
                                key={address.addressGUID}
                                value={address.addressGUID}
                              >
                                {address.companyName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={11 / 2} xs={12}>
                      <FormControl size="small" className="w-100">
                        <InputLabel id="demo-select-small-label">
                          Service Type
                        </InputLabel>
                        <Select
                          id="ddSvrServicetype"
                          label="Service Type"
                          value={verificationRequestParams.serviceID || "None"}
                          onChange={(e) =>
                            setverificationRequestParams({
                              ...verificationRequestParams,
                              serviceID: e.target.value,
                            })
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },

                            PaperProps: {
                              style: menuStyles,
                            },
                          }}
                        >
                          <MenuItem value="None">(None)</MenuItem>
                          {serviceData.length > 0 &&
                            serviceData.map((service: any) => (
                              <MenuItem
                                key={service.serviceGUID}
                                value={service.serviceID}
                              >
                                {service.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={2} md={2} sm={6} xs={12}>
                      <Button
                        sx={{
                          width: {
                            xs: "100%", // 100% width on xs screens
                            sm: "215px", // 215px width for sm and above
                          },
                        }}
                        id="btnDownloadReport"
                        variant="contained"
                        color="primary"
                        className="rounded"
                        onClick={() => {
                          DownloadReport();
                        }}
                      >
                        <DownloadIcon className="mr-8" />
                        Download Report
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    </div>
  );
};

export default ShipmentVerificationReport;
