import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TextField,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Grid,
} from "@mui/material";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import CommonPagination from "../../shared/components/CustomPagination";
import useAccounntsLogic from "../../shared/lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import useSearchOrdersLogic from "./search-orders.logic";
// import Loader from "../../shared/util/loader";
import { useMediaQuery } from "@mui/material";
import { SearchOrderItemDto } from "../../models/search-order-item-dto";
import { CountryItemDto } from "../../models/country-item-dto";
import { API_BASE_URL, MAX_COMPANY_ID } from "../../config/constants";
import useCountryLogic from "../../shared/lookup/country/country.logic";
import deleteicon from "../../assets/images/logo/delete-icon.svg";
import plusicon from "../../assets/images/logo/plus-icon.svg";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { downloadAndSavePDF } from "../../shared/util/download-pdf";
import axiosInstance from "../../config/axios-interceptor";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useDefaultDataLogic from "../long-form-orders/long-form-order.logic";
import { MySettingItemDto } from "../../models/long-form-item-dto";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RenderCellData, {
  RenderMobileCellData,
} from "../../shared/components/search-orders/RenderCellData";
import {
  defaultSearchState,
  formatISODateToYYYYMMDD,
  getHeadingAndRemainColumn,
  getSaveSearchParams,
  headCells,
  setTemplateCookie,
  getTemplateCookieValue,
} from "../../shared/util/search-order";
import SaveSearchPromptDialog from "../../shared/components/search-orders/save-search-daiog";
import { sortAndUpdateTheData } from "../../shared/util/rate-estimate";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import moment from "moment";
type Order = "desc" | "asc";
interface SearchOrdersProps {
  title: string;
  orderType: string;
}
interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof SearchOrderItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const SearchOrders: React.FC<SearchOrdersProps> = ({ title, orderType }) => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof SearchOrderItemDto>("shipmentNumber");
  const [notData, setNotData] = React.useState<any>("");
  const { account } = useAccounntsLogic();
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const { searchOrders, deleteSavedSearch, saveSearch, savedSearchList } =
    useSearchOrdersLogic();
  const [accounts, setAccounts] = useState<any>([]);
  const [searchOrderData, setSearchOrdersData] = useState<SearchOrderItemDto[]>(
    []
  );
  const token = useDecodeJWT();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [totalRecord, setTotalRecord] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
  const { country } = useCountryLogic();

  const [isSearch, setSearch] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(true);
  const { defaultData } = useDefaultDataLogic();
  const [defaultDatas, setDefaultDatas] = useState<MySettingItemDto>();
  const [savedSearchListData, setSavedSearchListData] = useState([]);
  const [selectedSavedSearch, setSelectedSavedSearch] = useState("");
  const [savedSearchError, setSavedSearchError] = useState("");
  const [searchFormState, setSearchFormState] = useState(defaultSearchState);
  const maxSearchDays = localStorage.getItem("maxSearchDays");

  const sortField = (property: string) => {
    switch (property) {
      case "pickupAddress":
        return "pickupAddressFull";
      case "deliveryAddress":
        return "deliveryAddressFull";
      case "pickupAddressFull":
        return "pickupAddress";
      case "deliveryAddressFull":
        return "deliveryAddress";
      default:
        return property;
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SearchOrderItemDto
  ) => {
    const isAsc = order === "asc";
    setOrder(isAsc ? "desc" : "asc");

    const fieldToSortBy: any = sortField(property);
    setOrderBy(fieldToSortBy);
    setPage(0);
  };

  useEffect(() => {
    getDefaultData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getSearchOrders();
    // eslint-disable-next-line
  }, [order, orderBy, page, rowsPerPage]);

  const getDefaultData = async () => {
    try {
      setIsLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
      };
      const response = await defaultData(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setDefaultDatas(res);
      }
      setIsLoading(false);
    } catch (err) {
      setDefaultDatas(undefined);

      setIsLoading(false);
    }
  };

  const getCountry = async () => {
    try {
      setIsLoading(true);
      const param = {};
      const response = await country(param);
      if (response.data && response.data.data) {
        setCountryData(response.data.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setCountryData([]);
    }
  };

  const [formatFormState, setFormatFormState] = useState({
    format: "xlsx",
  });

  useEffect(() => {
    getCountry();
    // eslint-disable-next-line
  }, []);

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property as any);
      };

    return (
      <Droppable droppableId="grid" direction="horizontal">
        {(provided: any) => (
          <TableHead ref={provided.innerRef} {...provided.droppableProps}>
            <TableRow>
              {gridItems.length > 0 ? (
                gridItems.map((headCell: any, index) => (
                  <Draggable
                    key={headCell.id}
                    draggableId={headCell.id}
                    index={index}
                  >
                    {(provided: any) => (
                      <TableCell
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={headCell.id}
                        align={"left"}
                        sx={{
                          minWidth: `${headCell.minwidth}px !important`,
                        }}
                        // sx={{
                        //   minWidth: headCell.minwidth,
                        //   color: "red !important",
                        // }} // Ensure valid CSS units
                        // width={headCell.minwidth}
                        // style={{ minWidth: headCell.minwidth }}
                        // minWidth={headCell.minwidth}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    )}
                  </Draggable>
                ))
              ) : (
                <TableCell align="left">
                  <Button style={{ display: "none" }}>Add New Column</Button>
                </TableCell>
              )}
              {provided.placeholder}
            </TableRow>
          </TableHead>
        )}
      </Droppable>
    );
  }

  const getAccounts = useCallback(async () => {
    try {
      const param = {
        userId: token.UserId,
        companyID: 0,
        useIncludeAllItemrId: false,
      };

      const response = await account(param);
      if (response.data && response.data.data) {
        setAccounts(response.data.data);
      } else {
        setAccounts([]);
        setNotData("No data to display");
      }
    } catch (err) {
      setAccounts([]);
      setNotData("No data to display");
    }
    // eslint-disable-next-line
  }, [token.UserId]);

  useEffect(() => {
    getAccounts();
    setNotData("No data to display");
  }, [getAccounts]);

  const getSearchOrders = async (
    currentPageData: boolean = false,
    searchData: any = ""
  ) => {
    try {
      setIsLoading(true);
      const {
        accountNumber,
        startDate,
        endDate,
        pickupCityName,
        pickupCountryId,
        deliveryCityName,
        deliveryCountryId,
        reference,
        reference2,
        reference3,
        reference4,
        reference5,
        referenceTypeId,
        // referenceTypeId2,
        // referenceTypeId3,
        // referenceTypeId4,
        // referenceTypeId5,
        trackingNumber,
        contents,
        invoiceNumber,
        orderByName,
        orderStatus,
        pickupPostalCode,
        pickupStateProvId,
        deliveryPostalCode,
        deliveryStateProvId,
        referenceTypeId6,
        referenceTypeId7,
        referenceTypeId8,
        referenceTypeId9,
      } = searchFormState;
      let param = {
        accountNumber: accountNumber === "All" ? "" : accountNumber,
        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        trackingNumber: trackingNumber,
        contents: contents,
        pickupCityName: pickupCityName,
        pickupCountryId: pickupCountryId === "All" ? "" : pickupCountryId,
        deliveryCityName: deliveryCityName,
        deliveryCountryId: deliveryCountryId === "All" ? "" : deliveryCountryId,
        reference: reference,
        reference2: reference2,
        reference3: reference3,
        reference4: reference4,
        reference5: reference5,
        referenceTypeId: referenceTypeId,
        referenceTypeId2:
          reference2 !== null ? defaultDatas?.referenceTypeID2 : 0,
        referenceTypeId3:
          reference3 !== null ? defaultDatas?.referenceTypeID3 : 0,
        referenceTypeId4:
          reference4 !== null ? defaultDatas?.referenceTypeID4 : 0,
        referenceTypeId5:
          reference5 !== null ? defaultDatas?.referenceTypeID5 : 0,
        invoiceNumber: parseInt(invoiceNumber, 10),
        orderByName: orderByName,
        orderStatus:  orderStatus == "ORD" ? "" : orderStatus,
        pickupPostalCode: pickupPostalCode,
        pickupStateProvId: pickupStateProvId,
        deliveryPostalCode: deliveryPostalCode,
        deliveryStateProvId: deliveryStateProvId,
        referenceTypeId6: referenceTypeId6,
        referenceTypeId7: referenceTypeId7,
        referenceTypeId8: referenceTypeId8,
        referenceTypeId9: referenceTypeId9,
        orderBy: orderBy,
        sorting: order,
        pagesize: rowsPerPage === -1 ? totalRecord : rowsPerPage,
        pageNo: currentPageData ? page : page + 1,
      };
      if (searchData) {
        param = {
          ...searchData,
          pagesize: rowsPerPage,
          pageNo: 1,
        };
      }
      setSearch(true);

      if (
        (startDate !== "" && endDate !== "") ||
        trackingNumber !== "" ||
        invoiceNumber !== "" ||
        searchData
      ) {
        const response = await searchOrders(param);

        if (response.data && response.data.data) {
          if (isSearch) {
            setSearchOrdersData(response.data.data);
            setTotalRecord(response.data.totalCount);
            setIsLoading(false);
          }
          setSearch(true);
        } else {
          setIsLoading(false);
          setSearchOrdersData([]);
          setTotalRecord(0);
        }
      }
    } catch (err) {
      setIsLoading(false);
      setSearchOrdersData([]);
      setTotalRecord(0);
    }
  };

  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const { endDate } = searchFormState;
    if (name === "startDate" && !endDate) {
      setSearchFormState({
        ...searchFormState,
        [name]: value,
        endDate: value,
      });
    } else {
      setSearchFormState({
        ...searchFormState,
        [name]: value,
      });
    }
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    if (pageSize === -1) {
      setRowsPerPage(-1);
    } else {
      setRowsPerPage(pageSize);
    }
    setPage(0);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setSearchFormState({
      ...searchFormState,
      [name]: value,
    });
  };

  const handleformatChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormatFormState({
      ...formatFormState,
      [name]: value,
    });
  };

  const handleSearch = () => {
    if (page > 0) {
      //just reset the page so api will call
      setPage(0);
    } else {
      getSearchOrders();
    }
  };

  const handlePrint = async () => {
    setIsLoading(true);
    if (
      (searchFormState.startDate !== "" && searchFormState.endDate !== "") ||
      searchFormState.trackingNumber !== "" ||
      searchFormState.invoiceNumber !== "" ||
      searchOrderData.length > 0
    ) {
      try {
        const columnList = gridItems
          .map((item) => {
            switch (item.id) {
              case "pickupAddress":
                return "pickupAddressFull";
              case "deliveryAddress":
                return "deliveryAddressFull";
              case "pickupAddressFull":
                return "pickupAddress";
              case "deliveryAddressFull":
                return "deliveryAddress";
              default:
                return item.id;
            }
          })
          .join(",");

        const param = {
          exportType: formatFormState.format,
          accountNumber:
            searchFormState?.accountNumber === "All"
              ? ""
              : searchFormState?.accountNumber,
          startDate: searchFormState.startDate
            ? moment(searchFormState.startDate).format("yyyy-MM-DD")
            : null,
          endDate: searchFormState.endDate
            ? moment(searchFormState.endDate).format("yyyy-MM-DD")
            : null,
          trackingNumber: searchFormState.trackingNumber
            ? searchFormState.trackingNumber
            : "",
          contents: searchFormState.contents ? searchFormState.contents : "",
          pickupCityName: searchFormState.pickupCityName
            ? searchFormState.pickupCityName
            : "",
          pickupCountryId:
            searchFormState.pickupCountryId === "All"
              ? ""
              : searchFormState.pickupCountryId,
          deliveryCityName: searchFormState.deliveryCityName
            ? searchFormState.deliveryCityName
            : "",
          deliveryCountryId:
            searchFormState.deliveryCountryId === "All"
              ? ""
              : searchFormState.deliveryCountryId,
          reference: searchFormState.reference ? searchFormState.reference : "",
          reference2: searchFormState.reference2
            ? searchFormState.reference2
            : "",
          reference3: searchFormState.reference3
            ? searchFormState.reference3
            : "",
          reference4: searchFormState.reference4
            ? searchFormState.reference4
            : "",
          reference5: searchFormState.reference5
            ? searchFormState.reference5
            : "",
          referenceTypeId: searchFormState.referenceTypeId
            ? searchFormState.referenceTypeId
            : null,
          referenceTypeId2:
            searchFormState?.reference2 !== null
              ? defaultDatas?.referenceTypeID2
              : 0,
          referenceTypeId3:
            searchFormState?.reference3 !== null
              ? defaultDatas?.referenceTypeID3
              : 0,
          referenceTypeId4:
            searchFormState?.reference4 !== null
              ? defaultDatas?.referenceTypeID4
              : 0,
          referenceTypeId5:
            searchFormState?.reference5 !== null
              ? defaultDatas?.referenceTypeID5
              : 0,
          invoiceNumber: searchFormState.invoiceNumber
            ? searchFormState.invoiceNumber
            : null,
          orderByName: searchFormState.orderByName
            ? searchFormState.orderByName
            : "",
          orderStatus: searchFormState.orderStatus
            ? searchFormState.orderStatus
            : "",
          pickupPostalCode: searchFormState.pickupPostalCode
            ? searchFormState.pickupPostalCode
            : "",
          pickupStateProvId: searchFormState.pickupStateProvId
            ? searchFormState.pickupStateProvId
            : "",
          deliveryPostalCode: searchFormState.deliveryPostalCode
            ? searchFormState.deliveryPostalCode
            : "",
          deliveryStateProvId: searchFormState.deliveryStateProvId
            ? searchFormState.deliveryStateProvId
            : "",
          referenceTypeId6: searchFormState.referenceTypeId6
            ? searchFormState.referenceTypeId6
            : null,
          referenceTypeId7: searchFormState.referenceTypeId7
            ? searchFormState.referenceTypeId7
            : null,
          referenceTypeId8: searchFormState.referenceTypeId8
            ? searchFormState.referenceTypeId8
            : null,
          referenceTypeId9: searchFormState.referenceTypeId9
            ? searchFormState.referenceTypeId9
            : null,
          orderBy: orderBy,
          sorting: order,
          //userId: searchFormState.userId ? searchFormState.userId : null,
          //companyId: MAX_COMPANY_ID,
          columnList: columnList,
        };
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Report/ExportSearchOrders`,
          param,
          { responseType: "arraybuffer" }
        );

        if (response.status) {
          var filetype = "";
          if (formatFormState.format === "xls") {
            filetype = ".xls";
          }
          if (formatFormState.format === "xlsx") {
            filetype = ".xlsx";
          }
          if (formatFormState.format === "csv") {
            filetype = ".csv";
          }
          if (formatFormState.format === "rtf") {
            filetype = ".rtf";
          }
          await downloadAndSavePDF(response.data, "gvSearchOrders" + filetype);
          setIsLoading(false);
        }
      } catch (error: any) { }
    } else {
      setIsLoading(false);
    }
  };

  interface MenuStyles {
    maxHeight: string;
  }

  // eslint-disable-next-line
  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "230px", // Default max height for desktop
  });

  const handlenumericFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    setSearchFormState((prevState) => ({
      ...prevState,
      [name]: numericValue,
    }));
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const [gridItems, setGridItems] = useState(headCells); //current display item
  const [selectItems, setSelectItems] = useState(headCells); //remain item
  const [promptOpen, setPromptOpen] = useState<boolean>(false);

  const setDefaultSetting = () => {
    const template = getTemplateCookieValue(token.UserId);
    if (template) {
      const columns = template;
      const { currentHeadCell, remainHeadCell } =
        getHeadingAndRemainColumn(columns);
      setGridItems(currentHeadCell);
      setSelectItems(remainHeadCell);
    } else {
      setGridItems(headCells.filter((_item: any) => _item.isDefault));
      setSelectItems(headCells.filter((_item: any) => !_item.isDefault));
    }
  };
  useEffect(() => {
    getSavedSearch();
    setDefaultSetting();
    // eslint-disable-next-line
  }, [token.UserId]);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;
    try {
      // Dragging within the same list
      if (source.droppableId === destination.droppableId) {
        if (source.droppableId === "grid") {
          const items = Array.from(gridItems) as any; // Make a shallow copy of the gridItems array
          const [movedItem] = items.splice(source.index, 1); // Remove the item from the source index
          items.splice(destination.index, 0, movedItem); // Insert the item at the destination index

          if (!selectedSavedSearch) {
            const templateItem = items;
            const headCell = templateItem.map((item: any) => item.id).join(",");
            setTemplateCookie(token.UserId, headCell);
          }
          setGridItems(items);
        } else {
          const items: any = Array.from(
            sortAndUpdateTheData(selectItems, "asc", "label")
          );
          const [movedItem] = items.splice(source.index, 1);
          items.splice(destination.index, 0, movedItem);
          setSelectItems(items);
        }
      } else {
        // Moving between lists
        if (source.droppableId === "grid") {
          const sourceItems = Array.from(gridItems) as any;
          const destItems: any = Array.from(
            sortAndUpdateTheData(selectItems, "asc", "label")
          );
          const [movedItem] = sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, movedItem);
          setGridItems(sourceItems);
          setSelectItems(destItems);

          if (!selectedSavedSearch) {
            const templateItem = sourceItems;
            const headCell = templateItem.map((item: any) => item.id).join(",");
            setTemplateCookie(token.UserId, headCell);
          }
        } else {
          const sourceItems: any = Array.from(
            sortAndUpdateTheData(selectItems, "asc", "label")
          );
          const destItems = Array.from(gridItems) as any;
          const [movedItem] = sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, movedItem);
          setGridItems(destItems);
          setSelectItems(sourceItems);

          if (!selectedSavedSearch) {
            const templateItem = destItems;
            const headCell = templateItem.map((item: any) => item.id).join(",");
            setTemplateCookie(token.UserId, headCell);
          }
        }
      }
    } catch (err: any) { }
  };

  const createSortHandler =
    (property: keyof SearchOrderItemDto) =>
      (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
      };

  const getSavedSearch = async () => {
    setIsLoading(true);
    const param = {
      UserGUID: token.UserGUID,
    };
    const response = await savedSearchList(param);
    if (response?.data?.success) {
      setIsLoading(false);
      setSavedSearchListData(response?.data?.data);
    }
  };

  const handleDelete = async () => {
    const confirm = window.confirm("Delete saved search?");
    const CriteriaGUID = selectedSavedSearch;
    if (confirm && CriteriaGUID) {
      setIsLoading(true);
      const param = {
        CriteriaGUID: CriteriaGUID,
      };
      const response = await deleteSavedSearch(param);
      if (response?.data?.success) {
        setSelectedSavedSearch("");
        setIsLoading(false);
        setSearchFormState(defaultSearchState);
        setDefaultSetting();
        getSavedSearch();
        const searchCriteria = getSaveSearchParams(
          defaultSearchState,
          defaultDatas,
          defaultSearchState?.sorting,
          defaultSearchState?.orderBy
        );
        getSearchOrders(false, searchCriteria);
      }
    }
  };
  const handleOpenSaveSearch = () => {
    setPromptOpen(true);
    setSavedSearchError("");
  };

  const handleSaveSearch = async (saveSearchName: string) => {
    setIsLoading(true);
    const searchCriteria = getSaveSearchParams(
      searchFormState,
      defaultDatas,
      order,
      orderBy
    );
    const columnList = gridItems.map((item) => item.id).join(",");
    const param = {
      name: saveSearchName,
      searchOrder: searchCriteria,
      columnList: columnList,
    };
    const response = await saveSearch(param);
    if (response?.data?.success && response?.data?.data === "Success") {
      setPromptOpen(false);
      setIsLoading(false);
      getSavedSearch();
      setSelectedSavedSearch("");
    } else {
      setIsLoading(false);
      const errMsg = response?.data?.data.replace(/\//g, "");
      setSavedSearchError(errMsg);
    }
  };

  const handlePromptClose = (result: string | null) => {
    const data = result?.trim();
    if (data && data !== null) {
      handleSaveSearch(data);
    } else {
      setPromptOpen(false);
    }
  };

  const handleSavedSearchChange = (e: any) => {
    setSelectedSavedSearch(e.target.value);
    // eslint-disable-next-line
    const currentItem: any = savedSearchListData.find(
      // eslint-disable-next-line
      (_item: any) => _item.criteriaGUID == e.target.value
    );
    let searchData = currentItem?.searchOrderRequest;
    const columns = currentItem?.columnList;
    const { currentHeadCell, remainHeadCell } =
      getHeadingAndRemainColumn(columns);

    const startDate = searchData?.startDate
      ? formatISODateToYYYYMMDD(searchData?.startDate)
      : null;
    const endDate = searchData?.endDate
      ? formatISODateToYYYYMMDD(searchData?.endDate)
      : null;
    searchData = {
      ...searchData,
      startDate: startDate,
      endDate: endDate,
      pickupStateProvId: searchData?.pickupStateProvID,
      pickupCountryId: searchData?.pickupCountryID,
      deliveryStateProvId: searchData?.deliveryStateProvID,
      deliveryCountryId: searchData?.deliveryCountryID,
    };

    setSearchFormState(searchData);
    setGridItems(currentHeadCell);
    setSelectItems(remainHeadCell);
    getSearchOrders(false, searchData);
  };

  const minDate = useMemo(() => {
    if (
      maxSearchDays &&
      maxSearchDays !== "null" &&
      maxSearchDays !== "undefined"
    ) {
      const now = new Date();
      const minDate = new Date(
        now.setUTCDate(now.getUTCDate() - parseInt(maxSearchDays))
      );
      return formatISODateToYYYYMMDD(minDate.toISOString());
    }
  }, [maxSearchDays]);

  const moreReportOptionsData = useMemo(() => {
    return sortAndUpdateTheData(selectItems, "asc", "label");
    // eslint-disable-next-line
  }, [selectItems]);

  const toPascalCase = (str:any) => {
    return str
      .replace(/\w\S*/g, (word:any) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .replace(/\s+/g, '');
  };
  return (
    <>
      {" "}
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="container-fluid active-order search-order-panel">
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box className="d-flex flex-column mt-10">
                <Typography
                  className="Main-Header headertitle"
                  variant="h4"
                  color={"primary"}
                >
                  Search Orders
                </Typography>
              </Box>
            </Box>
            <Box className="d-flex flex-column w-100 border p16 rounded">
              <Typography variant="h6" className="header-sub-title">
                Export settings
              </Typography>
              <Grid container className="mt-0" spacing={2}>
                <Grid item xs={8} sm={6} md={4}>
                  <FormControl className="w-100" size="small">
                    <InputLabel id="demo-select-small-label">
                      Saved Searches
                    </InputLabel>

                    <Select
                      labelId="demo-select-small-label"
                      id="ddSavedSearches"
                      value={selectedSavedSearch}
                      label="Saved Searches"
                      name="savedSearchSelect"
                      onChange={handleSavedSearchChange}
                    >
                      {savedSearchListData.map((item: any, index) => (
                        <MenuItem value={item?.criteriaGUID} >
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={2} md={1} className="Action_img_btn">
                  <img
                    className="Plus-icon wh14"
                    src={plusicon}
                    alt="Plus icon"
                    onClick={handleOpenSaveSearch}
                    id="ibPlusIcon"
                  />
                  <img
                    className={`${!selectedSavedSearch ? "disabled" : ""
                      } Delete-icon w14h18`}
                    src={deleteicon}
                    id="ibDeleteIcon"
                    alt="Delete icon"
                    onClick={() =>
                      selectedSavedSearch ? handleDelete() : null
                    }
                  />
                </Grid>
                <Grid item xs={7} sm={6} md={4}>
                  <FormControl className="w-100" size="small">
                    <InputLabel id="demo-select-small-label">Format</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="ddSoFormat"
                      value={formatFormState.format}
                      label="Format"
                      name="format"
                      onChange={handleformatChange}
                    >
                      {/* <MenuItem value="xls">Excel 2003</MenuItem> */}
                      <MenuItem value="xlsx">Excel 2007+</MenuItem>
                      <MenuItem value="csv">CSV</MenuItem>
                      {/* <MenuItem value="rtf">Rich Text Format</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={4} md={2} sm={2} lg={1}>
                  <Button
                    variant="outlined"
                    id="btnSoExport"
                    className="d-flex rounded w-100 export_btn"
                    onClick={() => {
                      handlePrint();
                    }}
                    startIcon={<UploadFileIcon />}
                  >
                    Export
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box className="d-flex flex-column w-100 border p16 mt-16 mb-16 rounded">
              <Typography variant="h6" className="header-sub-title">
                Search Criteria
                <Button onClick={toggleExpand} style={{ float: "right" }} id="btnSoExpand">
                  {expanded ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </Typography>

              {expanded && (
                <>
                  {/* <Grid
                container
                direction="row"
                spacing={2}
                className="mt-0"
                sx={{
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                }}
              >
                <Grid item lg={4} md={4} sm={12} xs={12} spacing={2}>
                  <Grid container spacing={2} className="pb-16">
                    <Grid item lg={12} sm={6} xs={6}>
                      item 1
                    </Grid>
                    <Grid item lg={12} sm={6} xs={6}>
                      item 2
                    </Grid>
                    <Grid item lg={12} sm={6} xs={6}>
                      item 3
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} spacing={2}>
                  <Grid container spacing={2} className="pb-16">
                    <Grid item lg={12} sm={6} xs={6}>
                      item 4
                    </Grid>
                    <Grid item lg={12} sm={6} xs={6}>
                      item 5
                    </Grid>
                    <Grid item lg={12} sm={6} xs={6}>
                      item 6
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} spacing={2}>
                  <Grid container spacing={2} className="pb-16">
                    <Grid item lg={12} sm={6} xs={6}>
                      item 4
                    </Grid>
                    <Grid item lg={12} sm={6} xs={6}>
                      item 5
                    </Grid>
                    <Grid item lg={12} sm={6} xs={6}>
                      item 6
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
                  {/* <Grid
                container
                direction="column"
                spacing={2}
                className="mt-0"
                sx={{
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                }}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2} className="pb-16">
                    <Grid item xs={6} lg={4} md={4}>
                      item 1
                    </Grid>
                    <Grid item xs={6} lg={4} md={4}>
                      item 2
                    </Grid>
                    <Grid item xs={6} lg={4} md={4}>
                      item 3
                    </Grid>
                    <Grid item xs={6} lg={4} md={4}>
                      item 4
                    </Grid>
                    <Grid item xs={6} lg={4} md={4}>
                      item 5
                    </Grid>
                    <Grid item xs={6} lg={4} md={4}>
                      item 6
                    </Grid>
                    <Grid item xs={6} lg={4} md={4}>
                      item 7
                    </Grid>
                    <Grid item xs={6} lg={4} md={4}>
                      item 8
                    </Grid>
                    <Grid item xs={6} lg={4} md={4}>
                      item 9
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Grid container spacing={2} className="pb-16"></Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Grid container spacing={2} className="pb-16"></Grid>
                </Grid>
              </Grid> */}
                  <Grid container spacing={2} className="mt-0">
                    <Grid item lg={4} md={4} sm={12} xs={12} spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Grid container spacing={2} className="pb-16">
                          <Grid item xs={6} md={6}>
                            <div className="small-cal-view">
                              <CustomDatePicker
                                id="txtSoStartDate"
                                handleDateChange={handleTextFieldChange as any}
                                label="Start Date"
                                name="startDate"
                                value={searchFormState?.startDate || ""}
                                isClearable={true}
                                minDate={minDate}
                              />
                            </div>
                            {/* <TextField
                              size="small"
                              className="w-100"
                              type="date"
                              label="Start Date"
                              name="startDate"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              value={searchFormState?.startDate || ""}
                              onChange={handleTextFieldChange}
                              inputProps={{ min: minDate }}
                            /> */}
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <div className="small-cal-view">
                              <CustomDatePicker
                                id="txtSoEndDate"
                                handleDateChange={handleTextFieldChange as any}
                                label="End Date"
                                name="endDate"
                                value={searchFormState?.endDate || ""}
                                isClearable={true}
                                minDate={minDate}
                              />
                            </div>
                            {/* <TextField
                              size="small"
                              type="date"
                              label="End Date"
                              name="endDate"
                              variant="outlined"
                              className="w-100"
                              InputLabelProps={{ shrink: true }}
                              value={searchFormState?.endDate || ""}
                              onChange={handleTextFieldChange}
                              inputProps={{ min: minDate }}
                            /> */}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} className="pb-16">
                        <FormControl size="small" fullWidth>
                          <InputLabel id="demo-select-small-label">
                            Select Account
                          </InputLabel>
                          <Select
                            className="w-100"
                            labelId="demo-select-small-label"
                            id="ddSoAccountNumber"
                            value={searchFormState?.accountNumber}
                            label="Select Account"
                            name="accountNumber"
                            onChange={handleSelectChange}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },

                              PaperProps: {
                                style: menuStyles,
                              },
                            }}
                          >
                            <MenuItem value="All">All</MenuItem>
                            {accounts.length > 0 &&
                              accounts.map((account: any) => (
                                <MenuItem
                                  key={account.userAccountRowGUID}
                                  value={account.accountNumber}
                                >
                                  {account.displayName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid container spacing={2} className="pb-16">
                        <Grid item xs={6} sm={6} md={12}>
                          <TextField
                            variant="outlined"
                            id="txtSoTrackingNumber"
                            label="Airbill #/Order #"
                            name="trackingNumber"
                            className="w-100"
                            size="small"
                            value={searchFormState?.trackingNumber}
                            onChange={handleTextFieldChange}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={12}>
                          <TextField
                            variant="outlined"
                            id="txtSoContents"
                            label="Contents"
                            name="contents"
                            className="w-100"
                            size="small"
                            value={searchFormState?.contents}
                            onChange={handleTextFieldChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6}>
                            <TextField
                              variant="outlined"
                              label="Invoice #"
                              name="invoiceNumber"
                              className="w-100"
                              size="small"
                              id="txtSoInvoiceNumber"
                              value={searchFormState.invoiceNumber}
                              onChange={handlenumericFieldChange}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <FormControl className="w-100" size="small">
                              <InputLabel id="demo-select-small-label">
                                Status
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                id="ddSoOrderOfStatus"
                                className="w-100"
                                value={searchFormState?.orderStatus}
                                label="Select Account"
                                name="orderStatus"
                                onChange={handleSelectChange}
                                sx={{ width: isMobile ? "100%" : undefined }}
                              >
                                <MenuItem value="ORD" id="liSoAllOrders">All Orders</MenuItem>
                                <MenuItem value="ACT" id="liSoActive">Active</MenuItem>
                                <MenuItem value="DEL" id="liSoDelivered">Delivered</MenuItem>
                                <MenuItem value="BIL" id="liSoBilled">Billed</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} spacing={2}>
                      <Grid item className="pb-16">
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={4} sm={6} lg={4}>
                            <TextField
                              id="txtSoPickupCityName"
                              size="small"
                              className="w-100"
                              label="Pickup City"
                              name="pickupCityName"
                              variant="outlined"
                              value={searchFormState?.pickupCityName}
                              onChange={handleTextFieldChange}
                            />
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <TextField
                              label="Pickup State"
                              id="txtSoPickupState"
                              variant="outlined"
                              className="w-100"
                              size="small"
                              value={searchFormState?.pickupStateProvId}
                              name="pickupStateProvId"
                              onChange={handleTextFieldChange}
                            />
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <TextField
                              label="Pickup Zip"
                              id="txtSoPickupPostalCode"
                              variant="outlined"
                              className="w-100"
                              size="small"
                              value={searchFormState?.pickupPostalCode}
                              name="pickupPostalCode"
                              onChange={handleTextFieldChange}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={12} lg={12}>
                            <FormControl className="w-100" size="small">
                              <InputLabel id="demo-select-small-label">
                                Pickup Country
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                value={searchFormState?.pickupCountryId}
                                label="Select Account"
                                id="ddSoPickupCountry"
                                name="pickupCountryId"
                                onChange={handleSelectChange}
                                sx={{ width: isMobile ? "100%" : undefined }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },

                                  PaperProps: {
                                    style: menuStyles,
                                  },
                                }}
                              >
                                <MenuItem value="All">All</MenuItem>
                                {countryData.length > 0 &&
                                  countryData.map((country: any) => (
                                    <MenuItem
                                      key={country.countryID}
                                      value={country.countryID}
                                    >
                                      {country.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={4} lg={4} sm={6}>
                            <TextField
                              size="small"
                              id="txtSoDeliveryCityName"
                              label="Del. City"
                              variant="outlined"
                              value={searchFormState?.deliveryCityName}
                              name="deliveryCityName"
                              onChange={handleTextFieldChange}
                              className="w-100"
                            />
                          </Grid>
                          <Grid item xs={6} md={4} sm={6} lg={4}>
                            <TextField
                              size="small"
                              label="Del. State"
                              variant="outlined"
                              id="txtSoDeliveryState"
                              value={searchFormState?.deliveryStateProvId}
                              name="deliveryStateProvId"
                              onChange={handleTextFieldChange}
                              className="w-100"
                            />
                          </Grid>
                          <Grid item xs={6} md={4} sm={6} lg={4}>
                            <TextField
                              size="small"
                              label="Del. Zip"
                              variant="outlined"
                              id="txtSoDeliveryPostalCode"
                              value={searchFormState?.deliveryPostalCode}
                              name="deliveryPostalCode"
                              onChange={handleTextFieldChange}
                              className="w-100"
                            />
                          </Grid>
                          <Grid item xs={6} md={12} sm={6}>
                            <FormControl className="w-100" size="small">
                              <InputLabel id="demo-select-small-label">
                                Del. Country
                              </InputLabel>
                              <Select
                                labelId="demo-select-small-label"
                                id="ddSoDeliveryCountry"
                                value={searchFormState?.deliveryCountryId}
                                label="Select Account"
                                name="deliveryCountryId"
                                onChange={handleSelectChange}
                                sx={{ width: isMobile ? "100%" : undefined }}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },

                                  PaperProps: {
                                    style: menuStyles,
                                  },
                                }}
                              >
                                <MenuItem value="All">All</MenuItem>
                                {countryData.length > 0 &&
                                  countryData.map((country: any) => (
                                    <MenuItem
                                      key={country.countryID}
                                      value={country.countryID}                 
                                    >
                                      {country.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} md={12} sm={6} lg={12}>
                            <TextField
                              id="txtSoOrderByName"
                              variant="outlined"
                              label="Caller"
                              name="orderByName"
                              className="w-100"
                              size="small"
                              value={searchFormState?.orderByName}
                              onChange={handleTextFieldChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} sm={12} spacing={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={12} className="pb-0">
                          <TextField
                            id="txtSoReference"
                            label={
                              defaultDatas?.referenceLabel != null
                                ? defaultDatas?.referenceLabel
                                : defaultDatas?.referencetypeName != null
                                  ? defaultDatas?.referencetypeName
                                  : "Refer"
                            }
                            variant="outlined"
                            className="w-100"
                            size="small"
                            value={searchFormState?.reference}
                            name="reference"
                            onChange={handleTextFieldChange}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={12}
                          className={
                            defaultDatas?.referenceLabel2 !== null ||
                              defaultDatas?.referencetypeName2 !== null
                              ? "pb-0"
                              : "hidden"
                          }
                        >
                          {defaultDatas?.referenceLabel2 !== null ||
                            defaultDatas?.referencetypeName2 != null ? (
                            <TextField
                              label={
                                defaultDatas?.referenceLabel2 != null
                                  ? defaultDatas?.referenceLabel2
                                  : defaultDatas?.referencetypeName2
                              }
                              variant="outlined"
                              className="w-100"
                              size="small"
                              id="txtSoReference2"
                              value={searchFormState?.reference2}
                              name="reference2"
                              onChange={handleTextFieldChange}
                            />
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={12}
                          className={
                            defaultDatas?.referenceLabel3 !== null ||
                              defaultDatas?.referencetypeName3 !== null
                              ? "pb-0"
                              : "hidden"
                          }
                        >
                          {defaultDatas?.referenceLabel3 !== null ||
                            defaultDatas?.referencetypeName3 != null ? (
                            <TextField
                              label={
                                defaultDatas?.referenceLabel3 != null
                                  ? defaultDatas?.referenceLabel3
                                  : defaultDatas?.referencetypeName3
                              }
                              id="txtSoReference3"
                              variant="outlined"
                              className="w-100"
                              size="small"
                              value={searchFormState?.reference3}
                              name="reference3"
                              onChange={handleTextFieldChange}
                            />
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={12}
                          className={
                            defaultDatas?.referenceLabel4 !== null ||
                              defaultDatas?.referencetypeName4 !== null
                              ? "pb-0"
                              : "hidden"
                          }
                        >
                          {defaultDatas?.referenceLabel4 !== null ||
                            defaultDatas?.referencetypeName4 != null ? (
                            <TextField
                              label={
                                defaultDatas?.referenceLabel4 != null
                                  ? defaultDatas?.referenceLabel4
                                  : defaultDatas?.referencetypeName4
                              }
                              id="txtSoReference4"
                              variant="outlined"
                              className="w-100"
                              size="small"
                              value={searchFormState?.reference4}
                              name="reference4"
                              onChange={handleTextFieldChange}
                            />
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={12}
                          className={
                            defaultDatas?.referenceLabel5 !== null ||
                              defaultDatas?.referencetypeName5 !== null
                              ? "pb-0"
                              : "hidden"
                          }
                        >
                          {defaultDatas?.referenceLabel5 !== null ||
                            defaultDatas?.referencetypeName5 != null ? (
                            <TextField
                              label={
                                defaultDatas?.referenceLabel5 != null
                                  ? defaultDatas?.referenceLabel5
                                  : defaultDatas?.referencetypeName5
                              }
                              id="txtSoReference5"
                              variant="outlined"
                              className="w-100"
                              size="small"
                              value={searchFormState?.reference5}
                              name="reference5"
                              onChange={handleTextFieldChange}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="mt-8">
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                        },
                      }}
                    ></Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                        },
                      }}
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      sx={{
                        paddingLeft: {
                          xs: 0,
                          sm: 0,
                          md: 2,
                          lg: 2,
                        },
                        paddingTop: "16px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={handleSearch}
                        className="w-100 SearchBtn"
                        id="btnSoSearch"
                      >
                        <SearchOutlinedIcon className="Search-icon" />
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
            <div className="d-flex align-items-center">
              <div className="content pb-15 active-order">
                <div className="subtitle"></div>
              </div>
            </div>
            {!isMobile && (
              <div>
                <Grid container>
                  <Grid item xs={6} md={4}>
                    <FormControl
                      className="w-100"
                      size="small"
                      sx={{ width: isMobile ? 320 : 295 }}
                    >
                      <InputLabel id="demo-select-small-label">
                        More Report Options
                      </InputLabel>
                      <Droppable droppableId="select">
                        {(provided: any) => (
                          <Select
                            id="ddSoMoreReport"
                            label="More Report Options"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            multiple
                            value={selectItems.map((item) => item.id)}
                            sx={{ width: isMobile ? "100%" : undefined }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },

                              PaperProps: {
                                style: menuStyles,
                              },
                            }}
                          // renderValue={(selected: any) => selected.join(", ")}
                          >
                            {moreReportOptionsData.map(
                              (item: any, index: any) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <MenuItem
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      value={item.id}
                                    >
                                      {/* {item.label} */}
                                      <TableSortLabel
                                        active={orderBy === item.id}
                                        direction={
                                          orderBy === item.id ? order : "asc"
                                        }
                                        onClick={createSortHandler(item.id)}
                                      >
                                        {item.label}
                                        {orderBy === item.id ? (
                                          <Box
                                            component="span"
                                            sx={visuallyHidden}
                                          >
                                            {order === "desc"
                                              ? "sorted descending"
                                              : "sorted ascending"}
                                          </Box>
                                        ) : null}
                                      </TableSortLabel>
                                    </MenuItem>
                                  )}
                                </Draggable>
                              )
                            )}
                            {provided.placeholder}
                          </Select>
                        )}
                      </Droppable>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            <Box sx={{ width: "100%" }}>
              <Paper className="ActiveOrder" sx={{ width: "100%", mb: 2 }}>
                <TableContainer className="table-container">
                  {/* Add class name for styling */}
                  <Table aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={searchOrderData.length}
                    />
                    <TableBody>
                      {searchOrderData.length === 0 ? (
                        <TableRow className="Nodata_found" id="trSearchOrderDataNotFound">
                          <TableCell
                            className=""
                            colSpan={gridItems.length}
                            align="center"
                            id="tdDataNotFound"
                          >
                            <Box className="d-flex flex-column w-100 border p16 rounded tes">
                              {notData}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : (
                        searchOrderData.map(
                          (row: SearchOrderItemDto, index: any) => {
                            return isMobile ? (
                              <>
                                <MobileCardView
                                  key={row.shipmentGUID}
                                  row={row}
                                  gridItems={gridItems}
                                />
                              </>
                            ) : (
                              <TableRow hover key={row.shipmentGUID} id="trSearchOrder">
                                {gridItems.map((column: any) => (
                                  <TableCell
                                    key={column.id}
                                    style={{ minWidth: 100 }}
                                    id={`td${toPascalCase(column.id)}`}
                                  >
                                    <RenderCellData
                                      columnId={column.id}
                                      row={row}
                                    />
                                  </TableCell>
                                ))}
                              </TableRow>
                            );
                          }
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* {!isLoading && searchOrderData.length > 0 && ( */}
                <CommonPagination
                  count={totalRecord}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {/* )} */}

                <SaveSearchPromptDialog
                  open={promptOpen}
                  title="Enter the Name for the Saved Search"
                  defaultValue=""
                  onClose={handlePromptClose}
                  savedSearchError={savedSearchError}
                />
              </Paper>
            </Box>
          </>
        </div>
      </DragDropContext>
    </>
  );
};

// Mobile card view component
const MobileCardView: React.FC<{ row: SearchOrderItemDto; gridItems: any }> = ({
  row,
  gridItems,
}) => {
  return (
    <Paper
      className="Card_view"
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
    >
      {gridItems.map((column: any) => (
        <>
          <RenderMobileCellData columnId={column.id} row={row} />
        </>
      ))}
    </Paper>
  );
};

export default SearchOrders;
