import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { importOrders } from "./import-order-reducer";

const useImportOrderLogic = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state: RootState) => state.auth);

  const handleImport = async (param: {
    CompanyId?: number | null;
    UserId?: number | null;
    UserGUID?: string | null;
    MainFile?: File | undefined;
    File?: string | null;
    FileName?: string | null;
    FileExt?: string | null;
    FilePath?: string | null;
    ImportRunGUID?: string | null;
    SizeUOM?: string;
    WeightUOM?: string;
  }) => {
    try {
      const response = await dispatch(importOrders(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    loading,
    error,
    handleImportFile: handleImport,
  };
};
export default useImportOrderLogic;
