import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { MySettingDto } from "../../../models/my-setting-dto";
import { CountryItemDto } from "../../../models/country-item-dto";
import useZipCodeLogic from "../../lookup/zip-code/zip-code.logic";
import { ZipCodeItemDto } from "../../../models/zip-code-item-dto";
import { extractTime } from "../../util/numeric-value";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DefaultPickupDetailsProps {
  mySetting: MySettingDto;
  handleInputChange: (updatedData: any) => void;
  countryData: CountryItemDto[];
  SetPickupZipCodeError: (errors: any) => void;
  pickupZipCodeError: any;
  setSuccessMessage: (success: any) => void;
  formErrors: any;
}

const DefaultPickupDetails: React.FC<DefaultPickupDetailsProps> = ({
  mySetting,
  handleInputChange,
  countryData,
  SetPickupZipCodeError,
  pickupZipCodeError,
  setSuccessMessage,
  formErrors,
}) => {
  const { zipCode } = useZipCodeLogic();
  const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);
  const [showAddress3, setShowAddress3] = useState(false); // New state for visibility
  const [cityName, setCity] = useState(mySetting?.pickupCityName);

  useEffect(() => {
    getZipCode();
  }, []);

  useEffect(() => {
    if (!mySetting.pickupPostalCode) {
      setZipCodeData([]);
    }
  }, [mySetting.pickupPostalCode]);

  const showAddress3Visibility = () => {
    setShowAddress3(true); // Toggle visibility
  };

  const menuStyles = {
    maxHeight: "200px", // Default max height for desktop
  };

  useEffect(() => {
    if (mySetting.pickupAddress3) {
      setShowAddress3(true); // Toggle visibility
    }
  }, [mySetting.pickupAddress3]);

  const getZipCode = async () => {
    try {
      const param = {
        postalCode: mySetting.pickupPostalCode || "",
        countryID: mySetting.pickupCountryID || "",
      };
      if (!param.postalCode) {
        return;
      }
      const response = await zipCode(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        if (res.length > 0) {
          setZipCodeData(res);
          return res;
        } else {
          setZipCodeData([]);
          if (
            mySetting?.pickupPostalCode &&
            mySetting.pickupCountryID != "AE"
          ) {
            setSuccessMessage("");
            SetPickupZipCodeError(true);
          }
          return [];
        }
      }
    } catch (err) {
      setZipCodeData([]);
    }
  };

  const getZipCodeWithCountry = async (countryID: string) => {
    try {
      const param = {
        postalCode: mySetting.pickupPostalCode || "",
        countryID: countryID || "",
      };
      if (!param.postalCode) {
        return;
      }
      const response = await zipCode(param);
      const res = response.data.data;
      setZipCodeData(res);
      if (res) {
        SetPickupZipCodeError(false);
        // const res = await getZipCode(); // Wait for getZipCode to complete
        if (res.length > 0) {
          handleInputChange({
            target: { name: "pickupCityName", value: res[0].cityName },
          } as any);
          handleInputChange({
            target: { name: "pickupAirportID", value: res[0].airportID },
          } as any);
          handleInputChange({
            target: { name: "pickupStateProvID", value: res[0].stateProvID },
          } as any);
        } else {
          if (
            mySetting?.pickupPostalCode &&
            mySetting.pickupCountryID != "AE"
          ) {
            setSuccessMessage("");
            SetPickupZipCodeError(true);
          }
          handleInputChange({
            target: { name: "pickupCityName", value: "" },
          } as any);
          handleInputChange({
            target: { name: "pickupAirportID", value: "" },
          } as any);
          handleInputChange({
            target: { name: "pickupStateProvID", value: "" },
          } as any);
          setCity("");
        }
      }
    } catch (err) {
      setZipCodeData([]);
    }
  };

  const handleZipCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Tab") {
      const res = await getZipCode(); // Wait for getZipCode to complete
      if (res && res.length > 0) {
        handleInputChange({
          target: { name: "pickupCityName", value: res[0].cityName },
        } as any);
        handleInputChange({
          target: { name: "pickupAirportID", value: res[0].airportID },
        } as any);
        handleInputChange({
          target: { name: "pickupStateProvID", value: res[0].stateProvID },
        } as any);
      } else {
        handleInputChange({
          target: { name: "pickupCityName", value: "" },
        } as any);
        handleInputChange({
          target: { name: "pickupAirportID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "pickupStateProvID", value: "" },
        } as any);
      }
    }
  };

  const handleZipCodeFocusOut = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const res = await getZipCode(); // Wait for getZipCode to complete
    if (res) {
      SetPickupZipCodeError(false);
      // const res = await getZipCode(); // Wait for getZipCode to complete
      if (res && res.length > 0) {
        handleInputChange({
          target: { name: "pickupCityName", value: res[0].cityName },
        } as any);
        handleInputChange({
          target: { name: "pickupAirportID", value: res[0].airportID },
        } as any);
        handleInputChange({
          target: { name: "pickupStateProvID", value: res[0].stateProvID },
        } as any);
      } else {
        if (mySetting?.pickupPostalCode && mySetting.pickupCountryID != "AE") {
          setSuccessMessage("");
          SetPickupZipCodeError(true);
        }
        handleInputChange({
          target: { name: "pickupCityName", value: "" },
        } as any);
        handleInputChange({
          target: { name: "pickupAirportID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "pickupStateProvID", value: "" },
        } as any);
      }
    }
  };

  const handleBlurInputChange = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    handleInputChange({
      target: { name, value: value }, // Set trimmed value
    } as any);
  };

  const handleCityInputChange = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    // Logic to set value based on focus out
    setCity(value);
  };

  const handleCountryInputChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    handleInputChange({
      target: { name, value },
    } as any);
    getZipCodeWithCountry(value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="txtPickupCompanyName"
            name="pickupCompanyName"
            label="Company Name"
            defaultValue={mySetting.pickupCompanyName}
            onChange={handleInputChange}
            variant="outlined"
            className="w-100"
            size="small"
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="txtPickupAddress1"
            name="pickupAddress1"
            defaultValue={mySetting.pickupAddress1}
            label="Address Line 1"
            onChange={handleInputChange}
            variant="outlined"
            className="w-100"
            size="small"
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Box className="d-flex align-center gap-8">
            <TextField
              id="txtPickupAddress2"
              style={{ width: !showAddress3 ? "90%" : "100%" }}
              variant="outlined"
              label="Address Line 2 (Optional)"
              name="pickupAddress2"
              defaultValue={mySetting.pickupAddress2}
              size="small"
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {!showAddress3 && (
              <IconButton onClick={showAddress3Visibility} id="btnLookupAdd">
                <AddIcon className="Add-icon" />
              </IconButton>
            )}
          </Box>
        </Grid>
        {showAddress3 && (
          <Grid item lg={12} md={12} xs={12}>
            <TextField
              id="txtPickupAddress3"
              name="pickupAddress3"
              label="Address Line 3 (Optional)"
              defaultValue={mySetting.pickupAddress3}
              onChange={handleInputChange}
              variant="outlined"
              className="w-100"
              size="small"
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        )}
        <Grid item lg={6} md={6} xs={6}>
          {mySetting?.pickupCountryID != "US" &&
          (zipCodeData.length < 1 ||
            zipCodeData.filter((s) => s.cityName !== null).length < 1) ? (
            <TextField
              size="small"
              id="txtPickupCityName"
              variant="outlined"
              label="City"
              name="pickupCityName"
              // defaultValue={mySetting.pickupCityName}
              value={cityName}
              // InputLabelProps={{ shrink: !!mySetting.pickupCityName }}
              onChange={handleCityInputChange}
              onBlur={handleBlurInputChange}
              className="w-100"
            />
          ) : (
            <FormControl size="small" className="w-100">
              <InputLabel
                id="demo-select-small-label"
                shrink={!!mySetting.pickupCityName}
              >
                City
              </InputLabel>
              <Select
                label="City"
                id="ddPickupCityName"
                name="pickupCityName"
                fullWidth
                defaultValue={mySetting.pickupCityName}
                value={mySetting.pickupCityName}
                onChange={handleInputChange}
              >
                {zipCodeData.length > 0 &&
                  zipCodeData.map((city: any) => (
                    <MenuItem key={city.cityName} value={city.cityName}>
                      {city.cityName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item lg={6} md={6} xs={6}>
          <TextField
            size="small"
            id="txtPickupState"
            variant="outlined"
            label="State"
            name="pickupStateProvID"
            defaultValue={mySetting.pickupStateProvID}
            value={mySetting.pickupStateProvID}
            InputLabelProps={{ shrink: !!mySetting.pickupStateProvID }}
            onChange={handleInputChange}
            inputProps={{ maxLength: 3 }}
            className="w-100"
          />
        </Grid>
        <Grid item lg={6} md={6} xs={6}>
          <TextField
            size="small"
            variant="outlined"
            id="txtPickupPostalCode"
            name="pickupPostalCode"
            defaultValue={mySetting.pickupPostalCode}
            onChange={handleInputChange}
            onKeyDown={handleZipCodeKeyDown}
            onBlur={handleZipCodeFocusOut}
            error={pickupZipCodeError && mySetting.pickupCountryID === "US"}
            label="ZIP"
            className="w-100"
            inputProps={{ maxLength: 10 }}
          />
        </Grid>

        <Grid item lg={6} md={6} xs={6}>
          <FormControl size="small" className="w-100">
            <InputLabel id="demo-select-small-label">Country</InputLabel>
            <Select
              id="ddPickupCountry"
              name="Country"
              defaultValue={mySetting.pickupCountryID}
              onChange={handleCountryInputChange}
              label="Country"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                PaperProps: {
                  style: menuStyles,
                },
              }}
            >
              {countryData.length > 0 &&
                countryData.map((country: any) => (
                  <MenuItem key={country.countryID} value={country.countryID}>
                    {country.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={6} md={6} xs={6}>
          <TextField
            variant="outlined"
            label="SEE"
            size="small"
            id="txtPickupPersonToSee"
            className="w-100"
            name="pickupPersonToSee"
            defaultValue={mySetting.pickupPersonToSee}
            onChange={handleInputChange}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={6}>
          <TextField
            variant="outlined"
            size="small"
            label="Airport"
            id="txtPickupAirport"
            name="pickupAirportID"
            className="w-100"
            defaultValue={mySetting.pickupAirportID}
            value={mySetting.pickupAirportID}
            onChange={(e) => {
              // const re = /^[a-zA-Z\s]*$/;
              const value = e.target.value;
              const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
              handleInputChange({
                target: { name: "pickupAirportID", value: filteredValue },
              } as any);
            }}
            inputProps={{ maxLength: 3 }}
            InputLabelProps={{ shrink: !!mySetting.pickupAirportID }}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            className="w-100"
            variant="outlined"
            id="txtPickupInstructions"
            label="Instructions"
            inputProps={{ maxLength: 2000 }}
            name="pickupInstructions"
            defaultValue={mySetting.pickupInstructions}
            onChange={handleInputChange}
            placeholder="Enter any additional instructions"
            multiline
            rows={4}
            error={!!formErrors.pickupInstructions}
          />
        </Grid>
      </Grid>
      <Typography className="sub_header pt-8 pb-8 mb-8 mt-16 pl-0">
        Default Pickup Contact
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} xs={6}>
          <TextField
            id="txtPickupPhoneNumber"
            name="pickupPhoneNumber"
            label="Phone"
            defaultValue={mySetting.pickupPhoneNumber}
            onChange={handleInputChange}
            variant="outlined"
            className="w-100"
            size="small"
            inputProps={{ maxLength: 25 }}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={6}>
          <TextField
            id="txtFaxNumber"
            name="pickupFaxNumber"
            label="Fax"
            defaultValue={mySetting.pickupFaxNumber}
            onChange={handleInputChange}
            variant="outlined"
            className="w-100"
            size="small"
            inputProps={{
              maxLength: 25,
            }}
          />
        </Grid>
        <Grid item lg={4} md={4} xs={6}>
          <TextField
            id="txtPickupEmail"
            name="pickupEmailAddress"
            label="Email"
            defaultValue={mySetting.pickupEmailAddress}
            onChange={handleInputChange}
            variant="outlined"
            className="w-100"
            size="small"
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
      </Grid>
      <Typography className="sub_header pt-8 pb-8 mb-8 mt-16 pl-0">
        Default Ready Time
      </Typography>
      <Grid lg={4} md={4} sm={6}>
        <DatePicker
          id="txtReadyDate"
          disabled={mySetting.pickupReadyNow}
          selected={
            mySetting.pickupReadyTime
              ? new Date(mySetting.pickupReadyTime)
              : null
          }
          onChange={(val: any) => {
            handleInputChange({
              target: {
                name: "pickupReadyTime",
                value: val,
              },
            });
          }}
          placeholderText="hh:mm AM/PM"
          name="pickupReadyTime"
          //isClearable={true}
          showTimeSelect
          showTimeSelectOnly
          popperClassName="custom-datepicker-popper"
          popperPlacement="bottom-start"
          timeIntervals={15}
          timeCaption="Ready Time"
          dateFormat="hh:mm aa"
          className="w-100"
          customInput={
            <TextField
              size="small"
              label="Ready Time"
              variant="outlined"
              id="txtMsPickupReadyTime"
              className="w-100"
              value={
                mySetting.pickupReadyTime
                  ? extractTime(mySetting.pickupReadyTime)
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccessTimeIcon style={{ width: "16px", height: "16px" }} />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
        {/* <TextField
          size="small"
          id="time-picker"
          label="Ready Time"
          type="time"
          variant="outlined"
          className="w-100"
          name="pickupReadyTime"
          disabled={mySetting.pickupReadyNow}
          defaultValue={extractTime(mySetting.pickupReadyTime)}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccessTimeIcon style={{ width: "16px", height: "16px" }} />
              </InputAdornment>
            ),
          }}
        /> */}
      </Grid>
      <Grid lg={12} sm={12} md={12} xs={12}>
        <FormControlLabel
          className="chk_bx_50 pr-8 pt-8"
          control={
            <Checkbox
              name="pickupReadyNow"
              defaultChecked={mySetting.pickupReadyNow}
              onChange={handleInputChange}
              id="cbReadyNow"
            />
          }
          label="Ready Now"
        />
      </Grid>
    </>
  );
};

export default DefaultPickupDetails;
